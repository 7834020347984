import React, { Component } from 'react';
import BaseInput from 'components/BaseInput';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { successConfirm } from "components/SuccessConfirm/index";
import { saveProfileChanges } from '../../../redux/actions/accountActions';

class InvoiceEmail extends Component
{
    state = {
        email: ''
    }

    componentDidMount() {
        const { user } = this.props.account.loginStatus;
        if (user.profile.invoice_email && user.profile.invoice_email.length) {
            this.setState({ email: user.profile.invoice_email });
            return;
        }
    }

    emailChanged = (event) => {
        this.setState({ email: event.target.value });
    }

    saveClicked = () => {
        const { email } = this.state;
        const { loginStatus } = this.props.account;

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            return;
        }
    
        const apiUrl = process.env.NODE_ENV === 'production' ?
            (
                process.env.REACT_APP_SERVER_MODE == 'LIVE' ?
                process.env.REACT_APP_BACKEND_LIVE :
                process.env.REACT_APP_BACKEND_TEST
            ) :
            process.env.REACT_APP_DEV_BACKEND;

        fetch(apiUrl + "api/admin/invoice_email", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            }),
        })
        .then(res => res.json())
        .then(result => {
            if (result['status']) {
                successConfirm('Email Updated!', `You will receive invoices through ${email}`, false);
                this.props.saveProfileChanges({
                    'invoice_email': email
                });
            }

            return result;
        });
    }

    render() {
        const { email } = this.state;

        return (
            <div style={{ textAlign: 'center', background: '#F3F2FF', paddingTop: 30, }}>
                <div style={{ 
                    width: 600,
                    borderRadius: 7,
                    border: '1px solid rgba(18, 145, 249, 0.18)',
                    background: 'white',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingTop: 50,
                    paddingBottom: 30,
                    minHeight: 300,
                }}>
                    <div style={{ fontSize: 20, color: '#0072BB', marginBottom: 30 }}>
                        Set up an email to receive invoices.
                    </div>
                    <div>
                        <BaseInput style={{ width: 300 }} type="email" value={ email } onChange={ this.emailChanged }  />
                    </div>
                    <Button 
                        style={{ background: '#0072BB', color: 'white', width: 120, height: 38, borderRadius: 20, marginTop: 30,  }}
                        onClick={ this.saveClicked }
                    >
                        Save
                    </Button>
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = { saveProfileChanges };

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEmail);