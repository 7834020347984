import React, { Component } from 'react'
import { connect } from 'react-redux';
import BaseInput from 'components/BaseInput/index.jsx';
import Button from 'components/Button/index.jsx';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import CategoriesPageStyle from './CategoriesPageStyle.jsx';
import { confirm } from "../../../components/Confirm/index";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { addCategory, getCategories, deleteCategory } from '../../../redux/actions/adminActions';
import { getGlobalInitialData } from "redux/actions/commonActions";

import { withTranslation  } from 'react-i18next';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

class CategoriesPage extends Component
{
    state = {
        eventAddClicked: false,
        eventAddModalOpen: false,
        categList: [],
        editCategData: {
            id: 0,
            level: 1,
            name: '',
            upper_id: 1,
        }
    }

    constructor(props){
        super(props);
        this.loadData();
    }

    loadData = async () => {
        await this.props.getCategories();
        var res = this.props.categoryResponse.categories;
        if(res.status){
            this.setState({categList: res.categories});
        }else{
            this.setState({categList: {}});
        }
    }

    addOrUpdateCategItem = async () => {
        if (this.state.editCategData.name.trim() == "") {
            return;
        }
        await this.props.addCategory(this.state.editCategData);
        await this.props.getGlobalInitialData();
        let addResult = this.props.categoryResponse['addResult'];
        if (addResult['status']) {
            this.setState({editCategData: {
                id: 0,
                level: 1,
                name: '',
                upper_id: 1,
            }});
            this.setState({eventAddClicked: false});
            this.setState({eventAddModalOpen: false});
            this.loadData();
        } else {
            confirm("Error", "Sorry! Backend server error occured! Please try later!").then(
                async () => {
                },
                () => {
                    console.log("Click Canceled");
                }
            );
        }
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {};
        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.formData[valueType] != 'undefined' && this.state.formData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }
        this.setState({ editCategData: Object.assign(this.state.editCategData, dataOject) });
    }

    openAddCategModal = (e, isAdd, categ) => {
        e.preventDefault();
        if (categ == null) {
            this.setState({editCategData: {
                id: 0,
                level: 1,
                name: '',
                upper_id: 1,
            }});
        } else {
            this.setState({editCategData: {
                id: categ.category.id,
                level: categ.category.level,
                name: categ.category.name,
                upper_id: categ.category.upper_id,
            }});
        }
        this.setState({eventAddClicked: true});
        this.setState({eventAddModalOpen: true});
    }

    closeAddEventModal = () => {
        this.setState({eventAddClicked: false});
        this.setState({eventAddModalOpen: false});
    }

    // User Detail Dialog
    showAddEventModal = () => {
        const { classes, t } = this.props;
        if (!this.state.eventAddClicked) return null;

        let title = this.state.editCategData.id == 0 ? 'Add Category' : 'Update Category';

        return (
            <Dialog
                open={this.state.eventAddModalOpen}
                onClose={this.closeUserDetail}
                className={classes.addCategDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="form-dialog-title">{ t(title) }</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        { this.showAddEventModalInputLabel() }
                        <Grid item xs={12}>
                            <BaseInput type="text" className={classes.inputName} onChange={e => this.handleChangeValue(e, 'name')} value={this.state.editCategData.name} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.addOrUpdateCategItem} className={classes.btnSave}>
                        {t('Save')}
                    </Button>
                    <Button onClick={this.closeAddEventModal} className={classes.btnCancel}>
                        {t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    showAddEventModalInputLabel = () => {
        const { t } = this.props;

        if (this.state.editCategData.id == 0) {
            return (
                <Grid item xs={12}>
                    {t('Enter Category Name')} :
                </Grid>
            );
        }
        return null;
    }


    // User approve
    actionEditEvent = async (e, event) => {
        this.setState({editCategData: event});
        this.setState({eventAddClicked: true});
        this.setState({eventAddModalOpen: true});
    }

    // Categ delete
    actionUpdateSubCateg = async (e, categ) => {
        if (categ.category.name.trim().length < 1) {
            return;
        }
        await this.props.addCategory(categ.category);
        await this.props.getGlobalInitialData();
        this.loadData();
    }

    // Categ delete
    actionDeleteMainCateg = async (e, categ) => {
        const { t } = this.props;
        console.log('ffffffffffffffffff', categ);
        confirm("Confirm", t('Are you sure delete this categ') + " \"" + categ.category.name + "\" " + t('and sub-categories of it?')).then(
            async () => {
                console.log("Click OK");
                await this.props.deleteCategory(categ.category.id);
                await this.props.getGlobalInitialData();
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    // Categ delete
    actionDeleteCateg = async (e, categ) => {
        const { t } = this.props;

        confirm("Confirm", t('Are you sure delete this categ') + " \"" + categ.category.name + "\"?").then(
            async () => {
                await this.props.deleteCategory(categ.category.id);
                await this.props.getGlobalInitialData();
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    editSubCategItem = async (e, categ, upperInd, subInd) => {
        let categList = this.state.categList;
        categList[upperInd]['sub_categories'][subInd]['category']['edit'] = true;
        this.setState({categList: categList});
    }

    handleSubCategChange = (e, categ, upperInd, subInd) => {
        let value = e.target.value;
        let categList = this.state.categList;
        categList[upperInd]['sub_categories'][categ['id'] == 0 ? 'new' : subInd]['category']['name'] = value;
        this.setState({categList: categList});
    }



    addSubCategItem = async (e, categ, upperInd) => {
        let categList = this.state.categList;
        let categId = categ['category']['id'];
        console.log(categId);
        categList[upperInd]['sub_categories'].push({
            category: {
                id: 0,
                edit: true,
                level: 2,
                name: "",
                upper_id: categId
            },
            sub_categories: {}
        });
        this.setState({categList: categList});
    }

    showCategoryList = () => {
        const { classes } = this.props;
        var categList = this.state.categList;
        return categList.map((categ, key) => {
            return(
                <Grid key={key} item xs={12} className={ classes.cardWrapper }>
                    <div className={ classes.tableHeader }>
                        <div className={ classes.tableHeaderLabel }>
                            { categ.category.name }
                            <span>&nbsp;</span>
                        </div>
                        <div className={ classes.tableHeaderAction }>
                            <Button onClick={ e => this.openAddCategModal(e, categ) }>
                                <img src="/assets/img/blue-pen.svg" />
                            </Button>
                            <Button onClick={ e => this.actionDeleteMainCateg(e, categ) }>
                                <img src="/assets/img/blue-trash.svg" />
                            </Button>
                            <AddCircleIcon onClick={ e => this.addSubCategItem(e, categ, key) } style={{'cursor': 'pointer'}} />
                        </div>
                    </div>
                    <div className={ classes.subCategWrapper }>
                        { this.showSubCategoryList(categ.sub_categories, key) }
                    </div>
                </Grid>
            );
        });
    }

    showSubCategoryList = (subCategs, upperInd) => {
        const { classes } = this.props;
        var categList = subCategs;
        return categList.map((categ, key) => {
            return(
                <div key={key} className={ classes.subCategItem }>
                    { this.showSubCategoryLableOrEdit(categ, upperInd, key) }
                    { this.showSubCategoryAction(categ, upperInd, key) }
                </div>
            );
        });
    }

    showSubCategoryLableOrEdit = (subCateg, upperInd, subInd) => {
        const { classes } = this.props;
        if (typeof subCateg.category['edit'] != 'undefined' && subCateg.category['edit']) {
            return (
                <span className={ classes.subCategItemContent }>
                    <input className={ classes.subCategItemContentInput }
                        type="text" value={ subCateg.category.name } onChange={ e => this.handleSubCategChange(e, subCateg, upperInd, subInd) } />
                </span>
            );
        } else {
            return (
                <span className={ classes.subCategItemContent }
                    onDoubleClick={ e => this.editSubCategItem(e, subCateg, upperInd, subInd) }
                    style={{'cursor': 'pointer'}}>
                    { subCateg.category.name }
                </span>
            );
        }
    }

    showSubCategoryAction = (subCateg, upperInd, subInd) => {
        const { classes } = this.props;
        if (typeof subCateg.category['edit'] != 'undefined' && subCateg.category['edit']) {
            return (
                <DoneIcon className={ classes.subCategItemClose } onClick={ e => this.actionUpdateSubCateg(e, subCateg) }
                    style={{color: '#1291F9', borderColor: '#1291F9'}} />
            );
        } else {
            return (
                <CloseIcon className={ classes.subCategItemClose } onClick={ e => this.actionDeleteCateg(e, subCateg) }/>
            );
        }
    }

    render(){
        const { classes } = this.props;
        return(
            <div className={ classes.tableContainer }>
                <div className={ classes.addEventWrapper }>
                    <Button onClick={ e => this.openAddCategModal(e, true, null) } className={ classes.btnAdd }>
                        Add New <AddCircleIcon />
                    </Button>
                </div>
                <Grid container spacing={0}>
                    { this.showCategoryList() }
                </Grid>
                { this.showAddEventModal() }
            </div>
        )

    }

}

const mapStateToProps = state => ({
    categoryResponse: state.categoryResponse
});
const mapDispatchToProps = {addCategory, getCategories, deleteCategory, getGlobalInitialData}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(CategoriesPageStyle)(CategoriesPage)));