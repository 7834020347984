import React from 'react';
import { useDrag } from 'react-dnd'

const Draggable = (props) => {
    const {data, children, type, onEnd} = props;

    const [{ isDragging }, drag] = useDrag({
        item: { type , ...data },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: onEnd
    });

    return (
        <div ref={drag}>
            { children }
        </div>
    )
}

export default Draggable;