import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCustomersData } from 'redux/actions/commonActions';
import { withStyles } from '@material-ui/core'
import SelectCustomerPageStyle from './SelectCustomerPageStyle.jsx'
import { userLogin, userCheck, userForgotPassword, userResetPassword } from '../../redux/actions/accountActions';

import { Link } from 'react-router-dom';

class SelectCustomerPage extends Component {

    state = {
    }

    constructor(props) {
        super(props);
        this.getInitialData();
    }

    getInitialData = () => {
        // this.props.getCustomersData();
    }

    componentDidMount() {
    }

    rendorCustomerList() {
        let customersResult = this.props.customersList.result;
        if (typeof customersResult['status'] == "undefined" || !customersResult['status']) {
            return null;
        }
        const { classes } = this.props;
        return customersResult['customers'].map((item, key) => {
            return (
                <li key={key + 1} className="customer-li">
                    <Link to={ '/login' } className={classes.footerNav}>
                        { item.name }
                    </Link>
                </li>
            )
        });

    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.pageContainer}>
                <div className={classes.container}>
                    <div className={classes.formContainer}>
                        <div className={classes.logoContainer}>
                            <img src="/assets/img/Logo_color.png" alt="Logo"/>
                        </div>
                        <h4>Thanks for choosing ipayflow, but you need a customer link from your customer</h4>
                        {/* <div className={classes.customerContainer}>
                            <ul className="customer-ul">
                                { this.rendorCustomerList() }
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    account: state.account,
    customersList: state.customersList
});

const mapDispatchToProps = {userLogin, userCheck, userForgotPassword, userResetPassword, getCustomersData}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(SelectCustomerPageStyle)(SelectCustomerPage));