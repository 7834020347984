import React, { Component } from 'react'

import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import { withStyles } from '@material-ui/core'
import userActivatePageStyle from './userActivatePageStyle.jsx'
import { Redirect  } from 'react-router-dom'
import { confirm } from "components/Confirm/index";
import { userLogin, userCheck, userForgotPassword, userResetPassword } from '../../redux/actions/accountActions';

class UserActivatePage extends Component {

    state = {
        permalink: '',
        passed: 0
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let token = this.props.match.params["token"];

        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        return fetch(apiUrl + 'api/account/activate/' + token, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                confirm("Success", "Your account is activated successfully. Admin will review and approve your account in shortly!", false).then(
                    async () => {
                        this.setState({permalink: `/${res['permalink']}`});
                        this.setState({passed: 2});
                    },
                    () => {
                    }
                );
            } else {
                confirm("Error", "This token is not valid.", false).then(
                    async () => {
                        this.setState({permalink: ''});
                        this.setState({passed: 1});
                    },
                    () => {
                    }
                );
            }
        })
        .catch(err => {
            confirm("Error", "Server error occured. Please try later!", false).then(
                async () => {
                    this.setState({passed: 1})
                },
                () => {
                }
            );
        });
    }

    renderRedirect = () => {
        if (this.state.passed != 0) {
            return <Redirect to={`/login`} />
        } else {
            return null;
        }
    }

    render(){
        const { classes } = this.props;
        return (
            <div className={classes.pageContainer}>
                {this.renderRedirect()}
                Checking now...
            </div>
        )
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = {userLogin, userCheck, userForgotPassword, userResetPassword}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(userActivatePageStyle)(UserActivatePage));