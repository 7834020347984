import React, {Component} from 'react'
import { connect } from 'react-redux';
import { Link, NavLink, Redirect  } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import InvoiceListStyle from './InvoiceListStyle.jsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Moment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

class InvoiceList extends Component
{

    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        isCollapsed: true,
        loginInfo: {
            user: {
                first_name: '',
                last_name: '',
                profile: {
                    user_type: ''
                }
            }
        }
    }
    constructor(props) {
        super(props);
        this.state.loginInfo = {...this.state.loginInfo, ...this.props['account']['loginStatus']};
    }

    handleClickHeader = () => {
        this.setState({isCollapsed: !this.state.isCollapsed});
    }

    showAttachFiles = (files) => {
        if(typeof files != 'undefined' && files != null){
            return files.map((file, key) => {
                if(file == '') return null;
                return (
                    <span key={key} style={{paddingLeft: '0.5rem'}}>
                        { this.showEachAttachFiles(file) }
                    </span>
                )
            })
        }else {
            return null;
        }
    }
    showEachAttachFiles = (file) => {
        var filebasename = file.substring(file.lastIndexOf('/') + 1);
        var filebasenameUrl = encodeURIComponent(filebasename);
        return (
            <a href={this.state.apiUrl + 'api/download/file/' + filebasenameUrl} style={{color: '#333'}} target="_blank">
                <AttachFileIcon style={{transform: 'rotate(45deg)'}} />
            </a>
        );
    }

    renderInvoices = () => {
        if(!this.state.isCollapsed)
            return null;
        var invoices = this.props.invoices;
        if(typeof invoices == 'undefined' || invoices == null) return null;

        const { classes } = this.props;
        return invoices.map((invoice, key) => {
            var files = invoice.attachments.split(',');
            var type = invoice.type;
            if(type == 'DebitNote') type = 'Debit Note'
            if(type == 'CreditNote') type = 'Credit Note'
            console.log(invoice)
            return (
                <div key={key} className={ classes.invoiceContainer }>
                    <div className={ classes.invoiceRowItem }>
                        <div className={ classes.invoiceNumberContainer } style={{ display: (invoice.approved_status == 'Approved'? 'block': 'none') }}>
                            <span style={{paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{type}</span>
                            <span>Inv#{invoice.key}</span>
                            <span style={{paddingLeft: '1rem'}}>{this.showAttachFiles(files)}</span>
                        </div>
                        <div className={ classes.invoiceNumberContainer } style={{ display: (invoice.approved_status == 'Approved'? 'none': 'block') }}>
                            <span style={{paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{type}</span>
                            <span> {invoice.approved_status}</span>
                            <span style={{ display: this.state.loginInfo['user']['profile']['user_type'] == 'BUYER' && invoice.approved_status == 'Rejected'? 'inline': 'none'}}>
                                <Link to={'/buyer/review-invoices/request/' + invoice.id}>
                                    <IconButton aria-label="Edit" color="secondary" className={classes.margin}>
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                            </span>
                            <span style={{paddingLeft: '2rem'}}>{this.showAttachFiles(files)}</span>
                        </div>
                    </div>
                    <div className={ classes.invoiceRowItem }>
                        <div className={ classes.invoiceSubNumberContainer }>
                            Ev#{invoice.eventId}
                        </div>
                        <div className={ classes.invoiceTitle }>
                            {invoice.sub_category} / {invoice.category}
                        </div>
                        <div className={ classes.invoiceRequestor }>
                            Requested { invoice.vendor.first_name + ' ' + invoice.vendor.last_name }
                        </div>
                        <div className={ classes.invoiceDate }>
                            To be paid <Moment format="Do MMM">{invoice.due_to}</Moment>
                        </div>
                        <div className={ classes.invoiceAmount }>
                            ${invoice.invoiceValue}
                        </div>
                    </div>
                </div>
            )
        });
    }

    render(){

        const {classes, iconUrl, category} = this.props;
        const {isCollapsed} = this.state;
        let dropDownIconClasses = "";
        if(isCollapsed == true)
            dropDownIconClasses = classes.dropDownIcon + " " + classes.iconTransform;
        else
            dropDownIconClasses = classes.dropDownIcon;
        return(
            <div className={classes.dropDownContainer}>
                <div className={ classes.invoices }>
                    { this.renderInvoices() }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {...state, account: state.account}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(InvoiceListStyle)(InvoiceList));