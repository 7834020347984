import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';

import { withStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from '@material-ui/core/Button';
import certificatesPageStyle from './certificatesPageStyle';

class CertificatesPage extends Component
{
    state = {
        formExpanded: false,
        canUpload: false,
        title: '',
        certificates: []
    }

    render() {
        const { classes } = this.props;
        const { formExpanded } = this.state;

        return (
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.contentContainer }>
                    <div className={ classes.pageContent }>
                        <div className={ classes.formContainer }>
                            <GridContainer>
                                <GridItem>
                                    <span className={ classes.uploadAttachment }>
                                        Uploaded Certificates of Insurance
                                    </span>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} style={{padding: '0px 40px'}}>
                                    {
                                        this.renderCertificates()
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={{ marginTop: '13px' }}>
                                <GridItem className={ classes.uploadIconContainer } xs={12}>
                                    <span className={ classes.uploadAttachment } onClick={this.uploadIconClicked}>Upload Lien Release Document</span>
                                    {
                                        !formExpanded &&
                                            <AddCircleOutlineIcon className={ classes.addIcon } onClick={this.uploadIconClicked}/>
                                    }
                                    {
                                        formExpanded &&
                                            <RemoveCircleOutlineIcon className={ classes.addIcon } onClick={this.uploadIconClicked}/>
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    {
                                        this.renderUploadCertificateForm()
                                    }
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    uploadIconClicked = () => {
        this.setState({formExpanded: !this.state.formExpanded});
    }

    renderCertificates = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const { certificates } = this.state;
        const { classes } = this.props;
        return certificates.map(certificate => {
            return (
                <div className={ classes.certificateContainer }>
                    <div>
                        {
                            certificate.title
                        }
                    </div>
                    <a className={ classes.certificateViewBtn } href={apiUrl + 'api/download/certificate/' + certificate.id}>
                        View
                    </a>
                </div>
            )
        });
    }

    renderUploadCertificateForm = () => {
        const { classes } = this.props;
        const { canUpload, formExpanded } = this.state;
        if (formExpanded) {
            return (
                <div className={ classes.certificateFormContainer }>
                    <div className={ classes.formLabel }>
                        Certificate Title <span className={ classes.iconRequired }>*</span>
                    </div>
                    <input className={ classes.input } onChange={this.titleChanged} value={ this.state.title }/>
                    <div className={ classes.formLabel }>
                        Certificate File
                    </div>
                    <input type="file" className={ classes.fileInput } ref={ ref => this.fileSelector = ref } onChange={this.fileChanged}/>
                    <Button variant="contained" style={{ marginLeft: 'auto', }} disabled ={ !canUpload } onClick={ this.uploadCertificate }>
                        Upload
                    </Button>
                </div>
            );
        }

        return null;
    }

    titleChanged = (event) => {
        this.setState({title: event.target.value});
        if (event.target.value.length && this.fileSelector.files.length) {
            this.setState({canUpload: true})
        } else {
            this.setState({canUpload: false})
        }
    }

    fileChanged = (event) => {
        if (this.state.title.length) {
            this.setState({canUpload: true})
        }
    }

    uploadCertificate = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const loginStatus = this.props.account.loginStatus;
        var formData = new FormData();
        formData.append('certificate', this.fileSelector.files.item(0));
        formData.append('title', this.state.title);

        return fetch(apiUrl + 'api/certificates/upload', {
            method: 'POST',
            headers: (loginStatus.token != '')? {
            'Authorization': 'Token ' + loginStatus.token,
            'X-CSRFToken': Cookies.get('csrftoken')
            }: {
            'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 'success') {
                this.fetchCertificates();
            }
        });
    }

    fetchCertificates = () => {
        this.setState({
            formExpanded: false,
            title: ''
        });
        const loginStatus = this.props.account.loginStatus;
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        return fetch(apiUrl + 'api/certificates/list', {
            method: 'POST',
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        })
        .then(res => res.json())
        .then(data => this.setState({certificates: data}))
    }

    componentDidMount() {
        this.fetchCertificates();
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(certificatesPageStyle)(CertificatesPage));