import React, { Component } from 'react'
import { connect } from 'react-redux';

import setupPageStyle from './setupPageStyle'
import { withStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import BaseInput from 'components/BaseInput';
import Button from 'components/Button';

import NativeSelect from 'components/NativeSelect';
import AutocompleteSelect from 'components/AutocompleteSelect/index.jsx';

import { saveBudgetSettings } from '../../../redux/actions/adminActions';

import { withTranslation  } from 'react-i18next';
import Cookies from "js-cookie";

class RequestorForm extends Component
{
    state = {
        bulkImportInfos: []
    }

    componentDidMount() {
        const { bulkImportInfos } = this.props;
        if (bulkImportInfos) {
            this.setState({ bulkImportInfos: bulkImportInfos});
        }
    }

    render(){
        const { classes, t } = this.props;
        const { bulkImportInfos } = this.state;

        return(
            <div
                className={ classes.subFormContainer }
                style={{ padding: '20px 15px', borderRadius: 8,}}>
                <GridContainer>
                    <GridItem xs={12} className={ classes.subFormTitle }>
                        {
                            t('Budget Import Process Summary')
                        }
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={16} style={{ marginTop: 16, color: '#1D8ACF' }}>
                    <GridItem xs={2}>
                        Excel Line
                    </GridItem>
                    <GridItem xs={3}>
                        Processing Status
                    </GridItem>
                    <GridItem xs={7}>
                        Processing Obs
                    </GridItem>
                </GridContainer>
                <div style={{
                        maxHeight: 300,
                        overflowY: 'auto'
                    }}
                    className={`scrollbar-style1`}
                >
                {
                    bulkImportInfos.map((info, key) => {
                        return (
                            <GridContainer spacing={16} key={key} style={{ marginTop: 8 }}>
                                <GridItem xs={2} style={{ textAlign: 'right', paddingRight: 32 }}>
                                    { info.line }
                                </GridItem>
                                <GridItem xs={3}>
                                    { info.status ? 'OK' : 'Error' }
                                </GridItem>
                                <GridItem xs={7}>
                                    { info.status ? '' : info.message }
                                </GridItem>
                            </GridContainer>
                        )
                    })
                }
                </div>
                <GridContainer>
                    <GridItem xs={12} className={ classes.marginTop_20 }
                        style={{ textAlign: 'right' }}
                    >
                        <Button className={ `${classes.btnSubmit}` } onClick={ this.props.cancelled }
                            style={{ background: '#FFC700' }}
                        >
                            {t('Close')}
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state, initialResponse: state.initialResponse}
}
const mapDispatchToProps = {saveBudgetSettings};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(setupPageStyle)(RequestorForm)));