import React from 'react';
import PropTypes from "prop-types";
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { confirmable, createConfirmation } from "react-confirm";
import { withTranslation  } from 'react-i18next';

class Confirm extends React.Component {
    renderCancelButton() {
        const {
          proceedLabel,
          cancelLabel,
          title,
          confirmation,
          showCancel,
          show,
          proceed,
          dismiss,
          cancel,
          t,
          enableEscape = true
        } = this.props;

        if (showCancel) {
            return (
                <Button onClick={cancel} style={{ color: 'white', background: '#FFC76F', width: 120, height: 30 }}>
                    {t(cancelLabel)}
                </Button>
            );
        } else {
            return null;
        }
    }

    render() {
      const {
        proceedLabel,
        cancelLabel,
        title,
        confirmation,
        showCancel,
        show,
        proceed,
        dismiss,
        cancel,
        enableEscape = true,
        t
      } = this.props;

      return (
        <div className="static-modal">
            <Dialog
                open={show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: 'alert-container' }}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title" classes={{ root: 'alert-title' }}>{t(title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(confirmation)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    { this.renderCancelButton() }
                    <Button onClick={proceed} autoFocus style={{ color: 'white', background: '#0072BB', width: 120, height: 30 }}>
                        {t(proceedLabel)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
      );
    }
}


Confirm.propTypes = {
    okLabbel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    cancel: PropTypes.func, // called when cancel button is clicked.
    dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
};

export function confirm(
    title,
    confirmation,
    showCancel = true,
    proceedLabel = "OK",
    cancelLabel = "Cancel",
    options = {}
) {
    return createConfirmation(confirmable(withTranslation()(Confirm)))({
        title,
        confirmation,
        showCancel,
        proceedLabel,
        cancelLabel,
        ...options
    });
}