import commonStyle from 'assets/jss/commonStyle'

const msaPageStyle = theme => ({
    ...commonStyle,
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        background: 'white',
        width: '100%',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070',
        position: 'relative',
        
    },
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: 14,
    },
    addIcon: {
        color: '#1D8ACF',
        marginLeft: '10px',
    },
    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    recipientForm: {
        width: '100%',
        border: '1px solid #a0a0a0',
        borderRadius: '4px',
        padding: '10px 30px',
        display: 'flex',
    },
    recipientDetail: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    signOrder: {
        padding: '10px 0px',
        paddingRight: '30px',
    },
    formLabel: {
        width: '100%',
        fontSize: '14px',
        color: '#000',
        margin: '5px 0px',
    },
    iconRequired: {
        color: '#A30C0C',
    },
    recipientFormContainer: {
        padding: '10px 50px',
    },
    recipientInput: {
        borderRadius: '3px',
        border: '1px solid #9e9e9e',
        padding: '3px 0px',
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '1px solid #9e9e9e',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
        '&[disabled]': {
            border: '1px solid #fdfdfd',
            background: '#dedede',
            cursor: 'not-allowed',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
    },
    removeIcon: {
        opacity: 0,
        marginLeft: '20px',
        cursor: 'pointer',
    },
    recipientWrapper: {
        '&:hover': {
            '& $removeIcon': {
                opacity: 1,
            }
        }
    },
    fileContainer: {
        padding: '10px 0px'
    },
    attachmentImg: {
        width: '30px',
        marginRight: '5px',
        marginLeft: '50px',
    },
    docBtnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        paddingLeft: 52,
    },
    labelGroupBy: {
        color: '#1D8ACF',
        marginRight: 20,
        marginLeft: 20,
    },
    groupBy: {
        width: '110px',
    },
    itemLabel: {
    },
    type: { },
    value: {},
    itemList: {
        background: 'white',
        padding: 0,
        marginTop: '0',
        marginBottom: '0',
        fontSize: '1rem',
    
        marginTop: 10,
        fontSize: 12,
        
        '& $itemLabel': {
            padding: '0 0.8rem',
            marginTop: '0',
            marginBottom: '0',
            fontSize: 16,
            backgroundColor: '#F2F2F2',
            '& $value': {
                color: '#1D8ACF',
                marginLeft: 8,
            },
            '& div': {
                paddingTop: 5,
                paddingBottom: 5,
            }
        }
    },
    itemHeader: {
        maxHeight: '50px',
        marginTop: '0',
        marginBottom: '0',
        padding: '0 0.5rem',
        borderBottom: '1px solid #CDCDCD',
        fontSize: '1rem',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        fontWeight: 700,

        '& div': {
            display: 'flex',
            alignItems: 'center',
            padding: '12px 8px',
            paddingTop: 6,
        }
    },
    NoList: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFC700',
        fontSize: '1.3rem',
        paddingTop: 30,
        paddingBottom: 30,
    },
    coiContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: '10',
        position: 'relative'
    },
    btnAdd: {
        borderRadius: 7,
        border: '1px solid #1291F9',
        background: 'rgba(0, 153, 255, 0.2)',
        fontSize: '14px !important',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 10px',
    }
});
export default msaPageStyle;