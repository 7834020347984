import * as Type from '../actions/types';

const initialState = {
  data: {
      list: []
  }
};

export const orgList = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_ORG_LIST:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
