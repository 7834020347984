import React, { Component } from 'react';
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux';
import LandingHeader from '../../../views/Landing/LandingHeader.jsx';
import LandingFooter from '../../../views/Landing/LandingFooter.jsx';

import PrivacyPageStyle from './PrivacyPageStyle.jsx'


class PrivacyPage extends Component {

    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.pageContainer}>
                <LandingHeader />
                <div className={classes.pageBody}>
                    <h1 className={classes.alignCenter}>Privacy Policy</h1>
                    <p className={classes.alignRight}>Effective date: 7 August 2019</p>
                    <section>
                        <h2 className={classes.h2}>Welcome to iPayflow!</h2>
                        <p>
                            iPayflow. and its affiliates (collectively “iPayflow”,  “we” and “us”) respect your privacy. 
                            We and our partners offer services that enable platforms and merchants to run businesses, and to safely conduct online payment transactions.
                        </p>
                        <p>
                            This Privacy Policy describes the types of Personal Data we collect through our payments products and services and those of our payment partners, 
                            which include the website at <a href="https://www.ipayflow.com">iPayFlow.com</a> (the “Services”). 
                            This policy also describes how we use Personal Data, with whom we share it, your rights and choices, and how you can contact us about our privacy practices.
                        </p>
                    </section>
                    <section>
                        <h2 className={classes.h2}>1. Data Usage Overview</h2>
                        <p>
                            The following terms used in this section relate to data provided to us by you or your Customers, or received or accessed by you through your use of the Services:
                            “iPayFlow” means the website at <a href="https://www.ipayflow.com">iPayFlow.com</a> and the services and systems contained therein.
                        </p>
                        <p>
                            “Payment Data” means Payment Account Details, information communicated to or by Payment Method Provider or Payment Method Acquirer, 
                            financial information specifically regulated by Laws and Payment Method Rules, and any other information used with the Payment Processing Services to complete a Transaction.
                        </p>
                        <p>
                            “Personal Data” means information that identifies a specific living person (not a company, legal entity, or machine) and is transmitted to or accessible through the Services.
                        </p>
                        <p>
                            “iPayFlow Data” means details of the API transactions over iPayFlow infrastructure, 
                            information used in fraud detection and analysis, aggregated or anonymized information generated from Data, 
                            and any other information created by or originating from us or the Services. 
                            For the avoidance of doubt, iPayFlow data is provided to us by THOT ANALYTICA INFORMATICA INC. for the purpose of providing payment Services to you and other iPayFlow users.
                        </p>
                        <p>
                            “User Data” means information that describes your business and its operations, your products or services, and orders placed by Customers.
                        </p>
                        <p>
                            The term “Data” used without a modifier means all Personal Data, User Data, Payment Data, and iPayFlow Data.
                        </p>
                        <p>
                            We process, analyze, and manage Data to: (a) provide Services to you, other iPayFlow users, and Customers; (b) mitigate fraud, 
                            financial loss, or other harm to users, Customers and us; and (c) analyze, develop and improve our products, systems, and tools. 
                            We provide Data to third-party service providers, including Payment Method Providers, Payment Method Acquirers, and their respective affiliates, 
                            as well as to our affiliates, to allow us to provide Services to you and such select users. 
                            We do not provide Personal Data to unaffiliated parties for marketing their products to you. You understand and consent to our use of Data as described herein.
                        </p>
                    </section>
                    <section>
                        <h2 className={classes.h2}>2. Data Protection and Privacy</h2>
                        <p>
                            a. Confidentiality: We will only use User Data as permitted by this Agreement, by other agreements between you and us, or as otherwise directed or authorized by you. 
                            You will protect all Data you receive through the Services, and you may not disclose or distribute any such Data, 
                            and you will only use such Data in conjunction with the Services and as permitted by this Agreement or by other agreements between you and us. 
                            Neither party may use any Personal Data to market to Customers unless it has received the express consent from a specific Customer to do so. 
                            You may not disclose Payment Data to others except in connection with processing Transactions requested by Customers and consistent with applicable Laws and Payment Method Rules.
                        </p>
                        <p>
                            b. Privacy: Protection of Personal Data is very important to us. 
                            Our Privacy Policy explains how and for what purposes we collect, use, retain, disclose, and safeguard the Personal Data you provide to us. 
                            You agree to the terms of our Privacy Policy, which we may update from time to time.
                        </p>
                        <p>
                            You affirm that you are now and will continue to be compliant with all applicable Laws governing the privacy, protection, 
                            and your use of Data that you provide to us or access through your use of the Services. 
                            You also affirm that you have obtained all necessary rights and consents under applicable Laws to disclose to us – 
                            or allow us to collect, use, retain, and disclose – any Personal Data that you provide to us or authorize us to collect, 
                            including Data that we may collect directly from Customers using cookies or other similar means. 
                            As may be required by Law and in connection with this Agreement, you are solely responsible for disclosing to Customers 
                            that we process Transactions (including payment Transactions) for you and may receive Personal Data from you.
                        </p>
                        <p>
                            If we become aware of an unauthorized acquisition, disclosure or loss of Personal Data on our systems, 
                            we will notify you consistent with our obligations under applicable Law.
                        </p>
                    </section>
                    <section>
                        <h2 className={classes.h2}>3. Security and Fraud Controls</h2>
                        <p>
                            a. iPayFlow Security: We are responsible for protecting the security of Data in our possession. 
                            We will maintain commercially reasonable administrative, technical, and physical procedures 
                            to protect User Data and Personal Data stored in our servers from unauthorized access, 
                            accidental loss, modification, or breach, and we will comply with applicable Laws and Payment Method Rules when we handle User and Personal Data.
                        </p>
                        <p>
                            However, no security system is impenetrable and we cannot guarantee that unauthorized parties will never be able to defeat our security measures or misuse any Data in our possession. 
                            You provide User Data and Personal Data to us with the understanding that any security measures we provide may not be appropriate or adequate for your business, 
                            and you agree to implement Security Controls (as defined below) and any additional controls that meet your specific requirements. 
                            In our sole discretion, we may take any action, including suspension of your iPayFlow Account, to maintain the integrity and security of the Services or Data, 
                            or to prevent harm to you, us, Customers, or others. You waive any right to make a claim against us for losses you incur that may result from such actions.
                        </p>
                        <p>
                            b. Your Security: You are solely responsible for the security of any Data on your website, your servers, in your possession, or that you are otherwise authorized to access or handle. 
                            You will comply with applicable Laws when handling or maintaining User Data and Personal Data, and will provide evidence of your compliance to us upon our request. 
                            If you do not provide evidence of such compliance to our satisfaction, we may suspend your iPayFlow Account or terminate this Agreement.
                        </p>
                        <p>
                            c. Security Controls: You are responsible for assessing the security requirements of your business, 
                            and selecting and implementing security procedures and controls (“Security Controls”) appropriate to mitigate your exposure to security incidents. 
                            We may provide Security Controls as part of the Services, or suggest that you implement specific Security Controls. 
                            However, your responsibility for securing your business is not diminished by any Security Controls that we provide or suggest, 
                            and if you believe that the Security Controls we provide are insufficient, then you must separately implement additional controls that meet your requirements.
                        </p>
                        <p>
                            Fraud Risk: While we may provide or suggest Security Controls, we cannot guarantee that you or Customers will never become victims of fraud. 
                            Any Security Controls we provide or suggest may include processes or applications developed by us, our affiliates, or other companies. 
                            You agree to review all the Security Controls we suggest and choose those that are appropriate for your business to protect against unauthorized Transactions and, 
                            if appropriate for your business, independently implement other security procedures and controls not provided by us. 
                            You are solely responsible for losses you incur from the use of lost or stolen payment credentials or accounts by fraudsters who engage in fraudulent Transactions with you, 
                            and your failure to implement Security Controls will only increase the risk of fraud. We may assist you with recovering lost funds, 
                            but you are solely responsible for losses due to lost or stolen credentials or accounts, compromise of your username or password, 
                            changes to your Payout Account, and any other unauthorized use or modification of your iPayFlow Account. 
                            We are not liable or responsible to you and you waive any right to bring a claim against us for any losses that result 
                            from the use of lost or stolen credentials or unauthorized use or modification of your iPayFlow Account, unless such losses result from our willful or intentional actions. 
                            Further, you will fully reimburse us for any losses we incur that result from the use of lost or stolen credentials or accounts.
                        </p>
                    </section>
                </div>
                <LandingFooter />
            </div>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(PrivacyPageStyle)(PrivacyPage));
