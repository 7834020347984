import React, { Component } from 'react'

import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import { withStyles } from '@material-ui/core'
import logInPageStyle from './loginPageStyle.jsx'
import { NavLink} from 'react-router-dom'
import { withRouter } from 'react-router'
import { confirm } from "components/Confirm/index";
import Button from 'components/Button/index.jsx';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { userLogin, userCheck, userForgotPassword, userResetPassword, clearErrorMsg, userLogout } from '../../redux/actions/accountActions';

import { Link } from 'react-router-dom';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import { successConfirm } from "components/SuccessConfirm/index";
const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
class LogInPage extends Component {

    state = {
        customer: '',
        loginData: {
            email: '',
            password: ''
        },
        lastTriedPassword: "",
        loginDataValidity: {
            email: {
                isValid: true,
                message: ''
            },
            password:  {
                isValid: true,
                message: ''
            }
        },
        resetData: {
            otp: '',
            password: '',
            repassword: ''
        },
        otpPassed: false,
        resetDataValid: false,
        otpDataValid: false,
        resetModalOpen: false,
        needChangePassword: false,
        changePasswordData: {
            password: '',
            repassword: '',
            validity: {
                isValid: false,
                errorMsg: ''
            }
        },
        passwordInputModal: false,
        resetPwdMsg: '',
        changePasswordModalOpen: false,
        selectingCustomer: false,
        customers: [],
        selectedCustomer: {},
        emailError: ''
    }

    componentDidMount = async() => {
        const isLogedIn = this.props.account.loginStatus.isLogedIn;
        const query = new URLSearchParams(this.props.location.search);
        if (query.get('email') !== null && query.get('token') !== null) {
            const email = query.get('email');
            const token = query.get('token');

            return fetch(apiUrl + 'api/account/pwd_token_check', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                body: JSON.stringify({
                    email,
                    token
                })
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res['status'] && !isLogedIn) {
                    let { loginData } = this.state;
                    loginData.email = query.get('email');
                    this.setState({ loginData });

                    this.handleForgotPassword();
                }
            });
        }

        let customer = this.props.match.params.customer;
        this.setState({selectedCustomer: { permalink: customer }});
    }

    handleClickOpen = () => {
        this.setState({resetModalOpen: false})
    }

    handleClose = () => {
        this.setState({resetModalOpen: false});
    }

    checkValidityResetPassword = () => {
        let isValid = true;
        let errorMsg = '';
        if (this.state.resetData.password.length === 0) {
            return;
        }

        if (this.state.resetData.otp.length < 5) {
            isValid = false;
        } else if (this.state.resetData.password.length < 8) {
            isValid = false;
            errorMsg = "Password should be longer than 8 letters";
        } else if (isValid && (
            !/[a-z]/.test(this.state.resetData.password)
            || !/[A-Z]/.test(this.state.resetData.password)
            || !/[0-9]/.test(this.state.resetData.password)
        )) {
            isValid = false;
            errorMsg = "Password should be consists of lowercase, uppercase letters and digits";
        } else if (isValid && this.state.resetData.password !== this.state.resetData.repassword) {
            isValid = false;
            errorMsg = "Please make sure Passwords match";
        }

        this.setState({resetDataValid: isValid, resetPwdMsg: errorMsg});
    }

    resetPasswordInputChange = (e, valueType) => {
        const dataOject = {}
        dataOject[valueType] = e.target.value;
        this.setState({ resetData: Object.assign(this.state.loginData, dataOject) });
        this.checkValidityResetPassword();
    }

    resetOtpInputChange = (e, valueType) => {
        const dataOject = {}
        dataOject[valueType] = e.target.value;
        this.setState({ resetData: Object.assign(this.state.loginData, dataOject) });
        if (e.target.value.length >= 5) {
            this.setState({otpDataValid: true});
        }
    }

    checkValidityChangePassword = () => {
        let isValid = true;
        let errorMsg = "";
        let password = this.state.changePasswordData.password;
        let repassword = this.state.changePasswordData.repassword;
        if (password.length < 8) {
            isValid = false;
            errorMsg = "Password should be longer than 8 letters";
        } else if (isValid
            && (
                !/[a-z]/.test(password)
                || !/[A-Z]/.test(password)
                || !/[0-9]/.test(password)
            )
        ) {
            isValid = false;
            errorMsg = "Password should be consists of lowercase, uppercase letters and digits";
        } else if (isValid && password != repassword) {
            isValid = false;
            errorMsg = "Please make sure Passwords match";
        }

        let changePasswordData = this.state.changePasswordData;
        changePasswordData.validity.isValid = isValid;
        changePasswordData.validity.errorMsg = errorMsg;

        this.setState({changePasswordData: changePasswordData});
    }

    changePasswordInputChange = (e, valueType) => {
        const dataOject = {}
        dataOject[valueType] = e.target.value;
        this.setState({ changePasswordData: Object.assign(this.state.changePasswordData, dataOject) });
        this.checkValidityChangePassword();
    }

    // Send reset password request
    handleResetPassword = async (e) => {
        e.preventDefault();

        var data = this.state.loginData;

        this.setState({'loginErrorMsg': ''});
        this.setState({'isBackendLoading': true});
        await this.props.userResetPassword({
          "email": data.email,
          "otp": this.state.resetData.otp,
          "password": this.state.resetData.password,
          'customer': this.state.selectedCustomer.permalink
        });

        this.setState({'isBackendLoading': false, resetModalOpen: false });

        successConfirm("Success", "Your password is successfully changed!", false);

        // this.handleResetResponse(this.props.account);
        this.handleLoginResponse(this.props.account);
    }

    checkOTPCode = async (e) => {
        e.preventDefault();

        var data = this.state.loginData;

        this.setState({'loginErrorMsg': ''});
        this.setState({'isBackendLoading': true});

        return fetch(apiUrl + 'api/account/checkOTP', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                "email": data.email,
                "otp": this.state.resetData.otp,
                'customer': this.state.selectedCustomer.permalink
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                this.setState({otpPassed: true});
                this.setState({resetData: Object.assign(this.state.resetData, {
                    password: '',
                    repassword: ''
                })});
            } else {
                confirm("Error", "This token is not valid.", false).then(
                    async () => {
                        this.setState({otpPassed: false});
                    },
                    () => {
                    }
                );
            }
        })
        .catch(err => {
            this.setState({otpPassed: false});
        });

    }

    // Process reset password Response
    handleResetResponse = (res) => {
        if (res["resetStatus"]["status"]) {
            confirm("Success", "Your password is successfully changed!", false).then(
                async () => {
                    this.setState({resetModalOpen: false});
                },
                () => {
                    console.log("Click Canceled");
                }
            );
        }
    }

    handleLoginClicked = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const { loginData } = this.state;

        return fetch(apiUrl + 'api/account/customers', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                "email": loginData.email,
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res['status']) {
                this.setState({ customers: res['data'], selectingCustomer: true, emailError: '' });
                return;
            }

            this.setState({ emailError: 'Email is not valid. Try again!' });
        })
        .catch(err => {

        });
    }


    changePassword = async () => {
        let data = this.state.loginData;
        data.newPasswrod = this.state.changePasswordData.password;

        this.setState({'isBackendLoading': true});
        await this.props.userLogin({
            "customer": this.state.selectedCustomer.permalink,
            "username": data.email,
            "password": data.password,
            "newPassword": data.newPasswrod
        });
        this.setState({'isBackendLoading': false});

        this.handleChangePasswordResponse(this.props.account);
    }

    // Process Login Response
    handleChangePasswordResponse = (res) => {
        const loginResult = res.loginStatus;
        if (typeof loginResult != null && loginResult.isLogedIn) {
            if (!loginResult.needChangePassword) {
                this.setState({changePasswordModalOpen: false});
                this.handleLoginResponse(res);
            }
        }
    }

    // Show Login Modal
    handleSubmit = async () => {
        console.log(this.state.selectedCustomer);
        const password = this.state.loginData.password;
        if(password.length < 4){
          this.setState({ loginDataValidity: Object.assign(this.state.loginDataValidity, {email: {isValid: false, message: 'Password is required'}}) })
          return;
        }

        var data = this.state.loginData;

        this.setState({'loginErrorMsg': ''});
        this.setState({'lastTriedPassword': password});
        this.setState({'isBackendLoading': true});
        await this.props.userLogin({
            "customer": this.state.selectedCustomer.permalink,
            "username": data.email,
            "password": data.password
        });
        this.setState({'isBackendLoading': false});

        this.handleLoginResponse(this.props.account);
    }

    // Process Login Response
    handleLoginResponse = (res) => {
        const loginResult = res.loginStatus;
        if (typeof loginResult != null && loginResult.isLogedIn) {
            if (loginResult.needChangePassword) {
                this.setState({'changePasswordModalOpen': true});
                this.setState({'passwordInputModal': false});
                return;
            }
            window.location.href = '/dashboard';
            setTimeout(() => {
                this.setState({isLogedIn: true});
            }, 1000);
        } else {
            this.setState({'loginErrorMsg': loginResult['message']});
            this.setState({isLogedIn: false});
        }
    }

    // Press Forgot Password
    handleForgotPassword = async () => {
        var data = this.state.loginData;

        this.setState({'loginErrorMsg': ''});
        this.setState({'isBackendLoading': true});
        await this.props.userForgotPassword({
          "username": data.email,
        });
        this.setState({'isBackendLoading': false});

        this.handleForgotResponse(this.props.account);
    }

    // Process Login Response
    handleForgotResponse = (res) => {
        if (res["forgotStatus"]["status"]) {
            this.setState({
                resetData: {
                    otp: '',
                    password: '',
                    repassword: ''
                }
            });
            this.setState({resetModalOpen: true, passwordInputModal: false});

            return ;
        }

        this.setState({ emailError: 'Email is not valid. Try again!' });
    }

    submitResultMessage = () => {
        const { classes } = this.props;
        if(typeof this.props.account.loginStatus.isLogedIn != 'undefined'){
            if (this.props.account.loginStatus.isLogedIn) {
                return null;
            } else {
                if (this.props.account.loginStatus.message.length > 0) {
                    return(
                        <div>
                            <p className={  classes.errorMsg }>
                                {this.props.account.loginStatus.message}
                            </p>
                        </div>
                    );
                }else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    // OTP Input Rendering
    renderOtpInput = () => {
        const { classes } = this.props;
        if (this.state.otpPassed) {
            return (
                <div>
                    <Input
                        autoFocus
                        margin="dense"
                        id="reset_password"
                        label="New Password"
                        type="password"
                        value={this.state.resetData.password}
                        onChange={e => this.resetPasswordInputChange(e, 'password')}
                        fullWidth
                        classes={{
                            root: classes.inputRoot,
                            input: classes.input
                        }}
                    />
                    <Input
                        margin="dense"
                        id="reset_repassword"
                        label="Confirm Password"
                        type="password"
                        value={this.state.resetData.repassword}
                        onChange={e => this.resetPasswordInputChange(e, 'repassword')}
                        fullWidth
                        classes={{
                            root: classes.inputRoot,
                            input: classes.input
                        }}
                    />
                    <p style={{color: 'red'}}>
                        { this.state.resetPwdMsg }
                    </p>
                </div>
            );
        } else {
            return (
                <Input
                    autoFocus
                    margin="dense"
                    id="otp_code"
                    label="OTP Code"
                    type="text"
                    value={this.state.resetData.otp}
                    onChange={e => this.resetOtpInputChange(e, 'otp')}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                        input: classes.input
                    }}
                />
            )
        }
    }

    renderOtpSubmitPanel = () => {
        const { classes } = this.props;
        if (this.state.otpPassed) {
            return (
                <div>
                    <Button
                        className={classes.resetCloseBtn}
                        onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        className={classes.resetSubmitBtn}
                        onClick={this.handleResetPassword} color="primary"
                        disabled={!this.state.resetDataValid}>
                        Reset
                    </Button>
                </div>
            );
        } else {
            return (
                <Button
                    className={classes.resetSubmitBtn}
                    onClick={this.checkOTPCode} color="primary"
                    disabled={!this.state.otpDataValid}>
                    Next
                </Button>
            )
        }
    }

    // Login data changed
    handleChangeValue = (e, valueType) => {
      const dataOject = {}
      dataOject[valueType] = e.target.value;
      if (valueType === 'email') {
          this.setState({ emailError: '' });
      }
      this.setState({ loginData: Object.assign(this.state.loginData, dataOject) });
    }

    renderRedirect = async () => {
      const loginStatus = this.props.account.loginStatus;
      const query = new URLSearchParams(this.props.location.search);
      if (query.get('email') !== null && query.get('token') !== null && loginStatus.isLogedIn && !loginStatus.needChangePassword) {
        const email = query.get('email');
        const token = query.get('token');
        const result = await fetch(apiUrl + 'api/account/pwd_token_check', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                email,
                token
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res['status']) {
                if (loginStatus.isLogedIn) {
                    this.props.userLogout();
                }

                return true;
            }
            return false;
        });

        if (result) {
            return null;
        }
      }

      if(loginStatus.isLogedIn && !loginStatus.needChangePassword){
        var userInfo = this.props.account.loginStatus.user;
        this.props.history.push('/dashboard');
      }
    }

    handleCustomerClicked = (customer) => {
        this.props.clearErrorMsg();
        this.setState({ passwordInputModal: true, selectedCustomer: customer });
    }

    signInToPlatform = () => {
        this.handleSubmit();
    }

    renderCustomerLogo = (customer) => {

        if (isNaN(customer.logo)) {
            return (
                <div style={{ padding: 0, borderRadius: 500, background: 'white' }}>
                    <img src={ customer.logo } />
                </div>
            );

        }

        return (
            <div style={{ padding: 5, borderRadius: 500, background: 'white' }}>
                <img src='/favicon.ico' />
            </div>
        );
    }

    render(){
        const { classes } = this.props;
        const { selectingCustomer, customers, selectedCustomer } = this.state;

        if (selectingCustomer) {
            return (
                <div className="w-full h-full justify-center items-center relative" style={{ flexDirection: 'row' }}>
                    <div className={ classes.backIconContainer } onClick={ () => this.setState({ selectingCustomer: false }) }>
                        <ChevronLeftRoundedIcon />
                        Back to Log In
                    </div>
                    <div className="flex flex-col items-center">
                        <img src="/assets/img/newui/header/blue_logo.png" style={{ width: 114, height: 43, }}/>
                        <div className={ classes.customerLabel }>
                            Which area do you want to access?
                        </div>
                        <div className="flex w-full flex-wrap xl:px-64 lg:px-40 px-20">
                            {
                                customers.map((customer, key) => {
                                    return (
                                        <div className={ classes.customerContainer } key={ key } onClick={ () => this.handleCustomerClicked(customer) }>
                                            {
                                                this.renderCustomerLogo(customer)
                                            }
                                            { customer.name }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <Dialog open={this.state.passwordInputModal} onClose={this.handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.inputPwdModalRoot }}>
						<DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>{ selectedCustomer.name }</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ textAlign: 'center', marginBottom: 15, }}>
                        <p>Input the password for the account<br/> <span style={{ color: '#1291F9', fontWeight: 600, }}>{ this.state.loginData.email }</span></p>
                            </DialogContentText>
                            <Input
                                variant="outlined"
                                required
                                error={!this.state.loginDataValidity.password.isValid}
                                placeholder="password" type="password"
                                classes={{ root: classes.inputRoot, input: classes.input }}
                                name="password"
                                onChange={e => this.handleChangeValue(e, 'password')}
                                error={!this.state.loginDataValidity.password.isValid}
                            />
                            {this.submitResultMessage()}
                            <div className={ classes.forgotPasswordLabel }>
                                Forgot your password? Get it back <a href="javascript:void(0)" onClick={ this.handleForgotPassword }>here</a>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button
                                className={classes.resetCloseBtn}
                                onClick={() => this.setState({ passwordInputModal: false })} color="primary">
                                Cancel
                            </Button>
                            <Button
                                className={classes.resetSubmitBtn}
                                onClick={ this.signInToPlatform } color="primary">
                                Log In
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {
                        this.renderModals()
                    }
                </div>
            )
        }

        this.renderRedirect();

        return (
            <div className={ classes.pageContainer } style={{ flexDirection: 'row' }}>
                <div className={ classes.formContainer }>
                    <div className={ classes.form }>
                        <img src="/assets/img/newui/header/blue_logo.png" style={{ width: 114, height: 43, marginBottom: 20 }} />
                        <div className={ classes.signInLabel }>
                            Log In
                        </div>
                        <Input
                            variant="outlined"
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={e => this.handleChangeValue(e, 'email')}
                            classes={{ root: classes.inputRoot, input: classes.input }}
                            value={ this.state.loginData.email }
                        />
                        {
                            this.state.emailError.length !==0 &&
                            (
                                <div className={ classes.errorMsg }>
                                    { this.state.emailError }
                                </div>
                            )

                        }
                        <Button className={ classes.btnLogin } onClick={ this.handleLoginClicked }>
                            Log In
                        </Button>
                        <div className='flex items-center w-full' style={{ marginLeft: -20, marginRight: -20, }}>
                            <hr style={{ flex: 1, borderColor: '#B7B7B7' }}  />
                            <span style={{ paddingLeft: 30, paddingRight: 30, fontSize: 18, fontWeight: 300}}>or</span>
                            <hr style={{ flex: 1, borderColor: '#B7B7B7'}} />
                        </div>
                        {this.submitResultMessage()}
                        <NavLink className={ classes.btnSignInGoogle } to="/register">
                            Sign Up
                        </NavLink>
                        <div className={ classes.forgotPasswordLabel }>
                            Forgot your Password? Get it back <a href="javascript:void(0)" onClick={ this.handleForgotPassword }>Here</a>
                        </div>
                        <div className={ classes.termsLabel }>
                            By clicking Log In, you agree to our <Link to={`/terms`}>Terms</Link> and have read and acknowledge our <Link to={`/privacy`}>Privacy Statement.</Link> <br/>
                        </div>
                    </div>
                </div>
                <div className={ classes.sideBanner }>
                    <img src="/assets/img/newui/login/wave-vector.svg" />
                </div>
                {
                    this.renderModals()
                }
            </div>
        );
    }

    renderModals = () => {
        const { classes } = this.props;
        const { otpPassed } = this.state;
        return (
            <div>
                <Dialog open={this.state.resetModalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                    <DialogContent>
                    {
                        !otpPassed ?
                            <DialogContentText>
                                <p>We just send email with OTP Code to your email.</p>
                                <p>Please check your email inbox.</p>
                            </DialogContentText> : null
                    }
                    {
                        otpPassed ?
                            <DialogContentText>
                                <p>OTP code passed.</p>
                                <p>Please input your new password.</p>
                            </DialogContentText> : null
                    }
                    { this.renderOtpInput() }
                    </DialogContent>
                    <DialogActions>
                    { this.renderOtpSubmitPanel() }
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.changePasswordModalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p>You should change your password for more safety.</p>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new_password"
                            label="New Password"
                            type="password"
                            value={this.state.changePasswordData.password}
                            onChange={e => this.changePasswordInputChange(e, 'password')}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="re_password"
                            label="Confirm Password"
                            type="password"
                            value={this.state.changePasswordData.repassword}
                            onChange={e => this.changePasswordInputChange(e, 'repassword')}
                            fullWidth
                        />
                        <DialogContentText>
                            <p style={{color: 'red'}}>
                                { this.state.changePasswordData.validity.errorMsg }
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.resetSubmitBtn}
                            onClick={this.changePassword} color="primary"
                            disabled={!this.state.changePasswordData.validity.isValid}>
                            Change Password
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = {userLogin, userCheck, userForgotPassword, userResetPassword, clearErrorMsg, userLogout}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(logInPageStyle)(LogInPage)));