import commonStyle from 'assets/jss/commonStyle';

const budgetsReportStyle = (theme) => ({
    ...commonStyle,
    pageContentContainer: {
      position: 'relative',
    },
    durationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 10,
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10
    },
    exportButton: {
      minWidth: 30,
      minHeight: 30,
    },
    durationFilter: {
      display: 'flex',
      alignItems: 'center',
      '& .react-datepicker-wrapper': {
        marginLeft: 10,
        marginRight: 10,
        width: 120,
      }
    },
    btnCustomDuration: {
      width: '100%',
      height: 40,
      border: '1px solid rgba(18, 145, 249, 0.18)',
      borderRadius: '6px',
      display: 'inline-block',
    },
    btnDuration: {
        background: 'rgba(0, 153, 255, 0.2)',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        color: '#1291F9',
        fontSize: 12,
        width: 60,
        height: 24,
        marginLeft: 10,
        '&.active': {
            color: '#F2F2F2',
            background: '#1291F9'
        }
    },
    tabContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 36,
    },
    btnTab: {
        background: 'rgba(0, 153, 255, 0.2)',
        borderRadius: 7,
        color: '#1291F9',
        fontSize: 14,
        width: 159,
        height: 34,
        marginLeft: 10,
        fontWeight: 700,
        '&.active': {
            color: '#F2F2F2',
            background: '#1291F9'
        }
    },
    tileTitle: {
        color: '#1291F9',
        fontSize: 20,
        fontWeight: 700,
    },
    budgetValue: {
        color: '#1E1E1E',
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 20,
    },
    budgetsContainer: {
        height: 'calc(100% - 135px)',
        overflow: 'auto',
        paddingRight: 5,
    },
    scroll: {
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            background: 'red',
        },
        "&::-webkit-scrollbar-button": {
            // Side buttons
            width: "0px",
            height: "0px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            // Slider hover
            background: "#777"
          },
          "&::-webkit-scrollbar-thumb:active": {
            // Slider active
            background: "#555"
          },
          "&::-webkit-scrollbar-track": {
            // Scrollbar track
            background: "#ff0000",
            borderRadius: "2px"
          },
          "&::-webkit-scrollbar-track:hover": {
            // Track hover
            background: "#ccc"
          },
          "&::-webkit-scrollbar-track:active": {
            // Track active
            background: "#ccc"
          },
          "&::-webkit-scrollbar-corner": {
            // Scrollbar corners where scrollbars meet
            background: "transparent"
          }
    },
    byLabel: {
      color: '#828282',
      fontSize: 12,
      fontWeight: 700,
    },
    colorPic: {
      width: 15,
      height: 15,
      background: '#2D7DD2',
      display: 'inline-block',
      marginLeft: 'auto',
    },
    lightColorPic: {
      width: 15,
      height: 15,
      background: 'rgba(45, 125, 210, 0.5)',
      display: 'inline-block',
      marginLeft: 'auto',
    },
    colorPicContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    budgetDetailsWrapper: {
      /* position: 'absolute',
      left: 0,
      bottom: 0, */
      paddingTop: 20,
      marginLeft: -30,
      marginRight: -30,
      width: 'calc(100% + 60px)',
      marginBottom: -12,
    },
    budgetDetailsHeader: {
      width: 'max-content',
      backgroundColor: 'white',
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      /* boxShadow: '1px -3px 2px 0px rgb(195 193 193 / 54%)', */
    },
    budgetDetailsContent: {
      backgroundColor: 'white',
      /* boxShadow: '1px -3px 2px 0px rgb(195 193 193 / 54%)', */
    },
    
    budgetContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 12,
      alignItems: 'center',
      '&:nth-child(odd)': {
          background: 'rgba(170, 170, 170, 0.1)'
      },
      '&:nth-child(even)': {
          background: '#fff'
      },
      '& > div': {
          wordBreak: 'break-all',
          paddingLeft: 3,
          paddingRight: 3,
      }
    },
});

export default budgetsReportStyle;