import { FILE_UPLOAD } from '../actions/types';

const initialState = {
  uploadResult: {},
  msa: null,
};

export const uploadResult = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD:
      return {
        ...state,
        uploadResult: action.payload
      };
      case 'MSA_FILE':
        return {
            ...state,
            msa: action.msa
        }
    default:
      return state;
  }
};
