
const ContactSidebarStyle = theme => ({
    ContactSidebarContainer: {
        '& .tabpanel': {
            height: 'calc(100% - 80px)',
            '& > div': {
                height: '100%'
            }
        },
        '& .tabs-wrapper': {
            background: 'white',
            boxShadow: 'none',
            '& .tab-item': {
                minWidth: '50%',
                maxWidth: '100px',
                height: '80px',
                maxHeight: '80px',
                '&:focus': {
                    outline: 'none'
                }
            },
            '& .MuiTabsIndicator': {
                backgroundColor: '#555'
            }
        },
        '& .tab-main-content': {
            borderTop: '1px solid #e1e1e1',
            marginTop: '-1px'
        },
    },
    sidebarPuller: {
        width: 10,
        height: 30,
        background: '#1D8ACF',
        position: 'absolute',
        left: -10,
        top: '48%',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 3,
        paddingRight: 3,
        display: 'flex',
        cursor: 'pointer'
    },
    verticalLine: {
        display: 'flex',
        flexBasis: 1,
        flexGrow: 0,
        flexShrink: 0,
        height: '100%',
        background: 'white',
        marginLeft: 1,
        marginRight: 1,
    }
});
export default ContactSidebarStyle;