import React, { Component } from 'react'
import { connect } from 'react-redux';
import BaseInput from 'components/BaseInput/index.jsx';
import Footer from 'components/Footer/index.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import MatButton from 'components/MatButton/index.jsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { confirm } from "../../../components/Confirm/index";
import { withStyles } from '@material-ui/core';
import approvePageStyle from './approvePageStyle.jsx';

import Moment from 'react-moment';
import $moment from 'moment';
import Cookies from 'js-cookie';

import { getAdminApproves, approveRequest, deleteRequest, clearApproves } from '../../../redux/actions/adminActions';

import { withTranslation  } from 'react-i18next';

class ApprovePage extends Component
{
    state = {
        isDataLoading: false,
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        userlist: [],
        loginInfo: {
            user: {
                first_name: '',
                last_name: ''
            }
        },
        sortFields: {
            type: 'name',
            direction: 'asc'
        }
    }

    constructor(props){
        super(props);
        this.state.loginInfo = { ...this.state.loginInfo, ...this.props['account']['loginStatus'] };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({isDataLoading: true});
        await this.props.clearApproves();
        // let res = await this.props.getAdminApproves(this.state.sortFields);
        let res = await this.props.getAdminApproves({
            type: '',
            direction: null
        });
        this.setState({isDataLoading: false});

        while (res.nextPage !== -1) {
            res = await this.props.getAdminApproves(this.state.sortFields, res.nextPage);
        }
    }

    actionDeleteRequest = async (e, approval) => {
        confirm("Confirm", "Are you sure delete this approval request?").then(
            async () => {
            console.log("Click OK");
            await this.props.deleteRequest(approval.id);
            this.loadData();
          },
          () => {
            console.log("Click Canceled");
          }
        );
    }

    // User/W9 approve
    actionApproveRequest = async (e, approval, flag) => {
        await this.props.approveRequest(approval.id, flag);
        this.loadData();
    }

    handleDownloadPdfButton = (e, w9Form) => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

        fetch(apiUrl + 'api/admin/print_w9_pdf', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                id: w9Form.id
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                window.location.href = apiUrl + 'api/admin/download/w9pdf/' + res.pdfFile;
            } else {
            }
        })
        .catch(err => {
            confirm("Error", "Server error occured. Please try later!", false);
        });
    }

    getFieldVal = (approval, type) => {
        const { t } = this.props;

        let val = null
        // Name
        if (type == 'name') {
            if (approval.category == "VENDOR" || approval.category == "ADMIN") {
                val = `${approval.user.first_name} ${approval.user.last_name}`;
            }
            else if (approval.category == "Invoice"
                || approval.category == "DebitNote"
                || approval.category == "CreditNote"
                || approval.category == "Reimbursements"
            ) {
                val = `${approval.user.first_name} ${approval.user.last_name}`;
            }
            else if (approval.category == "COMPANY_USERS") {
                val = `${approval.user.first_name} ${approval.user.last_name}`;
            }
            else {
                val = `${approval.w9form.name}`;
            }
        }
        // Category
        else if (type == 'category') {
            if (approval.category == "VENDOR" || approval.category == "ADMIN") {
                val = approval.category;
            }
            else if (approval.category == "Invoice"
                || approval.category == "DebitNote"
                || approval.category == "CreditNote"
                || approval.category == "Reimbursements"
            ) {
                val = approval.category;
            }
            else if (approval.category == "COMPANY_USERS") {
                val = t('Company Users');
            }
            else {
                val = approval.category;
            }
        }
        // Company
        else if (type == 'company') {
            if (approval.category == "VENDOR" || approval.category == "ADMIN") {
                val = approval.user.profile.company;
            }
            else if (approval.category == "Invoice"
                || approval.category == "DebitNote"
                || approval.category == "CreditNote"
                || approval.category == "Reimbursements"
            ) {
                val = approval.user.profile.company;
            }
            else if (approval.category == "COMPANY_USERS") {
                val = approval.user.profile.company;
            }
            else {
                val = approval.w9form.business_name;
            }
        }
        // Date
        else if (type == 'date') {
            val = $moment(approval.created_at).format('YYYY-MM-DD');
        }

        return val;
    }

    filteredApprovals = () => {
        const { approvePendings } = this.props.getApprovalsResult.data;
        const { query } = this.state;
        let filteredList = approvePendings.filter(approval => {
            if (query) {
                try {

                    const lowerQuery = query.toLowerCase();

                    if (approval['category'] === "W9") {
                        if (
                            approval['w9form']
                            && approval['w9form']['name']
                            && approval['w9form']['name'].toLowerCase().includes(lowerQuery)
                        ) {
                            return true;
                        }
    
                        if (
                            approval['w9form']
                            && approval['w9form']['business_name']
                            && approval['w9form']['business_name'].toLowerCase().includes(lowerQuery)
                        ) {
                            return true;
                        }
                    } else {
                        if (approval['user']
                            && approval['user']['first_name']
                            && approval['user']['first_name'].toLowerCase().includes(lowerQuery)
                        ) {
                            return true;
                        }
    
                        if (approval['user']
                            && approval['user']['last_name']
                            && approval['user']['last_name'].toLowerCase().includes(lowerQuery)
                        ) {
                            return true;
                        }
    
                        if (approval['company']
                            && approval['company'].toLowerCase().includes(lowerQuery)
                        ) {
                            return true;
                        }
                    }
                    if (approval['category'].toLowerCase().includes(lowerQuery)) {
                        return true;
                    }
    
    
                    if ('approve'.includes(lowerQuery)) {
                        if(approval.category == "Invoice" || approval.category == "DebitNote" || approval.category == "CreditNote" || approval.category == "Reimbursements") {
                            if (approval.invoice.approved_status == 'Requested') {
                                return true;
                            }
    
                            if (approval.invoice.approved_status == 'Rejected') {
                                return true;
                            }
                        } else if (!approval['is_approved']) {
                            return true;
                        }
                    }
    
                    if ('rejected'.includes(lowerQuery)) {
                        if(approval.category == "Invoice" || approval.category == "DebitNote" || approval.category == "CreditNote" || approval.category == "Reimbursements") {
                            if (approval.invoice.approved_status == 'Requested') {
                                return true;
                            }
    
                            if (approval.invoice.approved_status == 'Approved') {
                                return true;
                            }
                        } else if (approval['is_approved']) {
                            return true;
                        }
                    }
                    return false;

                }
                catch(err) {
                    return false;
                }
            } else {
                return true;
            }
        });

        const { type, direction } = this.state.sortFields;
        const sortBy = type;
        const sortAsc = direction === '' ? null : direction === 'asc';
        
        filteredList = [...filteredList];
        filteredList.sort((a, b) => {
            if (sortBy == '' || sortAsc == null) {
                return 1;
            }

            let aVal = this.getFieldVal(a, sortBy);
            let bVal = this.getFieldVal(b, sortBy);

            let retVal = 1;
            try {
                if (aVal.toLowerCase() < bVal.toLowerCase()) {
                    retVal = -1;
                }
            } catch(err) {
            }

            retVal = !sortAsc ? -1 * retVal : retVal;
            return retVal;
        });

        return filteredList;
    }

    showApprovalList = () => {
        const { classes, t } = this.props;
        if(typeof this.props.getApprovalsResult.data.approvePendings != 'undefined'
            && this.filteredApprovals().length
        ){
            return this.filteredApprovals().map((approval, key) => {
                if (approval.category == "VENDOR" || approval.category == "ADMIN") {
                    return (
                        <div key={key} className={ classes.tableRow }>
                            <div className={ classes.columnName }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.first_name} {approval.user.last_name}</span>
                            </div>
                            <div className={ classes.columnCategory }>
                                <span style={{ marginLeft: '20px' }}>{approval.category}</span>
                            </div>
                            <div className={ classes.columnCompany }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.profile.company}</span>
                            </div>
                            <div className={ classes.columnDate }>
                                <span style={{ marginLeft: '20px' }}>
                                    <Moment format="DD/MM/YY">{approval.created_at}</Moment>
                                </span>
                            </div>
                            <div className={ classes.columnView }>
                                <a onClick={e => this.showDetail(e, approval)} className="mr-2">
                                    <img src="/assets/img/newui/eye.png" />
                                </a>
                                <a onClick={e => this.actionDeleteRequest(e, approval)}>
                                    <img src="/assets/img/newui/trash.png" />
                                </a>
                            </div>
                            <div className={ classes.columnActions }>
                                <div className={ classes.suspendContainer } style={{'display': (approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.approveBtn} onClick={e => this.actionApproveRequest(e, approval, true)}>{t('Approve')}</div>
                                </div>
                                <div className={ classes.suspendContainer } style={{'display': (!approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.rejectBtn} onClick={e => this.actionApproveRequest(e, approval, false)}>{t('Reject')}</div>
                                </div>
                            </div>
                        </div>
                    );
                }
                else if (approval.category == "Invoice"
                    || approval.category == "DebitNote"
                    || approval.category == "CreditNote"
                    || approval.category == "Reimbursements"
                ) {
                    var invoice = approval.invoice;
                    return (
                        <div key={key} className={ classes.tableRow }>
                            <div className={ classes.columnName }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.first_name} {approval.user.last_name}</span>
                            </div>
                            <div className={ classes.columnCategory }>
                                <span style={{ marginLeft: '20px' }}>{approval.category}{invoice.approved_status == 'Rejected'? ' (Rejected)': ''}</span>
                            </div>
                            <div className={ classes.columnCompany }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.profile.company}</span>
                            </div>
                            <div className={ classes.columnDate }>
                                <span style={{ marginLeft: '20px' }}>
                                    <Moment format="DD/MM/YY">{approval.created_at}</Moment>
                                </span>
                            </div>
                            <div className={ classes.columnView }>
                                <a onClick={e => this.showDetail(e, approval)} className="mr-2">
                                    <img src="/assets/img/newui/eye.png" />
                                </a>
                                <a onClick={e => this.actionDeleteRequest(e, approval)}>
                                    <img src="/assets/img/newui/trash.png" />
                                </a>
                            </div>
                            <div className={ classes.columnActions }>
                                <div className={ classes.suspendContainer } style={{'display': (invoice.approved_status == 'Approved' ? 'none': 'flex')}}>
                                    <div className={classes.approveBtn} onClick={e => this.actionApproveRequest(e, approval, true)}>{t('Approve')}</div>
                                </div>
                                <div className={ classes.suspendContainer } style={{'display': (invoice.approved_status == 'Rejected'? 'none': 'flex')}}>
                                    <div className={classes.rejectBtn} onClick={e => this.actionApproveRequest(e, approval, false)}>{t('Reject')}</div>
                                </div>
                            </div>
                        </div>
                    );
                }
                else if (approval.category == "COMPANY_USERS") {
                    return (
                        <div key={key} className={ classes.tableRow }>
                            <div className={ classes.columnName }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.first_name} {approval.user.last_name}</span>
                            </div>
                            <div className={ classes.columnCategory }>
                                <span style={{ marginLeft: '20px' }}>{t('Company Users')}</span>
                            </div>
                            <div className={ classes.columnCompany }>
                                <span style={{ marginLeft: '20px' }}>{approval.user.profile.company}</span>
                            </div>
                            <div className={ classes.columnDate }>
                                <span style={{ marginLeft: '20px' }}>
                                    <Moment format="DD/MM/YY">{approval.created_at}</Moment>
                                </span>
                            </div>
                            <div className={ classes.columnView }>
                                <a onClick={e => this.showDetail(e, approval)} className="mr-2">
                                    <img src="/assets/img/newui/eye.png" />
                                </a>
                                <a onClick={e => this.actionDeleteRequest(e, approval)}>
                                    <img src="/assets/img/newui/trash.png" />
                                </a>
                            </div>
                            <div className={ classes.columnActions }>
                                <div className={ classes.suspendContainer } style={{'display': (approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.approveBtn} onClick={e => this.actionApproveRequest(e, approval, true)}>{t('Approve')}</div>
                                </div>
                                <div className={ classes.suspendContainer } style={{'display': (!approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.rejectBtn} onClick={e => this.actionApproveRequest(e, approval, false)}>{t('Reject')}</div>
                                </div>
                            </div>
                        </div>
                    );
                }
                else{
                    return (
                        <div key={key} className={ classes.tableRow }>
                            <div className={ classes.columnName }>
                                <span style={{ marginLeft: '20px' }}>{approval.w9form.name}</span>
                            </div>
                            <div className={ classes.columnCategory }>
                                <span style={{ marginLeft: '20px' }}>{approval.category}</span>
                            </div>
                            <div className={ classes.columnCompany }>
                                <span style={{ marginLeft: '20px' }}>{approval.w9form.business_name}</span>
                            </div>
                            <div className={ classes.columnDate }>
                                <span style={{ marginLeft: '20px' }}>
                                    <Moment format="DD/MM/YY">{approval.created_at}</Moment>
                                </span>
                            </div>
                            <div className={ classes.columnView }>
                                <a onClick={e => this.showDetail(e, approval)} className="mr-2">
                                    <img src="/assets/img/newui/eye.png" />
                                </a>
                                <a onClick={e => this.actionDeleteRequest(e, approval)}>
                                    <img src="/assets/img/newui/trash.png" />
                                </a>
                            </div>
                            <div className={ classes.columnActions }>
                                <div className={ classes.suspendContainer } style={{'display': (approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.approveBtn} onClick={e => this.actionApproveRequest(e, approval, true)}>{t('Approve')}</div>
                                </div>
                                <div className={ classes.suspendContainer } style={{'display': (!approval.is_approved? 'none': 'flex')}}>
                                    <div className={classes.rejectBtn} onClick={e => this.actionApproveRequest(e, approval, false)}>{t('Reject')}</div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })
        }else{
            return null;
        }
    }

    showDetail = (e, approval) => {
        this.setState({detailData: approval});
        this.setState({detailModalOpen: true});
    }
    closeDetail = () => {
        this.setState({detailData: null});
        this.setState({detailModalOpen: false});
    }

    showVUserList = (approval) => {
        const { classes } = this.props;
        var vUsers = approval.vUsers;
        return vUsers.map((vUser, key) => {
            return(
                <Grid key={key} container spacing={24} style={{padding: '0.5rem 2rem'}}>
                    <Grid item xs={2}>
                        Type: {vUser.profile.user_type}
                    </Grid>
                    <Grid item xs={3}>
                        Name: {vUser.first_name} {vUser.last_name}
                    </Grid>
                    <Grid item xs={4}>
                        Email: {vUser.email}
                    </Grid>
                    <Grid item xs={3}>
                        Phone: {vUser.profile.phone}
                    </Grid>
                </Grid>
            );
        })
    }

    sortByKey = (e, type) => {
        e.preventDefault();
        let sortFields = this.state.sortFields;
        if (sortFields['type'] != type) {
            sortFields['type'] = type;
            sortFields['direction'] = 'asc';
        } else {
            sortFields['type'] = type;
            sortFields['direction'] = sortFields['direction'] == '' ? 'asc' : (sortFields['direction'] == 'desc' ? '' : 'desc');
        }
        this.setState({sortFields: sortFields});
        console.log(sortFields);
        /* setTimeout(() => {
            console.log(this.state);
            this.loadData();
        }, 100); */
    }

    renderSortIcon = (type) => {
        if (this.state.sortFields['type'] != type) {
            return null;
        }
        else if (this.state.sortFields['direction'] == '') {
            return null;
        }
        else if (this.state.sortFields['direction'] == 'asc') {
            return (
                <ArrowDropUpIcon />
            );
        }
        else {
            return (
                <ArrowDropDownIcon />
            );
        }
    }


    showAttachFiles = (files) => {
        if(typeof files != 'undefined' && files != null){
            return files.map((file, key) => {
                if(file == '') return null;
                return (
                    <span key={key} style={{paddingLeft: '0.5rem'}}>
                        { this.showEachAttachFiles(file) }
                    </span>
                )
            })
        }else {
            return null;
        }
    }
    showEachAttachFiles = (file) => {
        var filebasename = file.substring(file.lastIndexOf('/') + 1);
        var filebasenameUrl = encodeURIComponent(filebasename);
        return (
            <a href={this.state.apiUrl + 'api/download/file/' + filebasenameUrl} style={{color: '#333'}} target="_blank">
                <AttachFileIcon style={{transform: 'rotate(45deg)'}} />
            </a>
        );
    }

    // User Detail Dialog
    showDetailDialog = () => {
        const { classes, t } = this.props;
        if(typeof this.state.detailData == 'undefined' || this.state.detailData == null) return null;
        var approval = this.state.detailData;
        if (approval.category == "VENDOR" || approval.category == "ADMIN") {
            var user = approval.user;
            return (
                <Dialog
                    open={this.state.detailModalOpen}
                    onClose={this.closeDetail}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="form-dialog-title">{t('User Details')}: {user.first_name} {user.last_name}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={3}>
                                {t('First Name')}: {user.first_name}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Last Name')}: {user.last_name}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Email')}: {user.email}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Phone')}: {user.phone}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Category')}: {user.profile.user_type}
                            </Grid>
                            <Grid item xs={9}>
                                {t('Company')}: {user.profile.company}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Status')}: {user.profile.is_approved? 'Yes': 'No'}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Register')}: <Moment format="DD/MM/YY">{user.date_joined}</Moment>
                            </Grid>
                            <Grid item xs={3}>
                                {t('Last login')}: <Moment format="DD/MM/YY">{user.last_login}</Moment>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <MatButton onClick={this.closeDetail} color="primary">
                            {t('Close')}
                        </MatButton>
                    </DialogActions>
                </Dialog>
            );
        } else if (approval.category == "Invoice" || approval.category == "DebitNote" || approval.category == "CreditNote" || approval.category == "Reimbursements") {
            var invoice = approval.invoice;
            var customer = approval.customer;
            var vendor = approval.user;
            var vendorW9 = approval.vendorW9;
            var files = invoice.attachments.split(',');
            return (
                <Dialog
                    open={this.state.detailModalOpen}
                    onClose={this.closeDetail}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="form-dialog-title">{invoice.type} {t('Details')} {invoice.type != 'Invoice' && invoice.type != 'Reimbursements' ? ' - Related Invoice Key: ' + invoice.parent.key: ''}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24} style={{display: (invoice.key != null && invoice.key.length > 0)? 'flex': 'none'}}>
                            <Grid item xs={12}>
                                {t('Key')}: {invoice.key}
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={3}>
                                {t('Requester')}: {invoice.creator.first_name} {invoice.creator.last_name}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Vendor')}: {invoice.vendor.first_name} {invoice.vendor.last_name}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Event ID')}: {invoice.eventId}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Amount')}: ${invoice.invoiceValue}
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={3}>
                                {t('Category')}: {invoice.category}
                            </Grid>
                            <Grid item xs={3}>
                                Sub Categ: {invoice.sub_category}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Payment terms')}: {invoice.pay_netdays} {t('days')}
                            </Grid>
                            <Grid item xs={3}>
                                {t('Attachments')}: { this.showAttachFiles(files) }
                            </Grid>
                        </Grid>

                        {
                            invoice.goods_provided_at != null || (invoice.goods_description != null && invoice.goods_description != '') ?
                            (
                                <React.Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6} className={ classes.blockLabel }>
                                            {t('Goods or Services')}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            {t('Provided at')} : {
                                                invoice.goods_provided_at != null ? (
                                                    <Moment format="DD/MM/YY">{ invoice.goods_provided_at }</Moment>
                                                ) : null
                                            }
                                        </Grid>
                                        <Grid item xs={9}>
                                            {t('Description')} : { invoice.goods_description }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : null
                        }

                        <Grid container spacing={24}>
                            <Grid item xs={6}></Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={6} className={ classes.blockLabel }>
                                {t('Vendor Business')}
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={3}>
                                {t('Name')} : { vendor.first_name } { vendor.last_name }
                            </Grid>
                            <Grid item xs={3}>
                                {t('Phone')} : { vendor.profile.phone }
                            </Grid>
                            <Grid item xs={4}>
                                {t('Email')} : { vendor.email }
                            </Grid>
                        </Grid>

                        <Grid container spacing={24}>
                            <Grid item xs={6}></Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={6} className={ classes.blockLabel }>
                                {t('Payment')}
                            </Grid>
                        </Grid>
                        {
                            vendorW9.payment_mode == 'paypal' ?
                            (
                                <React.Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            Paypal {t('Email')} : { vendorW9.paypal_email }
                                        </Grid>
                                        <Grid item xs={6}>
                                            Paypal ID : { vendorW9.paypal_id }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : vendorW9.payment_mode == 'bank' ?
                            (
                                <React.Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={4}>
                                            {t('Bank Account')} No. : { vendorW9.bank_account_number }
                                        </Grid>
                                        <Grid item xs={4}>
                                            {t('Routing Number')} : { vendorW9.bank_routing_number }
                                        </Grid>
                                        <Grid item xs={4}>
                                            {t('Name on Account')} : { vendorW9.bank_name_on_account }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={4}>
                                            {t('Bank Name')} : { vendorW9.bank_name_bank }
                                        </Grid>
                                        <Grid item xs={8}>
                                            {t('Bank Address')} : { vendorW9.bank_address_bank }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : vendorW9.payment_mode == 'googlepay' ?
                            (
                                <React.Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            Google Pay {t('Email')} : { vendorW9.googlepay_email }
                                        </Grid>
                                        <Grid item xs={6}>
                                            Google Pay ID : { vendorW9.googlepay_id }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : null
                        }

                        {
                            customer.permalink == 'wic' ?
                            (
                                <React.Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6} className={ classes.blockLabel }>
                                            WIC {t('Information')}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            {t('Name')} : { customer.name }
                                        </Grid>
                                        <Grid item xs={3}>
                                            {t('Email')} : { customer.contactEmail }
                                        </Grid>
                                        <Grid item xs={6}>
                                            {t('Address')} : { customer.address }
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            ) : null
                        }


                    </DialogContent>
                    <DialogActions>
                        <MatButton onClick={this.closeDetail} color="primary">
                            {t('Close')}
                        </MatButton>
                    </DialogActions>
                </Dialog>
            );
        } else if(approval.category == "COMPANY_USERS"){
            var user = approval.user;
            return (
                <Dialog
                    open={this.state.detailModalOpen}
                    onClose={this.closeDetail}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="form-dialog-title">{t('User Details')}: {user.first_name} {user.last_name}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                {t('Company')}: {user.profile.company}
                            </Grid>
                            <Grid item xs={6}>
                                {t('Requester')}: {user.first_name} {user.last_name}
                            </Grid>
                            <Grid item xs={12}>
                                {t('Users')}:
                            </Grid>
                            {this.showVUserList(approval)}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <MatButton onClick={this.closeDetail} color="primary">
                            {t('Close')}
                        </MatButton>
                    </DialogActions>
                </Dialog>
            );
        } else {
            var w9form = approval.w9form;
            var requesterName = approval.name;
            let requesterEmail = approval.user_email;
            return (
                <Dialog
                    open={this.state.detailModalOpen}
                    onClose={this.closeDetail}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="form-dialog-title">W9Form {t('Details')}: {w9form.name} - {w9form.business_name}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <strong>{t('Requester')}: {requesterName}</strong>&nbsp;&nbsp;({ requesterEmail })
                            </Grid>
                        </Grid>
                        {
                            approval.w9Histories.map((w9Row, key) => {
                                return this.bindW9Details(w9Row, key);
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <MatButton onClick={this.closeDetail} color="primary">
                            {t('Close')}
                        </MatButton>
                    </DialogActions>
                </Dialog>
            );
        }
    }

    bindW9Details (w9form, key) {
        const { classes, t } = this.props;
        return (
            <div key={key} className={classes.w9DetailsItem}>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        {t('Name')}: {w9form.name}
                    </Grid>
                    <Grid item xs={6}>
                        {t('Business Name')}: {w9form.business_name}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        {t('Federal Tax')}: {w9form.federal_tax}
                    </Grid>
                    <Grid item xs={3} style={{ display: (w9form.federal_tax == 'limited'? 'flex': 'none')}}>
                        {t('Limited Detail')}: {w9form.tax_limited_detail}
                    </Grid>
                    <Grid item xs={3} style={{ display: (w9form.federal_tax == 'other'? 'flex': 'none')}}>
                        {t('Other Detail')}: {w9form.tax_other_detail}
                    </Grid>
                    <Grid item xs={3} style={{ display: (w9form.exempt_payee_code != ''? 'flex': 'none')}}>
                        Exempt Payee: {w9form.exempt_payee_code}
                    </Grid>
                    <Grid item xs={3} style={{ display: (w9form.exempt_fatch != ''? 'flex': 'none')}}>
                        Exempt Fatch: {w9form.exempt_fatch}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        {t('Address')}: {w9form.address}
                    </Grid>
                    <Grid item xs={6}>
                        {t('Requester’s name and address')}: {w9form.requester_name_address}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        {t('City, State, ZipCode')}: {w9form.city_state_zipcode}
                    </Grid>
                    <Grid item xs={6} style={{ display: (w9form.tin_security_number != ''? 'flex': 'none')}}>
                        {t('Tin Security Number')}: {w9form.tin_security_number}
                    </Grid>
                    <Grid item xs={6} style={{ display: (w9form.tin_employer_id != ''? 'flex': 'none')}}>
                        {t('Tin Employer ID')}: {w9form.tin_employer_id}
                    </Grid>
                </Grid>
                <div className={`status`}>
                    <Moment format="MMM, D, YYYY hh:mmA">{w9form.created_at}</Moment>
                    <IconButton className={classes.iconButton} type="submit"
                        onClick={e => this.handleDownloadPdfButton(e, w9form)} aria-label="Download">
                        <PictureAsPdfIcon />
                    </IconButton>
                </div>
            </div>
        );
    }

    handleChangeQuery = (event) => {
        this.setState({ query: event.target.value })
    }

    render(){
        const { classes, t } = this.props;
        const { query } = this.state;
        return(
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Vendor setup approvals')}
                    </div>
                    <div>
                        <BaseInput
                            type="search"
                            style={{ background: 'rgba(255,255,255, 0.6)', width: 230 }}
                            placeholder="Search..."
                            onChange={ this.handleChangeQuery }
                            value={ query }
                        />
                    </div>
                </div>
                <div className={ classes.tableContainer }>
                    <div className={ classes.tableHeader }>
                        <div className={ classes.columnName }>
                            <a href="#" onClick={ e => this.sortByKey(e, 'name') }>
                                {t('Name')}
                                { this.renderSortIcon('name') }
                            </a>
                        </div>
                        <div className={ classes.columnCategory }>
                            <a href="#" onClick={ e => this.sortByKey(e, 'category') }>
                                {t('Category')}
                                { this.renderSortIcon('category') }
                            </a>
                        </div>
                        <div className={ classes.columnCompany }>
                            <a href="#" onClick={ e => this.sortByKey(e, 'company') }>
                                {t('Company Name')}
                                { this.renderSortIcon('company') }
                            </a>
                        </div>
                        <div className={ classes.columnDate }>
                            <a href="#" onClick={ e => this.sortByKey(e, 'date') }>
                                {t('Date')}
                                { this.renderSortIcon('date') }
                            </a>
                        </div>
                        <div className={ classes.columnView }>
                            {t('Actions')}
                        </div>
                        <div className={ classes.columnActions }>
                            {t('Status')}
                        </div>
                    </div>
                    { this.showApprovalList() }
                    { this.showDetailDialog() }
                </div>
                <Footer />
            </div>
        )

    }

}


const mapStateToProps = state => ({
    commonResult: state.commonResult, getApprovalsResult: state.getApprovalsResult, account: state.account
});
const mapDispatchToProps = {clearApproves, getAdminApproves, approveRequest, deleteRequest}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(approvePageStyle)(ApprovePage)));