import { LOGIN_USER, LOGIN_FORGOT, PWD_RESET } from '../actions/types';

var isLogedIn = false;
var token = '';
var user = null;

if (typeof localStorage.getItem('token') != 'undefined' && localStorage.getItem('token') != null) {
  token = localStorage.getItem('token');
}
if (typeof localStorage.getItem('user') != 'undefined' && localStorage.getItem('user') != null) {
  var userJsonStr = localStorage.getItem('user');
  try {
    user = JSON.parse(userJsonStr);
    isLogedIn = true;
  }
  catch(err) {
    isLogedIn = false;
    user = null;
    token = '';
  }
}


let initialState = {
  loginStatus: {
    status: isLogedIn,
    isLogedIn: token != '',
    token: token,
    message: (token != '')? 'Loged In': '',
    user: user
  },
  forgotStatus: {
    status: false
  },
  resetStatus: {
    status: false
  }
};


if (token !== '') {
  const apiUrl = process.env.NODE_ENV === 'production' ?
    (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) :
    process.env.REACT_APP_DEV_BACKEND;
  
  fetch(apiUrl + 'api/account/profile', {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + token,
      'content-type': 'application/json'
    },
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.status + "\"");
      }
      return res.json();
    })
    .then(result => {
      if (result.status) {
        initialState.loginStatus.user = result.user;
        window.localStorage.setItem('user', JSON.stringify(result.user));
      }
    })
    .catch(err => {
    })
}

export const account = (state = initialState, action) => {
  let newState = Object.assign({...state});
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loginStatus: action.payload
      };
    case LOGIN_FORGOT:
      return {
        ...state,
        forgotStatus: action.payload
      };
    case PWD_RESET:
      return {
        ...state,
        resetStatus: action.payload
      };
    case 'SAVE_PROFILE_CHANGES':
        newState.loginStatus.user.first_name = action.payload.first_name ? action.payload.first_name : newState.loginStatus.user.first_name;
        newState.loginStatus.user.last_name = action.payload.last_name ? action.payload.last_name : newState.loginStatus.user.last_name;
        newState.loginStatus.user.profile.phone = action.payload.phone ? action.payload.phone : newState.loginStatus.user.profile.phone;
        newState.loginStatus.user.profile.invoice_email = action.payload.invoice_email ? action.payload.invoice_email : newState.loginStatus.user.profile.invoice_email;

        window.localStorage.setItem('user', JSON.stringify(newState.loginStatus.user));
        return {
            ...newState
        }
    case 'SAVE_AVATAR':
        newState.loginStatus.user.profile.avatar = action.payload.avatar;
        window.localStorage.setItem('user', JSON.stringify(newState.loginStatus.user));
        return {
            ...newState
        }
    case 'CLEAR_ERR_MSG':
      return {
        ...state,
        ...{
          loginStatus: {
            ...state.loginStatus,
            ...{
              message: ''
            }
          }
        }
      };
    case 'SET_USER_PROFILE':
      return {
        ...state,
        ...{
          loginStatus: {
            ...state.loginStatus,
            ...{
              user: action.payload
            }
          }
        }
      };
        

    default:
      return state;
  }
};
