const signatureStyle = theme => ({
    container: {
        height: '48px',
        width: '70px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(251, 220, 128, 0.8)',
        border: '2px solid #2463D1',
        borderRadius: 3,
        fontSize: 10,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'move'
    }
});

export default signatureStyle;