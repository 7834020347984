import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import { getOrganizationList } from '../../redux/actions/commonActions';

const suggestions = [];

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.company}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    border: '1px solid #9e9e9e',
    height: '55px',
    fontXize: '1.3rem',
    background: '#fafafa',
    paddingLeft: '20px',
    borderRadius: '6px',
    marginTop: '40px'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    height: '55px',
    flexWrap: 'wrap',
    fontFamily: 'Mulish',
    fontSize: '1.3rem',
    '&::after': {
      borderBottom: '0'
    },
    '&::before': {
      border: '0',
      borderBottom: '0',
      display: 'none'
    },
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    '&::placeholder': {
      color: '#777 !important',
      opacity: '1'
    }
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

let popperNode;

//function CustomAutocomplete(props) {
class CustomAutocomplete extends Component
{

  constructor(props){
    super(props)
    this.props.getOrganizationList();
  }

  getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? this.props.orgList.data.list
      : this.props.orgList.data.list.filter(org => {
          const keep =
            count < 5 && org.company.substring(0, inputLength).toLowerCase() === inputValue;
          if (keep) {
            count += 1;
          }
          return keep;
        });
  }

  handleInputChange = (e) => {
    console.log(e);
  }

  render(){
    const { classes, required, display, placeholder, label, className, ...rest } = this.props;

    if(!display) return null;

    return (
      <Downshift className={`organization-input`} {...rest} >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => (
          <div className={`${classes.container} organization-input`}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                placeholder: placeholder,
                required: required
              }),
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.getSuggestions(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.company }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}

const mapStateToProps = state => ({
  orgList: state.orgList
});
const mapDispatchToProps = {getOrganizationList}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomAutocomplete));