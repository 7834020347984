import React from 'react';
import PropTypes from "prop-types";
import Button from 'components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { confirmable, createConfirmation } from "react-confirm";

class SuccessConfirm extends React.Component {
    renderCancelButton() {
        const {
          proceedLabel,
          cancelLabel,
          title,
          confirmation,
          showCancel,
          show,
          proceed,
          dismiss,
          cancel,
          enableEscape = true
        } = this.props;

        if (showCancel) {
            return (
                <Button onClick={cancel} style={{ width: 130, height: 30, color:'white', background: '#FFC700' }}>
                    {cancelLabel}
                </Button>
            );
        } else {
            return null;
        }
    }

    renderSubTitle = () => {
        const {
            subTitle,
        } = this.props;

        if (typeof subTitle == "undefined") {
            return null;
        }

        return (
            <p>{ subTitle }</p>
        );
    }

    renderEmail = () => {
        const {
            email,
        } = this.props;

        if (typeof email == "undefined") {
            return null;
        }

        return (
            <p><strong>Email</strong>: { email }</p>
        );
    }

    renderPhone = () => {
        const {
            phone,
        } = this.props;

        if (typeof phone == "undefined") {
            return null;
        }

        return (
            <p><strong>Phone</strong>: { phone }</p>
        );
    }

    render() {
        const {classes} = this.props;

        const {
            proceedLabel,
            cancelLabel,
            title,
            subTitle,
            confirmation,
            showCancel,
            show,
            proceed,
            dismiss,
            cancel,
            enableEscape = true
        } = this.props;

        return (
            <div className="static-modal">
                <Dialog
                    open={show}
                    onClose={dismiss}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={
                            {
                                'width': '100%',
                                'padding': '1rem 1rem',
                                'backgroundColor': '#1D8ACF',
                                display: 'flex'
                            }
                        }>
                        <img src="/assets/img/tick-inside-circle.png" alt="attachment" style={{paddingRight: '1rem', display: 'inline-block'}} />
                        <span style={{'color': 'white'}}>{ title }</span>
                    </DialogTitle>
                    <DialogContent style={{padding: 0}}>
                        <DialogContentText id="alert-dialog-description" style={{padding: '1.5rem 1.5rem 0 1.5rem'}}>
                            { confirmation }
                            { this.renderSubTitle() }
                            { this.renderEmail() }
                            { this.renderPhone() }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{'justifyContent': 'center'}}>
                        { this.renderCancelButton() }
                        <Button onClick={proceed} color="primary" autoFocus style={{ backgroundColor: '#1D8ACF', color: 'white', width: 130, height: 30}}>
                            {proceedLabel}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


SuccessConfirm.propTypes = {
    okLabbel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    cancel: PropTypes.func, // called when cancel button is clicked.
    dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
};

export function successConfirm(
    title,
    confirmation,
    showCancel = true,
    proceedLabel = "OK",
    cancelLabel = "Cancel",
    options = {}
) {
    return createConfirmation(confirmable(SuccessConfirm))({
        title,
        confirmation,
        showCancel,
        proceedLabel,
        cancelLabel,
        ...options
    });
}