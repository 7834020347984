import * as Type from './types';
import Cookies from 'js-cookie';

export const setSocket = (socket) => (dispatch, getState) => {

    dispatch({
        type: Type.CHAT,
        payload: {
            socket: socket
        }
    });

    return;
};

export const saveContacts = (contacts) => (dispatch, getState) => {

    dispatch({
        type: Type.CHAT,
        payload: {
            contacts: contacts
        }
    });

    return;
};

export const setUserOnline = (userId, isOnline) => (dispatch, getState) => {

    const chat = getState()['chat'];
    let contacts = {...chat['contacts']};
    
    if (typeof contacts[userId] != 'undefined') {
        contacts[userId]['profile']['is_online'] = isOnline;
    }

    dispatch({
        type: Type.CHAT,
        payload: {
            contacts: contacts
        }
    });

    return;
};


export const setTargetUser = (userId) => (dispatch, getState) => {

    const chat = getState()['chat'];
    let contacts = chat['contacts'];
    
    if (typeof contacts[userId] == 'undefined') {
        return;
    }

    dispatch({
        type: Type.CHAT,
        payload: {
            currentTargetUser: userId
        }
    });

    return;
};


export const clearChatMessage = (userId) => (dispatch, getState) => {

    const chat = getState()['chat'];
    let messages = {...chat['messages']};
    
    messages[userId] = [];

    dispatch({
        type: Type.CHAT,
        payload: {
            messages: messages
        }
    });

    return;
};


export const saveChatMessage = (userId, fromUserId, targetUserId, message, time) => (dispatch, getState) => {

    const chat = getState()['chat'];
    let messages = {...chat['messages']};
    
    if (typeof messages[userId] == 'undefined') {
        messages[userId] = [];
    }

    messages[userId].push({
        fromUserId,
        targetUserId,
        message,
        time
    });

    dispatch({
        type: Type.CHAT,
        payload: {
            messages: messages
        }
    });

    return;
};

