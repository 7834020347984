const searchInputStyle = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
    },
    input: {
        paddingLeft: 20,
        paddingRight: 30,
        width: '100%',
        background: 'white',
        height: 40,
        borderRadius: 20,
        borderColor: 'white',
        '&::placeholder': {
            color: 'black'
        }
    },
    searchIcon: {
        position: 'absolute',
        right: 15,
        color: 'black'
    },
    closeIcon: {
        position: 'absolute',
        right: 5,
        cursor: 'pointer'
    }
});

export default searchInputStyle;