import { isAbsolute } from "path";

const eventsPageStyle = theme => ({
    tableContainer: {
        position: 'relative',
        background: '#F3F2FF'
    },
    tableHeader: {
        background: '#1291F9',
        padding: '16px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #E8E8E8',
        fontWeight: 600,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 16,
        color: 'white',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderBottomWidth: 0,
        '& $columnName': {
            paddingLeft: '20px',
            justifyContent: 'center',
            textAlign: 'center',
        },
        '& $columnCategory': {
            paddingLeft: '0px',
            justifyContent: 'center',
        },
        '& $columnCompany': {
            paddingLeft: '0px',
            justifyContent: 'center',
        },
        '& $columnView': {
            paddingLeft: '0px',
            justifyContent: 'center',
        },
        '& $columnActions': {
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '0px',
            justifyContent: 'center',
        }
    },
    columnName: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnDescription: {
        flex: 6,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnDate: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnActions: {
        flex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            '& img': {
                height: '100%',
                width: 15,
                height: 15,
            },
            padding: '5px 8px',
        }
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        borderTopWidth: 0,
        background: '#fff',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        position: 'relative'
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid #E8E8E8',
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#0072BB',
    },
    addEventWrapper: {
        paddingBottom: 8,
        display: 'flex',
        justifyContent: 'flex-end',

        '& svg': {
            /* width: '2.5rem',
            height: '2.5rem',
            color: '#0072BB', */
        }
    },
    addEventDialog: {
    },
    inputName: {
        width: '100%'
    },
    inputDescription: {
        width: '100%',
        minHeight: '8rem',
        border: '1px solid #E8E8E8'
    },
    btnSave: {
        background: '#2687C5',
        width: 120,
        height: 30,
        color: 'white',
        marginRight: 30,
        marginBottom: 15,
    },
    btnCancel: {
        background: '#FFC700',
        width: 120,
        height: 30,
        color: 'white',
        marginRight: 20,
        marginBottom: 15,
    },
    dlgContent: {
        maxWidth: 600,
    },
    btnAddEvent: {
        fontSize: 14,
        color: '#1291F9',
        background: 'rgba(0, 153, 255, 0.2)',
        width: 155,
        height: 34, 
        marginBottom: 10,
        '& svg': {
            width: '1.5rem',
            height: '1.5rem',
            marginLeft: 5,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #1291F9'
    }
})

export default eventsPageStyle;