import React, { Component } from "react";

import { connect } from "react-redux";
import LeftSidebarStyle from "./LeftSidebarStyle";
import Check from "@material-ui/icons/Check";
import { withStyles } from "@material-ui/core";
import { NavLink, Redirect } from "react-router-dom";
import SideNavButton from "components/SideNavButton/index.jsx";
import AddIcon from "@material-ui/icons/Add";

import Dialog from "@material-ui/core/Dialog";
import Modal from "@material-ui/core/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Cookies from "js-cookie";

import ImageUploader from "react-images-upload";

import { updateCustomerLogo } from "../../redux/actions/accountActions";
import { withTranslation  } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class LeftSidebar extends Component {
  state = {
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
        profile: {
          user_type: "BUYER",
        },
      },
    },
    logoModalOpen: false,
    logoImage: "",
    logoUploadingError: "",
    logoIsUploading: false,
  };

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };
  }

  handleClickCheck = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  handleClickOpenLogoModal = () => {
    this.setState({ logoModalOpen: true });
  };

  handleClickOpenLogoModalClose = () => {
    this.setState({ logoImage: "" });
    this.setState({ logoModalOpen: false });
  };

  onLogoDrop = (picture) => {
    if (picture.length > 0) {
      var reader = new FileReader();
      reader.onload = () => {
        var dataURL = reader.result;
        let img = document.getElementById("logo-image");
        img.src = dataURL;
        this.setState({ logoImage: dataURL });
      };
      reader.readAsDataURL(picture[picture.length - 1]);
    }
    this.setState({ logoImage: "" });
  };

  uploadLogoImage = () => {
    if (this.state.logoIsUploading) {
      return;
    }

    if (this.state.logoImage == "") {
      return;
    }

    const data = {
      logo: this.state.logoImage,
    };

    const loginStatus = this.props.account.loginStatus;

    this.setState({ logoIsUploading: true });

    return fetch(apiUrl + "api/customer/update_logo", {
      method: "POST",
      headers: {
        Authorization: `Token ${loginStatus.token}`,
        "X-CSRFToken": Cookies.get("csrftoken"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status >= 400) {
          this.setState({ logoIsUploading: false });
          this.setState({
            logoUploadingError: "Backend api has error code " + res.status,
          });
        }
        return res.json();
      })
      .then((result) => {
        this.setState({ logoIsUploading: false });
        if (result.status) {
          this.props.updateCustomerLogo(this.state.logoImage);
          this.setState({ logoUploadingError: "" });
          setTimeout(() => {
            this.setState({ logoModalOpen: false });
          }, 500);
        }
      });
  };

  renderLogoUploadModal() {
    const { classes, t } = this.props;
    return (
      <Dialog
        open={this.state.logoModalOpen}
        onClose={this.handleClickOpenLogoModalClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">{t('Change logo')}</DialogTitle>
        <DialogContent>
          <div className={`w-full align-center mb-3 flex justify-center`}>
            {this.state.logoImage !== "" ? (
              <img
                id="logo-image"
                src={this.state.logoImage}
                style={{ maxHeight: "150px" }}
              />
            ) : (
              <img id="logo-image" style={{ maxHeight: "150px" }} />
            )}
          </div>
          <ImageUploader
            withPreview={false}
            withIcon={true}
            buttonText="Choose png logo"
            accept="image/png"
            label="Max file size: 2mb, accepted: png"
            onChange={this.onLogoDrop}
            imgExtension={[".png"]}
            maxFileSize={2097152}
          />
        </DialogContent>
        <DialogActions
          className={
            (classes.marginVertical_15,
            classes.action_bar,
            `flex flex-col justify-center`)
          }
        >
          {this.state.logoUploadingError != "" ? (
            <p className={`text-color-400`}>{this.state.logoUploadingError}</p>
          ) : null}
          <button
            onClick={this.uploadLogoImage}
            className={`text-white bg-blue-800 text-base leading-4 py-2 px-4 rounded-sm disabled:opacity-25`}
            disabled={
              this.state.logoImage == "" || this.state.logoIsUploading
                ? true
                : false
            }
          >
            {this.state.logoIsUploading ? t("Uploading") : t("Upload")}
          </button>
        </DialogActions>
      </Dialog>
    );
  }

  renderOnestaSideNavs = () => {
    const { t } = this.props;
    let user = this.props["account"]["loginStatus"]["user"];
    
    var userType =
      typeof user != "undefined" && user != null ? user.profile.user_type : "";

    if (userType == "VENDOR") {
      return null;
    }

    if (user.customer === null  || typeof user.customer === 'undefined') {
      return null;
    }
    if (user.customer.name === "Onesta"
      || user.customer.name === "Demo Customer"
    ) {
      // "TedxSeattle") {
      return [
        <div className={`nav-item`} key="1">
          <NavLink
            to="/msa/list"
            activeClassName={`active`}
            className={`nav-item-inner`}
          >
            {/* <div className={`nav-icon`}>
              <img src="/assets/img/newui/header/nav-invoices.svg" alt="D" />
              <img src="/assets/img/newui/header/nav-invoices-active.svg" class="active" alt="D" />
            </div> */}
            <div className={`nav-title`}>MSA</div>
          </NavLink>
        </div>,
        <div className={`nav-item`} key="2">
          <NavLink
            to="/certificates/list"
            activeClassName={`active`}
            className={`nav-item-inner`}
          >
            {/* <div className={`nav-icon`}>
              <img src="/assets/img/newui/header/nav-invoices.svg" alt="D" />
              <img src="/assets/img/newui/header/nav-invoices-active.svg" alt="D" class="active" />
            </div> */}
            <div className={`nav-title`}>{t('LIEN')}</div>
            <div className={`nav-badge badge-yellow`}></div>
            <div className={`nav-chevron`}>
              <div className={`icon`}></div>
            </div>
          </NavLink>
        </div>,
      ];
    }

    return null;
  };

  render() {
    const { name, classes, t } = this.props;
    const { checked } = this.state;
    const user = this.props["account"]["loginStatus"]["user"];
    const data = this.props.initialResponse.data;


    var userType =
      typeof user != "undefined" && user != null ? user.profile.user_type : "";
    if (userType == "") {
      return null;
    }
    
    const loginStatus = this.props.account.loginStatus;
    if (!loginStatus.isLogedIn) {
      return null;
    }

    let isOpened = this.props.uiSetting.data.leftSidebarOpened;
    if (!isOpened) {
      return null;
    }

    if (user.customer === null || typeof user.customer === 'undefined') {
      return null;
    }
    const customerID = user.customer.permalink ? user.customer.permalink : "";
    const customerName = user.customer.name ? user.customer.name : "";

    return (
      <div className={[classes.sidebar, `left-sidebar scrollbar-style1`].join(" ")}>
        {this.renderLogoUploadModal()}
        <div className={[classes.logoPlacer, `relative`].join(" ")}>
          <NavLink to={"/"}>
            {user.customer.logo && user.customer.logo != "" ? (
              <img
                src="/assets/img/newui/header/blue_logo.png"
                alt="iPayflow"
                style={{
                  maxWidth: "120px",
                  maxHeight: "50px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                src="/assets/img/newui/header/blue_logo.png"
                alt="iPayflow"
                style={{
                  maxWidth: "120px",
                  maxHeight: "50px",
                  objectFit: "contain",
                }}
              />
            )}
          </NavLink>
          {userType == "ADMIN" ? (
            <div
              style={{ width: "200px" }}
              className={[
                classes.avatarHover,
                `absolute top-0 left-0 p-2 w-full h-full`,
              ].join(" ")}
            >
              <div
                className={`logo-replacer-holder cursor-pointer text-blue-900 opacity-75 rounded-full font-bold bg-white w-full h-full flex flex-col justify-center items-center`}
                onClick={this.handleClickOpenLogoModal}
              >
                <AddIcon style={{ fontSize: 24 }} />
                <div style={{ textAlign: "center", marginTop: "-6px" }}>
                  {t('Click to change logo')}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={`nav-list`}>
          {/* Dashboard */}
          <div className={`nav-item`}>
            <NavLink
              to="/dashboard"
              activeClassName={`active`}
              className={`nav-item-inner`}
            >
              <div className={`nav-title`}>{t('Dashboard')}</div>
              <div className={`nav-chevron`}>
                <div className={`icon`}></div>
              </div>
            </NavLink>
          </div>

          {/* Mailbox */}
          <div className={`nav-item`}>
            <NavLink
              to="/mailbox"
              activeClassName={`active`}
              className={`nav-item-inner`}
            >
              <div className={`nav-title`}>{t('Mailbox')}</div>
              <div className={`nav-chevron`}>
                <div className={`icon`}></div>
              </div>
            </NavLink>
          </div>

          {/* Calendar */}
          {/* <div className={`nav-item`}>
            <NavLink
              to="/admin/setup"
              activeClassName={`active`}
              className={`nav-item-inner`}
            >
              <div className={`nav-icon`}>
                <img src="/assets/img/newui/header/nav-calendar.png" alt="D" />
              </div>
              <div className={`nav-title`}>Calendar</div>
              <div className={`nav-badge badge-yellow`}></div>
              <div className={`nav-chevron`}>
                <div className={`icon`}></div>
              </div>
            </NavLink>
          </div> */}

          {/* Vendor MSA Form */}
          {userType == "VENDOR" && (customerID == "onesta" || customerName === 'Demo Customer') ? (
            <div className={`nav-item`}>
              <NavLink
                to="/msa/list"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-title`}>MSA</div>
                <div className={`nav-badge badge-yellow`}></div>
                {
                  (typeof data.msa_completed !== 'undefined' && !data.msa_completed) && 
                    <Tooltip title="MSA document is not completed yet">
                      <WarningIcon style={{ color: '#EE2337' }} />
                    </Tooltip>
                }
              </NavLink>
            </div>
          ) : null}

          {/* Vendor W9 Form */}
          {userType == "VENDOR" ? (
            <div className={`nav-item`}>
              <NavLink
                to="/vendor/setup"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-title`}>W9</div>
                {
                  (typeof data.w9Approved !== 'undefined' && !data.w9Approved) && 
                    <Tooltip title="W9 Form is not approved yet">
                      <WarningIcon style={{ color: '#EE2337' }} />
                    </Tooltip>
                }
              </NavLink>
            </div>
          ) : null}

          {/* Vendor LEAN Form */}
          {userType == "VENDOR" && (customerID == "onesta" || customerName === 'Demo Customer') ? (
            <div className={`nav-item`}>
              <NavLink
                to="/certificates/list"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-title`}>{t('LIEN')}</div>
                <div className={`nav-badge badge-yellow`}></div>
                {
                  (typeof data.lean_documents_count !== 'undefined' && !data.lean_documents_count) && 
                    <Tooltip title="Lien document is not completed yet">
                      <WarningIcon style={{ color: '#EE2337' }} />
                    </Tooltip>
                }
              </NavLink>
            </div>
          ) : null}

          {/* Invoices */}
          <div className={`nav-item`}>
            <div href="#" className={`nav-item-inner nav-item-expand`}>
              <div className={`nav-title`}>{t('Invoices')}</div>
              <div className={`nav-chevron`}>
                <div className={`icon`}></div>
              </div>
              <div className={`break`}></div>
            </div>
            <div className={`nav-sublist`}>
              {userType == "VENDOR" ? (
                <NavLink
                  to="/buyer/buy-request"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Raise Invoice')}
                </NavLink>
              ) : null}
              {userType == "VENDOR" ? (
                <NavLink
                  to="/vendor/raise-invoice/list"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Track Invoice')}
                </NavLink>
              ) : null}
              {
                userType == "ADMIN" ? (
                <NavLink
                  to="/common/track-payment"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Track\\Approve Invoice')}
                </NavLink>
                ) : null
              }

              {userType == "BUYER" ? (
                <NavLink
                  to="/buyer/buy-request"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Buy Request')}
                </NavLink>
              ) : null}
              {userType == "BUYER" ? (
                <NavLink
                  to="/buyer/review-invoices"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Track Invoices')}
                </NavLink>
              ) : null}
              {
                userType == 'BUYER' ? (
                  <NavLink
                    to="/buyer/budgets"
                    activeClassName={`active`}
                    className={`nav-sublist-item`}
                  >
                    {t('Budgets')}
                  </NavLink>
                ) : null
              }
              {/* {userType == "BUYER" ? (
                <NavLink
                  to="/buyer/track-performance"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  Track Invoice
                </NavLink>
              ) : null} */}
            </div>
          </div>

          {/* Setup */}
          {userType == "ADMIN" ? (
            <div className={`nav-item`}>
              <NavLink
                to="/admin/setup"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-title`}>{t('Setup')}</div>
                <div className={`nav-badge badge-yellow`}></div>
              </NavLink>
            </div>
          ) : null}

          {/* Approve */}
          {userType == "ADMIN" ? (
            <div className={`nav-item`}>
              <div href="#" className={`nav-item-inner nav-item-expand`}>
                <div className={`nav-title`}>{t('Approve')}</div>
                <div className={`nav-chevron`}>
                  <div className={`icon`}></div>
                </div>
                <div className={`break`}></div>
              </div>
              <div className={`nav-sublist`}>
                <NavLink
                  to="/admin/approve/vendors"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Vendors')}
                </NavLink>
                <NavLink
                  to="/admin/approve/budgets"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Budget Settings')}
                </NavLink>
              </div>
            </div>
          ) : null}

          {/* Pay */}
          {userType == "ADMIN" ? (
            <div className={`nav-item`}>
              <NavLink
                to="/admin/pay"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-title`}>{t('Pay')}</div>
                <div className={`nav-badge badge-yellow`}></div>
              </NavLink>
            </div>
          ) : null}

          {/* Budgets Report */}
          {((userType == "ADMIN") || (userType == "BUYER")) ? (
            <div className={`nav-item`}>
              <div className='nav-item-inner nav-item-expand'>
                <div className='nav-title'>
                  {t('Reports')}
                </div>
                <div className={`nav-chevron`}>
                  <div className={`icon`}></div>
                </div>
              </div>
              <div className='nav-sublist'>
                <NavLink
                  to="/admin/report/budgets"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Budget Report')}
                </NavLink>
                <NavLink
                  to="/admin/report/spent"
                  activeClassName={`active`}
                  className={`nav-sublist-item`}
                >
                  {t('Spend Report')}
                </NavLink>
              </div>
            </div>
          ) : null}

          {
            (((userType === 'ADMIN') || (userType === 'VENDOR')) && (customerID == "onesta" || customerName === 'Demo Customer')) ? 
              <div className='nav-item'>
                <NavLink
                  to="/insurances/list"
                  activeClassName={`active`}
                  className={`nav-item-inner`}
                >
                  <div className={`nav-title`}>{t('Insurance')}</div>
                  <div className={`nav-badge badge-yellow`}></div>
                </NavLink>
              </div>
             : null
          }

          {/* Report */}
          {/* {userType == "ADMIN" ? (
            <div className={`nav-item`}>
              <NavLink
                to="/report"
                activeClassName={`active`}
                className={`nav-item-inner`}
              >
                <div className={`nav-icon`}>
                  <img src="/assets/img/newui/header/nav-setup.svg" alt="D" />
                </div>
                <div className={`nav-title`}>Report</div>
                <div className={`nav-badge badge-yellow`}></div>
                
              </NavLink>
            </div>
          ) : null} */}

          {this.renderOnestaSideNavs()}

          {/* Profile */}
          <div className={`nav-item`}>
            <NavLink
              to="/profile"
              activeClassName={`active`}
              className={`nav-item-inner`}
            >
              <div className={`nav-title`}>{t('Profile')}</div>
              <div className={`nav-badge badge-yellow`}></div>
              <div className={`nav-chevron`}>
                <div className={`icon`}></div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state, account: state.account, uiSetting: state.uiSetting };
};

const mapDispatchToProps = { updateCustomerLogo };

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LeftSidebarStyle)(LeftSidebar)));
