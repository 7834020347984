import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import BaseInput from 'components/BaseInput';
import { withStyles } from '@material-ui/core';
import searchInputStyle from './searchInputStyle.jsx';
import { withTranslation  } from 'react-i18next';

class SearchInput extends Component
{
    render() {
        const { classes, onClose, value, onChange, t } = this.props;
        return (
            <div className={ classes.container }>
                <SearchIcon className={ classes.searchIcon } fontSize="small" />
                <BaseInput small className={ classes.input } onChange={ onChange } value={ value } placeholder={t("Search...")} />
                {
                    value && value.length &&
                        <CloseIcon className={ classes.closeIcon } fontSize="small" onClick={ onClose } />
                }
            </div>
        )
    }
}

export default withTranslation()(withStyles(searchInputStyle)(SearchInput));