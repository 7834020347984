import commonStyle from 'assets/jss/commonStyle'

const profilePageStyle = theme => ({
    ...commonStyle,
    profileContainer: {
        display: 'flex',
        flex: 1,
        background: 'rgba(217, 217, 217, 0.2)',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0px',
        maxWidth: 400,
        height: 'fit-content',
        borderRadius: 10,
    },
    userName: {
        marginTop: 20,
        fontWeight: 600,
        fontSize: 25,
        color: '#0B0202'
    },
    userType: {
        marginBottom: 20,
        fontSize: 14,
        fontWeight: 600,
        color: '#000000',
    },
    btnMessage: {
        background: '#8CC248',
        color: 'white',
        fontSize: 15,
        width: 110,
        height: 38,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: 50,
    },
    detailContainer: {
        flexDirection: 'column',
        width: '100%',
        fontSize: 14,
        textAlign: 'center',
        borderBottom: '1px solid #E2DFDF',
    },
    detailLabel: {
        fontWeight: 700,
    },
    profileDetail: {
        borderTop: '1px solid #E2DFDF',
        padding: '13px 0px',
        color: 'black'
    },
    invoiceStatContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        borderRight: '1px solid #E9E9E9'
    },
    profileFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        borderLeft: '1px solid #E9E9E9',
        paddingLeft: 30,
        paddingTop: 20,
    },
    controlContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    inputControl: {
        width: 300,
        background: 'rgba(255, 255, 255, 0.7)',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.5)'
        }
    },
    inputLabelWrapper: {
        alignSelf: 'flex-start',
        fontSize: 16,
        fontWeight: 600,
        minWidth: 150,
        color: '#1291F9',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    inputWrapper: {
        width: 300,
        alignItems: 'center',
        fontSize: 16,
    },
    checkRoot: {
        marginLeft: -12
    },
    btn: {
        background: '#1291F9',
        borderRadius: 7,
        borderWidth: 0,
        color: 'white',
        height: 33,
        width: 90,
        marginLeft: 'auto',
        float: 'right',
        fontSize: 14,
        '&:hover': {
            color: 'white',
        }
    },
    statusTag: {
        borderRadius: 2,
        padding: '1px 25px',
        minWidth: '150px',
        color: 'white',
        fontSize: 16,
        position: 'relative',
        marginBottom: 20,
        marginRight: 25,
        '&:after': {
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '12px 0 12px 24px',
            borderColor: 'transparent transparent transparent #0072BB',
            content: '" "',
            position: 'absolute',
            right: '-18px',
            top: 1,
        },
        '&.processing': {
            background: '#FFC700',
            '&:after': {
                borderColor: 'transparent transparent transparent #FFC700',
            }
        },
        '&.requested': {
            background: '#0072BB',
        },
        '&.completed': {
            background: '#8CC34B',
            '&:after': {
                borderColor: 'transparent transparent transparent #8CC34B',
            }
        },
    },
    passwordAlert: {
        background: '#FDECEA',
        color: '#611A15',
        padding: '6px 10px',
        width: '100%',
        fontSize: 14,
        borderRadius: 4,
        marginTop: 5,
    },
    modalContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContent: {
        display: 'flex',
        background: '#EFF3F4',
        borderRadius: 4,
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
    },
    modalBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    btnAdpotAvatar: {
        background: '#0072BB',
        borderWidth: 0,
        color: 'white',
        width: 100,
        height: 30,
        borderRadius: 3,
        fontSize: 16,
    },
    avatarHover: {
        background: 'white',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        position: 'absolute',
        opacity: 0,
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 15,
    },
    avatarContainer: {
        width: 95,
        height: 95,
        borderRadius: '100%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: 10,
        '& img': {
            width: '100%',
            height: '100%'
        },
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            '& $avatarHover': {
                opacity: 0.8,
                transition: 'opacity .25s ease-in-out',
            }
        }
    },
    initialContainer: {
        color: 'white',
        background: '#555555',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 36,
    },
    statusContainer: {
        borderRadius: 9,
        background: '#fff',
        boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.25)',
        color: '#1291F9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 24,
        paddingBottom: 10,
        maxWidth: 120,
        marginRight: 40,
        flex: 1,
        '& img': {
            height: 45,
            objectFit: 'contain',
            marginBottom: 10,
        },
        '& .statusText': {
            fontWeight: 'bold',
            fontSize: 16,
        },
        '& .numeric': {
            fontSize: 16,
            fontWeight: 'bold',
        }
    },
});
export default profilePageStyle;