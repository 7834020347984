import React, { Component } from 'react';
import GlobalProcessor from 'components/GlobalProcessor/index';
import senderViewStyle from './senderViewStyle';
import { withStyles } from '@material-ui/core';
import { Document, Page, pdfjs  } from 'react-pdf';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TodayIcon from '@material-ui/icons/Today';
import Draggable from 'components/Draggable';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import Droppable from 'components/Droppable';
import Signature from 'components/Signature';
import Initial from 'components/Initial';
import Button from 'components/Button';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from '@material-ui/core/Modal';
import DateSigned from 'components/DateSigned';
import Loading from 'components/Loading';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { withTranslation  } from 'react-i18next';
import { confirm } from "../../components/Confirm/index";
import NativeSelect from 'components/NativeSelect';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const COLORS = [
    'rgba(251, 220, 128, 0.5)',
    'rgba(189, 225, 232, 0.8)'
];
const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class SenderViewPage extends Component
{
    state = {
        numPages: 0,
        editingRecipient: 0,
        signatures: [],
        goToList: false,
        successModal: false,
        initials: [],
        dates: [],
        canvasWidth: 600,
        uploading: false
    }

    componentDidMount() {
        window.addEventListener("resize", this.windowResized);
    }

    windowResized = () => {
        this.regulatePositions(this.state.canvasWidth);
        this.setState({ canvasWidth: this.pdf.getBoundingClientRect().width - 12});
    }

    regulatePositions = (previousWidth) => {
        let { signatures, initials, dates } = this.state;
        const currentWidth = this.pdf.getBoundingClientRect().width - 12;
        const ratio = currentWidth / previousWidth;

        signatures.map(item => {
            item.x *= ratio;
            item.y *= ratio;
        });

        initials.map(item => {
            item.x *= ratio;
            item.y *= ratio;
        });

        dates.map(item => {
            item.x *= ratio;
            item.y *= ratio;
        });
    }

    render() {
        const { classes, uploadResult, t } = this.props;
        const { numPages, editingRecipient, signatures, goToList, successModal, initials, dates, canvasWidth, uploading } = this.state;
        const { msa } = uploadResult;

        if (!msa) {
            return (
                <Redirect to="/msa/list" />
            );
        }

        if (goToList) {
            return (
                <Redirect to="/msa/list" />
            );
        }

        return (
            <DndProvider backend={Backend}>
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.topWrapper } style={{ opacity: uploading ? 0.5 : 1 }}>
                    <div style={{ display:'flex', alignItems: 'center' }}>
                        <div style={{ color: '#1D8ACF', marginRight: 20, }}>
                            { t('Recipient') }:
                        </div>
                        <NativeSelect
                            id="demo-simple-select"
                            value={ editingRecipient }
                            onChange={ this.recipientChanged }
                        >
                            {
                                msa.recipients.map((recipient, key) =>
                                    <MenuItem value={ key } key={key}>{ recipient.name }</MenuItem>
                                )
                            }
                        </NativeSelect>
                    </div>
                    <Button className={ classes.btnSend } onClick={ this.sendClicked } disabled={ !this.canSend() }>
                        { t('SEND') }
                    </Button>
                </div>
                <div className={ classes.contentContainer } style={{ opacity: uploading ? 0.5 : 1 }}>
                    <div className={ classes.toolboxContainer }>
                        <div>
                            <Draggable data={{ control: 'signature' }} type="draggable">
                                <div className={ classes.ctrlContainer }>
                                    <BorderColorIcon className={ classes.ctrlIcon } style={{ backgroundColor: COLORS[editingRecipient] }} />
                                    <div>
                                        { t('Signature') }
                                    </div>
                                </div>
                            </Draggable>
                            <Draggable data={{ control: 'initial' }} type="draggable">
                            <div className={ classes.ctrlContainer }>
                                <FontDownloadIcon className={ classes.ctrlIcon }  style={{ backgroundColor: COLORS[editingRecipient] }} />
                                <div>
                                    { t('Initial') }
                                </div>
                            </div>
                            </Draggable>
                            <Draggable data={{ control: 'date_signed' }} type="draggable">
                            <div className={ classes.ctrlContainer }>
                                <TodayIcon className={ classes.ctrlIcon }  style={{ backgroundColor: COLORS[editingRecipient] }} />
                                <div>
                                    { t('Date Signed') }
                                </div>
                            </div>
                            </Draggable>
                        </div>
                    </div>
                    <div className={ classes.pdfContainer } ref={ this.drop } >
                        <Droppable onDrop={ this.controlDropped }>
                            <div ref= { ref => this.pdf = ref } style={{ position: 'relative' }}>
                                <Document
                                    file={ `data:application/pdf;base64,${msa.file.content}`}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    onLoadError={error => console.log('ffffffffffff', error)}
                                >
                                    {
                                        Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <Page
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                renderTextLayer={true}
                                                width={canvasWidth}
                                            />
                                        ),
                                        )
                                    }
                                </Document>
                                {
                                    signatures.map((signature, key) => <Signature signature = {signature} key={ key } />)
                                }
                                {
                                    initials.map((initial, key) => <Initial key={ `initial-${key}` } data = { initial }  />)
                                }
                                {
                                    dates.map((date, key) => <DateSigned key={ `date-${key}` } data = { date }  />)
                                }
                            </div>
                        </Droppable>
                    </div>
                </div>
                <Loading uploading={ uploading }/>
                <Modal
                    open={ successModal }
                >
                    <div className={ classes.modalWrapper }>
                        <div className={ classes.modalContainer }>
                            <div style={{ textAlign: 'center' }}>
                                <CheckCircleOutlineIcon style={{ color: '#4caf50', marginRight: '10px', fontSize: 30, marginBottom: 5 }} />
                                <div style={{ color: 'rgb(30, 70, 32)', fontSize: 18 }}>
                                    { t('The document is successfully sent to signers by email.') }
                                </div>
                            </div>
                            <Button className={ classes.modalBtnConfirm } onClick={ this.successModalClosed }>OK</Button>
                        </div>
                    </div>
                </Modal>
            </div>
            </DndProvider>
        )
    }

    successModalClosed = () => {
        this.setState({ successModal: false, goToList: true });

    }

    controlDropped = (item, monitor) => {
        let { editingRecipient, signatures, signatureData, initials, dates } = this.state;
        let itemOffset = {};
        let index = 0;

        switch(item.type) {
            case 'draggable': // item dropped from toolbox
                switch (item.control) {
                    case 'initial': // Initial input dropped
                        itemOffset = monitor.getClientOffset();
                        const initial = {
                            signer_order: editingRecipient,
                            y: itemOffset.y - this.pdf.getBoundingClientRect().y - 10,
                            x: itemOffset.x - this.pdf.getBoundingClientRect().x - 30,
                            initial_index: initials.length,
                            control: 'initial'
                        }
                        initials.push(initial);
                        this.setState({ initials });
                        break;
                    case 'date_signed':
                        itemOffset = monitor.getClientOffset();
                        const date = {
                            signer_order: editingRecipient,
                            y: itemOffset.y - this.pdf.getBoundingClientRect().y - 10,
                            x: itemOffset.x - this.pdf.getBoundingClientRect().x - 30,
                            date_index: dates.length,
                            control: 'date_signed'
                        }
                        dates.push(date);
                        this.setState({ dates });
                        break;
                    default:
                        itemOffset = monitor.getClientOffset();
                        const signature = {
                            signer_order: editingRecipient,
                            y: itemOffset.y - this.pdf.getBoundingClientRect().y - 10,
                            x: itemOffset.x - this.pdf.getBoundingClientRect().x - 30,
                            signature_index: signatures.length,
                            control: 'signature'
                        }
                        signatures.push(signature);
                        this.setState({ signatures });
                        break;
                }

                break;
            case 'control':
                switch (item.control) {
                    case 'initial': // Initial control on pdf drag and drop
                        index = item.initial_index;
                        itemOffset = monitor.getDifferenceFromInitialOffset();
                        initials[index].x += itemOffset.x;
                        initials[index].y += itemOffset.y;
                        this.setState({initials});
                        break;
                    case 'date_signed':
                        index = item.date_index;
                        itemOffset = monitor.getDifferenceFromInitialOffset();
                        dates[index].x += itemOffset.x;
                        dates[index].y += itemOffset.y;
                        this.setState({dates});
                        break;
                    default:
                        index = item.signature_index;
                        itemOffset = monitor.getDifferenceFromInitialOffset();
                        signatures[index].x += itemOffset.x;
                        signatures[index].y += itemOffset.y;
                        this.setState({signatures});
                        break;
                }
                break;
        }
    }

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({numPages, canvasWidth: this.pdf.getBoundingClientRect().width - 12});
    }

    recipientChanged = (event) => {
        this.setState({editingRecipient: event.target.value});
    }

    uploadDocument = () => {
        const PDF_STATIC_WIDTH = 1000;
        const ratio = PDF_STATIC_WIDTH / this.pdf.getBoundingClientRect().width;
        const { uploadResult } = this.props;
        const { msa } = uploadResult;

        const signatureData = this.prepareSignatureData(ratio);
        const file = msa.file;
        //let signatures = [], dates = [], initials = [];
        let { signatures, dates, initials } = this.state;
        signatures = [...this.state.signatures];
        dates = [...this.state.dates];
        initials = [...this.state.initials];

        let { recipients } = msa;
        recipients.forEach((recipient, recipientIndex) => {
            recipient['signatures'] = signatures.filter(signature => signature.signer_order === recipientIndex).map(control => {
                let newControl = Object.assign({...control});
                newControl.x = newControl.x * ratio;
                newControl.y = newControl.y * ratio;
                return newControl;
            });
            recipient['signed_dates'] = dates.filter(date => date.signer_order === recipientIndex).map(control => {
                let newControl = Object.assign({...control});
                newControl.x = newControl.x * ratio;
                newControl.y = newControl.y * ratio;
                return newControl;
            });
            recipient['initials'] = initials.filter(initial => initial.signer_order === recipientIndex).map(control => {
                let newControl = Object.assign({...control});
                newControl.x = newControl.x * ratio;
                newControl.y = newControl.y * ratio;
                return newControl;
            });
        });

        let formData = new FormData();
        formData.append('file', JSON.stringify(file));
        const meta = { recipients };
        formData.append('meta', JSON.stringify(meta));

        if (msa.id) {
            formData.append('id', msa.id);
        }

        this.setState({uploading: true});
        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + 'api/msa/upload', {
            method: 'POST',
            headers:{
                'Authorization': 'Token ' + loginStatus.token,
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 'success') {
                this.setState({uploading: false, successModal: true});
                return;
            }

            this.setState({uploading: false})
        });
    }

    sendClicked = () => {
        const { uploadResult } = this.props;
        const { msa } = uploadResult;

        if (msa.id) {
            confirm("Confirm", "Are you sure to update this MSA Document? Previous Signers will be notified about the change.").then(
                async () => {
                    await this.uploadDocument();
                },
                () => {
                }
            );
            return;
        }

        this.uploadDocument();
    }

    prepareSignatureData = (ratio = 0) => {
        let signatureData = [];
        const { uploadResult } = this.props;
        const { msa } = uploadResult;
        let signatures = []
        Object.assign(signatures, this.state.signatures);

        signatures.forEach(signature => {
            let newSign = Object.assign({...signature});
            if (ratio) {
                newSign.x = signature.x * ratio;
                newSign.y = signature.y * ratio;
            }
            if (!signatureData[newSign.signer_order]) {
                signatureData[newSign.signer_order] = [];
            }

            signatureData[newSign.signer_order].push(newSign);
        });

        return signatureData;
    }

    canSend = () => {
        let signatureData = this.prepareSignatureData();
        const { uploadResult } = this.props;

        const { msa } = uploadResult;
        const canSend = signatureData.length === msa.recipients.length;
        signatureData.forEach(data => {
            if (!data.length) {
                canSend = false;
            }
        });

        return canSend;
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(senderViewStyle)(SenderViewPage)));