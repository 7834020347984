import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import MessageBoardStyle from "./MessageBoardStyle";
import Avatar from "components/Avatar";
import SearchInputType2 from "components/SearchInputType2";
import Cookies from "js-cookie";
import Moment from "react-moment";
import {
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Input,
} from "@material-ui/core";

import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { Editor } from "@tinymce/tinymce-react";
import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class MessageBoard extends Component {
  state = {
    selectedDuration: "week",
    isDataLoading: true,
    mailIndex: 0,
    search: {
      type: "inbox",
    },
    userInfo: {
      id: 0,
    },
    data: {
      messages: [],
      contacts: [],
    },
    composeData: {
      receiverId: 0,
      title: "",
      content: "",
    },
    composeDataError: [],
    composeDataSuccess: [],
    replyData: {
      content: "",
    },
    showCompose: false,
    showReply: true,
    query: ''
  };

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };
  }

  componentDidMount() {
    const loginStatus = this.props.account.loginStatus;
    this.setState({ userInfo: loginStatus.user });

    setTimeout(() => {
      this.loadData();
    }, 300);
  }

  loadData() {
    const loginStatus = this.props.account.loginStatus;

    return fetch(apiUrl + "api/message/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify(this.state.search),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          this.setState({
            data: {
              messages: result.data,
              contacts: result.contacts,
            },
          });
        }
        this.setState({ mailIndex: 0 });
        this.setState({ isDataLoading: false });
      });
  }

  clickMenu(type) {
    this.setState({ showCompose: false });
    this.setState({ search: { ...this.search, type: type } });
    setTimeout(() => {
      this.loadData();
    }, 100);
  }

  clickMsgItem(index) {
    this.setState({ mailIndex: index });
  }

  clickComposeMail() {
    this.setState({ showCompose: true });
  }

  goPrevOrNext(direction) {
    this.setState({ mailIndex: this.state.mailIndex + direction });
  }

  handleEditorChange = (content, editor) => {
    this.handleChangeComposeData({ target: { value: content } }, "content");
  };

  handleReplyEditorChange = (content, editor) => {
    this.setState({ replyData: { content: content } });
  };

  handleChangeComposeData(e, type) {
    let value = e.target.value;
    const dataOject = {};
    dataOject[type] = value;
    this.setState({
      composeData: Object.assign(this.state.composeData, dataOject),
    });
  }

  clickSendMail(e) {
    const loginStatus = this.props.account.loginStatus;

    e.preventDefault();
    if (this.state.composeData.receiverId == 0) {
      this.setState({ composeDataError: ["Please select one of contact!"] });
      return;
    } else if (this.state.composeData.title.trim().length == 0) {
      this.setState({ composeDataError: ["Please input the title!"] });
      return;
    } else if (this.state.composeData.content.trim().length == 0) {
      this.setState({ composeDataError: ["Please input the content!"] });
      return;
    }

    fetch(apiUrl + "api/message/messages/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify(this.state.composeData),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          window.location.reload();
          this.setState({
            composeDataSuccess: ["Mail has sent successfully"],
          });
          setTimeout(() => {
            this.setState({
              composeData: {
                receiverId: 0,
                title: "",
                content: "",
              },
            });
            this.setState({
              composeDataSuccess: [],
            });
          }, 1000);
        }
        this.setState({ mailIndex: 0 });
        this.setState({ isDataLoading: false });
      });
  }

  clickSendReply(e) {
    const loginStatus = this.props.account.loginStatus;
    const messages = this.state.data.messages;
    let message = messages[this.state.mailIndex];

    e.preventDefault();
    if (this.state.replyData.content.trim().length == 0) {
      return;
    }

    fetch(apiUrl + "api/message/reply/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify({
        ...this.state.replyData,
        msgId: message["id"],
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          window.location.reload();
          this.setState({
            composeDataSuccess: ["Mail has sent successfully"],
          });
          setTimeout(() => {
            this.setState({
              replyData: {
                content: "",
              },
            });
            this.setState({
              composeDataSuccess: [],
            });
          }, 1000);
        }
        this.setState({ mailIndex: 0 });
        this.setState({ isDataLoading: false });
      });
  }

  /* Show mail list on left sidebar */
  renderListMessage() {
    const { classes, t } = this.props;
    const messages = this.state.data.messages;

    if (messages.length < 1) {
      return (
        <div className={`h-full flex items-center justify-center`}>{ t('No mail') }</div>
      );
    }

    return messages.map((message, index) => {
      let contact = message.sender;
      if (message.sender.id == this.state.userInfo.id) {
        contact = message.receiver;
      }
      return (
        <a
          href="#"
          onClick={(e) => this.clickMsgItem(index)}
          key={`message-${index}`}
          className={`flex hover:no-underline cursor-pointer pl-2 py-3 pr-2
            ${this.state.mailIndex == index ? "bg-gray-300" : ""}`}
          style={{ borderBottomColor: "#edf2f7" }}
        >
          <div className={`text-sm mr-3`}>
            <div>{contact.name}</div>
            <div className={`text-xs opacity-75`}>{contact.job}</div>
          </div>
          <div className={`w-10`}>
            <div className={`w-full h-10`}>
              <Avatar
                user={contact}
                topBubble={false}
                active={contact.status === "active"}
                hideBubble={contact.status === ""}
              />
            </div>
          </div>
        </a>
      );
    });
  }

  /* Show each mail item */
  renderMessageItem() {
    const { classes } = this.props;
    const messages = this.state.data.messages;

    if (
      typeof messages === "undefined" ||
      messages.length < 1 ||
      messages.length <= this.state.mailIndex
    ) {
      return null;
    }

    let message = messages[this.state.mailIndex];
    let isSent = true;
    let contact = message.sender;
    if (message.sender.id == this.state.userInfo.id) {
      isSent = false;
      contact = message.receiver;
    }

    const { t } = this.props;

    return (
      <div className={`mailbox-maincontent flex-grow h-full relative`}
        style={{ minHeight: '780px' }}
      >
        {/* Mail Content */}
        <div
          className={`px-5 pt-4 pb-4 flex justify-between border-b border-gray-200`}
        >
          <div className={`mail-sender flex flex-grow`}>
            <div className={`mr-3`}>
              <p className={`text-xl`}>{contact.name}</p>
              {isSent ? (
                <p className={`text-sm`}>
                  <span>{contact.email}</span>
                  {/* { t('to') }{" "}
                  <span className={`text-blue-800`}>{ t('me') }</span> */}
                </p>
              ) : (
                <p className={`text-sm`}>
                  <span>{contact.email}</span>
                  {/* <span className={`text-blue-800`}>{ t('Sent') }</span>
                  { t('to') }{" "}
                  <span className={`text-blue-800`}>{contact.email}</span> */}
                </p>
              )}
            </div>
            <Avatar
              user={contact}
              topBubble={false}
              active={contact.status === "active"}
              hideBubble={contact.status === ""}
            />
          </div>
          <div className={`mail-time text-xs flex items-center`}>
            <svg
              version="1.1"
              className={`w-5 h-5 inline-block mr-2`}
              x="0px"
              y="0px"
              viewBox="0 0 53.867 53.867"
            >
              <polygon
                style={{ fill: "#EFCE4A" }}
                points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798
                  10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "
              />
            </svg>
            <Moment format="MMM D YYYY HH:mm">{message.sent_at}</Moment>
          </div>
        </div>
        <div className={`px-5 pt-3`}
          style={{
            height: 'calc(100% - 360px)',
            overflowY: 'auto'
          }}
        >
          <div className={`pb-3 text-bold`}>
            { message.title }
          </div>
          <div
            className={`pb-4 text-sm`}
            dangerouslySetInnerHTML={{ __html: message.content }}
          ></div>
          {/* Reply message list */}
          <div className={``}>
            {message.replies.map((reply, index) => {
              let isSent = false;
              if (reply.sender.id == this.state.userInfo.id) {
                isSent = true;
              }

              let contact = reply.sender;
              return (
                <div
                  key={`reply-${index}`}
                  className={`flex flex-col py-3 pl-6 text-base`}
                >
                  <div className={`flex flex-row ${isSent ? 'justify-end' : ''}`}>
                    <div className={`flex pr-4`}>
                      <div className={`text-sm mr-3`}>
                        <div>{contact.name}</div>
                        <div className={`text-xs opacity-75`}>{contact.job}</div>
                      </div>
                      <div className={`w-10`}>
                        <div className={`w-full h-10`}>
                          <Avatar
                            user={contact}
                            topBubble={false}
                            active={contact.status === "active"}
                            hideBubble={contact.status === ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`mail-time text-xs flex items-center`}>
                      <Moment format="MMM D YYYY HH:mm">{reply.sent_at}</Moment>
                    </div>
                  </div>
                  <div
                    className={`flex mt-3 opacity-75 w-75 ${isSent ? 'self-end justify-end' : ''}`}
                    dangerouslySetInnerHTML={{ __html: reply.content }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Replay div part */}
        {this.state.showReply ? (
          <div className={`absolute w-full bottom-0`}>
            <div className={`px-5 py-4 relative border-t border-gray-200`}
              style={{
                backgroundColor: 'rgba(196, 196, 196, 0.08)'
              }}
            >
              <Editor
                initialValue=""
                apiKey="mxzo7lu78igeralayfg88wjc6omxgshv5raf2hiou918464h"
                value={this.state.composeData.content}
                init={{
                  height: 150,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={this.handleReplyEditorChange}
              />
            </div>
            <div className={`pt-3 px-5 pb-3 relative border-t border-gray-200 text-right`}>
              <button
                type="button"
                onClick={(e) => this.clickSendReply(e)}
                className={`right-0 bottom-0 ml-3 w-24 bg-white border-2 border-blue-500 text-blue-500
                  text-xs leading-4 py-1 px-2 rounded-sm disabled:opacity-25`}
                disabled={
                  this.state.replyData.content.trim().length > 0 ? false : true
                }
              >
                { t('Send') }
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  queryChanged = (value) => {
    this.setState({ query: value });
  }

  /* Show main mail list part */
  renderMailListPart() {
    const { classes } = this.props;
    const messages = this.state.data.messages;
    const type = this.state.search.type;

    const { t } = this.props;

    return (
      <div className={`mailbox-leftbar bg-white flex-grow ml-5`}
        style={{border: '1px solid rgba(18, 145, 249, 0.18)', borderRadius: '10px'}}
      >
        {/* Main Part */}
        <div className={`flex flex-row h-full`}>
          {/* Left List */}
          {/* <div
            className={`relative mailbox-leftbar min-w-90r flex-grow-0 border-r border-gray-200 pb-72px`}
          >
            <div
              className={`absolute bottom-0 w-full p-3 border-t border-gray-200`}
            >
              <div
                className={`relative w-full h-full border border-gray-200 rounded overflow-hidden`}
              >
                <input
                  type="search"
                  className={`bg-purple-white text-sm border-0 focus:outline-none p-2 pr-5 w-full`}
                  placeholder={ t('Search') }
                />
                <div
                  className={`absolute right-0 top-0 h-full w-10 p-2 text-purple-lighter bg-gray-100 border-l border-gray-200`}
                >
                  <svg
                    x="0px"
                    y="0px"
                    viewBox="0 0 52.966 52.966"
                    className={`h-full w-full`}
                    style={{
                      enableBackground: "new 0 0 52.966 52.966",
                    }}
                  >
                    <path
                      d="M51.704,51.273L36.845,35.82c3.79-3.801,6.138-9.041,6.138-14.82c0-11.58-9.42-21-21-21s-21,9.42-21,21s9.42,21,21,21
c5.083,0,9.748-1.817,13.384-4.832l14.895,15.491c0.196,0.205,0.458,0.307,0.721,0.307c0.25,0,0.499-0.093,0.693-0.279
C52.074,52.304,52.086,51.671,51.704,51.273z M21.983,40c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19
S32.459,40,21.983,40z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div> */}
          {/* Mail Email Part */}
          {this.renderMessageItem()}
        </div>
      </div>
    );
  }

  /* Show compose part */
  renderMailComposePart() {
    const { classes } = this.props;
    const messages = this.state.data.messages;
    const type = this.state.search.type;

    return (
      <div className={`mailbox-leftbar bg-white flex-grow ml-4`}>
        <div className={`w-full h-full px-3 py-3 flex`}>
          <form
            onSubmit={(e) => this.clickSendMail(e)}
            className={`w-full`}
            noValidate
            autoComplete="off"
          >
            <div className={`mt-3`}>
              <TextField
                id="standard-select-currency"
                select
                label="Please select contact to send mail"
                value={this.state.composeData.receiverId}
                fullWidth
                onChange={(e) => this.handleChangeComposeData(e, "receiverId")}
                helperText=""
              >
                {this.state.data.contacts.map((contact) => (
                  <MenuItem key={contact.name} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={`mt-3`}>
              <FormControl fullWidth>
                <InputLabel htmlFor="component-title">Title</InputLabel>
                <Input
                  id="component-title"
                  value={this.state.composeData.title}
                  onChange={(e) => this.handleChangeComposeData(e, "title")}
                />
              </FormControl>
            </div>
            <div className={`mt-3`}>
              <Editor
                initialValue=""
                apiKey="mxzo7lu78igeralayfg88wjc6omxgshv5raf2hiou918464h"
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={this.handleEditorChange}
              />
            </div>
            <div className={`w-full mt-3 text-right`}>
              {this.state.composeDataSuccess.map((msg, index) => {
                return (
                  <p
                    key={`error-${index}`}
                    className={`text-blue-700 text-base leading-none`}
                  >
                    {msg}
                  </p>
                );
              })}
              <button
                className={`w-32 bg-blue-800 hover:bg-blue-800 text-white text-sm leading-4 py-2 px-4 rounded-sm mt-3`}
              >
                Send
              </button>
              {this.state.composeDataError.map((err, index) => {
                return (
                  <p
                    key={`error-${index}`}
                    className={`text-red-600 text-base leading-none`}
                  >
                    {err}
                  </p>
                );
              })}
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const messages = this.state.data.messages;
    const type = this.state.search.type;
    const { t } = this.props;
    const { query } = this.state;

    let isNextAvailable = false;
    let isPrevAvailable = false;
    if (this.state.mailIndex < messages.length - 1) {
      isNextAvailable = true;
    }
    if (this.state.mailIndex > 0) {
      isPrevAvailable = true;
    }

    return (
      <div className={[classes.container, `min-h-full`].join(" ")}>
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>{ t('MailBox') }</div>
        </div>
        <div className={`py-4`} style={{ borderBottom: '1px solid #E1E1E1' }}>
          <div className={`flex flex-row mt-3`}>
            <div className={`mailbox-leftbar`} style={{  flexBasis: 250, flexShrink: 0 }}>
            </div>
            <div className={`mailbox-leftbar flex-grow ml-5 flex justify-between`}
            >
              {/* Select Type */}
              <div className={`relative`}>
                <select
                  className={`block appearance-none w-32 h-10 text-sm text-black py-1 px-2
                    pr-4 rounded leading-tight focus:outline-none`}
                  style={{ color: 'black' }}
                >
                  <option>{ t('Filter') }</option>
                  <option style={{ background: 'rgba(196, 196, 196, 0.2)' }}>{ t('Missouri') }</option>
                  <option style={{ background: 'rgba(196, 196, 196, 0.2)' }}>{ t('Texas') }</option>
                </select>
                <div
                  className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2`}
                  style={{ color: 'black' }}
                >
                  <svg
                    className={`fill-current h-6 w-6`}
                    style={{ fill: 'rgba(0, 0, 0, 1)' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {/* Search Input */}
              <div className={ `ml-3` }>
                <SearchInputType2
                  placeholder={t('Search') + "..."}
                  value={query}
                  onChange={(event) => this.queryChanged(event.target.value)}
                  onClose={() => this.queryChanged("")}
                />
              </div>
              {/* Navigation Button */}
              <div className={`flex py-1`}>
                <button
                  onClick={(e) => this.goPrevOrNext(-1)}
                  className={`w-10 h-8 border rounded-sm flex items-center justify-center text-center disabled:opacity-50 mr-3`}
                  disabled={isPrevAvailable ? false : true}
                  style={{ background: 'rgba(18, 145, 249, 0.18)', fontWeight: 700 }}
                >
                  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.1461 0.264972L8.63633 0.757439C8.78942 0.912424 8.87349 1.11835 8.87299 1.33823C8.87249 1.55799 8.78748 1.76377 8.63368 1.91805L3.0543 7.51025L8.61423 13.1344C8.76733 13.2891 8.85128 13.4953 8.85077 13.715C8.85027 13.9348 8.76539 14.1407 8.61158 14.2948L8.12214 14.7852C7.80385 15.1045 7.28658 15.1033 6.96975 14.7826L0.326128 8.08634C0.173152 7.9316 0.0655894 7.72562 0.0660958 7.50391L0.0661016 7.50135C0.0666039 7.28147 0.175228 7.07598 0.328788 6.92194L6.98487 0.26232C7.13855 0.108035 7.34924 0.023636 7.56749 0.0238913C7.78585 0.0243901 7.99324 0.109987 8.1461 0.264972Z" fill="black"/>
                  </svg>
                </button>
                <button
                  onClick={(e) => this.goPrevOrNext(1)}
                  className={`w-10 h-8 border rounded-sm flex items-center justify-center text-center disabled:opacity-50`}
                  disabled={isNextAvailable ? false : true}
                  style={{ background: 'rgba(18, 145, 249, 0.18)', fontWeight: 700 }}
                >
                  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.844274 0.248568L0.348393 0.738782C0.193527 0.893064 0.107935 1.0986 0.107432 1.31848C0.10693 1.53824 0.191582 1.74441 0.345742 1.8994L5.93809 7.51711L0.313903 13.1157C0.159038 13.2697 0.0735667 13.4755 0.0730647 13.6952C0.0725628 13.915 0.157093 14.1213 0.311253 14.2762L0.80184 14.7687C1.12087 15.0895 1.64172 15.0907 1.96221 14.7714L8.68251 8.1057C8.83725 7.95166 8.94651 7.74618 8.94701 7.52447L8.94702 7.52191C8.94752 7.30203 8.83909 7.09605 8.68517 6.9413L2.01355 0.251239C1.85951 0.0962508 1.64775 0.0108871 1.428 0.0101419C1.20812 0.0096397 0.998897 0.0942851 0.844274 0.248568Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>

          </div>
          <div className={`flex flex-row mt-3`}>
            {/* Left Sidebar */}
            <div className={`mailbox-leftbar`} style={{ background: '#FAFAFA', flexBasis: 250, flexShrink: 0 }}>

              {/* Compose Button */}
              <div className={`pl-6`}>
                <button
                  onClick={(e) => this.clickComposeMail()}
                  className={`bg-blue-800 hover:bg-blue-800 text-white text-md leading-4 py-2 px-4 mt-4 h-8`}
                  style={{ borderRadius: 10, background: '#1291F9', width: 158 }}
                >
                  { t('Compose') }
                </button>
              </div>
              
              {/* Mailbox Style */}
              <div className={`mt-3 text-sm`}>
                <a
                  onClick={(e) => this.clickMenu("inbox")}
                  className={`block px-4 py-2 cursor-pointer hover:bg-gray-300
                  ${type == "inbox" ? classes.activeItem : ""}`}
                >
                  { t('Inbox') }
                </a>
                <a
                  onClick={(e) => this.clickMenu("outbox")}
                  className={`block px-4 py-2 cursor-pointer hover:bg-gray-300
                    ${type == "outbox" ? classes.activeItem : ""}`}
                >
                  { t('Sent Mail') }
                </a>
                <a
                  onClick={(e) => this.clickMenu("important")}
                  className={`block px-4 py-2 cursor-pointer hover:bg-gray-300
                  ${type == "important" ? classes.activeItem : ""}`}
                >
                  { t('Important') }
                </a>
                <a
                  onClick={(e) => this.clickMenu("draft")}
                  className={`block px-4 py-2 cursor-pointer hover:bg-gray-300
                    ${type == "draft" ? classes.activeItem : ""}`}
                >
                  { t('Draft') }
                </a>
                <a
                  onClick={(e) => this.clickMenu("trash")}
                  className={`block px-4 py-2 cursor-pointer hover:bg-gray-300
                  ${type == "trash" ? classes.activeItem : ""}`}
                >
                  { t('Trash') }
                </a>
              </div>
              {/* Recent Contacts */}
              <div className={`labels-list px-3 mt-2 pt-3 border-t`}>
                <div className={`text-lg mb-2 flex justify-between px-2`}>
                  <div className={``} style={{color: '#1291F9'}}>
                    { t('Recent Contacts') }
                  </div>
                  <div className={`flex`}>
                    <AddCircleRoundedIcon className={`cursor-pointer`} style={{color: '#1291F9'}} />
                  </div>
                </div>
                <div className={`overflow-y-auto mb-3`} style={{ maxHeight: "450px" }}>
                  {this.renderListMessage()}
                </div>
              </div>
              {/* <div className={`labels-list px-4 mt-5`}>
                <div className={`text-lg mb-2`}>{ t('Labels') }</div>
                <div className={`flex flex-row text-sm items-center my-2`}>
                  <span
                    className={`w-2 h-2 inline-block rounded-full bg-blue-800 mr-3`}
                  ></span>
                  { t('Work') }
                </div>
                <div className={`flex flex-row text-sm items-center my-2`}>
                  <span
                    className={`w-2 h-2 inline-block rounded-full bg-green-400 mr-3`}
                  ></span>
                  { t('Private') }
                </div>
              </div> */}
            </div>
            {/* Main List */}
            {!this.state.showCompose
              ? this.renderMailListPart()
              : this.renderMailComposePart()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList,
  };
};

const mapDispatchToProps = {};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(MessageBoardStyle)(MessageBoard)));
