import * as Type from '../actions/types';

const initialState = {
    data: {
        leftSidebarOpened: true,
        contactSidebarOpened: false
    }
};

export const uiSetting = (state = initialState, action) => {
  switch (action.type) {
    case Type.UI_SETTING:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
