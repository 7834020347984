import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import EmailTemplatesPageStyle from "./EmailTemplatesPageStyle.jsx";
import Cookies from "js-cookie";

import {
  addCategory,
  getCategories,
  deleteCategory,
} from "../../../redux/actions/adminActions";

import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class EmailTemplatesPage extends Component {
  state = {
    isEditMode: false,
    editData: null,
    templates: [],
  };

  constructor(props) {
    super(props);
    this.loadData();
  }

  loadData = async () => {
    const loginStatus = this.props.account.loginStatus;

    fetch(apiUrl + "api/admin/email-template", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          this.setState({ templates: result["templates"] });
        }
      });
  };

  showDetails(item) {
    const loginStatus = this.props.account.loginStatus;

    fetch(apiUrl + "api/admin/email-template/" + item.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          this.setState({ editData: result["data"] });
          this.setState({ isEditMode: true });
        }
      });
  }

  cancelDetails() {
    this.setState({ editData: null });
    this.setState({ isEditMode: false });
    this.loadData();
  }

  saveDetails() {
    const loginStatus = this.props.account.loginStatus;
    let item = this.state.editData;

    fetch(apiUrl + "api/admin/email-template/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify(item),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          this.setState({ editData: null });
          this.setState({ isEditMode: false });
          this.loadData();
        }
      });
  }

  onDetailsChange(e, type) {
    let data = {};
    data[type] = e.target.value;
    this.setState({
      editData: Object.assign(this.state.editData, data),
    });
  }

  render() {
    const { classes, t } = this.props;
    const title =
      !this.state.isEditMode || this.state.editData == null
        ? t("Email Templates")
        : t("Email Template") + " - " + this.state.editData.kind;
    return (
      <div className={`emailtemp-wrapper`}>
        <div className={`w-full`}>
          <div className={`w-full text-lg pb-4`}>{t(title)}</div>
          {!this.state.isEditMode || this.state.editData == null
            ? this.renderEmailTemlates()
            : this.renderEmailDetails()}
        </div>
      </div>
    );
  }

  renderEmailTemlates() {
    const { classes, t } = this.props;

    return (
      <div
        className={`template-list rounded-md border overflow-hidden border-gray-200`}
      >
        {/* Header */}
        <div className={`bg-gray-200 text-sm px-3 w-full flex flex-row`}>
          <div className={`w-9/12 flex flex-row`}>
            <div className={`w-1/12 py-2 px-1 mr-1 border-r border-white`}>
              No
            </div>
            <div className={`w-3/12 py-2 px-1 mr-1 border-r border-white`}>
              {t('Kind')}
            </div>
            <div className={`w-4/12 py-2 px-1 mr-1 border-r border-white`}>
              {t('Title')}
            </div>
            <div className={`w-4/12 py-2 px-1 mr-1 border-r border-white`}>
              {t('Template')}
            </div>
          </div>
          <div className={`w-3/12 flex flex-row`}>
            <div className={`w-7/12 py-2 px-1 mr-1 border-r border-white`}>
              {t('Updated By')}
            </div>
            <div className={`w-5/12 py-2 px-1 `}>{t('Last Updated At')}</div>
          </div>
        </div>
        {/* Data List */}
        <div className={`w-full row-list`}>
          {this.state.templates.map((item, key) => {
            return (
              <div
                key={key}
                className={`px-3 w-full flex flex-row cursor-pointer border-b border-gray-200 break-all hover:bg-gray-100`}
                onClick={(e) => this.showDetails(item)}
              >
                <div className={`w-9/12 flex flex-row`}>
                  <div
                    className={`w-1/12 py-2 px-1 mr-1 border-r border-gray-200`}
                  >
                    {key + 1}
                  </div>
                  <div
                    className={`w-3/12 py-2 px-1 mr-1 border-r border-gray-200`}
                  >
                    {item.kind}
                  </div>
                  <div
                    className={`w-4/12 py-2 px-1 mr-1 border-r border-gray-200`}
                  >
                    {item.title}
                  </div>
                  <div
                    className={`w-4/12 py-2 px-1 mr-1 border-r border-gray-200`}
                  >
                    {item.template}
                  </div>
                </div>
                <div className={`w-3/12 flex flex-row`}>
                  <div
                    className={`w-7/12 py-2 px-1 mr-1 border-r border-gray-200`}
                  >
                    Lucas Moura
                  </div>
                  <div className={`w-5/12 py-2 px-1 `}>Mar 5, 02:26</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderEmailDetails() {
    const { classes, t } = this.props;
    const item = this.state.editData;
    return (
      <div className={`emailtemp-wrapper`}>
        <div className={`w-full flex flex-row mb-4`}>
          <div className={`w-1/2 mr-4`}>
            <div className={`w-full text-blue-700`}>{t('Edit template')}</div>
          </div>
          <div className={`w-1/2`}>
            <div className={`w-full text-blue-700`}>{t('Preview')}</div>
          </div>
        </div>
        <div className={`w-full  flex flex-row mb-3`}>
          <div className={`w-1/2 mr-4`}>
            <textarea
              onChange={(e) => this.onDetailsChange(e, "template")}
              value={this.state.editData.template}
              className={`w-full h-full rounded-md border border-gray-200 p-2`}
            ></textarea>
          </div>
          <div className={`w-1/2`}>
            <code dangerouslySetInnerHTML={{ __html: item.template }}></code>
          </div>
        </div>
        <div className={`w-full flex flex-row mb-4 justify-center`}>
          <button
            onClick={(e) => this.saveDetails()}
            className={`w-32 bg-blue-800 hover:bg-blue-900 text-white text-sm leading-4 py-2 px-4 rounded-sm mt-3`}
          >
            {t('Save')}
          </button>
          <button
            onClick={(e) => this.cancelDetails()}
            className={`ml-4 w-32 bg-red-800 hover:bg-red-700 text-white text-sm leading-4 py-2 px-4 rounded-sm mt-3`}
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    invoiceResult: state.invoiceResult,
    initialResponse: state.initialResponse,
    account: state.account,
  };
};

const mapDispatchToProps = { addCategory, getCategories, deleteCategory };
export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(EmailTemplatesPageStyle)(EmailTemplatesPage)));
