import commonStyle from 'assets/jss/commonStyle'

const msaUploadPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        paddingTop: 30,
        paddingBottom: 30,
        height: '100%',
    },
    contentContainer: {
        marginTop: 20,
        height: 'calc(100% - 30px)',
        paddingLeft: 30,
        paddingRight: 30,
    },
    topWrapper: {
        display: 'flex',
        marginLeft: 30,
        marginRight: 30,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolboxContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#F9F9F9',
        padding: '20px 20px',
    },
    ctrlIcon: {
        marginRight: 10,
        backgroundColor: '#F4E19D',
        borderRadius: 4,
        padding: 2,
    },
    pdfContainer: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    ctrlContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 16,
        margin: '5px 0px'
    },
    btnSend: {
        textAlign: 'center',
        background: '#1D8ACF',
        color: 'white',
        paddingTop: '6px',
        paddingBottom: '6px',
        width: '120px',
        display: 'inline-block',
    },
    btnGo: {
        textAlign: 'center',
        background: '#41AE97',
        color: 'white',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 'auto',
        marginRight: '20px'
    },
    modalContainer: {
        width: '800px',
        backgroundColor: 'white',
        borderRadius: 4,
    },
    modalWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signature_pad: {
        width: '100%',
        marginBottom: '0px !important'
    },
    modalTitle: {
        padding: 20,
        borderBottom: '1px solid #E9E9E9',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btnAdopt: {
        background: '#1D8ACF',
        borderRadius: 0,
        fontSize: 16,
        color: 'white',
        marginRight: 20,
        padding: '5px 8px'
    },
    btnModal: {
        borderRadius: 0,
        fontSize: 16,
        marginRight: 20,
        padding: '5px 8px'
    },
    successModalContainer: {
        width: '600px',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '20px 25px'
    },
    modalBtnConfirm: {
        background: '#1D8ACF',
        borderRadius: 0,
        fontSize: 16,
        color: 'white',
        padding: '5px 23px',
        marginTop: '15px',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});
export default msaUploadPageStyle;