import React, { Component } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/Button/index.jsx';
import Loading from 'components/Loading';
import BaseInput from 'components/BaseInput/index.jsx';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import BrushIcon from '@material-ui/icons/Brush';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SignaturePad from 'react-signature-pad-wrapper';
import { withTranslation  } from 'react-i18next';
import { connect } from 'react-redux';
import { uploadFiles } from '../../redux/actions/fileActions';
import leanDocumentStyle from './leanDocumentStyle';
import { successConfirm } from "components/SuccessConfirm/index";
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';
import moment from 'moment';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc,image/*');

    return fileSelector;
}

class InterimLien extends Component
{
    state = {
        uploading: false,
        lean_document: {
            release_name: '',
            business_address: '',
            city: '',
            project: '',
            project_manager: '',
            project_telephone: '',
            contact_person: '',
            contact_telephone: '',
            conditional_release_amount: '',
            unconditional_release_amount: '',
            date: moment().format('YYYY-MM-DD'),
            title: '',
            state: '',
            acknowledge_date: null,
            notary_state: '',
            notary_email: '',
            notary_name: '',
            appointment_expires_at: null,
            conditional_date: '',
            unconditional_date: '',
            doc_type: 'interim',
            message: ''
        },
        existing_attachments: [],
        attachments: [],
        signed: false,
        viewMode: false,
    }

    constructor (props) {
        super(props);

        const { user } = props.account.loginStatus;
        this.state.lean_document.contact_person = user.first_name + ' ' + user.last_name;
        this.state.lean_document.contact_telephone = user.profile.phone;
    }

    componentDidMount() {
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));
        
        if (this.props.document) {
            this.setState({ lean_document: this.props.document, viewMode: true });
        }
    }

    handleClickUploadAttachment = () => {
        this.fileSelector.click();
    }

    fileChanged = () => {
        if(this.fileSelector.files.item(0) === null)
            return;
        let attachments = this.fileSelector.files;
        this.setState({ attachments });
    }

    render() {
        const { uploading, lean_document, viewMode } = this.state;
        const { t, classes } = this.props;
        return (
            <div>
                <div style={{ opacity: uploading ? 0.5 : 1, width: '100%' }}>
                    <h4 className={ classes.leanTitle }>
                        {t('INTERIM LIEN / CLAIM WAIVER')}
                    </h4>
                    <GridContainer style={{ marginTop: 30 }}>
                        <GridItem md={12} lg={6}  style={{ padding: '0px 10px' }}>
                            <GridContainer>
                                <GridItem xs={4} style={{ paddingTop: 15 }}>
                                    {t('From')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small placeholder={t("Name of Firm Giving Release")} className={ classes.input } style={{ marginTop: 10, marginBottom: 10 }} onChange={event => this.textChanged(event, 'release_name')} value={ lean_document.release_name } readOnly={ viewMode } />
                                    <BaseInput small placeholder={t("Business Address")} className={ classes.input } style={{ marginTop: 10, marginBottom: 10 }} onChange={event => this.textChanged(event, 'business_address')} value={ lean_document.business_address } readOnly={ viewMode } />
                                    <BaseInput small placeholder={t("City, State, Zip Code")} className={ classes.input } style={{ marginTop: 10, marginBottom: 10 }} onChange={event => this.textChanged(event, 'city')} value={ lean_document.city } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={{ marginTop: 10, marginBottom: 10 }} >
                                <GridItem xs={4} style={{ alignItems: 'center' }}>
                                    {t('Contact Person')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small className={ classes.input } onChange={event => this.textChanged(event, 'contact_person')} value={ lean_document.contact_person } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={{ marginTop: 10, marginBottom: 10 }}>
                                <GridItem xs={4} style={{ alignItems: 'center' }}>
                                    {t('Contact Telephone')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small className={ classes.input } onChange={event => this.textChanged(event, 'contact_telephone')} value={ lean_document.contact_telephone } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem md={12} lg={6} style={{ padding: '0px 10px' }}>
                            <GridContainer  style={{ padding: '10px 0px' }}>
                                <GridItem xs={4}>
                                    {t('Project')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small className={ classes.input } onChange={event => this.textChanged(event, 'project')} value={ lean_document.project } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer  style={{ padding: '10px 0px' }}>
                                <GridItem xs={4}>
                                    {t('Project Manager')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small className={ classes.input } onChange={event => this.textChanged(event, 'project_manager')} value={ lean_document.project_manager } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer  style={{ padding: '10px 0px' }}>
                                <GridItem xs={4}>
                                    {t('Project Telephone')}:
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small className={ classes.input } onChange={event => this.textChanged(event, 'project_telephone')} value={ lean_document.project_telephone } readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <p className={ classes.description }>
                        {t('The Contractor has contracted to construct, after or repair an improvement on the real property located at the Project Address referenced above, and legally described on Exhibit A, if attached (the "Property"), or to furnish labor upon the Property, or to furnish material or to transport material to be used in the construction of an improvement on the Property.')}
                    </p>
                    <GridContainer style={{ marginTop: 30 }}>
                        <GridItem xs={6} style={{ padding: '5px 10px' }}>
                            <h5 className={ classes.leanTitle } style={{ marginBottom: 20, }}>
                                {t('FINAL CONDITIONAL LIEN RELEASE')}
                            </h5>
                            <p>
                            {t('The undersigned does hereby acknowledge that upon receipt by the undersigned of a check from Hermanson Company in the sum of ')} 
                            $<BaseInput small type="number" value={ lean_document.conditional_release_amount } onChange={ event => this.textChanged(event, 'conditional_release_amount') } readOnly={ viewMode } />
                            {t(' and when the check had been properly endorsed and has been paid by the bank upon which it was drawn, this document shall become effective to release pro tanto any and all claims and rights of lien which the undersigned has on the above referenced job except work performed to the extent that payment is being claims or right of lien for contract and/or change order retained or will subsequently become due. This release covers a progress payment for labor, services, equipment, materials furnished and/or claims through ')}
                            <TextField label="" type="date" value={ lean_document.conditional_date } onChange={ event => this.textChanged(event, 'conditional_date') } style={{ marginLeft: 10, width: 200, height: 32, paddingTop: 0, }} className={`datepicker common`} readOnly={ viewMode } />
                            {t(' only and does not cover any retention or items furnished after that date. Before any recipient of this document relies on it, said party should verify evidence of payment to the undersigned.')}
                            </p>
                        </GridItem>
                        <GridItem xs={6} style={{ padding: '5px 10px' }} >
                            <h5 className={ classes.leanTitle } style={{ marginBottom: 20, }}>
                                {t('FINAL UNCONDITIONAL LIEN RELEASE')}
                            </h5>
                            <p>
                                {t('The undersigned does hereby acknowledge that the undersigned has been paid and has received progress payments in the sum of ')}
                                $<BaseInput small type="number" value={ lean_document.unconditional_release_amount } onChange={ event => this.textChanged(event, 'unconditional_release_amount') } readOnly={ viewMode } /> 
                                {t(' for labor, services, equipment or materials furnished to the above referenced job and does hereby release pro tanto any and all claims and right of lien which the undersigned has on the above referenced job. This release covers all payment for labor, services, equipment and materials furnished and/or claims to the above referenced job through ')}
                                <TextField label="" type="date" value={ lean_document.unconditional_date } onChange={ event => this.textChanged(event, 'unconditional_date') } style={{ marginLeft: 10, width: 200, height: 32, paddingTop: 0, }} className={`datepicker common`} readOnly={ viewMode } />
                                {t(' only and does not conver any retention or items furnished after that date and does not cover claims or right of lien for contract and/or change order work performed to the extent that payment is being retained or will subsequently become due.')}
                            </p>
                            <p>
                                {t('Notice') }: {t('THIS DOCUMENT WAIVES RIGHTS UNCONDITIONALLY AND STATES THAT YOU HAVE BEEN PAID FOR GIVING UP THOSE RIGHTS. THIS DOCUMENT IS ENFORCEABLE AGAINST YOU IF YOU SIGN IT, EVEN IF YOU HAVE NOT BEEN PAID. IF YOU HAVE NOT BEEN PAID, USE A CONDITIONAL RELEASE FORM.')}
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: 30 }}>
                        <GridItem xs={3}>
                            {t('Authorized Signature')}
                        </GridItem>
                        <GridItem xs={3}>
                            {
                                viewMode && 
                                    <img src={ lean_document.user_signature } alt="Signature" style={{ width: '500px', objectFit: 'contain' }} />
                            }
                        </GridItem>
                        <GridItem xs={3}>
                            {t('Date')}
                        </GridItem>
                        <GridItem xs={3}>
                            <TextField
                                id="datepicker"
                                label=""
                                type="date"
                                value={ lean_document.date }
                                onChange={ event => this.textChanged(event, 'date') }
                                className={`datepicker common`}
                                readOnly={ viewMode }
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={ classes.notifyWrapper }>
                        <GridItem xs={3}>
                            {t('Legal Name')}
                        </GridItem>
                        <GridItem xs={9}>
                            <BaseInput
                                style={{ width: '100%' }}
                                small
                                value={ lean_document.title }
                                onChange={ event => this.textChanged(event, 'title') }
                                readOnly={ viewMode }
                                />
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={ classes.notifyWrapper }>
                        <GridItem xs={3}>
                            {t('State of')}:
                        </GridItem>
                        <GridItem xs={9}>
                            <BaseInput
                                style={{ width: '100%' }}
                                small
                                value={ lean_document.state }
                                onChange={ event => this.textChanged(event, 'state') }
                                readOnly={ viewMode }
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: 30 }}>
                        <GridItem xs={3}>
                        </GridItem>
                        <GridItem xs={9}>
                            {/* <GridContainer className={ classes.notifyWrapper }>
                                <GridItem xs={4}>
                                    {t('Insurance Agent Public in and for the State of')}
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small style={{ width: '80%', float: 'right' }} value={ lean_document.notary_state } onChange={event => this.textChanged(event, 'notary_state')} readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={ classes.notifyWrapper }>
                                <GridItem xs={4}>
                                    {t('Insurance Agent Email')}
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small style={{ width: '80%', float: 'right' }} type="email" value={ lean_document.notary_email } onChange={event => this.textChanged(event, 'notary_email')} readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={ classes.notifyWrapper }>
                                <GridItem xs={4}>
                                    {t('Insurance Agent Name')}
                                </GridItem>
                                <GridItem xs={8}>
                                    <BaseInput small style={{ width: '80%', float: 'right' }} value={ lean_document.notary_name } onChange={event => this.textChanged(event, 'notary_name')} readOnly={ viewMode } />
                                </GridItem>
                            </GridContainer> */}
                        </GridItem>
                    </GridContainer>
                    {
                        !viewMode && 
                            <GridContainer spacing={10}>
                                <GridItem xs={8} style={{ paddingBottom: 0 }}>
                                    {t('Signature')}
                                </GridItem>
                                <GridItem xs={8} style={{ paddingRight: 0 }}>
                                    <div className={`${classes.signature_pad} sign_pad_wrapper`}>
                                        <SignaturePad ref={ref => this.signaturePad = ref} options={{ dotSize: 5, minWidth: 1, maxWidth: 1.5, maxHeight: 2, penColor: 'rgb(0, 0, 0)' }} />
                                        <div style={{ textAlign: 'right' }}>
                                            <Button className={`button-sm`} onClick={e => this.cleanSignPad(e)}>
                                                {t('Clear')}
                                            </Button>
                                        </div>
                                        {/* <button onClick={e => this.saveSignPad(e)}>Save</button> */}
                                        <div className={`preload_font type1`}>Preloading font</div>
                                        <div className={`preload_font type2`}>Preloading font</div>
                                        <div className={`preload_font type3`}>Preloading font</div>
                                        <div className={`preload_font type4`}>Preloading font</div>
                                    </div>
                                </GridItem>
                                <GridItem xs={4}>
                                    <List component="nav">
                                        <ListItem button onClick={e => this.setSignType('')} selected={this.state.signType == ''}>
                                            <ListItemIcon><BrushIcon /></ListItemIcon>
                                            <ListItemText primary={ t('Draw') } />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('amandasignature')} selected={this.state.signType == 'amandasignature'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Amanda Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('geovana')} selected={this.state.signType == 'geovana'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Geovana Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('julietta')} selected={this.state.signType == 'julietta'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Julietta Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('vtks-hunt')} selected={this.state.signType == 'vtks-hunt'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Vtks Hunt Font" />
                                        </ListItem>
                                    </List>
                                </GridItem>
                            </GridContainer>
                    }
                    {/* <GridContainer>
                        <GridItem xs={12} style={{ marginBottom: 20 }}>
                            Special message for insurance agent
                        </GridItem>
                        <GridItem xs={12} style={{ marginBottom: 20 }}>
                            <textarea
                                className={ classes.messageArea }
                                readOnly={ viewMode }
                                value={ lean_document.message }
                                onChange={ event => this.textChanged(event, 'message') }
                            >
                            </textarea>
                        </GridItem>
                    </GridContainer> */}
                    {/* {
                        !viewMode && 
                            <GridContainer style={{marginBottom: '16px'}}>
                                <GridItem xs={12} className={ `${classes.uploadAttachmentContainer}` } 
                                    style={{cursor: 'pointer'}}
                                onClick={this.handleClickUploadAttachment}>
                                    <h5 className={ [classes.uploadAttachment, `inline`].join(' ') }>Upload attachment</h5>
                                    <AddCircleOutlineIcon className={ [classes.addIcon, `inline`].join(' ') } onClick={ this.handleClick } />
                                </GridItem>
                            </GridContainer>
                    }
                    <div className={ classes.attachmentSection }>
                        { this.renderExistingAttachments() }
                        { this.renderAttachments() }
                    </div> */}
                    {
                        !viewMode && 
                        <GridContainer>
                            <GridItem xs={12} style={{ textAlign: 'center'}}>
                                <Button
                                    variant="contained"
                                    className={ classes.btnSubmit }
                                    onClick={ this.submitClicked }
                                >
                                    {t('Submit')}
                                </Button> <br/>
                                <span style={{ fontSize: 14, color: '#A30C0C' }}>
                                    { this.state.errMessage }
                                </span>
                            </GridItem>
                        </GridContainer>
                    }
                </div>
                <Loading uploading={ uploading }/>
            </div>
        )
    }

    // Show existing attachments
    renderExistingAttachments = () => {
        const {classes} = this.props;
        return this.state.existing_attachments.map((filename, key) => {
            var filebasename = filename.substring(filename.lastIndexOf('/') + 1);
            var filebasenameUrl = encodeURIComponent(filebasename);
            var apiUrl = this.state.apiUrl;
            if(filebasename == ''){
                return null;
            }else{
                return (
                    <div className={ classes.attachmentContainer } key={key}>
                        <img src="/assets/img/attachment-active.png" style={{width: 16, height: 16}} alt="attachment" className={ [classes.attachmentImg, `inline-block`].join(' ') }/>
                            { filebasename }&nbsp;
                            <a href={apiUrl + 'api/download/file/' + filebasenameUrl} target="_blank"><SaveAltIcon /></a>
                    </div>
                )
            }
        })
    }

    // Show new added attachments
    renderAttachments = () => {
        const {classes} = this.props;

        let fileNames = [];
        for(let i = 0; i < this.state.attachments.length;i ++)
        {
            fileNames.push(this.state.attachments.item(i).name);
        }
        return fileNames.map((filename, key) => {
            return (
                <div className={ classes.attachmentContainer } key={key}>
                    <img src="/assets/img/attachment-inactive.png" style={{width: 24, height: 24, marginRight: 8}} alt="attachment" className={ [classes.attachmentImg, `inline-block`].join(' ') } />
                        { filename }
                </div>
            )
        })
    }

    textChanged = (event, key) => {
        let { lean_document: doc } = this.state;
        doc[key] = event.target.value;
        this.setState({ lean_document: doc });
    }

    submitClicked = async () => {
        let {lean_document: doc} = this.state;
        const { t } = this.props;
        var canvas = this.signaturePad._canvas;
        doc['user_signature'] = canvas.toDataURL();

        if (!doc.release_name.length) {
            this.setState({errMessage: t('Please input name of firm giving release.')});
            return;
        }

        if (!doc.business_address.length) {
            this.setState({errMessage: ('Please input business address.')});
            return;
        }

        if (!doc.city.length) {
            this.setState({errMessage: t('Please input city, state, zip code.')});
            return;
        }

        if (!doc.project.length) {
            this.setState({errMessage: t('Please input project.')});
            return;
        }

        if (!doc.project_manager.length) {
            this.setState({errMessage: t('Please input project manager.')});
            return;
        }

        if (!doc.project_telephone.length) {
            this.setState({errMessage: t('Please input project telephone.')});
            return;
        }

        if (!doc.contact_person.length) {
            this.setState({errMessage: t('Please input contact person.')});
            return;
        }

        if (!doc.contact_telephone.length) {
            this.setState({errMessage: t('Please input contact telephone.')});
            return;
        }

        if (!doc.conditional_release_amount.length) {
            this.setState({errMessage: t('Please input conditional release amount.')});
            return;
        }

        if (!doc.unconditional_release_amount.length) {
            this.setState({errMessage: t('Please input unconditional release amount.')});
            return;
        }

        if (!doc.date.length) {
            this.setState({errMessage: t('Please input date.')});
            return;
        }

        if (!doc.title.length) {
            this.setState({errMessage: t('Please input title.')});
            return;
        }

        if (!doc.state.length) {
            this.setState({errMessage: t('Please input state.')});
            return;
        }

        /* if (!doc.notary_state.length) {
            this.setState({errMessage: t('Insurance Agent Public in and for the State.')});
            return;
        } */

        if (!doc.conditional_date.length) {
            this.setState({errMessage: t('Please input conditional date.')});
            return;
        }

        if (!doc.unconditional_date.length) {
            this.setState({errMessage: t('Please input unconditional date.')});
            return;
        }

        /* if (!doc.notary_email.length || !doc.notary_email.includes('@')) {
            this.setState({errMessage: t('Please input Insurance Agent email.')});
            return;
        }

        if (!doc.notary_name.length) {
            this.setState({errMessage: t('Please input Insurance Agent name.')});
            return;
        } */

        if (this.signaturePad.isEmpty() && !this.state.signed) {
            this.setState({errMessage: t('Please sign the document.')});
            return;
        }

        this.setState({errMessage: '', uploading: true});
        if (this.state.uploading) {
            return;
        }

        await this.props.uploadFiles(this.state.attachments, 'lean_attachments');
        this.handleFileUploadResponse(this.props.uploadResult, doc);
    }

    // Form Submitting
    handleFileUploadResponse = async (response, doc) => {
        var res = response['uploadResult'];
        
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const loginStatus = this.props.account.loginStatus;

        doc['existing_attachments'] = this.state.existing_attachments.join(",");
        doc['attachments'] = res.files.join(",");

        if (res.status) {
            return fetch(apiUrl + 'api/lean_release/upload', {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + loginStatus.token,
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                body: JSON.stringify({ document: doc })
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === 'success') {
                    successConfirm("Submitted Successfully", "You have successfully signed a interim lien document.", false).then(
                        async () => {
                            this.props.history.push('/certificates/list');
                        },
                        () => {
                        }
                    );

                    return;
                }
    
                this.setState({uploading: false})
            });
        }else{
            this.setState({'submitErrorMessage': res['message']});
        }
    }

    cleanSignPad = (e) => {
        this.signaturePad.clear();
        this.signaturePad.isEmpty();
        this.setState({signed: false});
    }

    setSignType = (signType) => {
        this.setState({ signType: signType });
        if (signType != '') {
            const { user } = this.props.account.loginStatus;
            const name = user.first_name + " " + user.last_name;
            this.drawNameOnSignPad(name, 'sign-' + signType);
        } else {
            this.signaturePad.clear();
            this.signaturePad.isEmpty();
        }
    }

    drawNameOnSignPad = (name, font = 'sign-amandasignature', fontSize = 56) => {
        if (name.length < 1) return;
        this.setState({signed: true});
        this.signaturePad.clear();
        this.signaturePad.isEmpty();

        var canvas = this.signaturePad._canvas;
        var ctx = canvas.getContext('2d');
        var cWidth = canvas.width;
        var cHeight = canvas.height;
        var nLength = name.length;
        ctx.font = fontSize + 'px "' + font + '"';
        ctx.fillText(name, 30, canvas.offsetHeight * 0.5 + (cHeight / canvas.offsetHeight) * fontSize * 0.5);
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {uploadFiles}

export default withRouter(withStyles(leanDocumentStyle)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InterimLien))));