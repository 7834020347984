import * as Type from '../actions/types';

const initialState = {
    data: {
        status: false,
        message: ''
    }
};

export const printSendEmailResult = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_SEND_INVOICE:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
