import commonStyle from 'assets/jss/commonStyle'

const dashboardHomeStyle = theme => ({
    ...commonStyle,
    statBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingBottom: 80,
        marginTop: 32
    },
    statCard: {
        background: '#FFFFFF',
        borderRadius: 10,
        flex: 1,
        border: '1px solid rgba(18, 145, 249, 0.18)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'    
    },
    statCatgContainer: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        color: '#000',
        fontSize: 14,
        background: '#1291F9',
        height: 79,
        color: '#fff',
        borderRadius: '7px 7px 0px 0px',
        lineHeight: '79px',
        '& div': {
            flex: 1,
            textAlign: 'center',
        }
    },
    numericContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        fontSize: 18,
        '& div': {
            flex: 1,
            textAlign: 'center',
            fontSize: 25,
            paddingTop: 25,
            paddingBottom: 33,
        }
    },
    btnRaiseInvoice: {
        color: '#1291F9',
        fontSize: 12,
        lineHeight: '15px',
        textAlign: 'center',
        fontWeight: 'bold',
        width: 132,
        height: 40,
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(183, 164, 164, 0.25)',
        borderRadius: 30,
        marginTop: 15,
        lineHeight: '40px',
        '&:hover': {
            color: '#1291F9',
        }
    },
    statBtn: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 4,
        padding: '25px 0px',
        marginRight: 30,
    },
    statBtnTitle: {
        color: '#fff',
        fontSize: 36,
        textDecoration: 'none',
        '&:hover': {
            color: '#fff',
            textDecoration: 'none'
        }
    },
    statBtnSubtitle: {
        color: '#fff',
        opacity: 0.8,
        fontSize: 13,
        fontWeight: 400,
        textAlign: 'center'
    },
    tileContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        minHeight: 420,
        paddingBottom: 80,
        '&:last-child': {
            borderBottom: '0px solid #DADAE1',
        }
    },
    leftTile: {
        width: 'calc(40% - 18px)',
        marginRight: 30,
        backgroundColor: '#fff',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        paddingBottom: 10,
        border: '1px solid rgba(18, 145, 249, 0.18)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    rightTile: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: '17px 20px',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    rightTileTitle: {
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: '0.005em',
        color: 'rgba(0, 0, 0, 0.4)'
    },
    statusTitle: {
        background: 'rgba(62, 143, 182, 0.1)',
        color: '#000',
        fontSize: 20,
        height: 56,
        lineHeight: '56px',
        paddingLeft: 34,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    chartWrapper: {
        flex: 1,
        position: 'relative',
    },
    chartContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: 20,
        fontWeight: 600,
    },
    bulletContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
        minWidth: 200,
        paddingLeft: 40,
    },
    bullet: {
        display: 'block',
        width: 18,
        height: 18,
        borderRadius: 10,
        marginTop: 1,
    },
    pieLabel: {
        textAlign: 'center',
    },
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        }
    },
    btnDuration: {
        fontSize: 9,
        marginLeft: 3,
        marginRight: 3,
        padding: '1px 10px',
        cursor: 'pointer',
        border: '1px solid #fff',
        lineHeight: '25px',
        '&.selected': {
            border: '1px solid #EBEBEB',
            borderRadius: 20,
        }
    },
    statTotalContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    contactContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    activityContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginLeft: -30,
        marginRight: -30,
        padding: '10px 40px',
    },
    activityDescription: {
        fontSize: 10,
        marginLeft: 20,
        display: 'flex', 
        width: '100%',
        borderBottom: '0.3px solid rgba(0, 0, 0, 0.33)',
        paddingTop: 12,
        paddingBottom: 12,
        letterSpacing: '0.005em',
        justifyContent: 'space-between'
    },
    historyContainer: {
        background: '#fff',
        marginTop: 30,
    },
    outlinedSelect: {
        border: '1px solid #EBEBEB',
        borderRadius: 4,
        paddingLeft: 10,
        paddingRight: 10,
        '&:before': {
            borderBottom: '0px solid #fff !important'
        }
    },
    btnApply: {
        border: '1px solid #EBEBEB',
        borderRadius: 4,
        background: 'white',
        color: '#555555',
        fontSize: 16,
        paddingLeft: 22,
        paddingRight: 22,
        marginLeft: 15,
    },
    searchInput: {
        border: '1px solid #EBEBEB',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        fontSize: 16,
        height: '100%',
        paddingLeft: 10,
    },
    btnSearchGo: {
        border: '1px solid #EBEBEB',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        paddingLeft: 10,
        paddingRight: 10,
        background: 'white',
        fontSize: 16,
        height: '100%',
        borderLeftWidth: 0,
        color: '#555555',
    },
    historyTableHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        borderBottom: '1px solid #E4E4E4',
        '& div': {
            display: 'flex',
        }
    },
    checkBoxCell: {
        flex: 1,
    },
    nameCell: {
        flex: 4,
    },
    invoiceCell: {
        flex: 6,
    },
    dateCell: {
        flex: 3,
    },
    statusCell: {
        flex: 3,
        justifyContent: 'center'
    },
    actionCell: {
        flex: 4,
        justifyContent: 'center',
        '& button': {
            height: '100%',
            background: '#FFFFFF',
            border: '1px solid #E1E1E1',
            borderRadius: 3,
            '& img': {
                height: '100%',
                width: 15,
                height: 15,
            },
            padding: '4px 7px',
            margin: 5
        }
    },
    historyTableContent: {
        width: '100%'
    },
    historyTableRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 16,
        borderBottom: '1px solid #E4E4E4',
        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '&:nth-child(2n + 1)': {
            background: '#F9F9F9'
        }
    },
    historyTableFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '30px 0px',
    },
    itemsCount: {
        opacity: 0.6,
        fontSize: 14,
    },
    pagination: {
        '& button': {
            background: 'white',
            border: '1px solid #ECECEC',
            fontSize: 16,
            color: '#555555',
            width: 35,
            height: 35,
            '&:first-child': {
                borderTopLeftRadius: 4,
                borderBottomLeftRadisu: 4,
            },
            '&:last-child': {
                borderTopRightRadius: 4,
                borderBottomRightRadisu: 4,
            }
        }
    },
    tileBadge: { 
        background: '#1291F9',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        color: 'white',
        fontSize: 15,
        position: 'absolute',
        right: -20,
        top: -30,
        width: 40,
        height: 36,
        borderRadius: 40,
        fontSize: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center' 
    },
    contactTitle: {
        fontSize: 25,
        fontWeight: 600,
        lineHeight: '31px',
        color: '#000',
        position: 'relative'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 30,
        maxWidth: 420,
        margin: '10px 30px'
    },
    activityScroll: {
        maxHeight: 240,
        overflowY: 'auto',
        overflowX: 'hidden'
    }
});
export default dashboardHomeStyle;