import * as Type from '../actions/types';

const initialState = {
    data: {}
};

export const commonData = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_DATA:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};