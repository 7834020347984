const commonStyle = {
    marginVertical_25: {
        marginTop: '25px',
        marginBottom: '25px',
    },
    marginRight_40: {
        marginRight: '40px',
    },
    marginLeft_25: {
        marginLeft: '25px'
    },
    marginTop_25: {
        marginTop: '25px'
    },
    marginTop_10: {
        marginTop: '10px'
    },
    marginBottom_10: {
        marginBottom: '10px'
    },
    marginBottom_25: {
        marginBottom: '25px'
    },
    marginTop_20: {
        marginTop: '20px'
    },
    marginTop_40: {
        marginTop: '40px'
    },
    marginTop_30: {
        marginTop: 30,
    },
    marginVertical_40: {
        marginTop: '40px',
        marginBottom: '40px',
    },
    marginRight_25: {
        marginRight: '25px !important'
    },
    paddingLeft_12: {
        paddingLeft: '12px'
    },
    marginVertical_10: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    width_100: {
        width: '100%'
    },
    colorPrimary: {
        color: '#A30C0C'
    },
    primaryColor: '#A30C0C',
    container: {
        backgroundColor: '#F3F2FF',
        color: '#000',
        minHeight: '100%',
        paddingLeft: 30,
        paddingRight: 30,
    },
    headerContainer: {
        paddingTop: 22,
        paddingBottom: 22,
        borderBottom: '1px solid #E1E1E1',
        display: 'flex',
        justifyContent: 'space-between'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    pageTitle: {
        color: '#000',
        fontSize: 25,
        lineHeight: '31px',
        letterSpacing: '0.005em',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    pageSubTitle: {
        color: 'rgba(0, 0, 0, 0.4)',
        fontSize: 20,
        lineHeight: '25px',
        letterSpacing: '0.005em',
        marginBottom: -18,
        fontWeight: 600,
        display: 'flex',
        paddingBottom: 13,
    },
    pageContentContainer: {
        padding: '12px 0px',
    },
    contentContainer: {
        background: 'white',
        borderRadius: 7,
        padding: '30px 20px',
        border: '1px solid rgba(18, 145, 249, 0.18)'
    },
    btnHeaderNav: {
        background: '#1291F9',
        color: 'white',
        height: 34,
        lineHeight: '34px',
        display: 'inline-block',
        fontSize: 14,
        borderRadius: 10,
        width: 159,
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: 400,
        '&:hover': {
            color: 'white',
            opacity: 1,
        }
    },
    btnClearFilter: {
        opacity: 0.9,
        '&:hover': {
            opacity: 1
        }
    },
    approveBtn: {
        background: 'rgba(0, 153, 255, 0.2)',
        borderRadius: 7,
        color: '#1291F9',
        fontSize: 12,
        cursor: 'pointer',
        width: 80,
        height: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rejectBtn: {
        background: 'rgba(255, 0, 46, 0.2)',
        borderRadius: 7,
        color: '#EB5757',
        fontSize: 12,
        cursor: 'pointer',
        width: 80,
        height: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    endBtn: {
        background: 'rgba(255, 128, 0, 0.3)',
        borderRadius: 7,
        color: 'rgb(255, 128, 0)',
        fontSize: 12,
        cursor: 'pointer',
        width: 80,
        height: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}

export default commonStyle;