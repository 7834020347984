import React, { Component } from 'react'
import { connect } from 'react-redux';
import setupPageStyle from './setupPageStyle';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import RequestorForm from './RequestorForm';
import BudgetsBulkImportResultsPopup from './BudgetsBulkImportResultsPopup';
import MatButton from 'components/MatButton/index.jsx';
import { confirm } from "../../../components/Confirm/index";
import { getBudgetSettings, delBudgetSettings } from '../../../redux/actions/adminActions';
import { withTranslation  } from 'react-i18next';
import Cookies from "js-cookie";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Modal from '@material-ui/core/Modal';
import { successConfirm } from "components/SuccessConfirm/index";
import PublishIcon from '@material-ui/icons/Publish';
import { withRouter } from 'react-router';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/Button';
import NativeSelect from 'components/NativeSelect';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import SortBtn from 'components/SortBtn';
import SearchInputType2 from "components/SearchInputType2";
import { clearCommonResponse } from '../../../redux/actions/commonActions';

class BudgetsPage extends Component
{
    state = {
        userlist: [],
        showSettingsForm: false,
        expanded: [true,],
        viewMode: 'category',
        editingBudget: null,
        showBulkImportResult: false,
        bulkImportInfos: [],
        sortOption: {
            sortBy: 'name',
            sortAsc: null
        },
        query: '',
        filter: {
            event: 'all',
            category: 'all',
            vendor: 'all',
            buyer: 'all'
        }
    }

    constructor(props){
        super(props);
        this.loadData();
    }

    loadData = async () => {
        await this.props.getBudgetSettings();
    }

    submittedNewBudgetSetting = () => {
        this.loadData();
        setTimeout(() => {
            this.setState({showSettingsForm: false});
        }, 2000);
    }

    populateBudget = (budget) => {
        confirm("Confirm", "Are you sure to send buy request with this budget?").then(
            () => {
                this.props.history.push('/buyer/buy-request', [budget]);
            },
            () => {
            }
        );
    }

    editBudgetSetting = (budget) => {
        this.setState({ editingBudget: budget });
    }

    editModalClosed = () => {
        this.setState({ editingBudget: null });
    }

    deleteBudgetSetting = async (budget) => {
        confirm("Confirm", "Are you sure to delete this budget setting?").then(
            async () => {
                console.log("Click OK");
                await this.props.delBudgetSettings(budget.id);
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    showBudgetSettingsForm = () => {
        this.setState({ showSettingsForm: true });
    }

    hideSettingsForm = () => {
        this.props.clearCommonResponse();
        this.setState({ showSettingsForm: false });
    }

    bannerClicked = (key) => {
        let { expanded } = this.state;
        if (typeof expanded[key] === 'undefined') {
            expanded[key] = true;
        }

        expanded[key] = !expanded[key];

        this.setState({ expanded });
    }

    sortOptionChanged = (sortBy, sortAsc) => {
        this.setState({
            sortOption: {sortBy, sortAsc}
        })
    }

    queryChanged = (value) => {
      this.setState({ query: value });
    }

    filteredBudgets = () => {
        const { budgets } = this.props.commonData.data;
        const { query, viewMode, filter } = this.state;
        const { sortBy, sortAsc } = this.state.sortOption;

        let filteredBudgets = budgets.filter(budget => {
            if (query) {
                const lowerQuery = query.toLowerCase();

                if (budget['category']) {
                    if (budget['category'].toLowerCase().includes(lowerQuery)) {
                        return true;
                    }
                }

                if (budget['eventId']) {
                    if (budget['eventId'].toLowerCase().includes(lowerQuery)) {
                        return true;
                    }
                }

            if (budget['vendor']) {
                    const vendor = budget['vendor'];
                    if (vendor['first_name'].toLowerCase().includes(lowerQuery)) {
                        return true;
                    }

                    if (vendor['last_name'].toLowerCase().includes(lowerQuery)) {
                        return true;
                    }
                }

                return false;
            }

            return true;
        })

        filteredBudgets = filteredBudgets.filter(budget => {
            if (filter['event'] !== 'all' && budget['eventId'] !== filter['event']) {
                return false;
            }

            if (filter['category'] !== 'all' && budget['category'] !== filter['category']) {
                return false;
            }

            if (filter['vendor'] !== 'all' && ((budget['vendor'] && budget['vendor']['id'] !== filter['vendor']) || budget['vendor'] === null)) {
                return false;
            }

            if (filter['buyer'] !== 'all' && ((budget['buyer'] && budget['buyer']['id'] !== filter['buyer']) || budget['buyer'] === null)) {
                return false;
            }

            return true;
        })

        filteredBudgets = [...filteredBudgets];
        filteredBudgets.sort((a, b) => {
            if (sortBy == '' || sortAsc == null) {
                return 1;
            }

            let retVal = 1;
            if (sortBy === 'event' && viewMode === 'category') {
                if (a.eventId.toLowerCase() < b.eventId.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'category') {
                if (a.category.toLowerCase() < b.category.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'category') {
                if (a.sub_category.toLowerCase() < b.sub_category.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'sub_categ') {
                if (a.sub_category.toLowerCase() < b.sub_category.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'desc') {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'value') {
                if (a.value < b.value) {
                    retVal = -1;
                }
            }

            retVal = !sortAsc ? -1 * retVal : retVal;
            return retVal;
        });

        return filteredBudgets;
    }


    excelFileSelected = (event) => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const loginStatus = this.props.account.loginStatus;

        if (this.fileSelector.files.length < 1) {
            return;
        }

        const file = this.fileSelector.files.item(0);
        if (file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            && file.type != 'application/vnd.ms-excel'
        ) {
            NotificationManager.warning('Only .xlsx or .xls file allowed', 'Warning!', 3000);
            return;
        }

        
        var formData = new FormData();
        formData.append('file', file);

        return fetch(apiUrl + 'api/admin/budget_bulk_import', {
            method: 'POST',
            headers: (loginStatus.token != '')? {
            'Authorization': 'Token ' + loginStatus.token,
            'X-CSRFToken': Cookies.get('csrftoken')
            }: {
            'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 'success') {
                this.props.getBudgetSettings();
                this.fileSelector.value = null;
                this.setState({bulkImportInfos: data.importInfo}, () => {
                    this.setState({showBulkImportResult: true});
                });
            } else {
                NotificationManager.error(data.message, 'Error!', 3000);
            }
        });

    }

    showBudgetSettingList = () => {
        const { classes, t } = this.props;
        const { showSettingsForm, viewMode } = this.state;
        const { user } = this.props.account.loginStatus;

        if (showSettingsForm) {
            return null;
        }

        if(typeof this.props.commonData.data.status != 'undefined' && this.props.commonData.data.status){
            const { sortBy, sortAsc } = this.state.sortOption;
            const { query } = this.state;

            // Sort budgets
            const filterdBudgets = this.filteredBudgets();

            return (
                <div className={`mt-0 relative`}>
                    <div className="w-full flex justify-end pb-3 mt-3">
                        <div>
                            <label
                                className={ classes.btnHeaderNav }
                                style={{
                                    opacity: 0.85
                                }}
                            >
                                <input type="file"
                                    className={ classes.fileInput }
                                    ref={ ref => this.fileSelector = ref }
                                    onChange={this.excelFileSelected}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                        application/vnd.ms-excel"
                                    style={{
                                        width: 1,
                                        height: 1,
                                        position: 'absolute',
                                        opacity: 0
                                    }}
                                />
                                Import Budget File
                            </label>
                        </div>
                        <div className={`ml-3`}>
                            <Button
                                className={ classes.btnHeaderNav }
                                onClick={ this.showBudgetSettingsForm }
                            >
                                Create Budget
                            </Button>
                        </div>
                        <div className={`ml-3 -mt-2`}>
                            <SearchInputType2
                                placeholder={t('Search') + "..."}
                                value={query}
                                onChange={(event) => this.queryChanged(event.target.value)}
                                onClose={() => this.queryChanged("")}
                                small
                            />
                        </div>
                    </div>
                    <div>
                        <Grid container className={ `${classes.budgetContainer} text-white` }
                            style={{ background: '#5cb6ff', fontWeight: 600 }}
                        >
                            <Grid item xs={ 2 }>
                                {t('Ev#')}
                                <SortBtn sortOptionChanged={this.sortOptionChanged}
                                    sortBy={sortBy} isAscSort={sortAsc} sortName={'event'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {t('Category')}
                                <SortBtn sortOptionChanged={this.sortOptionChanged}
                                    sortBy={sortBy} isAscSort={sortAsc} sortName={'category'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {t('Sub category')}
                                <SortBtn sortOptionChanged={this.sortOptionChanged}
                                    sortBy={sortBy} isAscSort={sortAsc} sortName={'subcategory'}
                                />
                            </Grid>
                            <Grid item xs={ 3 }>
                                {t('Desc')}
                                <SortBtn sortOptionChanged={this.sortOptionChanged}
                                    sortBy={sortBy} isAscSort={sortAsc} sortName={'desc'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {t('Value')}
                                <SortBtn sortOptionChanged={this.sortOptionChanged}
                                    sortBy={sortBy} isAscSort={sortAsc} sortName={'value'}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                style={{textAlign: 'right', paddingRight: 20}}
                            >
                                {t('Actions')}
                            </Grid>
                        </Grid>

                        {
                            filterdBudgets.map((budget) => {
                                return (
                                    <Grid container key={`budget-${budget.id}`} className={ classes.budgetContainer }>
                                        <Grid item xs={ 2 }>
                                            { budget.eventId }
                                        </Grid>
                                        <Grid item xs={2}>
                                            { budget.category }
                                        </Grid>
                                        <Grid item xs={2}>
                                            { budget.sub_category }
                                        </Grid>
                                        <Grid item xs={ 3 }>
                                            {budget.description}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {budget.value}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{textAlign: 'right'}}
                                        >
                                            {
                                                user.profile.user_type === 'BUYER' &&
                                                <Tooltip
                                                    title={ this.isApproved(budget) ? t('Convert to Invoice') : 'Not approved by admin' }
                                                >
                                                    <span>
                                                    <MatButton
                                                        size="small"
                                                        style={{ minWidth: 40 }}
                                                        onClick={() => this.populateBudget(budget)}
                                                        disabled={ !this.isApproved(budget) }
                                                    >
                                                        <PublishIcon
                                                            className={classes.icon}
                                                            style={{cursor: 'pointer', color: '#0072BB'}}
                                                        />
                                                    </MatButton>
                                                    </span>
                                                </Tooltip>
                                            }
                                            <Tooltip
                                                title={ t('Edit') }
                                            >
                                                <MatButton
                                                    size="small"
                                                    onClick={e => this.editBudgetSetting(budget)}
                                                    style={{ minWidth: 40 }}
                                                >
                                                    <img src="/assets/img/black-pen.svg" />
                                                </MatButton>
                                            </Tooltip>
                                            <Tooltip
                                                title={ t('Delete') }
                                            >
                                                <MatButton
                                                    size="small"
                                                    onClick={e => this.deleteBudgetSetting(budget)}
                                                    style={{ minWidth: 40 }}
                                                >
                                                    <img src="/assets/img/black-trash.svg" />
                                                </MatButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </div>
                    {
                        this.props.commonData.data.budgets.length === 0 &&
                        <div className="flex flex-col w-full items-center pt-5" style={{ color: '#ee2337' }}>
                            <ErrorOutlineIcon
                                style={{ fontSize: "2.8rem", marginRight: "1rem", marginBottom: 15 }}
                            />
                            {t("No Budgets found")}
                            <a
                                href="javascript:void(0)"
                                className="hover:no-underline"
                                style={{
                                    color: 'rgb(29, 138, 207)',
                                    textDecoration: 'underline',
                                    marginTop: 10,
                                    marginBottom: 30,
                                }}
                                onClick={ this.showBudgetSettingsForm }
                            >
                                {t('Add Budget')}
                            </a>
                        </div>
                    }
                </div>
            )
        }else{
            return null;
        }
    }

    renderTotalBudget = () => {
        if (typeof this.props.commonData.data.status != 'undefined'
            && this.props.commonData.data.status
            && this.props.commonData.data.budgets.length
        ) {
            const totalBudget = this.props.commonData.data.budgets.map(budget => budget.value)
                .reduce((sum, value) => sum + value);

            return totalBudget;
        }

        return 0;
    }

    renderCategoryCount = () => {
        if (typeof this.props.commonData.data.status != 'undefined' && this.props.commonData.data.status) {
            let categories = [];
            this.props.commonData.data.budgets.forEach(budget => {
                if (categories.indexOf(budget.category) === -1) {
                    categories.push(budget.category);
                }
            });

            return categories.length;
        }

        return 0;
    }

    handleChangeFilterEvent = (event, attr) => {
        const value = event.target.value;
        let { filter } = this.state;
        filter[attr] = value;
        this.setState({
            filter
        });
    }

    handleClickFilterAll = () => {
        this.setState({
            filter: {
                event: 'all',
                category: 'all',
                vendor: 'all',
                buyer: 'all'
            }
        });
    }

    handleRenderFilterList = () => {
        const { classes } = this.props;
        const { budgets, filter } = this.state;

        if(typeof this.props.commonData.data.status === 'undefined' || !this.props.commonData.data.status) {
            return null;
        }


        const isNoFilter = filter['event'] === 'all' && filter['category'] === 'all' && filter['vendor'] === 'all' && filter['buyer'] === 'all';

        return (
            <div className={ classes.filterContainer }>
                <Button className={ `${ classes.btnAll } ${isNoFilter ? 'active': ''}` } onClick={ this.handleClickFilterAll }>
                    All
                </Button>

                <NativeSelect value={ filter['event'] } onChange={ (event) => this.handleChangeFilterEvent(event, 'event') }>
                    <option value='all'>
                        All Events
                    </option>
                    {
                        this.renderEventOptions()
                    }
                </NativeSelect>
                <NativeSelect value={ filter['category'] } onChange={ (event) => this.handleChangeFilterEvent(event, 'category') }>
                    <option value='all'>
                        All Categories
                    </option>
                    {
                        this.renderCategoryOptions()
                    }
                </NativeSelect>
                <NativeSelect value={ filter['buyer'] } onChange={ (event) => this.handleChangeFilterEvent(event, 'buyer') }>
                    <option value='all'>
                        All Buyers
                    </option>
                    {
                        this.renderBuyerOptions()
                    }
                </NativeSelect>
                <NativeSelect value={ filter['vendor'] } onChange={ (event) => this.handleChangeFilterEvent(event, 'vendor') }>
                    <option value='all'>
                        All Vendors
                    </option>
                    {
                        this.renderVendorOptions()
                    }
                </NativeSelect>
            </div>
        )
    }

    renderEventOptions = () => {
        const { budgets } = this.props.commonData.data;
        const eventIds = budgets.map(budget => budget['eventId']);
        const uniqueEventIds = [...new Set(eventIds)].filter(eventId => eventId);

        return uniqueEventIds.map((eventId, key) => {
            return (
                <option value={ eventId } key={`event-${key}`}>
                    { eventId }
                </option>
            )
        });
    }

    renderCategoryOptions = () => {
        const { budgets } = this.props.commonData.data;
        const categories = budgets.map(budget => budget['category']);
        const uniqueCategories = [...new Set(categories)].filter(category => category);

        return uniqueCategories.map((category, key) => {
            return (
                <option value={ category } key={`category-${key}`}>
                    { category }
                </option>
            )
        });
    }

    renderBuyerOptions = () => {
        const { budgets } = this.props.commonData.data;
        const buyers = budgets.map(budget => budget['buyer']);
        let uniqueBuyers = [];
        let uniqueBuyerIds = [];
        buyers.forEach(buyer => {
            if (buyer) {
                if (uniqueBuyerIds.indexOf(buyer['id']) === -1) {
                    uniqueBuyers.push(buyer);
                    uniqueBuyerIds.push(buyer['id']);
                }
            }
        })

        return uniqueBuyers.map((buyer, key) => {
            return (
                <option value={ buyer['id'] } key={key}>
                    { buyer['first_name'] } { buyer['last_name'] }
                </option>
            )
        });
    }

    renderVendorOptions = () => {
        const { budgets } = this.props.commonData.data;
        const vendors = budgets.map(budget => budget['vendor']);
        let uniqueVendors = [];
        let uniqueVendorIds = [];
        vendors.forEach(vendor => {
            if (vendor) {
                if (uniqueVendorIds.indexOf(vendor['id']) === -1) {
                    uniqueVendors.push(vendor);
                    uniqueVendorIds.push(vendor['id']);
                }
            }
        })

        return uniqueVendors.map((vendor, key) => {
            return (
                <option value={ vendor['id'] } key={key}>
                    { vendor['first_name'] } { vendor['last_name'] }
                </option>
            )
        });
    }

    render(){
        const { classes, t } = this.props;
        const { showSettingsForm, invoiceData, editingBudget, showBulkImportResult, bulkImportInfos } = this.state;

        return(
            <div style={{
                marginTop: '76px',
                background: '#FFFFFF',
                border: '1px solid rgba(18, 145, 249, 0.18)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: 7,
                minHeight: 300,
            }}>
                {
                    this.handleRenderFilterList()
                }
                { this.showBudgetSettingList() }
                {/* <EventForm /> */}
                {/* <CategoryForm /> */}
                {
                    showSettingsForm &&
                        <RequestorForm
                            submitted={this.submittedNewBudgetSetting}
                            cancelled={ this.hideSettingsForm }
                        />
                }
                <Modal
                    open={ isNaN(editingBudget) }
                    onClose={ this.editModalClosed }
                    className="flex items-center justify-center"
                >
                    <div style={{
                        background: '#FDF9F9',
                        borderRadius: 10,
                        minWidth: 700,
                        padding: '20px 30px',
                        paddingTop: 0,
                    }}>
                        <RequestorForm
                            budget={ editingBudget }
                            submitted={ () => {
                                this.loadData();
                                this.setState({editingBudget: null});
                                successConfirm("Success", "Successfully updated the budget setting", false);
                            } }
                            cancelled={ () => this.setState({ editingBudget: null }) }
                        />
                    </div>
                </Modal>
                <Modal
                    open={ showBulkImportResult }
                    className="flex items-center justify-center"
                >
                    <div style={{
                        background: '#FDF9F9',
                        borderRadius: 10,
                        minWidth: 1024,
                        padding: '20px 30px',
                        paddingTop: 0,
                    }}>
                        <BudgetsBulkImportResultsPopup
                            bulkImportInfos={ bulkImportInfos }
                            cancelled={ () => {
                                this.loadData();
                                this.setState({ showBulkImportResult: false });
                            }}
                        />
                    </div>
                </Modal>
                
                <NotificationContainer/>
            </div>
        )
    }

    isApproved = (budget) => {
        if (budget.approved_by_id != null) {
            return true;
        }

        return false;
    }

    componentDidMount = async () => {
        const { history } = this.props;
        if (history.location.state
            && history.location.state[0]
        ) {
            this.setState({showSettingsForm: true});
        }

        const loginStatus = this.props.account.loginStatus;
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        await fetch(apiUrl + "api/common/dashboard_data", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chartPeriod: 'week'
            }),
        })
        .then(res => res.json())
        .then(result => {
            this.setState({invoiceData: result});
            this.setState({chartData: result['chartData']});
            return result;
        });
    }
}

const mapStateToProps = state => ({
    ...state
});
const mapDispatchToProps = {getBudgetSettings, delBudgetSettings, clearCommonResponse}
export default withRouter(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(
            withStyles(setupPageStyle)(
                BudgetsPage
            )
        )
    )
);