import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import profilePageStyle from './profilePageStyle';
import BaseInput from 'components/BaseInput/index.jsx';
import { NavLink } from 'react-router-dom';
import { uploadFiles } from '../../redux/actions/fileActions';
import { submitBuyRequest } from '../../redux/actions/commonActions';
import { saveProfileChanges } from '../../redux/actions/accountActions';
import Cookies from "js-cookie";
import { userLogout, saveAvatar, setUserProfile, refreshUserProfile } from "../../redux/actions/accountActions";
import Modal from '@material-ui/core/Modal';
import Avatar from 'react-avatar-edit'
import AddIcon from '@material-ui/icons/Add';
import { withTranslation  } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class ProfilePage extends Component
{
    state = {
        changingPassword: false,
        firstName: '',
        lastName: '',
        organization: '',
        budget: '',
        phone: '',
        email: '',
        address: '',
        website: '',
        newPassword: '',
        newPasswordConfirm: '',
        passwordAlert: '',
        croppedImage: null,
        avatarModal: false,
        invoiceData: {},
        errorMsg: ''
    }

    async componentDidMount() {
        const { user } = this.props.account.loginStatus;
        /* this.setState({
            organization: user.profile.company,
            firstName: user.first_name,
            lastName: user.last_name,
            budget: user.profile.budget_limit,
            phone: user.profile.phone,
            address: user.profile.address,
            website: user.profile.website,
            email: user.email,
        }); */

        const loginStatus = this.props.account.loginStatus;

        await fetch(apiUrl + "api/account/profile", {
            method: "GET",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(result => {
            if (result.status) {
                let user = result.user;

                this.setState({
                    organization: user.profile.company,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    budget: user.profile.budget_limit,
                    phone: user.profile.phone,
                    address: user.profile.address,
                    website: user.profile.website,
                    email: user.email,
                });
            }
            return result;
        });

        await fetch(apiUrl + "api/common/dashboard_data", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(result => {
            this.setState({invoiceData: result});
            return result;
        });
    }

    render(){
        const { classes, t } = this.props;
        const { user } = this.props.account.loginStatus;

        const { invoiceData, changingPassword, organization, firstName, lastName, budget, phone, address, website, email, newPassword, newPasswordConfirm, passwordAlert, avatarModal } = this.state;
        const { invoiceResult } = this.props;

        return (
            <div className={ classes.container }>
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Profile')}
                        <NavLink className={ classes.btn } style={{ width: 160, textAlign: 'center', borderRadius: 10, lineHeight: '33px' }} to="/buyer/buy-request">
                            {t('Create Invoice')}
                        </NavLink>
                    </div>
                </div>
                <div className={ classes.pageContentContainer } style={{ paddingBottom: 50, paddingTop: 20, }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', }}>
                        <div className={ classes.profileContainer }>
                            <div className={ classes.userName }>
                                { user.first_name + ' ' + user.last_name }
                            </div>
                            <div className={ classes.userType }>
                                { t(user.profile.user_type) }
                            </div>
                            <div className={ classes.avatarContainer } onClick={ () => this.setState({ avatarModal: true }) }>
                                {
                                    user.profile.avatar && user.profile.avatar.length &&
                                        <img src={ user.profile.avatar } />
                                }
                                {
                                    (!user.profile.avatar || !user.profile.avatar.length) &&
                                        <div className={ classes.initialContainer }>
                                            { user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase() }
                                        </div>
                                }
                                <div className={ classes.avatarHover }>
                                    <AddIcon style={{ fontSize: 24, }}/>
                                    <div style={{ textAlign: 'center', fontSize: 12 }}>
                                        {t('Click to update avatar')}
                                    </div>
                                </div>
                            </div>
                            <div className={ classes.detailContainer }>
                                <div className={ classes.profileDetail }>
                                    <span className={ classes.detailLabel }>
                                        {t('Cell Phone')}:
                                    </span>
                                    <span className={ classes.detailContent }>
                                        &nbsp;{ user.profile.phone }
                                    </span>
                                </div>
                                <div className={ classes.profileDetail }>
                                    <span className={ classes.detailLabel }>
                                        {t('Email')}:
                                    </span>
                                    <span className={ classes.detailContent }>
                                        &nbsp;{ user.email }
                                    </span>
                                </div>
                                <div className={ classes.profileDetail }>
                                    <span className={ classes.detailLabel }>
                                        {t('Address')}:
                                    </span>
                                    <span className={ classes.detailContent }>
                                        &nbsp;{ user.profile.address }
                                    </span>
                                </div>
                                <div className={ classes.profileDetail }>
                                    <span className={ classes.detailLabel }>
                                        {t('Website')}:
                                    </span>
                                    <span className={ classes.detailContent }>
                                        &nbsp;{ user.profile.website }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 3, height: 'fit-content', flexDirection: 'column' }}>
                            <div style={{ width: '100%', color: '#1291F9', fontSize: 25, borderBottom: '1px solid rgba(194, 194, 194, 0.5)', padding: '15px 35px' }}>
                                    <strong>{t('My Invoices')}</strong>
                            </div>
                            <div style={{ display: 'flex', padding: '40px 35px', justifyContent: 'space-between', maxWidth: 600, marginBottom: 20 }}>
                                <div className={ classes.statusContainer }>
                                    <img src="/assets/img/newui/profile/requestd-invoice-icon.svg" />
                                    <div className="statusText">
                                        Requested
                                    </div>
                                    <div className="numeric">
                                        { typeof invoiceData.requested !== 'undefined' ? invoiceData.requested : ''  }
                                    </div>
                                </div>
                                <div className={ classes.statusContainer }>
                                    <img src="/assets/img/newui/profile/processing-invoice-icon.svg" />
                                    <div>
                                        Processing
                                    </div>
                                    <div>
                                        { typeof invoiceData.submitted !== 'undefined' ? invoiceData.submitted : ''  }
                                    </div>
                                </div>
                                <div className={ classes.statusContainer }>
                                    <img src="/assets/img/newui/profile/done-invoice-icon.svg" />
                                    <div>
                                        Completed
                                    </div>
                                    <div>
                                        { typeof invoiceData.payed !== 'undefined' ? invoiceData.payed : ''  }
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderTop: '1px solid rgba(194, 194, 194, 0.5)' }}></div>
                            <div style={{ padding: '20px 35px' }}>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                        {t('First Name')}
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <BaseInput
                                            className={ classes.inputControl }
                                            placeholder={t("First Name")}
                                            value = { firstName }
                                            onChange = { (event) => this.setState({ firstName: event.target.value }) }
                                        />
                                    </div>
                                </div>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                        {t('Last Name')}
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <BaseInput
                                            className={ classes.inputControl }
                                            placeholder={t("Last Name")}
                                            value = { lastName }
                                            onChange = { (event) => this.setState({ lastName: event.target.value }) }
                                            />
                                    </div>
                                </div>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                        {t('Phone')}
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <BaseInput
                                            className={ classes.inputControl }
                                            placeholder={t("Phone")}
                                            value = { phone }
                                            onChange={ (event) => this.setState({ phone: event.target.value }) }
                                            type="text"
                                            />
                                    </div>
                                </div>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                        {t('Address')}
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <BaseInput
                                            className={ classes.inputControl }
                                            placeholder={t("Address")}
                                            value = { address }
                                            onChange={ (event) => this.setState({ address: event.target.value }) }
                                            type="text"
                                            />
                                    </div>
                                </div>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                        {t('Website')}
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <BaseInput
                                            className={ classes.inputControl }
                                            placeholder={t("Website")}
                                            value = { website }
                                            onChange={ (event) => this.setState({ website: event.target.value }) }
                                            type="text"
                                            />
                                    </div>
                                </div>
                                {
                                    this.state.errorMsg != '' ? (<p style={{color: 'red'}}>
                                        { this.state.errorMsg }
                                    </p>) : null
                                }
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <button className={ classes.btn } onClick={ () => this.submitProfileChange() }>
                                            {t('Submit')}
                                        </button>
                                    </div>
                                </div>
                                <div className={ classes.controlContainer }>
                                    <div className={ classes.inputLabelWrapper }>
                                    </div>
                                    <div className={ classes.inputWrapper }>
                                        <span
                                            style={{ color: '#1291F9', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => this.setState({ changingPassword: true })}
                                        >
                                            { t('Need to change password?') }
                                        </span>
                                    </div>
                                </div>
                                {
                                    changingPassword &&
                                        <div>
                                            <div className={ classes.controlContainer }>
                                                <div className={ classes.inputLabelWrapper }>
                                                    {t('New Password')}
                                                </div>
                                                <div className={ classes.inputWrapper }>
                                                    <BaseInput
                                                        className={ classes.inputControl }
                                                        placeholder={t('New Password')}
                                                        type="password"
                                                        autoComplete="new-password"
                                                        value={ newPassword }
                                                        onChange={ (event) => this.setState({ newPassword: event.target.value }) }
                                                        />
                                                </div>
                                            </div>
                                            <div className={ classes.controlContainer }>
                                                <div className={ classes.inputLabelWrapper }>
                                                    {t('Confirm Password')}
                                                </div>
                                                <div className={ classes.inputWrapper }>
                                                    <BaseInput
                                                        className={ classes.inputControl }
                                                        placeholder={t('New Password Confirm')}
                                                        type="password"
                                                        value={ newPasswordConfirm }
                                                        onChange={ (event) => this.setState({ newPasswordConfirm: event.target.value }) }
                                                    />
                                                    {
                                                        passwordAlert.length !== 0 &&
                                                            <div className={ classes.passwordAlert }>
                                                                { passwordAlert }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={ classes.controlContainer }>
                                                <div className={ classes.inputLabelWrapper }>
                                                </div>
                                                <div className={ classes.inputWrapper }>
                                                    <button className={ classes.btn } onClick={ this.submitPasswordChange }>
                                                        {t('Submit')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={avatarModal}
                >
                    <div className={ classes.modalContainer }>
                        <div className={ classes.modalContent }>
                            <Avatar
                                width={300}
                                height={295}
                                onCrop={this.onCrop}
                                onClose={this.onClose}
                                onBeforeFileLoad={this.onBeforeFileLoad}
                                src={this.state.src}
                            />
                            <div className={ classes.modalBtnContainer }>
                                <button className={ classes.btnAdpotAvatar } onClick={ this.updateAvatar }>
                                    Adopt
                                </button>
                                <button
                                    className={ classes.btnAdpotAvatar }
                                    style={{ background: '#FFC700' }}
                                    onClick={ this.closeModal }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    updateAvatar = () => {
        const data = {
            avatar: this.state.croppedImage
        };

        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + "api/account/update_avatar", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(result => {
            if (result.status === 'success') {
                this.props.saveAvatar({ avatar: this.state.croppedImage });
                this.setState({ avatarModal: false });
            }
        });
    }

    closeModal = () => {
        this.setState({ avatarModal: false });
    }

    onCrop = (preview) => {
        this.setState({ croppedImage:preview });
    }

    onClose = () => {
        this.setState({preview: null})
    }

    onBeforeFileLoad = (elem) => {
        if(elem.target.files[0].size > 71680){
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    isValidUrl = (urlStr) => {
        try {
          new URL(urlStr);
        } catch (_) {
          return false;
        }
        return true;
    }

    submitProfileChange = () => {
        const { t } = this.props;

        this.setState({errorMsg: ''});

        const {firstName, lastName, phone, address, website} = this.state;

        let isValidPhone = true;
        const phoneNumber = parsePhoneNumberFromString(phone || '', 'BR');
        if (phone && phone.trim().length > 0 && (typeof phoneNumber == "undefined" || !phoneNumber.isValid())) {
            isValidPhone = false;
        }

        let isValidWwebsite = true;
        if (website && website.trim().length > 0 && !this.isValidUrl(website)) {
            isValidWwebsite = false;
        }

        if (!firstName || firstName.trim().length < 1) {
            this.setState({errorMsg: t('First Name is required!')});
            return;
        } else if (!lastName || lastName.trim().length < 1) {
            this.setState({errorMsg: t('Last Name is required!')});
            return;
        } else if (!isValidPhone) {
            this.setState({errorMsg: t('Phone Number is not valid!')});
            return;
        } else if (!isValidWwebsite) {
            this.setState({errorMsg: t('Website is not valid!')});
            return;
        }

        const data = {
            'first_name': firstName,
            'last_name': lastName,
            'phone': phone,
            'address': address,
            'website': website
        };

        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + "api/account/update_profile", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(result => {
            // this.props.saveProfileChanges(data);
            this.props.refreshUserProfile();
        });
    }

    submitPasswordChange = () => {
        const { newPassword, newPasswordConfirm } = this.state;
        const loginStatus = this.props.account.loginStatus;

        if (newPassword !== newPasswordConfirm) {
            this.setState({passwordAlert: 'Please make sure passwords match'});
            return;
        }

        if (newPassword.length < 8) {
            this.setState({passwordAlert: 'Password should be longer than 8 letters'});
            return;
        }

        if (!/[a-z]/.test(newPassword)
            || !/[A-Z]/.test(newPassword)
            || !/[0-9]/.test(newPassword)
        ) {
            this.setState({passwordAlert: 'Password should be consists of lowercase, uppercase letters and digits'});
            return;
        }

        const data = {
            new_password: newPassword,
            new_password_confirm: newPasswordConfirm
        };

        return fetch(apiUrl + "api/account/update_password", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(result => {
            if (result.status === 'success') {
                const permalink = loginStatus.user.customer.permalink;
                this.props.userLogout();
                window.location.href = '/login';
            }
        });
    }
}

const mapStateToProps = (state) => {
    return {...state, initialResponse: state.initialResponse, uploadResult: state.uploadResult, profileResult: state.profileResult}
}
const mapDispatchToProps = {uploadFiles, submitBuyRequest, saveProfileChanges, userLogout, saveAvatar, setUserProfile, refreshUserProfile};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(profilePageStyle)(ProfilePage)));