import * as Type from '../actions/types';

const initialState = {
  info: {}
};

export const error = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_ERROR:
      return {
        ...state,
        info: action.payload
      };
    default:
      return state;
  }
};
