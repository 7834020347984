import React, { Component } from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { confirm } from "../../components/Confirm/index";

import { withStyles } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MatButton from 'components/MatButton/index.jsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NativeSelect from 'components/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import buyRequestPageStyle from './buyRequestPageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import CheckBox from 'components/CustomCheckBox/index.jsx';
import AutocompleteSelect from 'components/AutocompleteSelect/index.jsx';
import Autosuggest from 'react-autosuggest';
import Button from 'components/Button/index.jsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { getGlobalInitialData } from '../../redux/actions/commonActions';

import { uploadFiles } from '../../redux/actions/fileActions';
import { submitBuyRequest, getInvoiceData } from '../../redux/actions/commonActions';

import TextField from '@material-ui/core/TextField';
import moment2 from 'moment';
import Cookies from 'js-cookie';
import { withTranslation  } from 'react-i18next';
import { withRouter } from 'react-router';

function buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '*.*');
    return fileSelector;
}

class BuyRequestPage extends Component {

    fields = {
    }

    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        loginInfo: {
            user: {
                first_name: '',
                last_name: '',
                profile: {
                    user_type: ''
                }
            }
        },
        skipBuyer: false,
        invoiceId: null,
        showPayTermModal: false,
        formData: {
            eventId: '',
            category: '',
            sub_category: '',
            vendor: '',
            date_of_service: moment2().format("YYYY-MM-DD"),
            goods_provided_at: null,
            goods_description: '',
            description: '',
            parent_invoice: '',
            type: 'Invoice',
            invoiceValue: '',
            netdays: '0',
            lean_document_id: '',
        },
        submitErrorMessage: '',
        submitSuccessMessage: '',
        attachments: [],
        existing_attachments: [],
        selectedOption: 'chocolate',
        DDaySuggestionForceOpen: false,
        confirmModalOpend: false,
        certificates: [],
        budget: null,
        isEditable: true
    }

    constructor(props) {
        super(props);
        this.state.loginInfo = {...this.state.loginInfo, ...this.props['account']['loginStatus']};
        this.loadData();
        this.netDaySuggestionRef = React.createRef();
    }

    loadData = () => {
        setTimeout(() => {
            this.props.getGlobalInitialData();
        }, 50);
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state
            && history.location.state[0]
        ) {
            const budget = history.location.state[0];
            this.setState({
                formData: {
                    eventId: budget.eventId,
                    category: budget.category,
                    description: budget.description,
                    invoiceValue: budget.value,
                    vendor: budget.vendor_id ? budget.vendor_id : '',
                },
                budget,
            });
        }

        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change', e => this.handleCloseFileDialog(e));

        this.fetchLeanDocuments();
        this.loadInvoiceData();
    }

    loadInvoiceData = async () => {
        var params = this.props.match.params;
        if (typeof params['invoiceId'] != 'undefined') {
            var invoiceId = params['invoiceId'];
            this.setState({ invoiceId: invoiceId });
            await this.props.getInvoiceData(invoiceId);
            if (this.props.commonResult.result.status) {
                var invoice = this.props.commonResult.result.data;

                if (invoice['approved_status'] !== 'Requested') {
                    this.setState({isEditable: false});
                }

                var formData = {
                    eventId: invoice['eventId'],
                    sub_category: invoice['sub_category'],
                    category: invoice['category'],
                    vendor: invoice['buyer_id'],
                    date_of_service: moment2(invoice['date_of_service']).format("YYYY-MM-DD"),
                    description: invoice['description'],
                    goods_description: invoice['goods_description'],
                    parent_invoice: (typeof invoice['parent'] != 'undefined' && invoice['parent'] != null ? invoice['parent']['key'] : ''),
                    type: invoice['type'],
                    invoiceValue: invoice['invoiceValue'],
                    lean_document_id: invoice['lean_document_id'],
                    netdays: String(invoice['pay_netdays']),
                    agreePaymentTerms: true
                }
                try {
                    if (invoice['goods_provided_at'] !== null) {
                        let goodsProvidedAt = moment2(invoice['goods_provided_at']).toDate();
                        formData.goods_provided_at = goodsProvidedAt;
                    }
                } catch(err) {}

                this.setState({ formData: { ...this.state.formData, ...formData } });

                var files = invoice['attachments'].split(',');
                this.setState({ 'existing_attachments': files });

                setTimeout(() => {
                    console.log(this.state.formData)
                }, 500);
            } else {

            }
        }
    }

    handleCloseFileDialog = () => {
        if (this.fileSelector.files.item(0) === null)
            return;
        let attachments = this.fileSelector.files;
        this.setState({ attachments });
        /* console.log(this.fileSelector.files.item(0));
        var file = this.fileSelector.files.item(0); */

    }

    checkFormValidation = () => {

        let loginInfo = this.state.loginInfo;
        let customerName = '';
        let customerPermalink = '';
        if (loginInfo.user && loginInfo.user.customer) {
            customerName = loginInfo.user.customer.name;
            customerPermalink = loginInfo.user.customer.permalink;
        }

        let userType = this.state.loginInfo.user.profile.user_type;
        let formData = {}
        for (var field in this.fields) {
            formData[field] = this.fields[field].value;
        }

        formData.eventId = (typeof this.state.formData['eventId'] != 'undefined') ?
            this.state.formData['eventId'] : '';
        formData.category = (typeof this.state.formData['category'] != 'undefined') ?
            this.state.formData['category'] : '';
        formData.sub_category = (typeof this.state.formData['sub_category'] != 'undefined') ?
            this.state.formData['sub_category'] : '';
        formData.vendor = (typeof this.state.formData['vendor'] != 'undefined') ?
            this.state.formData['vendor'] : '';

        /* if (formData['eventId'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input Event ID, Name' }); return false;
        } */
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && formData['eventId'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please select Event ID, Name' }); return false;
        }
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && formData['category'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please select Product or Service Category' }); return false;
        }
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && customerPermalink != 'wic' && formData['sub_category'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please select Sub Category' }); return false;
        }
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && formData['vendor'].length < 1) {
            if (userType === "VENDOR") {
                if (!this.state.skipBuyer) {
                    this.setState({
                        'submitErrorMessage': 'Please choose a ' + (userType === "BUYER" ? "Vendor" : "Buyer") + " or if you would like to proceed without a buyer, click on submit"
                    });
                    this.setState({skipBuyer: true});
                    return false;
                }
            } else {
                this.setState({ 'submitErrorMessage': 'Please choose a ' + (userType === "BUYER" ? "Vendor" : "Buyer") }); return false;
            }
        }
        if (formData['date_of_service'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input date of service' }); return false;
        }
        if (formData['invoiceValue'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input Invoice value' }); return false;
        }
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && formData['type'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input type' }); return false;
        }
        if (formData['type'] != 'Invoice' && formData['type'] != 'Reimbursements' && formData['parent_invoice'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input related invoice key' }); return false;
        }
        if (customerName !== 'Onesta' && customerName !== 'Demo Customer' && formData['description'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please input Short Decription' }); return false;
        }
        if (this.state.formData['agreePaymentTerms'] != true) {
            this.setState({ 'submitErrorMessage': 'Need to agree with payment terms' }); return false;
        }

        const { user } = this.state.loginInfo;
        if (user.customer.name === 'Onesta' || customerName === 'Demo Customer') {
            // Not needed for now
            /* if (typeof this.state.formData['lean_document_id'] === 'undefined' || this.state.formData['lean_document_id'].length < 1) {
                this.setState({ 'submitErrorMessage': 'Please select Lien Release document' }); return false;
            } */
        }

        /*if(this.state.attachments.length < 1){
            this.setState({'submitErrorMessage': 'Please upload attachment file'}); return false;
        }*/

        return true;
    }

    handleClickSubmit = async () => {
        let totalFileSize = 0;
        if (this.state.attachments) {
            for (let i = 0; i < this.state.attachments.length; i++) {
                let file = this.state.attachments[i];
                totalFileSize += file.size;
            }
        }
        if (totalFileSize > 30 * 1024 * 1024) {
            this.setState({submitErrorMessage: 'Attachments should be less than 30MB.'});
            return;
        }
        this.setState({submitErrorMessage: ''});

        if (!this.checkFormValidation()) return;

        this.setState({ 'submitErrorMessage': '' });

        await this.props.uploadFiles(this.state.attachments, 'invoice_attachments');
        this.setState({skipBuyer: false});
        this.handleFileUploadResponse(this.props.uploadResult);
    }

    // file upload function
    handleFileUploadResponse = async (response) => {
        var res = response['uploadResult'];
        if (res.status) {
            let formData = {}
            for (var field in this.fields) {
                formData[field] = this.fields[field].value;
            }

            formData['eventId'] = (typeof this.state.formData['eventId'] != 'undefined') ?
                this.state.formData['eventId'] : '';
            formData['category'] = (typeof this.state.formData['category'] != 'undefined') ?
                this.state.formData['category'] : '';
            formData['sub_category'] = (typeof this.state.formData['sub_category'] != 'undefined') ?
                this.state.formData['sub_category'] : '';
            formData['vendor'] = (typeof this.state.formData['vendor'] != 'undefined') ?
                this.state.formData['vendor'] : '';
            formData['goods_provided_at'] = (typeof this.state.formData['goods_provided_at'] != 'undefined') ?
                moment2(this.state.formData['goods_provided_at']).format("YYYY-MM-DD") : null;
            formData['goods_provided_at'] = formData['goods_provided_at'] === 'Invalid date' ? null : formData['goods_provided_at'] ;
            formData['goods_description'] = (typeof this.state.formData['goods_description'] != 'undefined') ?
                this.state.formData['goods_description'] : '';

            formData['existing_attachments'] = this.state.existing_attachments.join(",");
            formData['attachments'] = res.files.join(",");
            formData['invoiceId'] = this.state.invoiceId;
            formData['netdays'] = this.state.formData['netdays'] ? this.state.formData['netdays'] : 0;
            formData['lean_document_id'] = this.state.formData['lean_document_id'];

            await this.props.submitBuyRequest(formData);

            this.handleSubmitResponse(this.props.commonResponse);
        } else {
            this.setState({ 'submitErrorMessage': res['message'] });
        }
    }

    // Process Response
    handleSubmitResponse = (res) => {
        if (res.commonResponse.status) {

            if (this.state.invoiceId != null) {
                setTimeout(() => {
                    this.props.history.goBack();
                }, 700);
            }

            var newItemId = res.commonResponse.affected_id;
            this.setState({ 'submitSuccessMessage': res.commonResponse.message });
            this.setState({ 'attachments': [] })
            this.setState({
                formData: {
                    eventId: '',
                    sub_category: '',
                    category: '',
                    vendor: '',
                    description: '',
                    invoiceValue: '',
                    parent_invoice: '',
                    type: 'Invoice',
                    date_of_service: moment2().format("YYYY-MM-DD"),
                    goods_provided_at: null,
                    goods_description: '',
                    netdays: '0'
                }
            });
            setTimeout(() => {
                this.setState({ 'submitSuccessMessage': '' });
            }, 4000);
        } else {
            this.setState({ 'submitErrorMessage': res.commonResponse.message });
        }
    }

    handleClickUploadAttachment = () => {
        this.fileSelector.click();
    }

    handleChangeValue = (e, valueType) => {

        const dataOject = {};

        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (e instanceof Date) {
            dataOject[valueType] = e;
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.formData[valueType] != 'undefined' && this.state.formData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }

        if (valueType == "category") {
            dataOject["sub_category"] = "";
        }
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });
    }

    onChangeNetDays = (event, { newValue }) => {
        const dataOject = {
            'netdays': newValue
        };
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });
    };

    onChangeNetDaysSelectBox = selectedOption => {
        const dataOject = {
            'netdays': selectedOption.value
        };
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });
    };

    onFocusInNetDays = (event) => {
        this.setState({DDaySuggestionForceOpen: true});
    };

    onFocusOutNetDays = (event) => {
        this.setState({DDaySuggestionForceOpen: false});
    };

    renderAttachments = () => {
        const { classes } = this.props;

        let fileNames = [];
        for (let i = 0; i < this.state.attachments.length; i++) {
            fileNames.push(this.state.attachments.item(i).name);
        }
        return fileNames.map((filename, key) => {
            return (
                <div className={classes.attachmentContainer} key={key}>
                    <img src="/assets/img/attachment-inactive.png" alt="attachment" className={classes.attachmentImg} />
                    {filename}
                </div>
            )
        })
    }

    checkResponseOrError = () => {
        const { classes } = this.props;
        if (this.state.submitErrorMessage != '') {
            return (
                <span style={{ color: '#CC3333' }}>
                    &nbsp;&nbsp;{this.state.submitErrorMessage}
                </span>
            );
        }
        else if (this.state.submitSuccessMessage != '') {
            return (
                <span style={{ color: '#33CC33' }}>
                    &nbsp;&nbsp;{this.state.submitSuccessMessage}
                </span>
            );
        }
    }

    // Show category list function
    drawCategorySelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            var categGroup = this.props.initialResponse.data.categGroup;
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.categ.name}>
                        {item.categ.name}
                    </option>
                )
            })
        } else {
            return null;
        }
    }

    // Show sub-category list function
    drawSubCategorySelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            if (typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if (item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.name}>
                        {item.name}
                    </option>
                )
            })
        } else {
            return null;
        }
    }

    // Show buyer list function
    getEventOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.events != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.events;
            return optjionGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
        }else{
            return [];
        }
    }

    // Get current sub category list
    getSubCategorySelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            if (typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if (item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            var retVal = categGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
            if (typeof retVal == "undefined" || retVal == null) {
                retVal = [];
            }
            return retVal;
        } else {
            return [];
        }
    }


    // Show vendor list function
    getVendorOptions = () => {
        const { budget } = this.state;
        if (budget && budget.vendor) {
            return [{
                label: budget.vendor.first_name + ' ' + budget.vendor.last_name,
                value: budget.vendor.id
            }];
        }

        if (
            typeof this.props.initialResponse.data != 'undefined'
            && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.vendors != 'undefined'
        ) {
            var optjionGroup = this.props.initialResponse.data.vendors;
            var retVal = optjionGroup.map((item, key) => ({
                label: item.first_name + ' ' + item.last_name,
                value: item.id
            }));
            return retVal;
        } else {
            return [];
        }
    }

    // Show buyer list function
    getBuyerOptions = () => {
        const { classes } = this.props;
        if (
            typeof this.props.initialResponse.data != 'undefined'
            && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.buyers != 'undefined'
        ) {
            var optjionGroup = this.props.initialResponse.data.buyers;
            var retVal = optjionGroup.map((item, key) => ({
                label: item.first_name + ' ' + item.last_name,
                value: item.id
            }));
            return retVal;
        } else {
            return [];
        }
    }

    // Show buyer list function
    drawVendorSelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.vendors != 'undefined') {
            var optjionGroup = this.props.initialResponse.data.vendors;
            return optjionGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.id}>
                        {item.first_name + ' ' + item.last_name}
                    </option>
                )
            })
        } else {
            return null;
        }
    }

    showPayTermDueDays = () => {
        const { classes, account, t } = this.props;

        /* var initialData = this.props.initialResponse.data;
        if(typeof initialData.payTerms != 'undefined' && initialData.status){
            return (
                <span> - (Net {initialData.payTerms.due_days} days)</span>
            )
        }
        else{
            return null;
        } */

        const sugList = [
            {
                label: 'Zero', value: '0'
            },
            {
                label: '15', value: '15'
            },
            {
                label: '30', value: '30'
            },
            {
                label: '45', value: '45'
            },
            {
                label: '60', value: '60'
            },
            {
                label: '90', value: '90'
            }
        ];

        const inputProps = {
            placeholder: '',
            value: typeof this.state.formData['netdays'] != 'undefined' ? this.state.formData['netdays']: '0',
            type: 'number',
            onChange: this.onChangeNetDays,
            onFocus: this.onFocusInNetDays,
            onBlur: this.onFocusOutNetDays,
        };

        const renderSuggestion = (suggestion) => (
            <div>
                {suggestion.label}
            </div>
        );

        const getSuggestionValue = (suggestion) => {
            return suggestion.value;
        }

        // Teach Autosuggest how to calculate suggestions for any given input value.
        const getSuggestions = value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;

            return inputLength === 0 ? sugList : sugList.filter(suggession =>
                suggession.value == inputValue
            );
        };

        const onSuggestionsFetchRequested = ({ value }) => {
            this.setState({
                suggestions: getSuggestions(value)
            });
        };

        const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
            console.log(this.netDaySuggestionRef);
            setTimeout(() => {
                this.netDaySuggestionRef.current.input.blur();
            }, 300);
        };

        // Autosuggest will call this function every time you need to clear suggestions.
        const onSuggestionsClearRequested = () => {
            /* this.setState({
                suggestions: []
            }); */
        };

        let DDaySuggestionForceOpen = this.state.DDaySuggestionForceOpen;

        if (!this.state.isEditable) {
            return (
                <span
                    className={`suggession-type1`}
                >
                    - (Net&nbsp;
                    { this.state.formData['netdays'] }&nbsp;
                    days)
                </span>
            );
        }

        return (
            <span
                className={`suggession-type1`}
            >
                - (Net
                <Autosuggest
                    ref={this.netDaySuggestionRef}
                    suggestions={sugList}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    inputProps={inputProps}
                    alwaysRenderSuggestions={DDaySuggestionForceOpen}
                    renderSuggestion={renderSuggestion}
                    value={this.state.formData['netdays']}
                />
                {/* <Select
                    className={`simple-select mx-1`}
                    value={getSuggestions(this.state.formData['netdays'])}
                    onChange={this.onChangeNetDaysSelectBox}
                    options={sugList}
                /> */}
                days)
            </span>
        );
    }

    showPaymentTermsModal = () => {
        this.setState({ 'showPayTermModal': true });
    }
    closePaymentTermsModal = () => {
        this.setState({ 'showPayTermModal': false });
    }

    showPayTermDetailDialog = () => {
        const { classes } = this.props;
        var initialData = this.props.initialResponse.data;
        if (typeof initialData.payTerms == 'undefined' || !initialData.status) return null;

        var payTerms = initialData.payTerms
        let payDueDays = payTerms ? " - (Net " + payTerms.due_days + " days)" : "";
        let content = payTerms ? payTerms.content.replace(/(<? *script)/gi, 'illegalscript') : "Sorry, Payment terms is not exists for now";

        var user = this.state.userDetail;

        return (
            <Dialog
                open={this.state.showPayTermModal}
                onClose={this.closePaymentTermsModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="form-dialog-title">Payment Terms{payDueDays}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12} dangerouslySetInnerHTML={{ __html: content }}>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <MatButton onClick={this.closePaymentTermsModal} color="primary">
                        Close
                    </MatButton>
                </DialogActions>
            </Dialog>
        );
    }

    // Show existing attachments
    renderExistingAttachments = () => {
        const { classes } = this.props;
        return this.state.existing_attachments.map((filename, key) => {
            var filebasename = filename.substring(filename.lastIndexOf('/') + 1);
            var filebasenameUrl = encodeURIComponent(filebasename);
            var apiUrl = this.state.apiUrl;
            if (filebasename == '') {
                return null;
            } else {
                return (
                    <div className={classes.attachmentContainer} key={key}>
                        <img src="/assets/img/attachment-active.png" alt="attachment" className={classes.attachmentImg} />
                        {filebasename}&nbsp;
                            <a href={apiUrl + 'api/download/file/' + filebasenameUrl} target="_blank"><SaveAltIcon /></a>
                    </div>
                )
            }
        })
    }

    renderVendorBuyer = () => {
        const { classes } = this.props;

        let userType = this.state.loginInfo.user.profile.user_type;

        if (userType == "BUYER") {
            return (
                <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                    <GridContainer className={`lg:pr-3`}>
                        <GridItem xs={12}>
                            Vendor
                        </GridItem>
                        <GridItem xs={12} className={classes.marginVertical_10}>
                            <AutocompleteSelect
                                className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                value={this.state.formData.vendor}
                                options={this.getVendorOptions()}
                                onChange={selectedOption => this.handleChangeValue(selectedOption, 'vendor')}
                                name="subCateg"
                                isDisabled={!this.state.isEditable}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            );
        } else {
            return (
                <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                    <GridContainer className={`lg:pr-3`}>
                        <GridItem xs={12}>
                            Buyer
                        </GridItem>
                        <GridItem xs={12} className={classes.marginVertical_10}>
                            <AutocompleteSelect
                                className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                value={this.state.formData.vendor}
                                options={this.getBuyerOptions()}
                                onChange={selectedOption => this.handleChangeValue(selectedOption, 'vendor')}
                                name="subCateg"
                                isDisabled={!this.state.isEditable}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            );
        }

    }

    checkCanAccess = () => {
        const { classes } = this.props;
        if (this.state.confirmModalOpend) {
            return null;
        }

        const { user } = this.state.loginInfo;
        const { data:initialResponse } = this.props.initialResponse;
        /* if (user.customer.name === 'Onesta' || customerName === 'Demo Customer') {
            if (!initialResponse.msa_completed && typeof initialResponse.msa_completed !== 'undefined') {

                this.setState({confirmModalOpend: true});
                confirm("Confirm", "You need to setup MSA document before raising invoice", false).then(
                    async () => {
                        window.location.href = '/msa/upload';
                    },
                    () => {
                        window.location.href = '/msa/upload';
                    }
                );

                return;
            }
        } */

        // If vendor is not setup w9 form
        if (this.state.loginInfo.user.profile.user_type == "VENDOR") {
            if (
                typeof this.props.initialResponse.data != 'undefined'
                && this.props.initialResponse.data.status
                && this.props.initialResponse.data.w9Approved !== true
            ) {
                this.setState({confirmModalOpend: true});
                confirm("Confirm", "You need to setup w9 form before raising invoice?", false).then(
                    async () => {
                        window.location.href = '/vendor/setup';
                    },
                    () => {
                        window.location.href = '/vendor/setup';
                    }
                );

                return;
            }
        }

        if (user.customer.name === 'Onesta' || user.customer.name === 'Demo Customer') {
            // Not need to check for now
            /* if (!initialResponse.lean_documents_count  && typeof initialResponse.lean_documents_count !== 'undefined') {
                this.setState({confirmModalOpend: true});
                confirm("Confirm", "You need to complete COI before raising invoice", false).then(
                    async () => {
                        window.location.href = '/certificates/list';
                    },
                    () => {
                        window.location.href = '/certificates/list';
                    }
                );

                return;
            } */
        }

        return null;

    }

    render() {
        const { classes, account, t } = this.props;
        const { certificates, formData } = this.state;

        var catOptions = this.drawCategorySelect();
        if (typeof catOptions == "undefined" || catOptions == null) {
            catOptions = [];
        } else {
            catOptions = catOptions.map((item) => ({
                label: item.props.value,
                value: item.props.value
            }));
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })

        return (
            <div className={ classes.container }>
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Raise Invoice')}
                        <NavLink to="/common/track-payment" className={ classes.btnHeaderNav }>
                            Track Invoices
                        </NavLink>
                    </div>
                </div>
                <div className={ classes.pageContentContainer }>
                    <div className={ classes.contentContainer }>
                        { this.checkCanAccess() }
                        <GlobalProcessor />
                        <div className={classes.pageContent}>
                            <div className={classes.formContainer} ref={ref => { this.form = ref }}>
                                <GridContainer spacing={40}>
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={5} lg={3}>
                                        <GridContainer className={`sm:pr-3`}>
                                            <GridItem xs={12}>
                                                {t('Event ID, Name')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <AutocompleteSelect
                                                    className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                                    value={this.state.formData.eventId}
                                                    options={ this.getEventOptions() }
                                                    onChange={e => this.handleChangeValue(e, 'eventId')}
                                                    name="event"
                                                    isDisabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={7} lg={6} xl={3}>
                                        <GridContainer className={`lg:pr-3`}>
                                            <GridItem xs={12} className={classes.inputLabel}>
                                                {t('Product or Service Category')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <AutocompleteSelect
                                                    className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                                    value={this.state.formData.category}
                                                    options={catOptions}
                                                    onChange={selectedOption => this.handleChangeValue(selectedOption, 'category')}
                                                    name="category"
                                                    isDisabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                                        <GridContainer className={`sm:pr-3 lg:pr-0`}>
                                            <GridItem xs={12}>
                                                {t('Subcategory')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <AutocompleteSelect
                                                    className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                                    value={this.state.formData.sub_category}
                                                    options={this.getSubCategorySelect()}
                                                    onChange={selectedOption => this.handleChangeValue(selectedOption, 'sub_category')}
                                                    name="subCateg"
                                                    isDisabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    { this.renderVendorBuyer() }
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                                        <GridContainer className={`sm:pr-3`}>
                                            <GridItem xs={12}>
                                                {t('Date of Service')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <TextField
                                                    id="datepicker"
                                                    label=""
                                                    type="date"
                                                    inputRef={ref => this.fields['date_of_service'] = ref}
                                                    value={this.state.formData.date_of_service}
                                                    onChange={e => this.handleChangeValue(e, 'date_of_service')}
                                                    className={`datepicker common input-elem` + (!this.state.isEditable ? ' disabled' : '')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                                        <GridContainer className={`lg:pr-3`}>
                                            <GridItem xs={12}>
                                                {t('Type')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <NativeSelect
                                                    inputRef={ref => this.fields['type'] = ref}
                                                    value={this.state.formData.type}
                                                    onChange={e => this.handleChangeValue(e, 'type')}
                                                    name="type"
                                                    disabled={!this.state.isEditable}
                                                >
                                                    <MenuItem value="Invoice">{t('Invoice')}</MenuItem>
                                                    <MenuItem value="DebitNote">{t('Debit note')}</MenuItem>
                                                    <MenuItem value="CreditNote">{t('Credit note')}</MenuItem>
                                                    <MenuItem value="Reimbursements">{t('Reimbursements')}</MenuItem>
                                                </NativeSelect>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} sm={6} md={6} lg={3}>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                {t('Order value')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <BaseInput type="number" className={classes.input}
                                                    inputRef={ref => this.fields['invoiceValue'] = ref}
                                                    onChange={e => this.handleChangeValue(e, 'invoiceValue')}
                                                    value={this.state.formData.invoiceValue}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} style={{ display: (this.state.formData.type != 'Invoice' && this.state.formData.type != 'Reimbursements' ? 'flex' : 'none') }}>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                {t('Related Invoice Key')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <BaseInput
                                                    className={classes.input}
                                                    inputRef={ref => this.fields['parent_invoice'] = ref}
                                                    onChange={e => this.handleChangeValue(e, 'parent_invoice')}
                                                    value={this.state.formData.parent_invoice}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12}>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                {t('Short Description')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <BaseInput
                                                    className={classes.input}
                                                    inputRef={ref => this.fields['description'] = ref}
                                                    onChange={e => this.handleChangeValue(e, 'description')}
                                                    value={this.state.formData.description}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>

                                    <GridItem className={classes.formInputItem} xs={12} sm={12} md={6} lg={4}>
                                        <GridContainer className={`md:pr-3`}>
                                            <GridItem xs={12}>
                                                {t('Goods/Service Provided At')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <DatePicker
                                                    selected={ this.state.formData.goods_provided_at }
                                                    onChange={e => this.handleChangeValue(e, 'goods_provided_at')}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem className={classes.formInputItem} xs={12} sm={12} md={6} lg={8}>
                                        <GridContainer className={`md:pr-0`}>
                                            <GridItem xs={12}>
                                                {t('Goods/Service Description')}
                                            </GridItem>
                                            <GridItem xs={12} className={classes.marginVertical_10}>
                                                <BaseInput className={classes.input}
                                                    inputRef={ref => this.fields['goods_description'] = ref}
                                                    onChange={e => this.handleChangeValue(e, 'goods_description')}
                                                    value={this.state.formData.goods_description}
                                                    disabled={!this.state.isEditable}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                {
                                    this.renderLeanReleaseDocument()
                                }
                                {
                                    this.state.isEditable ? (
                                        <GridContainer>
                                            <GridItem className={`${classes.marginTop_25} ${classes.marginBottom_10} ${classes.uploadAttachmentContainer}`} onClick={this.handleClickUploadAttachment}>
                                                <span className={classes.uploadAttachment}>{t('Upload attachment')}</span>&nbsp;&nbsp;<AddCircleOutlineIcon className={classes.addIcon} onClick={this.handleClick} />
                                            </GridItem>
                                        </GridContainer>
                                    ) : null
                                }
                                <div className={classes.attachmentSection}>
                                    {this.renderExistingAttachments()}
                                    {this.renderAttachments()}
                                </div>
                                <GridContainer>
                                    <GridItem style={{ marginLeft: -15, marginTop: 10, }}>
                                        <CheckBox name="federal_tax" label={t("Agree to")}
                                            checked={this.state.formData.agreePaymentTerms == true}
                                            disabled={!this.state.isEditable}
                                            onChange={e => this.handleChangeValue(e, 'agreePaymentTerms')}
                                        />
                                        &nbsp;
                                        <span onClick={this.showPaymentTermsModal} style={{ cursor: 'pointer' }}>{t('Payment terms')}</span>
                                        {this.showPayTermDueDays()}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} className={[classes.marginTop_25, 'flex justify-end']}>
                                        <span className='text-2xl text-black font-semibold' style={{ color: 'black' }}>Total: { formatter.format(formData.invoiceValue) }</span>
                                    </GridItem>
                                </GridContainer>
                                { this.state.isEditable ? (
                                    <GridContainer>
                                        <GridItem xs={12} className={[classes.marginTop_25, 'flex justify-end']}>
                                            <Button className={classes.btnSubmit} onClick={this.handleClickSubmit}>
                                                {t('Send')}
                                            </Button>
                                            {/* <input type="submit" value="Submit" className={ `${classes.btnSubmit}` } /> */}
                                            {this.checkResponseOrError()}
                                        </GridItem>
                                    </GridContainer>
                                ) : null }
                                {this.showPayTermDetailDialog()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    fetchLeanDocuments = () => {
        const loginStatus = this.props.account.loginStatus;
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        return fetch(apiUrl + 'api/lean_release/list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${loginStatus.token}`,
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({status: 'completed'})
        })
        .then(res => res.json())
        .then(data => this.setState({certificates: data}))
    }

    getLeanOptions = () => {
        const { certificates } = this.state;
        return certificates.map(certificate => {
            return {
                label: certificate.release_name,
                value: certificate.id
            };
        });
    }

    handleChangeLean = (e) => {
        let { formData } = this.state;
        formData['lean_document_id'] = e.value;
        this.setState({formData});
    }

    renderLeanReleaseDocument = () => {
        const { classes, t } = this.props;
        const { user } = this.state.loginInfo;
        if (user.customer.name === 'Onesta' || user.customer.name === 'Demo Customer') {
            return [
                <GridContainer className={classes.marginVertical_10} style={{ marginTop: 40 }} key={ 'release1' }>
                    <GridItem xs={6}>
                        {t('Lien Release Document')}
                    </GridItem>
                </GridContainer>,
                <GridContainer key={ 'release2' }>
                    <GridItem xs={6}>
                        <AutocompleteSelect
                            className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                            value={this.state.formData.lean_document_id}
                            options={ this.getLeanOptions() }
                            onChange={e => this.handleChangeLean(e)}
                            isDisabled={!this.state.isEditable}
                            name="event"
                        />
                    </GridItem>
                </GridContainer>
            ];
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return { ...state, initialResponse: state.initialResponse, uploadResult: state.uploadResult, commonResult: state.commonResult, account: state.account }
}
const mapDispatchToProps = { uploadFiles, submitBuyRequest, getInvoiceData, getGlobalInitialData };
export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(buyRequestPageStyle)(BuyRequestPage))));