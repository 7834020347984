import commonStyle from 'assets/jss/commonStyle'

const MessageBoardStyle = theme => ({
    ...commonStyle,
    statBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    statBtn: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 4,
        padding: '25px 0px',
        marginRight: 30,
    },
    statBtnTitle: {
        color: '#fff',
        fontSize: 36,
    },
    statBtnSubtitle: {
        color: '#fff',
        opacity: 0.8,
        fontSize: 12,
        fontWeight: 100,
    },
    tileContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 30,
        minHeight: 420,
    },
    leftTile: {
        width: 'calc(40% - 18px)',
        marginRight: 30,
        backgroundColor: '#fff',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
    },
    rightTile: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: 2,
        padding: 30,
    },
    chartWrapper: {
        flex: 1,
        position: 'relative',
    },
    chartContainer: {
        flex: 1,
        display: 'flex',
    },
    bulletContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
        minWidth: 200,
        paddingLeft: 40,
    },
    bullet: {
        display: 'block',
        width: 12,
        height: 12,
        borderRadius: 6,
        marginTop: 1,
    },
    bulletLabel: {
        fontSize: 16,
        color: '#565656'
    },
    pieLabel: {
        textAlign: 'center',
    },
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        }
    },
    btnDuration: {
        fontSize: 15,
        marginLeft: 3,
        marginRight: 3,
        padding: '1px 15px',
        cursor: 'pointer',
        border: '1px solid #fff',

        '&.selected': {
            border: '1px solid #EBEBEB',
            borderRadius: 20,
        }
    },
    statTotalContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    contactContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
    },
    activityContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 30,
        position: 'relative'
    },
    historyContainer: {
        background: '#fff'
    },
    outlinedSelect: {
        border: '1px solid #EBEBEB',
        borderRadius: 4,
        paddingLeft: 10,
        paddingRight: 10,
        '&:before': {
            borderBottom: '0px solid #fff !important'
        }
    },
    btnApply: {
        border: '1px solid #EBEBEB',
        borderRadius: 4,
        background: 'white',
        color: '#555555',
        fontSize: 16,
        paddingLeft: 22,
        paddingRight: 22,
        marginLeft: 15,
    },
    searchInput: {
        border: '1px solid #EBEBEB',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        fontSize: 16,
        height: '100%',
        paddingLeft: 10,
    },
    btnSearchGo: {
        border: '1px solid #EBEBEB',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        paddingLeft: 10,
        paddingRight: 10,
        background: 'white',
        fontSize: 16,
        height: '100%',
        borderLeftWidth: 0,
        color: '#555555',
    },
    historyTableHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        borderBottom: '1px solid #E4E4E4',
        '& div': {
            display: 'flex',
        }
    },
    checkBoxCell: {
        flex: 1,
    },
    nameCell: {
        flex: 4,
    },
    invoiceCell: {
        flex: 6,
    },
    dateCell: {
        flex: 3,
    },
    statusCell: {
        flex: 3,
        justifyContent: 'center'
    },
    actionCell: {
        flex: 4,
        justifyContent: 'center',
        '& button': {
            height: '100%',
            background: '#FFFFFF',
            border: '1px solid #E1E1E1',
            borderRadius: 3,
            '& img': {
                height: '100%',
                width: 15,
                height: 15,
            },
            padding: '0px 7px',
            paddingBottom: '4px',
            margin: 5
        }
    },
    historyTableContent: {
        width: '100%'
    },
    historyTableRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 16,
        borderBottom: '1px solid #E4E4E4',
        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '&:nth-child(2n + 1)': {
            background: '#F9F9F9'
        }
    },
    historyTableFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '30px 0px',
    },
    itemsCount: {
        opacity: 0.6,
        fontSize: 14,
    },
    pagination: {
        '& button': {
            background: 'white',
            border: '1px solid #ECECEC',
            fontSize: 16,
            color: '#555555',
            width: 35,
            height: 35,
            '&:first-child': {
                borderTopLeftRadius: 4,
                borderBottomLeftRadisu: 4,
            },
            '&:last-child': {
                borderTopRightRadius: 4,
                borderBottomRightRadisu: 4,
            }
        }
    },
    activeItem: {
        color: '#1291F9 !important',
        background: 'rgba(196, 196, 196, 0.1)'
    }
});
export default MessageBoardStyle;