const budgetProgressStyle = (theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    bar: {
        height: 15,
        background: 'rgba(45, 125, 210, 0.5)',
        width: '100%',
        margin: '3px 0px'
    },
    spent: {
        color: '#333333',
        fontSize: 12,
        fontWeight: 700,
    },
    available: {
        color: '#828282',
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'right'
    }
});

export default budgetProgressStyle;