
const styledBy = (property, mapping) => (props) => mapping[props[property]];

const nativeSelectStyle = theme => ({
    select: {
        borderRadius: 8,
        width: '100%',
        height: 40,
        background: styledBy('color', {
            default: 'rgba(194, 194, 194, 0.06)',
            blue: 'rgba(0, 153, 255, 0.2)'
        }),
        border: '1px solid rgba(18, 145, 249, 0.18)',
        '&:before': {
            display: 'none'
        },
        '&:after': {
            display: 'none'
        },
        '&.opened': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    selectPaper: {
        transition: 'none !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        background: 'white',
        borderTop: '1px solid rgba(194, 194, 194, 0.3)',
        '& ul': {
            background: styledBy('color', {
                default: 'rgba(194, 194, 194, 0.06)',
                blue: 'rgba(0, 153, 255, 0.2)'
            }),
        },
        cursor: 'pointer',
    },
    selectLabel: {
        paddingLeft: 10,
        color: styledBy('color', {
            default: '#000',
            blue: '#1291F9'
        }),
        fontSize: 14,
        fontWeight: 400,
    }
});

export default nativeSelectStyle;