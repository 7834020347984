const searchBarStyle = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative'
    },
    input: {
        paddingLeft: 10,
        paddingRight: 30,
        width: '100%',
        background: 'rgba(18, 145, 249, 0.18)',
        borderRadius: 20,
        fontSize: 14,
        height: 40,
        color: '#000',
        '&:focus': {
            outline: 'none'
        },
        '&::placeholder ': {
            color: '#000',
        }
    },
    searchIcon: {
        position: 'absolute',
        right: 5,
        color: '#000',
    },
    closeIcon: {
        position: 'absolute',
        right: 5,
        cursor: 'pointer'
    }
});

export default searchBarStyle;