import { CM_PAY_LIST } from '../actions/types';

const initialState = {
  data: {
    dueToday: [],
    holded: []
  }
};

export const paylistResult = (state = initialState, action) => {
  switch (action.type) {
    case CM_PAY_LIST:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
