import React, { Component } from 'react';
import GlobalProcessor from "components/GlobalProcessor/index";
import insuranceUploadStyle from './insuranceUploadStyle';
import { withStyles } from '@material-ui/styles';
import { withTranslation  } from 'react-i18next';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BaseInput from 'components/BaseInput/index.jsx';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { successConfirm } from "components/SuccessConfirm/index";
import { withRouter } from 'react-router-dom';
import NativeSelect from 'components/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc,image/*');

    return fileSelector;
}

class InsuranceUpload extends Component
{
    state = {
        insurance: {
            agent_name: '',
            agent_state: '',
            agent_email: '',
            expiry_date: moment().format('YYYY-MM-DD'),
            message: ''
        },
        errMsg: '',
        fileName: '',
        vendors: []
    }
    
    componentDidMount() {
        const { loginStatus } = this.props.account;
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        const endpoint = apiUrl + 'api/admin/get/vendors';

        return fetch(endpoint, {
            method: "POST",
            headers: {
              Authorization: `Token ${loginStatus.token}`,
            }
        })
        .then(result => result.json())
        .then((result) => {
            if (result['status']) {
                this.setState({ vendors: result['vendors'] });
            }
        });
    }

    textChanged = (event, field) => {
        let { insurance } = this.state;
        insurance[field] = event.target.value;

        this.setState({ insurance });
    }

    fileChanged = (event) => {
        const file = this.fileSelector.files.item(0);
        this.setState({ fileName: file.name });
    }

    render() {
        const { classes, t } = this.props;
        const { insurance, errMsg, fileName, vendors } = this.state;
        const userType = this.props.account.loginStatus.user.profile.user_type;
        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Insurance Document')}
                    </div>
                </div>
                <div className={ classes.pageContentContainer } style={{ padding: 30 }}>
                    <div className={ classes.contentContainer }>
                        <p>
                            If you need your insurance agent to send Onesta your insurance information please complete the below:
                        </p>
                        <br />
                        <br />
                        <GridContainer spacing={4}>
                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        {t('Insurance Agent Name')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                            style={{ width: '80%', float: 'right' }} 
                                            value={ insurance.agent_name } 
                                            onChange={event => this.textChanged(event, 'agent_name')} 
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem xs={4}>
                                        {t('Insurance Agent Public in State of')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                            style={{ width: '80%', float: 'right' }} 
                                            value={ insurance.agent_state } 
                                            onChange={event => this.textChanged(event, 'agent_state')} 
                                        />
                                    </GridItem>
                                </GridContainer>
                                {
                                    userType === 'ADMIN' &&
                                        <GridContainer style={{ marginTop: 20 }}>
                                            <GridItem xs={4}>
                                                { t('Vendor') }
                                            </GridItem>
                                            <GridItem xs={8}>
                                                <NativeSelect
                                                    style={{ width: '80%', float: 'right' }}
                                                    onChange={ event => this.textChanged(event, 'vendor_id') }
                                                >
                                                    {
                                                        vendors.map(vendor => 
                                                            <MenuItem value={ vendor.id }>{ vendor.first_name + ' ' + vendor.last_name }</MenuItem>
                                                        )
                                                    }
                                                </NativeSelect>
                                            </GridItem>
                                        </GridContainer>
                                }
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem xs={12}
                                        className={ `${classes.uploadAttachmentContainer}` } 
                                        style={{cursor: 'pointer'}}
                                        onClick={ this.uploadClicked }
                                    >
                                        <h5 className={ [classes.uploadAttachment, `inline`].join(' ') }>Upload attachment</h5>
                                        <AddCircleOutlineIcon className={ [classes.addIcon, `inline`].join(' ') } onClick={ this.handleClick } />
                                        <span style={{ marginLeft: 20, color: '#1291F9', fontWeight: 700 }}>{ fileName }</span>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        {
                                            userType === 'ADMIN' ? t('Insurance Agent Email (Optional)') : t('Insurance Agent Email')
                                        }
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                                style={{ width: '80%', float: 'right' }} 
                                                value={ insurance.agent_email } 
                                                onChange={event => this.textChanged(event, 'agent_email')} 
                                                type="email"
                                            />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem xs={4}>
                                        {t('Expiry Date')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <TextField
                                            id="datepicker"
                                            label=""
                                            type="date"
                                            value={ insurance.expiry_date }
                                            onChange={ event => this.textChanged(event, 'expiry_date') }
                                            className={`datepicker common`}
                                            style={{ width: '80%', float: 'right' }} 
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <div style={{ marginTop: 20, }}>
                                    Special message for Insurance agent
                                </div>
                                <div style={{ marginTop: 20, marginBottom: 20 }}>
                                    <textarea
                                        className={ classes.messageArea }
                                        value={ insurance.message }
                                        onChange={ event => this.textChanged(event, 'message') }
                                    >
                                    </textarea>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <div className={ classes.submitContainer }>
                            <Button
                                className={ classes.btnSubmit }
                                onClick={ this.submitClicked }
                            >
                                Submit
                            </Button>
                        </div>
                        <div className="flex justify-center w-full" style={{ color: 'rgb(237, 28, 36)' }}>
                            { errMsg }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    submitClicked = () => {
        const { loginStatus } = this.props.account;
        const data = {};
        const { insurance } = this.state;
        const user = loginStatus.user;

        if (insurance.agent_name.length === 0) {
            this.setState({ errMsg: 'Agent Name is required.'});
            return;
        }

        if (user.profile.user_type === 'VENDOR') {
            if (insurance.agent_email.length === 0 ) {
                this.setState({ errMsg: 'Agent Email is required.'});
                return;
            }
            
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
            if (!regex.test(insurance.agent_email)) {
                this.setState({ errMsg: 'Agent Email is not valid.' })
                return;
            }
        }

        if (user.profile.user_type === 'ADMIN') {
            if (typeof insurance.vendor_id === 'undefined') {
                this.setState({ errMsg: 'Please select a vendor.' })
                return;
            }

            if (this.fileSelector.files.length === 0) {
                this.setState({ errMsg: 'Please upload an attachment.' })
                return;
            }
        }

        this.setState({ errMsg: '' });

        const endpoint = apiUrl + 'api/insurance/upload'
        let formData = new FormData();
        formData.append('insurance', JSON.stringify(insurance));
        if (this.fileSelector.files.length) {
            formData.append('file', this.fileSelector.files.item(0));
        }
        
        return fetch(endpoint, {
            method: "POST",
            headers: {
              Authorization: `Token ${loginStatus.token}`,
            },
            body: formData
        })
        .then(result => result.json())
        .then((result) => {
            if (result['status']) {
                let confirmMsg  = 'Successfully uploaded the insurance document.';
                if (user.profile.user_type === 'VENDOR') {
                    confirmMsg = 'Successfully uploaded the insurance document and verify email is sent to the Insurance Agent.';
                }
                successConfirm('Success', confirmMsg, false)
                    .then(() => {
                        this.props.history.push('/insurances/list');
                    });
            }
        });
    }

    uploadClicked = () => {
        this.fileSelector.click();
    }
}

const mapStateToProps = (state) => ({...state});
const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(insuranceUploadStyle)(InsuranceUpload))));