import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import AutocompleteSelectStyle from './AutocompleteSelectStyle.jsx'

import Select from 'react-select';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' : '#333333',
        padding: 20,
    }),
    control: (provided) => ({
        ...provided,
        height: 56,
        backgroundColor: "rgba(194, 194, 194, 0.06)",
        border: "1px solid rgba(18, 145, 249, 0.18)"
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        return { ...provided, opacity };
    }
}

class AutocompleteSelect extends Component {

    state = {
        selectedOption: null,
    };

    render() {
        var { classes, options, isDisabled } = this.props;
        options = (options == null) ? []: options;
        var selectedValue = options.find(option => option.value === this.props.value);
        selectedValue = (typeof selectedValue == 'undefined' || selectedValue == null) ?
            {value: '', label: ''} : selectedValue;

        return (
            <div>
                <Select
                    isClearable
                    value={selectedValue}
                    styles={customStyles}
                    options={options}
                    isDisabled={isDisabled}
                    onChange={this.props.onChange}
                    styles={{
                        container: (provided, state) => ({
                            ...provided,
                            borderRadius: 8,
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            marginTop: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderWidth: 0,
                            borderTopWidth: 1,
                            borderTopColor: 'rgba(18, 145, 249, 0.18)',
                        }),
                        control: (provided, state) => {
                            if (state.isFocused) {
                                return {
                                    ...provided,
                                    borderRadius: 7,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    height: 40,
                                    cursor: 'pointer',
                                    border: "1px solid rgba(18, 145, 249, 0.18)",
                                    backgroundColor: "rgba(194, 194, 194, 0.06)",
                                }
                            }
                            
                            return {
                                ...provided,
                                borderRadius: 7,
                                height: 40,
                                cursor: 'pointer',
                                backgroundColor: "rgba(194, 194, 194, 0.06)",
                                border: "1px solid rgba(18, 145, 249, 0.18)",
                            };
                        }
                    }}
                />
            </div>
        );
    }
}

export default AutocompleteSelect;