import * as Type from './types';
import { parse } from 'path';
import Cookies from 'js-cookie';
var moment = require('moment');

/********************************************************
 *
 * Admin side
 *
 */


// Buyer Create
export const userCreate = (postData) => (dispatch, getState) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    const loginStatus = getState()['account']['loginStatus'];
    return fetch(apiUrl + 'api/account/create', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};


export const addEvent = (data) => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/event', {
    method: 'POST',
    headers: {
      'Authorization': 'Token ' + loginStatus.token,
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.status + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        var expMoment = moment().add(12, 'hours');
        dispatch({
          type: Type.CM_EVT_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_EVT_RESULT,
          payload: result
        });
      }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_EVT_RESULT,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
    });
};


// Buyer List
export const getEvents = () => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/event', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Buyer Approve/Suspend
export const eventDelete = (eventId) => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/event/delete', {
      method: 'DELETE',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        eventId: eventId
      })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }

        return result;
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};


// Add Category
export const addCategory = (data) => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/category', {
    method: 'POST',
    headers: {
      'Authorization': 'Token ' + loginStatus.token,
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.status + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        var expMoment = moment().add(12, 'hours');
        dispatch({
          type: Type.CM_CAT_ADD_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_CAT_ADD_RESULT,
          payload: result
        });
      }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_CAT_ADD_RESULT,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
    });
};

// Categories List
export const getCategories = () => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/category', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          dispatch({
            type: Type.CM_CAT_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_CAT_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_CAT_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Delete Category


// Buyer Approve/Suspend
export const deleteCategory = (categId) => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/admin/category/delete', {
      method: 'DELETE',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        categId: categId
      })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Buyer List
export const userBuyerList = (page = 1) => (dispatch, getState) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    const loginStatus = getState()['account']['loginStatus'];
    return fetch(apiUrl + 'api/account/get_buyer_list', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({ page })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Buyer Approve/Suspend
export const userBuyerStatus = (userid, status) => dispatch => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    return fetch(apiUrl + 'api/account/set_buyer_status', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Cookie': '',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        userid: userid,
        status: status
      })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Buyer Approve/Suspend
export const userBuyerDelete = (userid) => dispatch => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    return fetch(apiUrl + 'api/account/delete_buyer', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Cookie': '',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        userid: userid
      })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

 // Get Admin Budget Limit settings
export const getBudgetSettings = () => (dispatch, getState) => {

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const loginStatus = getState()['account']['loginStatus'];

    return fetch(apiUrl + 'api/admin/budget_settings', {
        method: 'GET',
        headers: {
          'Authorization': 'Token ' + loginStatus.token,
          'content-type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
        }
    })
    .then(res => {
      if (res.status >= 400) {
        //throw new Error("Backend Error with Status \"" + res.statusText + "\"");
        console.log("error", res);
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
      }
      return res.json();
    })
    .then(result => {
        if(result.status){
          dispatch({
            type: Type.CM_DATA,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_DATA,
            payload: result
          });
        }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_DATA,
        payload: {
          'status': false,
          'message': 'Backend Error Occured',
          'categGroup': [],
          'buyers': [],
          'vendors': []
        }
      });
    });
};

// Get Admin Budget Limit settings
export const saveBudgetSettings = (data) => (dispatch, getState) => {

   const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

   const loginStatus = getState()['account']['loginStatus'];

   return fetch(apiUrl + 'api/admin/budget_settings', {
       method: 'POST',
       headers: {
         'Authorization': 'Token ' + loginStatus.token,
         'content-type': 'application/json',
         'X-CSRFToken': Cookies.get('csrftoken')
       },
       body: JSON.stringify(data)
   })
   .then(res => {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
     if (res.status >= 400) {
       throw new Error("Backend Error with Status \"" + res.statusText + "\"");
     }
     return res.json();
   })
   .then(result => {
       if(result.status){
         dispatch({
           type: Type.CM_RESULT,
           payload: result
         });
         return true;
       }else{
         dispatch({
           type: Type.CM_RESULT,
           payload: result
         });
         return false;
       }
   })
   .catch(err => {
     dispatch({
       type: Type.CM_RESULT,
       payload: {
         'status': false,
         'message': 'Backend Error Occured'
       }
     });
     return false;
   });
};

// Get Admin Budget Limit settings
export const delBudgetSettings = (budgetId) => (dispatch, getState) => {

   const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

   const loginStatus = getState()['account']['loginStatus'];

   return fetch(apiUrl + 'api/admin/budget_settings', {
       method: 'DELETE',
       headers: {
         'Authorization': 'Token ' + loginStatus.token,
         'content-type': 'application/json',
         'X-CSRFToken': Cookies.get('csrftoken')
       },
       body: JSON.stringify({
         budgetId: budgetId
       })
   })
   .then(res => {
     if (res.status >= 400) {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
       throw new Error("Backend Error with Status \"" + res.statusText + "\"");
     }
     return res.json();
   })
   .then(result => {
       if(result.status){
         dispatch({
           type: Type.CM_BUDGET_DEL,
           payload: result
         });
       }else{
         dispatch({
           type: Type.CM_BUDGET_DEL,
           payload: result
         });
       }
   })
   .catch(err => {
     dispatch({
       type: Type.CM_BUDGET_DEL,
       payload: {
         'status': false,
         'message': 'Backend Error Occured'
       }
     });
   });
};

// Get Admin approve list
export const getAdminPaysDue = () => (dispatch, getState) => {

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const loginStatus = getState()['account']['loginStatus'];

    return fetch(apiUrl + 'api/common/get_admin_paylist', {
        method: 'GET',
        headers: {
            'Authorization': 'Token ' + loginStatus.token,
            'content-type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    })
    .then(res => {
        if (res.status >= 400) {
            dispatch({
                type: Type.CM_ERROR,
                payload: res
            });
            throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
    })
    .then(result => {
        if(result.status){
            dispatch({
            type: Type.CM_PAY_LIST,
            payload: result
            });
        }else{
            dispatch({
            type: Type.CM_PAY_LIST,
            payload: result
            });
        }
    })
    .catch(err => {
        dispatch({
        type: Type.CM_PAY_LIST,
        payload: {
            'status': false,
            'message': 'Backend Error Occured',
        }
        });
    });
};

// Get Admin Budget Limit settings
export const holdInvoicePay = (invoiceId, flag = true) => (dispatch, getState) => {

   const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

   const loginStatus = getState()['account']['loginStatus'];

   return fetch(apiUrl + 'api/admin/invoice_pay_action', {
       method: 'DELETE',
       headers: {
         'Authorization': 'Token ' + loginStatus.token,
         'content-type': 'application/json',
         'X-CSRFToken': Cookies.get('csrftoken')
       },
       body: JSON.stringify({
        invoiceId: invoiceId,
        flag: flag
       })
   })
   .then(res => {
     if (res.status >= 400) {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
       throw new Error("Backend Error with Status \"" + res.statusText + "\"");
     }
     return res.json();
   })
   .then(result => {
       if(result.status){
         dispatch({
           type: Type.CM_BUDGET_DEL,
           payload: result
         });
       }else{
         dispatch({
           type: Type.CM_BUDGET_DEL,
           payload: result
         });
       }
   })
   .catch(err => {
     dispatch({
       type: Type.CM_BUDGET_DEL,
       payload: {
         'status': false,
         'message': 'Backend Error Occured'
       }
     });
   });
};

// Admin pay invoice via Paypal
export const payInvoiceViaPaypal = (invoice, payment) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/invoice_pay_paypal', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        invoiceId: invoice.id,
        payment: payment
      })
  })
  .then(res => {
    if (res.status >= 400) {
     dispatch({
       type: Type.CM_ERROR,
       payload: res
     });
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_BUDGET_DEL,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};


// Admin pay invoice via Paypal
export const payInvoiceViaPaypalApi = (invoice) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/invoice_pay_paypal_check', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        invoiceId: invoice.id
      })
  })
  .then(res => {
    if (res.status >= 400) {
     dispatch({
       type: Type.CM_ERROR,
       payload: res
     });
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_BUDGET_DEL,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};



export const clearApproves = () => (dispatch) => {
  dispatch({
    type: Type.CM_APPRV_CLEAR,
  });
}

// Get Admin approve list
export const getAdminApproves = (sortFields, page = 1) => (dispatch, getState) => {

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const loginStatus = getState()['account']['loginStatus'];

    return fetch(apiUrl + 'api/common/get_admin_approves', {
        method: 'POST',
        headers: {
            'Authorization': 'Token ' + loginStatus.token,
            'content-type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        },
        body: JSON.stringify({
          sortFields: sortFields,
          page
        })
    })
    .then(res => {
        if (res.status >= 400) {
        //throw new Error("Backend Error with Status \"" + res.statusText + "\"");
        console.log("error", res);
        dispatch({
            type: Type.CM_ERROR,
            payload: res
        });
        }
        return res.json();
    })
    .then(async result => {
        if(result.status){
            dispatch({
            type: Type.CM_APPRV_RESULT,
            payload: result
            });
        }else{
            dispatch({
            type: Type.CM_APPRV_RESULT,
            payload: result
            });
        }
        return result;
    })
    .catch(err => {
        dispatch({
        type: Type.CM_APPRV_RESULT,
        payload: {
            'status': false,
            'message': 'Backend Error Occured',
            'categGroup': [],
            'buyers': [],
            'vendors': []
        }
        });
    });
};


// Get Admin approve list
export const approveRequest = (approvalId, flag) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/approve_requests', {
      method: 'POST',
      headers: {
          'Authorization': 'Token ' + loginStatus.token,
          'content-type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        approvalId: approvalId,
        flag: flag
      })
  })
  .then(res => {
      if (res.status >= 400) {
      //throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      console.log("error", res);
      dispatch({
          type: Type.CM_ERROR,
          payload: res
      });
      }
      return res.json();
  })
  .then(result => {
      if(result.status){
          dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
          });
      }else{
          dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
          });
      }
  })
  .catch(err => {
      dispatch({
      type: Type.CM_ACTION_RESULT,
      payload: {
          'status': false,
          'message': 'Backend Error Occured',
          'categGroup': [],
          'buyers': [],
          'vendors': []
      }
      });
  });
};

// Get Admin Budget Limit settings
export const deleteRequest = (approvalId) => (dispatch, getState) => {

   const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

   const loginStatus = getState()['account']['loginStatus'];

   return fetch(apiUrl + 'api/admin/approve_requests', {
       method: 'DELETE',
       headers: {
         'Authorization': 'Token ' + loginStatus.token,
         'content-type': 'application/json',
         'X-CSRFToken': Cookies.get('csrftoken')
       },
       body: JSON.stringify({
        approvalId: approvalId
       })
   })
   .then(res => {
     if (res.status >= 400) {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
       throw new Error("Backend Error with Status \"" + res.statusText + "\"");
     }
     return res.json();
   })
   .then(result => {
       if(result.status){
         dispatch({
           type: Type.CM_ACTION_RESULT,
           payload: result
         });
       }else{
         dispatch({
           type: Type.CM_ACTION_RESULT,
           payload: result
         });
       }
   })
   .catch(err => {
     dispatch({
       type: Type.CM_ACTION_RESULT,
       payload: {
         'status': false,
         'message': 'Backend Error Occured'
       }
     });
   });
};


// Quickbook related functions
export const qbGetAuthUrl = (invoice) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/quickbook/' + invoice.id, {
      method: 'GET',
      headers: {
          'Authorization': 'Token ' + loginStatus.token,
          'content-type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
      }
  })
  .then(res => {
    if (res.status >= 400) {
      dispatch({
          type: Type.CM_ERROR,
          payload: res
      });
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
      dispatch({
        type: Type.CM_ACTION_RESULT,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
  });
};


// Buyer Side Submit Buy Request (Create Invoice)
export const submitQbAuth = (postData) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/submit_qb_auth', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ACTION_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_ACTION_RESULT,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};



// Buyer Side Submit Buy Request (Create Invoice)
export const accountReconcilList = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/account_reconcil', {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + loginStatus.token,
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    }
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_INVOICE_LIST,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_INVOICE_LIST,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_INVOICE_LIST,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};



// Buyer Side Submit Buy Request (Create Invoice)
export const reportBudgetInfo = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/admin/budget_info', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
    if(result.status){
      dispatch({
        type: Type.ADM_BUDGET_INFO,
        payload: result
      });
    }else{
      dispatch({
        type: Type.ADM_BUDGET_INFO,
        payload: result
      });
    }
  })
  .catch(err => {
    dispatch({
      type: Type.ADM_BUDGET_INFO,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};