import React, {Component} from 'react'

import baseInputStyle from './baseInputStyle.jsx'
import { withStyles } from '@material-ui/core';

class BaseInput extends Component
{
    render(){
        const {className, classes, inputRef, small, ...rest} = this.props;

        if (small) {
            return (
                <input { ...rest } className={[classes.baseSmallInput, className].join(' ')} ref={inputRef} onChange={this.props.onChange} />
            )
        }
        return (
            <input { ...rest } className={[classes.baseInput, className].join(' ')} ref={inputRef} onChange={this.props.onChange} />
        )
    }
}

export default withStyles(baseInputStyle)(BaseInput);