import React, { Component } from 'react';
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import { getCustomerInfo } from 'redux/actions/commonActions';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/Button/index.jsx';
import { confirm } from "components/Confirm/index";
import { successConfirm } from "components/SuccessConfirm/index";

import Cookies from 'js-cookie';


import ContactUsPageStyle from './ContactUsPageStyle.jsx'


function getContactModalOuterStyle() {
    const top = 5;
    const left = 50;
    return {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    };
}

function getContactModalInnerStyle() {
    const top = 5;
    const left = 50;
    return {
        /* position: 'absolute', */
        maxWidth: '60rem',
        width: '50rem',
        /* maxHeight: '300px', */
        borderRadius: '0.5rem',
        margin: '0 auto',
        left: 'calc(50% - 25rem)',
        maxHeight: '60%',
        overflowY: 'auto',
        backgroundColor: 'white',
        padding: '1.5rem 2rem'
    };
}

class ContactUsPage extends Component {

    state = {
        customer: '',
        isContactModalOpened: false,
        contactModal: {
            data: {
                name: "",
                company: "",
                email: "",
                phone: "",
                content: "",
            },
            validationError: ""
        },
        isSupportModalOpened: false,
        supportModal: {
            data: {
                name: "",
                company: "",
                email: "",
                phone: "",
                supportArea: "",
                content: "",
            },
            validationError: ""
        },
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let customer = this.props.match.params.customer;
        this.props.getCustomerInfo(customer);
        this.setState({customer});
    }

    setValueChange = (e, formName, valueName) => {
        const dataOject = this.state[formName];
        dataOject.data[valueName] = e.target.value;
        let data = {};
        data[formName] = Object.assign(this.state[formName], dataOject);
        this.setState(data);
    }

    // Submit contact form
    _validationCheckContact = () => {
        let modalItem = this.state.contactModal;
        if (modalItem.data.name.length == 0) {
            return { status: false, message: 'Name is required.' };
        } else if (modalItem.data.company.length == 0) {
            return { status: false, message: 'Company is required.' };
        } else if (modalItem.data.email.length == 0) {
            return { status: false, message: 'Email is required.' };
        } else if (modalItem.data.phone.length == 0) {
            return { status: false, message: 'Phone is required.' };
        } else if (modalItem.data.content.length == 0) {
            return { status: false, message: 'Content is required.' };
        }
        return { status: true, message: '' };
    }

    handleSubmitContact = () => {
        const errorOject = {
            validationError: ""
        };
        let validationCheck = this._validationCheckContact();
        if (!validationCheck.status) {
            errorOject.validationError = validationCheck.message;
            this.setState({ contactModal: Object.assign(this.state.contactModal, errorOject) });
            return;
        }
        this.setState({ contactModal: Object.assign(this.state.contactModal, errorOject) });


        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        let postData = this.state.contactModal.data;
        postData["customer"] = this.state.customer;

        fetch(apiUrl + 'api/contact/sales', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify(postData)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                this.handleContactModalClose();
                successConfirm("Submitted Successfully", "\"Thank you for your query. We will contact you shortly\"", false);
            } else {
                confirm("Error", res.message, false);
            }
        })
        .catch(err => {
            confirm("Error", "Server error occured. Please try later!", false);
        });
    }

    // handleContactModalClose
    handleContactModalClose = () => {
        this.setState({'isContactModalOpened': false});
    }
    openContactModal = () => {
        this.setState({'isContactModalOpened': true});
    }


    // Support Modal Part
    _validationCheckSupport = () => {
        let modalItem = this.state.supportModal;
        if (modalItem.data.name.length == 0) {
            return { status: false, message: 'Name is required.' };
        } else if (modalItem.data.company.length == 0) {
            return { status: false, message: 'Company is required.' };
        } else if (modalItem.data.email.length == 0) {
            return { status: false, message: 'Email is required.' };
        } else if (modalItem.data.phone.length == 0) {
            return { status: false, message: 'Phone is required.' };
        } else if (modalItem.data.supportArea.length == 0) {
            return { status: false, message: 'Please input area of support.' };
        } else if (modalItem.data.content.length == 0) {
            return { status: false, message: 'Content is required.' };
        }
        return { status: true, message: '' };
    }

    handleSubmitSupport = () => {
        let customerInfo = this.props.customerInfo.result;
        let customerName = '';
        let customerWebsite = '';
        let contactName = '';
        let contactEmail = '';
        let contactPhone = '';
        if (typeof customerInfo.customer !== "undefined" && customerInfo.customer !== null) {
            customerName = customerInfo.customer.name;
            customerWebsite = customerInfo.customer.website;
            contactName = customerInfo.customer.contactName;
            contactEmail = customerInfo.customer.contactEmail;
            contactPhone = customerInfo.customer.contactPhone;
            console.log(contactName);
        }

        const errorOject = {
            validationError: ""
        };
        let validationCheck = this._validationCheckSupport();
        if (!validationCheck.status) {
            errorOject.validationError = validationCheck.message;
            this.setState({ supportModal: Object.assign(this.state.supportModal, errorOject) });
            return;
        }
        this.setState({ supportModal: Object.assign(this.state.supportModal, errorOject) });

        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        let postData = this.state.supportModal.data;
        postData["customer"] = this.state.customer;

        fetch(apiUrl + 'api/contact/support', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify(postData)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                this.handleSupportModalClose();
                successConfirm(
                    "Submitted Successfully",
                    "\"Thank you for your query. We will contact you shortly\"",
                    false,
                    "OK",
                    "Cancel",
                    {
                        "subTitle": "Alternatively, you can contact " + contactName,
                        "email": contactEmail,
                        "phone": contactPhone
                    });
            } else {
                confirm("Error", res.message, false);
            }
        })
        .catch(err => {
            confirm("Error", "Server error occured. Please try later!", false);
        });
    }

    // handleContactModalClose
    handleSupportModalClose = () => {
        this.setState({'isSupportModalOpened': false});
    }
    openSupportModal = () => {
        this.setState({'isSupportModalOpened': true});
    }

    rendorCustomerWebsite() {
        const { classes } = this.props;
        let customerInfo = this.props.customerInfo.result;
        let customerName = '';
        let customerWebsite = '';

        if (typeof customerInfo.customer !== "undefined" && customerInfo.customer !== null) {
            customerName = customerInfo.customer.name;
            customerWebsite = customerInfo.customer.website;
        }

        if (typeof customerWebsite === 'undefined' || customerWebsite === '') {
            return (
                <span target="_blank" className={classes.panelAction} href={ customerWebsite }>
                    &nbsp;
                </span>
            );
        }

        return (
            <a target="_blank" className={classes.panelAction} href={ customerWebsite }>
                Visit { customerName }&nbsp;<img src="/assets/img/left-red_x20.png" />
            </a>
        );
    }

    render() {
        const { classes } = this.props;

        let customerInfo = this.props.customerInfo.result;
        let customerName = '';
        if (typeof customerInfo.customer !== "undefined" && customerInfo.customer !== null) {
            customerName = customerInfo.customer.name;
        }

        return (
            <div className={classes.pageContainer}>
                <div className={classes.pageheader}>
                    <div className={classes.pageheaderLogo}>
                        <a href="/">
                            <img src="/assets/img/newui/header/blue_logo.png" className={classes.headerLogoImage} />
                        </a>
                    </div>
                    <div className={classes.pageheaderNav}>
                        <Link to="contact-us" className={classes.headerNav}>
                            Support
                        </Link>
                        <Link to={ '/login' } className={classes.headerNav}>
                            Sign In
                            &nbsp;<img className={`inline`} src="/assets/img/left-white_x20.png" />
                        </Link>
                    </div>
                </div>
                <div className={classes.pageBody}>
                    <p className={classes.labelPara}>Contact Us</p>
                    <p>Get in touch and let us konw how we can help</p>
                    <div className={classes.panelWrapper}>
                        <div className={classes.panelItem}>
                            <div className={classes.panelItemInner}>
                                <div className={classes.panelLogo}>
                                    <div className={classes.logo}>
                                        <img src="/assets/img/sales.png" />
                                    </div>
                                </div>
                                <div className={classes.panelTitle}>Sales</div>
                                <div className={classes.panelDesc}>
                                    We`d love to talk about how we can work together.
                                </div>
                                <div className={classes.panelAction} onClick={ this.openContactModal }>
                                    Contact Sales&nbsp;<img src="/assets/img/newui/left-blue-100_x20.png" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.panelItem}>
                            <div className={classes.panelItemInner}>
                                <div className={classes.panelLogo}>
                                    <div className={classes.logo}>
                                        <img src="/assets/img/support.png" />
                                    </div>
                                </div>
                                <div className={classes.panelTitle}>Help & Support</div>
                                <div className={classes.panelDesc}>
                                    We`re help to help with any questions or code.
                                </div>
                                <div className={classes.panelAction} onClick={ this.openSupportModal }>
                                    Get Support&nbsp;<img src="/assets/img/newui/left-blue-100_x20.png" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.panelItem}>
                            <div className={classes.panelItemInner}>
                                <div className={classes.panelLogo}>
                                    <div className={classes.logo}>
                                        <img src="/assets/img/events.png" />
                                    </div>
                                </div>
                                <div className={classes.panelTitle}>{ customerName } Events</div>
                                <div className={classes.panelDesc}>
                                    Get information on current and past { customerName } events.
                                </div>
                                { this.rendorCustomerWebsite() }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.pageFooter}>
                    <div className={classes.pageFooterCopyright}>
                        Copyright @ 2019 iPayflow
                    </div>
                    <div className={classes.pageFooterMain}>
                        <Link to="contact-us" className={classes.footerNav}>
                            Contact US
                        </Link>
                        <Link to="privacy" className={classes.footerNav}>
                            Privacy
                        </Link>
                        <Link to="terms" className={classes.footerNav}>
                            Legal
                        </Link>
                    </div>
                </div>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.isContactModalOpened}
                    onClose={this.handleContactModalClose}
                >
                    <div style={getContactModalOuterStyle()}>
                        <div style={getContactModalInnerStyle()} className={classes.paper}>
                            <Grid container className={classes.modalHeader}>
                                <Grid item xs={12} style={{marginLeft: 8, flex: 1,}}>
                                    <Typography variant="h6" className={classes.modalTitle}>
                                        Contact Sales
                                    </Typography>
                                </Grid>
                                <IconButton
                                    className={classes.closeIconButton}
                                    onClick={this.handleContactModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Grid container className={classes.formFirstLine}>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.contactModal.data.name}
                                        onChange={(e) => this.setValueChange(e, 'contactModal', 'name')}
                                        type="text" name="name" placeholder="Name" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.contactModal.data.company}
                                        onChange={(e) => this.setValueChange(e, 'contactModal', 'company')}
                                        type="text" name="company" placeholder="Company" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.contactModal.data.email}
                                        onChange={(e) => this.setValueChange(e, 'contactModal', 'email')}
                                        type="text" name="email" placeholder="Email" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.contactModal.data.phone}
                                        onChange={(e) => this.setValueChange(e, 'contactModal', 'phone')}
                                        type="text" name="phone" placeholder="Phone Number" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={12} style={{flex: 1,}} style={{marginLeft: 8, flex: 1,}}>
                                    <textarea
                                        value={this.state.contactModal.data.content}
                                        onChange={(e) => this.setValueChange(e, 'contactModal', 'content')}
                                        placeholder="How can I help you?"
                                        className={classes.inputText}></textarea>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={12} style={{flex: 1,}} style={{marginLeft: 8, flex: 1, textAlign: 'center'}}>
                                    <Button
                                        className={classes.submitBtn}
                                        onClick={this.handleSubmitContact} color="primary">
                                        Submit
                                    </Button>
                                    <p className={classes.errorMsg}>
                                        { this.state.contactModal.validationError }
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Modal>



                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.isSupportModalOpened}
                    onClose={this.handleSupportModalClose}
                >
                    <div style={getContactModalOuterStyle()}>
                        <div style={getContactModalInnerStyle()} className={classes.paper}>
                            <Grid container className={classes.modalHeader}>
                                <Grid item xs={12} style={{marginLeft: 8, flex: 1,}}>
                                    <Typography variant="h6" className={classes.modalTitle}>
                                        Get Support
                                    </Typography>
                                </Grid>
                                <IconButton
                                    className={classes.closeIconButton}
                                    onClick={this.handleSupportModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Grid container className={classes.formFirstLine}>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.supportModal.data.name}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'name')}
                                        type="text" name="name" placeholder="Name" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.supportModal.data.company}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'company')}
                                        type="text" name="company" placeholder="Company" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.supportModal.data.email}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'email')}
                                        type="text" name="email" placeholder="Email" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                                <Grid item xs={6} style={{flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.supportModal.data.phone}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'phone')}
                                        type="text" name="phone" placeholder="Phone Number" style={{marginLeft: 8, flex: 1,}} required />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={12} style={{flex: 1,}} style={{marginLeft: 0, flex: 1,}}>
                                    <InputBase className={classes.input}
                                        value={this.state.supportModal.data.supportArea}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'supportArea')}
                                        type="text" name="supportArea" placeholder="Area of Support Needed" style={{marginLeft: 8, flex: 1, width: '98%'}} required />
                                    {/* <select
                                        className={classes.inputSelect}
                                        value={this.state.supportModal.data.supportArea}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'supportArea')}>
                                        <option value="">Area of Support Needed</option>
                                        <option value="area1">area1</option>
                                        <option value="area2">area2</option>
                                        <option value="area3">area3</option>
                                        <option value="area4">area4</option>
                                        <option value="area5">area5</option>
                                    </select> */}
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={12} style={{flex: 1,}} style={{marginLeft: 8, flex: 1,}}>
                                    <textarea
                                        value={this.state.supportModal.data.content}
                                        onChange={(e) => this.setValueChange(e, 'supportModal', 'content')}
                                        placeholder="How can I help you?"
                                        style={{width: '99%'}}
                                        className={classes.inputText}></textarea>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.formLine}>
                                <Grid item xs={12} style={{flex: 1,}} style={{marginLeft: 8, flex: 1, textAlign: 'center'}}>
                                    <Button
                                        className={classes.submitBtn}
                                        onClick={this.handleSubmitSupport} color="primary">
                                        Submit
                                    </Button>
                                    <p className={classes.errorMsg}>
                                        { this.state.supportModal.validationError }
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerInfo: state.customerInfo
});

const mapDispatchToProps = {getCustomerInfo}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ContactUsPageStyle)(ContactUsPage));
