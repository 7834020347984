import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import WhiteHeader from 'components/WhiteHeader/index.jsx';
import LeftSidebar from 'components/LeftSidebar/index.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';
import { withStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from '@material-ui/core/Button';
import CustomButton from 'components/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from 'components/Loading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AutocompleteSelect from 'components/AutocompleteSelect';
import { Redirect } from 'react-router-dom';

import msaEditPageStyle from './msaEditStyle';
import BaseInput from 'components/BaseInput';

import { saveMsaFile } from '../../redux/actions/fileActions';

import moment from 'moment';
import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc');

    return fileSelector;
}

class MsaEdit extends Component
{
    state = {
        signerOptions: [],
        signerId: null,
        fileName: '',
        uploading: false,
        email: 'risestar0927@outlook.com',
        availableSigners: [],
        msa: null,
        isEditing: false,
        defaultDocument: null
    }

    file = null

    async componentDidMount() {
        const { msaId } = this.props.match.params;

        const loginStatus = this.props.account.loginStatus;

        this.setState({email: loginStatus.user.email});
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        await this.fetchAvailableSigners();

        return fetch(apiUrl + 'api/msa/get/by_id', {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + loginStatus.token,
                'X-CSRFToken': Cookies.get('csrftoken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: msaId})
        })
        .then(res => res.json())
        .then(result => {
            if (result['status']) {
                this.setState({msa: result['document']});
            }
        });
    }

    showUploadForm = () => {
        this.setState({isEditing: true});
    }

    renderMsaDetails = () => {
        const { isEditing, msa } = this.state;
        const { classes, t } = this.props;

        if (isEditing) {
            return null;
        }

        if (!msa) {
            return null;
        }

        return (
            <div className={ classes.formContainer }>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <CustomButton className={ classes.btnViewDoc } onClick={ this.showUploadForm }>
                        {t('Update MSA Document')}
                    </CustomButton>
                </div>
                <div style={{ marginBottom: 15, }}>
                    <span className={ classes.uploadAttachment }>
                        {t('Signers')}
                    </span>
                </div>
                <div>
                    <div style={{ marginLeft: 50, display: 'flex', border: '1px solid #1D8ACF', borderTopLeftRadius: 3, borderTopRightRadius: 3, background: '#1D8ACF', color: 'white'}}>
                        <div style={{ flex: 1, borderRight: '1px solid #DEDEDE', textAlign: 'center', paddingTop: 4, paddingBottom: 4, }}>
                            {t('Name')}
                        </div>
                        <div style={{ flex: 1, borderRight: '1px solid #DEDEDE', textAlign: 'center', paddingTop: 4, paddingBottom: 4, }}>
                            {t('Email')}
                        </div>
                        <div style={{ flex: 1, textAlign: 'center', paddingTop: 4, paddingBottom: 4, }}>
                            {t('Status')}
                        </div>
                    </div>
                    {
                        msa.signers.map((signer, key) => {
                            return (
                                <div style={{ marginLeft: 50, display: 'flex', border: '1px solid #DEDEDE', borderTopWidth: 0, }} key={ key }>
                                    <div style={{ flex: 1, borderRight: '1px solid #DEDEDE', textAlign: 'center', paddingTop: 3, paddingBottom: 3, }}>
                                        { signer.name }
                                    </div>
                                    <div style={{ flex: 1, borderRight: '1px solid #DEDEDE', textAlign: 'center', paddingTop: 3, paddingBottom: 3, }}>
                                        { signer.email }
                                    </div>
                                    <div style={{ flex: 1, textAlign: 'center', paddingTop: 3, paddingBottom: 3, }}>
                                        { signer.signed ? 'Signed': 'Not Signed' }
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
                <div style={{ marginBottom: 10, marginTop: 15, }}>
                    <span className={ classes.uploadAttachment }>
                        {t('Uploaded At')}
                    </span>
                </div>
                <div style={{ marginLeft: 50, }}>
                    { moment(msa.uploaded_at).format('DD/MM/YYYY') }
                </div>
                <div style={{ marginBottom: 10, marginTop: 15, }}>
                    <span className={ classes.uploadAttachment }>
                        {t('Uploaded By')}
                    </span>
                </div>
                <div style={{ marginLeft: 50, }}>
                    { msa.uploaded_by.first_name } { msa.uploaded_by.last_name }
                </div>

                <CustomButton className={ classes.btnViewDoc } style={{ marginTop: 40, }} onClick={ this.downloadOriginal }>
                    {t('Download Original Document')}
                </CustomButton>
            </div>
        )
    }

    downloadOriginal = () => {
        const { msa } = this.state;

        window.location.href = apiUrl + 'api/download/msa/' + msa.id;
    }

    renderUploadForm = () => {
        const { isEditing, uploading, fileName, email, signerId, signerOptions } = this.state;
        const { classes, t } = this.props;

        if (isEditing) {
            return (
                <div className={ classes.formContainer }>
                    <div style={{ opacity: uploading ? 0.5 : 1, width: '100%' }}>
                        <GridContainer>
                            <GridItem xs={12} className={ classes.uploadIconContainer }>
                                <span className={ classes.uploadAttachment } onClick={this.uploadIconClicked}>{ t('Upload') } MSA { t('Document') }</span>
                                <AddCircleOutlineIcon className={ classes.addIcon } onClick={this.uploadIconClicked}/>
                            </GridItem>
                            {
                                fileName !== '' &&
                                    <GridItem xs={12} className={ classes.fileContainer }>
                                        <img src="/assets/img/attachment-inactive.png" alt="attachment" className={ classes.attachmentImg }/>
                                        <span style={{ marginLeft: 8 }}>{ fileName }</span>
                                    </GridItem>
                            }
                        </GridContainer>
                        <GridContainer style={{ marginTop: 30 }}>
                            <GridItem xs={12} style={{ marginBottom: 20 }}>
                                <span className={ classes.uploadAttachment }>{ t('Your email address to receive document') }</span>
                            </GridItem>
                            <GridItem xs={8} style={{ marginLeft: 50 }}>
                                <BaseInput value={email} style={{ width: '100%' }} onChange={ event => this.setState({ email: event.target.value }) } type="email" />
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{ marginTop: 30 }}>
                            <GridItem xs={12} style={{ marginBottom: 20 }}>
                                <span className={ classes.uploadAttachment }>{ t('Select a signer') }</span>
                            </GridItem>
                            <GridItem xs={8} style={{ marginLeft: 50 }}>
                                <AutocompleteSelect
                                    className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                    value={ signerId }
                                    options={ signerOptions }
                                    onChange={ option => this.signerChanged(option) }
                                    name="event"
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{ marginTop: 30 }}>
                            <GridItem style={{ display: 'flex' }} xs={12}>
                                <CustomButton variant="contained" disabled ={!this.formValidated()} onClick={ this.uploadToDocuSign } className={ classes.btnComplete }>
                                    {t('UPDATE AND SEND DOCUMENT')}
                                </CustomButton>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <Loading uploading={ uploading }/>
                </div>
            );
        }

        return null;
    }

    fileChanged = async () => {
        this.setState({fileName: this.fileSelector.files.item(0).name});
    }

    fetchAvailableSigners = () => {
        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + 'api/msa/available_signers', {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + loginStatus.token,
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        })
        .then(res => res.json())
        .then(res => {
            let signers = res['signers'];
            let signerOptions = signers.map(signer => ({
                value: signer.id,
                label: signer.name
            }));

            this.setState({ signerOptions, availableSigners: signers });

            if (typeof res['msaDefaultDocument'] != 'undefined') {
                this.setState({ defaultDocument: res['msaDefaultDocument']});
            }
        });
    }

    render() {
        const { classes, t } = this.props;
        const { signerOptions, signerId, fileName, uploading, email, uploaded, openModal } = this.state;

        if (uploaded) {
            return <Redirect to="/msa/sender-view" />
        }

        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        MSA { t('Document') }
                    </div>
                </div>
                <div className={ classes.pageContentContainer }>
                    <div className={ classes.contentContainer } style={{ position: 'relative' }}>
                        {
                            this.renderMsaDetails()
                        }
                        {
                            this.renderUploadForm()
                        }
                    </div>
                </div>
            </div>
        )
    }

    uploadIconClicked = () => {
        this.fileSelector.click();
    }

    signerChanged = (option) => {
        this.setState({signerId: option ? option.value : null});
    }

    formValidated = () => {
        const { signerId, email, fileName, defaultDocument } = this.state;
        const fileUploaded = fileName.length > 0;

        return signerId && (fileUploaded || defaultDocument != null) && email.includes('@');
    }

    uploadToDocuSign = async () => {
        const loginStatus = this.props.account.loginStatus;

        if (this.fileSelector.files.length > 0) {
            const file = this.fileSelector.files.item(0);
            let formData = new FormData();
            formData.append('file', file);
            this.setState({uploading: true});
            this.file = await fetch(apiUrl + 'api/convert_to_pdf', {
                method: 'POST',
                headers: {
                    Authorization: 'Token ' + loginStatus.token,
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                body: formData
            })
            .then(res => res.json())
            .then(result => {
                return result;
            });
        }
        else if (this.state.defaultDocument != null) {
            this.file = await fetch(apiUrl + 'api/get_default_pdf', {
                method: 'POST',
                headers: {
                    Authorization: 'Token ' + loginStatus.token,
                    'content-type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                body: JSON.stringify({document: this.state.defaultDocument})
            })
            .then(res => res.json())
            .then(result => {
                return result;
            });
        }

        const { signerId, email } = this.state;
        let recipients = [];
        recipients.push({
            user_id: loginStatus.user.id,
            email: email,
            name: loginStatus.user.first_name + ' ' + loginStatus.user.last_name
        });
        const signer = this.state.availableSigners.filter(signer => signer.id === signerId)[0];
        recipients.push({
            user_id: signer.id,
            email: signer.email,
            name: signer.name
        });

        const msa = { file: this.file, id: this.state.msa.id, recipients };
        this.props.saveMsaFile(msa);
        this.setState({uploading: false, uploaded: true});
    }
}

const mapStateToProps = (state) => {
    console.log('========================', state);
    return state;
}

const mapDispatchToProps = { saveMsaFile }

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(msaEditPageStyle)(MsaEdit)));