
const whiteHeaderStyle = theme => ({
    globalHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '74px',
        marginBottom: '2px',
        '&$expanded $logoPlacer': {
            display: 'flex'
        },
        '& $logoPlacer': {
            display: 'none',
            flexGrow: '1',
            justifyContent: 'center',
            alignItems: 'center',
            width: '200px',
            '& a': {
                display: 'inline-block',
            }
        },
        '& $headerWrapper': {
            display: 'flex',
            fontSize: '1rem',
            flexGrow: '50',
            alignItems: 'center',
            backgroundColor: 'white',
            boxShadow: '1px 3px 2px 0px rgba(0,0,0,0.2)',
            justifyContent: 'space-between',
            '& $headerLeftWrapper': {
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: '30px',
                '& .customLogo': {
                    marginLeft: '30px'
                },
                '& .person': {
                    marginLeft: '30px'
                },
                '& .customLogo + .person': {
                    marginLeft: '16px'
                },
                '& .search': {
                    marginLeft: '30px'
                }
            },
            '& $headerRightWrapper': {
                maxHeight: '100%',
                paddingRight: 30,
                display: 'flex',
                flexDirection: 'row',
                '& .language-wrapper': {
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    maxHeight: '100%',
                    marginRight: '2.5rem',
                    paddingRight: '1rem',
                    color: '#1291F9',
                    '&:after': {
                        position: 'absolute',
                        display: 'block',
                        content: `''`,
                        right: 0,
                        top: 0,
                        width: '0.7rem',
                        height: '100%',
                        backgroundImage: 'url("/assets/img/newui/header/language-dropdown-nav.svg")',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        transition: '0.3s ease-in-out'
                    },
                    '&:hover': {
                        '& .language-list': {
                            display: 'block',
                        },
                        '&:after': {
                            transform: 'rotate(-180deg)'
                        }
                    },
                    '& .language-list': {
                        display: 'none',
                        position: 'absolute',
                        top: '100%',
                        listStyle: 'none',
                        padding: '0.5rem 1rem',
                        margin: 0,
                        width: 150,
                        left: 0,
                        color: '#000',
                        backgroundColor: 'white',
                        borderRadius: '0.5rem',
                        boxShadow: '2px 0 5px 0px rgba(0, 0, 0, 0.4);',
                        '&:before': {
                            position: 'absolute',
                            content: `''`,
                            top: '-4px',
                            width: 0,
                            height: 0,
                            borderStyle: 'solid',
                            borderWidth: '0 6px 6px 6px',
                            borderColor: 'transparent transparent #ffffff transparent'
                        },
                        '& li': {
                            cursor: 'pointer',
                            '&.active': {
                                color: '#1291f9',
                            },
                            '&:hover': {
                                color: '#1291f9',
                            }
                        }
                    }
                },
                '& .calendar-wrapper': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '2.5rem',
                },
                '& .notification-wrapper': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '2.5rem',
                    cursor: 'pointer',
                    position: 'relative'
                },
                '& .icon-with-badge': {
                    display: 'flex',
                    position: 'relative',
                    '& .badge': {
                        padding: 0,
                        minWidth: 9,
                        height: 9,
                        width: 9,
                        fontWeight: 400,
                        borderRadius: '9px',
                        position: 'absolute',
                        backgroundColor: '#ee2337',
                        color: 'white',
                        fontSize: '11px',
                        lineHeight: '16px',
                        top: -4,
                        right: -4,
                        display: 'block'
                    }
                }
            },
        },
    },

    /**
     * Css all classes for assign class
     */
    expanded: {},
    logoPlacer: {},
    headerWrapper: {},
    headerLeftWrapper: {},
    headerRightWrapper: {},


    /**
     * Old css code
     */
    totalHeaderContainer: {
        paddingTop: '5rem'
    },
    headerContainer: {
        position: 'fixed',
        top: '0',
        width: '100%',
        background: 'white',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 55px',
        boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.20)',
        zIndex: '100'
    },
    headerLogoImage: {
        width: '162px',
        height: '59px',
    },
    headerButtonContainer: {
        right: '55px',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    headerButtonImg: {
        marginRight: '25px',
        '&:hover' : {
            cursor: 'pointer',
        }
    },
    spanUserName: {
        fontSize: '1.6rem',
        color: '#707070',
        display: 'inline-block',
        marginRight: '25px',
        height: '100%',
        color: '#707070',
        fontFamily: 'Mulish'
    },
    welcomeDiv: {
        color: '#A30C0C',
        fontFamily: 'Mulish',
        fontSize: '1.4rem',
        height: '2rem',
        margin: '1.5rem 0 1rem 0',
        paddingLeft: '55px',
        paddingRight: '55px'
    },
    notificationList: {
        width: 250,
        maxHeight: 300,
        position: 'absolute',
        top: 45,
        right: -15,
        background: 'white',
        border: '1px solid #DEDEDE',
        borderRadius: 3,
        zIndex: 10,
        boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.3)',
        '&:before': {
            content: `''`,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 10px 10px 10px',
            borderColor: 'transparent transparent #1D8ACF transparent',
            position: 'absolute',
            top: -10,
            right: 13,
        }
    },
    notificationScroll: {
        width: '100%',
        maxHeight: 300,
        overflowY: 'auto',
    },
    notification: {
        display: 'block',
        width: '100%',
        padding: '5px 10px',
        borderBottom: '1px solid #DEDEDE',
    },
    notificationDescription: {
        fontSize: 13,
        opacity: 0.8,
        paddingLeft: 10,
    },
    notificationTime: {
        fontSize: 12,
        textAlign: 'right',
        color: '#39B54A'
    },
    notificationTitle: {
        color: '#1D8ACF'
    }
});
export default whiteHeaderStyle;