import commonStyle from 'assets/jss/commonStyle'

const buyRequestPageStyle = theme => ({
    ...commonStyle,
    contentContainer: {
        background: 'white',
        borderRadius: 7,
        padding: '20px 30px',
        border: '1px solid rgba(18, 145, 249, 0.18)'
    },
    redTitle: {
        color: '#A30C0C',
        fontSize: '1.4rem',
        textAlign: 'center',
    },
    input: {
        width: '100%',
        '&:disabled': {
            background: '#F2F2F2',
            'borderWidth': '0'
        }
    },
    btnSubmit: {
        background: '#1291F9',
        color: 'white',
        height: 34,
        width: '120px',
        display: 'inline-block',
        fontSize: 14,
        borderRadius: 20,
        width: 125,
        textAlign: 'center',
        '&:hover': {
            color: 'white'
        }
    },
    uploadAttachmentContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: 20,
    },
    addIcon: {
        color: '#1D8ACF',
    },
    attachmentImg: {
        width: '26px',
        height: '26px',
        minWidth: '26px',
        minHeight: '26px',
        marginRight: '20px',
    },
    attachmentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        margin: '12px 0px',
        paddingLeft: 15,
    },
    Select: {
        color: "red",
        fontSize: '1.3rem',
        textAlign: 'center',
        borderRadius: '6px',
        opacity: '0.85',
        '&:hover': {
            opacity: '1',
            cursor: 'pointer'
        },
        border: 'none',
        '&:active': {
            outline: 'none',
            border: 'none'
        },
        '&[disabled]': {
            backgroundColor: '#af5555'
        },
        '&[disabled]:hover': {
            opacity: '0.85',
        }
    },
    inputLabel: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    formInputItem: {
        paddingBottom: '0 !important'
    },
    btnSign: {
        margin: '15px 0px'
    }
});
export default buyRequestPageStyle;