import { CM_ACTION_W9SETUP } from '../actions/types';

const initialState = {
    commonResponse: {}
};

export const commonResponse = (state = initialState, action) => {
  switch (action.type) {
    case CM_ACTION_W9SETUP:
      return {
        ...state,
        commonResponse: action.payload
      };
    default:
      return state;
  }
};
