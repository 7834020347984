
const RecentContactStyle = theme => ({
    RecentContactContainer: {
        height: '100%',
        '& .tabs-wrapper': {
            background: 'white',
            boxShadow: 'none',
            '& .tab-item': {
                minWidth: '33.33%',
                maxWidth: '100px',
                height: '80px',
                maxHeight: '80px',
                '&:focus': {
                    outline: 'none'
                }
            },
            '& .MuiTabsIndicator': {
                backgroundColor: '#555'
            }
        },
        '& .tab-main-content': {
            borderTop: '1px solid #e1e1e1',
            marginTop: '-1px'
        },
        '& .contacts-container': {
            maxHeight: 'calc(100% - 90px)',
            overflowY: 'auto'
        }
    }
});
export default RecentContactStyle;