import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import history from "../../utils/history";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import BaseInput from "components/BaseInput";
import { userLogout } from "../../redux/actions/accountActions";
import { getGlobalInitialData } from "redux/actions/commonActions";
import { withStyles } from "@material-ui/core";
import avatarStyle from "./avatarStyle.jsx";

class Avatar extends Component {
  state = {
    loginInfo: {
      user: {
        first_name: "",
        last_name: ""
      }
    },
    customerInfo: null
  };

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"]
    };
    this.state.user = this.props.user;
  }

  handleClickLogout = e => {
    let customerInfo = this.props.userLogout();
    this.setState({ customerInfo });

    let loginPath = '/login';

    history.push(loginPath);
  };

  renderActiveBubble = () => {
    const { hideBubble, classes, topBubble } = this.props;
    let active = false;
    if (typeof this.props.user != 'undefined') {
      active = this.props.user.profile['is_online'];
    }
    
    if (hideBubble) {
        return null;
    }

    if (topBubble) {
        return (
            <div className={ classes.statusBubble  }>
                <div className={ (active ? ' active' : '') }></div>
            </div>
        )
    }
    return (
        <div className={`user-activity ${active ? ' active' : ''}`}></div>
    )
  }

  renderUserImage = () => {
    if (this.user.profile && this.user.profile.avatar) {
        return (
            <img src={ this.user.profile.avatar } style={{ width:'100%', objectFit: 'contain', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '100%'  }}/>
        )
    }
    let user = this.user;

    const userNameInitial =
      user != null
        ? user["first_name"].charAt(0) +
          user["last_name"].charAt(0)
        : "";
    return (
        <div className={`avatar-initial`}>{userNameInitial}</div>
    );
  }

  render() {
    const { classes, showMenu, showName, size } = this.props;
    this.user = this.props.user;

    if (typeof this.user === 'undefined') {
        this.user = this.props["account"]["loginStatus"]['user'];
    }

    let user = this.user;
    const userName =
      user != null
        ? user["first_name"] +
          " " +
          user["last_name"]
        : "";
    return (
      <div className={classes.container}>
        {showName && <span style={{ marginRight: 16 }}>{ userName }</span>}
        <div className={ size === 'small' ? `avatar-img` : 'avatar-img avatar-img-medium' }>
          <div className={`avatar-img-inner ${showMenu ? "cursor" : ""}`}>
          {
            this.renderUserImage()
          }
          </div>
          {
              this.renderActiveBubble()
          }
          {showMenu ? (
            <ul className={`profile-menu z-50`}>
              <li>
                <NavLink
                  to="/profile"
                  activeClassName={`active`}
                  className={`nav-item-inner`}
                >
                  <span className={`w-6 h-6 mr-2 inline-block`} style={{ minWidth: 30 }}>
                    <img className={`max-w-full inline`} src="/assets/img/newui/header/person.png" alt="D" />
                  </span>
                  Profile
                </NavLink>
              </li>
              <li>
                <a href="#" onClick={e => this.handleClickLogout(e)}>
                  <span className={`w-6 h-6 mr-2 inline-block`} style={{ minWidth: 30 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ marginLeft: -1 }}>
                    <g data-name="Layer 2">
                      <g data-name="log-out">
                        <rect
                          width="24"
                          height="24"
                          opacity="0"
                          transform="rotate(90 12 12)"
                        />
                        <path d="M7 6a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2H6V6zM20.82 11.42l-2.82-4a1 1 0 0 0-1.39-.24 1 1 0 0 0-.24 1.4L18.09 11H10a1 1 0 0 0 0 2h8l-1.8 2.4a1 1 0 0 0 .2 1.4 1 1 0 0 0 .6.2 1 1 0 0 0 .8-.4l3-4a1 1 0 0 0 .02-1.18z" />
                      </g>
                    </g>
                  </svg>
                  </span>
                  Logout
                </a>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList
  };
};

const mapDispatchToProps = { getGlobalInitialData, userLogout };

Avatar.defaultProps = {
    size: 'small',
    active: false,
    hideBubble: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(avatarStyle)(Avatar));
