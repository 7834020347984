import * as Type from './types';
import Cookies from 'js-cookie';


// Global Get Organization List
export const getOrganizationList = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/get_organization_list', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ORG_LIST,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ORG_LIST,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_ORG_LIST,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
        'list': []
      }
    });
  });
};

// Global Get Customers Data
export const getCustomersData = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  return fetch(apiUrl + 'api/common/get_customers', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    }
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status) {
        dispatch({
          type: Type.CM_CUSTOMER_DATA,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_CUSTOMER_DATA,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_CUSTOMER_DATA,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
        'categGroup': [],
        'buyers': [],
        'vendors': []
      }
    });
  });
};

// Global Get Customer Info with permallink
export const getCustomerInfo = (permallink) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  return fetch(apiUrl + 'api/common/get_customer_info', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify({permallink})
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status) {
        dispatch({
          type: Type.CM_CUSTOMER_INFO,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_CUSTOMER_INFO,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_CUSTOMER_DATA,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
        'categGroup': [],
        'buyers': [],
        'vendors': []
      }
    });
  });
};

// Global Get Initial Data
export const getGlobalInitialData = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/get_initial_data', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_INIT_DATA,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_INIT_DATA,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_INIT_DATA,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
        'categGroup': [],
        'buyers': [],
        'vendors': []
      }
    });
  });
};


// Get Invoice Item Data
export const getInvoiceData = (invoiceId) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/invoice', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        invoiceId: invoiceId
      })
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_RESULT,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
        'data': null
      }
    });
  });
};

// Vendor Side Setup W9 Form
export const setupW9Form = (postData) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/w9form_setup', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    if (res.status >= 400) {
      //throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
    }else{
      return res.json();
    }
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_ACTION_W9SETUP,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
      }
    });
  });
};

// Vendor Side search invoice by key
export const searchInvoiceKey = (key) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/search_invoice_key', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({key: key})
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_ACTION_W9SETUP,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};

// Vendor Side Raise Invoice
export const submitInvoice = (postData) => (dispatch, getState) => {

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const loginStatus = getState()['account']['loginStatus'];

    return fetch(apiUrl + 'api/common/invoice_submit', {
        method: 'POST',
        headers: {
          'Authorization': 'Token ' + loginStatus.token,
          'content-type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
        },
        body: JSON.stringify(postData)
    })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
        if(result.status){
          dispatch({
            type: Type.CM_ACTION_W9SETUP,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_ACTION_W9SETUP,
            payload: result
          });
        }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_ACTION_W9SETUP,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
    });
};

// Invoice Print Action
export const printInvoice = (invoiceKey, emailList) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  var postData = {
    invoiceKey: invoiceKey,
    emails: emailList
  }

  return fetch(apiUrl + 'api/common/print/invoice', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_PRINT_PDF,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_PRINT_PDF,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_PRINT_PDF,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
      }
    });
  });
};

// Invoice Print Action
export const printInvoiceSendEmail = (invoiceKey, emailList, fileUrl) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  var postData = {
    invoiceKey: invoiceKey,
    emails: emailList,
    pdfFile: fileUrl
  }

  return fetch(apiUrl + 'api/common/send_email/invoice', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_SEND_INVOICE,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_SEND_INVOICE,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_SEND_INVOICE,
      payload: {
        'status': false,
        'message': 'Backend Error Occured',
      }
    });
  });
};

// Buyer Side Submit Buy Request (Create Invoice)
export const submitBuyRequest = (postData) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/buy_request_submit', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_ACTION_W9SETUP,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_ACTION_W9SETUP,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};

// Buyer Side Submit Buy Request (Create Invoice)
export const reviewInvoiceList = () => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/common/invoice', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
  })
  .then(res => {
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_INVOICE_LIST,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_INVOICE_LIST,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_INVOICE_LIST,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};

// Clear commont response
export const clearCommonResponse = () => (dispatch) => {
  dispatch({
    type: Type.CM_RESULT_CLEAR,
  })
}