import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import reviewInvoicesPageStyle from "./reviewInvoicesPageStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BaseInput from "components/BaseInput/index.jsx";
import Button from "components/Button/index.jsx";
import InvoiceDropDown from "components/InvoiceDropDown/index.jsx";
import { Link, NavLink } from "react-router-dom";
import Cookies from "js-cookie";

import { reviewInvoiceList } from "../../redux/actions/commonActions";

import Dialog from "@material-ui/core/Dialog";
import Modal from "@material-ui/core/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MatButton from "components/MatButton/index.jsx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";
import AutocompleteSelect from "components/AutocompleteSelect/index.jsx";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import Moment from "react-moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { confirm } from "../../components/Confirm/index";

import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Loading from "components/Loading";
import { uploadFiles } from "../../redux/actions/fileActions";
import {
  submitInvoice,
  searchInvoiceKey,
  printInvoice,
  printInvoiceSendEmail,
} from "../../redux/actions/commonActions";

import { withTranslation  } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { withRouter } from 'react-router';
import SearchBar from "components/SearchBar/index.jsx";

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

function getPrintModalStyle() {
  const top = 5;
  const left = 50;
  return {
    position: "absolute",
    maxWidth: "40rem",
    width: "34rem",
    /* maxHeight: '300px', */
    margin: "0 auto",
    top: "5%",
    left: "calc(50% - 17rem)",
    maxHeight: "90%",
    overflowY: "auto",
    backgroundColor: "white",
    padding: "1.5rem 2rem",
  };
}

class ReviewInvoicesPage extends Component {

  fields = {};

  state = {
    pageType: "",
    showOnlyPending: false,
    pageListType: "requested",
    paginationInfo: {
      perPage: 30,
      pageNum: 0,
    },
    apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
        profile: {
          user_type: "BUYER",
        },
      },
    },
    invoiceCount: 0,
    invoiceData: [],
    detailData: null,
    existing_attachments: [],
    attachments: [],
    detailModalOpen: false,
    isPrintModalOpened: false,
    pdfSendEmailList: [],
    loading: true,
    query: ''
  };

  constructor(props) {
    super(props);

    let pageType = this.props.match.params["type"];
    if (pageType == "list") {
      this.state.pageListType = "pending";
    }

    this.loadingData();
  }

  componentDidMount() {
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };
  }

  loadingData = async () => {
    const loginStatus = this.props.account.loginStatus;
    this.setState({ loading: true });
    fetch(apiUrl + "api/invoice/track/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginStatus.token,
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: JSON.stringify({
        type: this.state.pageListType,
        start:
          this.state.paginationInfo.perPage * this.state.paginationInfo.pageNum,
        count: this.state.paginationInfo.perPage,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result["status"]) {
          this.setState({ loading: false });
          this.setState({ invoiceCount: result["totalCount"] });
          this.setState({ invoiceData: result["data"] });
        }
      });
  };

  clickListType(e, type) {
    this.setState({ pageListType: type });
    this.setState({
      paginationInfo: Object.assign(this.state.paginationInfo, {
        pageNum: 0,
      }),
    });
    this.setState({ invoiceCount: 0 });
    setTimeout(() => {
      this.loadingData();
    }, 100);
  }

  renderNewInvoiceButton = () => {
    const { classes, t } = this.props;
    let userType = this.state.user ? this.state.user.profile.user_type : "";
    if (userType == "VENDOR") {
      return (
        <div className={classes.raiseNew}>
          <NavLink to="/buyer/buy-request">
            <Button
              className={classes.btnSubmit}
              onClick={this.handleClickSubmit}
              ref={(ref) => {
                this.submitButton = ref;
              }}
            >
              {t('Raise New Invoice')}
            </Button>
          </NavLink>
        </div>
      );
    } else {
      return null;
    }
  };

  renderList() {
    const { classes } = this.props;
    let pageType = this.props.match.params["type"];

    if (pageType == "list") {
      return (
        <div className={classes.formContainer}>
          <InvoiceDropDown
            iconUrl="/assets/img/calendar.png"
            category="Invoice Upload Request"
            invoices={this.props.invoiceResult.data.pendings}
            onInvoiceChanged={this.loadingData}
            canRaiseInvoice="VENDOR"
          />
          {this.renderNewInvoiceButton()}
        </div>
      );
    } else {
      return (
        <div className={classes.formContainer}>
          <InvoiceDropDown
            iconUrl="/assets/img/calendar.png"
            category="Request for Approval – Direct"
            invoices={this.props.invoiceResult.data.requested}
            onInvoiceChanged={this.loadingData}
          />
          <InvoiceDropDown
            iconUrl="/assets/img/calendar.png"
            category="Invoice Upload Request"
            invoices={this.props.invoiceResult.data.pendings}
            onInvoiceChanged={this.loadingData}
            canRaiseInvoice="VENDOR"
          />
          <InvoiceDropDown
            iconUrl="/assets/img/shopping-list.png"
            category="Payment Processing"
            invoices={this.props.invoiceResult.data.submitted}
            onInvoiceChanged={this.loadingData}
          />
          <InvoiceDropDown
            iconUrl="/assets/img/tick-inside-circle.png"
            category="Payment Completed (Please allow 5-7 working days for funds to reach bank account)"
            invoices={this.props.invoiceResult.data.payed}
            onInvoiceChanged={this.loadingData}
          />
          {this.renderNewInvoiceButton()}
        </div>
      );
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      paginationInfo: Object.assign(this.state.paginationInfo, {
        pageNum: newPage,
      }),
    });
    this.loadingData();
  };

  handleClickEdit = (event, canEdit, invoice) => {
    if (!canEdit) {
      return;
    }

    this.props.history.push('/invoice/edit/' + invoice.id);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      paginationInfo: Object.assign(this.state.paginationInfo, {
        perPage: event.target.value,
      }),
    });
    this.loadingData();
  };

  renderInvoice(invoice, key) {
    const { classes, t } = this.props;
    const userType = this.state.loginInfo["user"]["profile"]["user_type"];
    const { pageListType } = this.state;

    const tblColumns = [
      { id: "details", label: t("Details"), align: 'center' },
      { id: "name", label: t("Vendor Name"), align: 'center' },
      { id: "date", label: t("Date"), align: 'center' },
      { id: "value", label: t("$"), align: 'center'},
      { id: "view", label: t("View"), align: 'center'},
      {
          id: "action",
          label: t("Actions"),
          align: 'center'
      },
  ];

    let canEdit = userType === 'ADMIN' ? true : false;

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={key}
        class={canEdit ? `cursor-pointer`: ''}>
        {tblColumns.map((column) => {
          let creator = invoice["vendor"];
          if (
            invoice.buyer &&
            invoice.buyer != null &&
            invoice.created_by == invoice.buyer.id
          ) {
            creator = invoice["buyer"];
          }
          var files = invoice.attachments.split(",");
          let type = invoice.type;
          if (type == "DebitNote") type = t("Debit Note");
          if (type == "CreditNote") type = t("Credit Note");

          if (column.id == 'name') {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none', minWidth: 300 }}
                width="40%"
              >
                { invoice.vendor.first_name } { invoice.vendor.last_name }
              </TableCell>
            )
          } else if (column.id == "details") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none', minWidth: 300 }}
                width="40%"
              >
                <div>
                  <div>
                    Invoice Inv#{ invoice.key }
                  </div>
                  <div>
                    Ev#{ invoice.eventId } | { invoice.category }
                  </div>
                </div>
              </TableCell>
            );
          } else if (column.id == "code") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none' }}
              >
                {invoice.approved_status == "Approved" ? (
                  <span>{invoice.key}</span>
                ) : (
                  <span>
                    {type}  {invoice.approved_status}
                  </span>
                )}
              </TableCell>
            );
          } else if (column.id == "title") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none' }}
              >
                {invoice.sub_category} / {invoice.category}
                <br />
                Ev#{invoice.eventId}
              </TableCell>
            );
          } else if (column.id == "value") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none' }}
              >
                ${invoice.invoiceValue}
              </TableCell>
            );
          } else if (column.id == "date") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ wordBreak: "break-all", background: 'white', fontSize: 12, color: '#000', border: 'none' }}
              >
                <Moment format="MMM, D, YYYY">{invoice.created_at}</Moment>
              </TableCell>
            );
          } else if (column.id == "status") {
            return (
              <TableCell
                onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                key={column.id}
                align={column.align}
                size='small'
                style={{ background: 'white', fontSize: 12, color: '#000', border: 'none' }}
              >
                <span
                  className={`px-2 bg-blue-300`}
                  style={{ background: 'rgba(0, 153, 255, 0.2)', borderRadius: 7, color: '#1291F9', padding: '3px 0px' }}
                >
                  {pageListType === 'payed' ? 'Paid' : invoice.approved_status}
                </span>
              </TableCell>
            );
          } else if (column.id == "action") {
            return (
              <TableCell key={column.id} align={column.align} style={{ background: 'white', border: 'none' }} size='small'>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                {invoice.approved_status == "Approved" &&
                !invoice.is_submitted &&
                userType == "VENDOR" ? (
                  <IconButton
                    onClick={(e) => this.showDetail(e, invoice)}
                    aria-label="Details"
                    color="secondary"
                  >
                    <img src="https://img.icons8.com/small/16/000000/details.png" />
                  </IconButton>
                ) : null}
                {userType == "BUYER" &&
                invoice.approved_status == "Rejected" ? (
                  <Link to={"/buyer/review-invoices/request/" + invoice.id}>
                    <IconButton aria-label="Edit" color="secondary">
                      <img src="https://img.icons8.com/material-sharp/24/000000/edit.png" />
                    </IconButton>
                  </Link>
                ) : null}
                {
                  userType === 'ADMIN' &&
                    this.renderAdminAction(invoice)
                }
                {this.showAttachFiles(files)}
                </div>
              </TableCell>
            );
          } else if (column.id == 'view') {
              return (
                <TableCell
                  key={ column.id }
                  style={{ background: 'white', border: 'none' }}
                  size='small'
                  align={column.align}
                  onClick={e => this.handleClickEdit(e, canEdit, invoice)}
                >
                  <Button>
                    <img src="/assets/img/newui/eye.png" className="mx-auto" />
                  </Button>
                </TableCell>
              )
          } else {
            return <TableCell key={column.id} align={column.align} style={{ background: 'white' }}></TableCell>;
          }
        })}
      </TableRow>
    );
  }

  renderAdminAction = (invoice) => {
    const { pageListType } = this.state;

    switch (pageListType) {
      case 'requested':
        return (
          <div className="flex">
            <Tooltip title="Approve">
              <Button style={{ marginRight: 10, }} onClick={ () => this.approveInvoice(invoice) }>
                <img src="/assets/img/newui/checked.png"  style={{ width: 15, height: 15 }} />
              </Button>
            </Tooltip>
            <Tooltip title="Reject">
              <Button onClick={ () => this.rejectInvoice(invoice) }>
                <img src="/assets/img/newui/close.png" style={{ width: 15, height: 15 }} />
              </Button>
            </Tooltip>
          </div>
        )
      case 'submitted':
        return (
          <div className="flex">
            <Tooltip title="Mark as paid">
              <Button style={{ marginRight: 10, }} onClick={ () => this.markAsPaid(invoice) }>
                <img src="/assets/img/newui/checked.png"  style={{ width: 15, height: 15 }} />
              </Button>
            </Tooltip>
          </div>
        )
      default:
        return null;
    }
  }

  approveInvoice = (invoice) => {
    confirm(
      "Confirm",
      'Do you confirm the budget Approval?'
    ).then(
      async () => {
        const data = {
          'invoice_id': invoice.id
        }
        const { loginStatus } = this.props.account;
        return fetch(apiUrl + 'api/admin/approve/invoice', {
          'method': 'POST',
          'headers': {
            'Authorization': 'Token ' + loginStatus.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(result => result.json())
        .then(result => {
          if (result['status']) {
            this.loadingData();
          }
        });
      },
      () => {
      }
    );
  }

  markAsPaid = (invoice) => {
    confirm(
      "Confirm",
      'Do you want to mark the invoice as paid?',
      true,
      'Yes',
      'No'
    ).then(
      async () => {
        const data = {
          'invoice_id': invoice.id
        }
        const { loginStatus } = this.props.account;
        return fetch(apiUrl + 'api/admin/invoice/mark_as_paid', {
          'method': 'POST',
          'headers': {
            'Authorization': 'Token ' + loginStatus.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(result => result.json())
        .then(result => {
          if (result['status']) {
            this.loadingData();
          }
        });
      },
      () => {
      }
    );
  }

  rejectInvoice = (invoice) => {
    confirm(
      "Confirm",
      'Do you confirm the budget Rejection?'
    ).then(
      async () => {
        const data = {
          'invoice_id': invoice.id
        }
        const { loginStatus } = this.props.account;
        return fetch(apiUrl + 'api/admin/reject/invoice', {
          'method': 'POST',
          'headers': {
            'Authorization': 'Token ' + loginStatus.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(result => result.json())
        .then(result => {
          if (result['status']) {
            this.loadingData();
          }
        });
      },
      () => {
      }
    );
  }

  approveAll = () => {
    confirm(
      "Confirm",
      'Do you confirm the Approval of all requests?'
    ).then(
      async () => {
        const { loginStatus } = this.props.account;
        return fetch(apiUrl + 'api/admin/approve/all_invoice', {
          'method': 'POST',
          'headers': {
            'Authorization': 'Token ' + loginStatus.token,
            'Content-Type': 'application/json'
          },
        })
        .then(result => result.json())
        .then(result => {
          if (result['status']) {
            this.loadingData();
          }
        });
      },
      () => {
      }
    );
  }

  rejectAll = () => {
    confirm(
      "Confirm",
      'Do you confirm the Rejection of all requests?'
    ).then(
      async () => {
        const { loginStatus } = this.props.account;
        return fetch(apiUrl + 'api/admin/reject/all_invoice', {
          'method': 'POST',
          'headers': {
            'Authorization': 'Token ' + loginStatus.token,
            'Content-Type': 'application/json'
          },
        })
        .then(result => result.json())
        .then(result => {
          if (result['status']) {
            this.loadingData();
          }
        });
      },
      () => {
      }
    );
  }

  showAttachFiles = (files) => {
    if (typeof files != "undefined" && files != null) {
      return files.map((file, key) => {
        if (file == "") return null;
        return (
          <span key={key} style={{ paddingLeft: "0.5rem" }}>
            {this.showEachAttachFiles(file)}
          </span>
        );
      });
    } else {
      return null;
    }
  };

  showEachAttachFiles = (file) => {
    const { classes } = this.props;

    var filebasename = file.substring(file.lastIndexOf("/") + 1);
    var filebasenameUrl = encodeURIComponent(filebasename);
    return (
      <a
        href={this.state.apiUrl + "api/download/file/" + filebasenameUrl}
        style={{ color: "#333" }}
        target="_blank"
      >
        <AttachFileIcon style={{ transform: "rotate(45deg)", fontSize: 20 }} className={ classes.attachFile } />
      </a>
    );
  };

  // Show details dialog
  /* showDetail = (e, invoice) => {
    let canRaiseType = "VENDOR";

    if (this.state.loginInfo["user"]["profile"]["user_type"] != canRaiseType) {
      return;
    }

    let detailData = cloneDeep(invoice);
    let buyerId = detailData["buyer"]["id"];
    detailData.buyer = buyerId;
    this.setState({ detailData: detailData });
    this.setState({ detailModalOpen: true });
  };
  closeDetail = () => {
    this.setState({ detailData: null });
    this.setState({ detailModalOpen: false });
  }; */

  // Show buyer list function
  getEventOptions = () => {
    const { classes } = this.props;
    if (
      typeof this.props.initialResponse.data != "undefined" &&
      this.props.initialResponse.data.status &&
      typeof this.props.initialResponse.data.events != "undefined"
    ) {
      var optjionGroup = this.props.initialResponse.data.events;
      return optjionGroup.map((item, key) => ({
        label: item.name,
        value: item.name,
      }));
    } else {
      return [];
    }
  };

  // Show buyer list function
  getBuyerOptions = () => {
    const { classes } = this.props;
    if (
      typeof this.props.initialResponse.data != "undefined" &&
      this.props.initialResponse.data.status &&
      typeof this.props.initialResponse.data.buyers != "undefined"
    ) {
      var optjionGroup = this.props.initialResponse.data.buyers;
      return optjionGroup.map((item, key) => ({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      }));
    } else {
      return [];
    }
  };

  // Get current sub category list
  getSubCategorySelect = () => {
    const { classes } = this.props;
    if (
      typeof this.props.initialResponse.data != "undefined" &&
      this.props.initialResponse.data.status
    ) {
      if (
        typeof this.state.detailData.category == "undefined" ||
        this.state.detailData.category == null ||
        this.state.detailData.category == ""
      )
        return null;
      var mainCateg = this.state.detailData.category;
      var mainCategGroup = this.props.initialResponse.data.categGroup;
      var categGroup = [];
      mainCategGroup.forEach((item, key) => {
        if (item.categ.name == mainCateg) categGroup = item["subCateg"];
      });
      var retVal = categGroup.map((item, key) => ({
        label: item.name,
        value: item.name,
      }));
      if (typeof retVal == "undefined" || retVal == null) {
        retVal = [];
      }
      return retVal;
    } else {
      return [];
    }
  };

  // Show existing attachments
  renderExistingAttachments = (existing_attachments) => {
    const { classes } = this.props;
    return existing_attachments.map((filename, key) => {
      var filebasename = filename.substring(filename.lastIndexOf("/") + 1);
      var filebasenameUrl = encodeURIComponent(filebasename);
      var apiUrl = this.state.apiUrl;
      if (filebasename == "") {
        return null;
      } else {
        return (
          <div className={classes.attachmentContainer} key={key}>
            <img
              src="/assets/img/attachment-active.png"
              alt="attachment"
              className={classes.attachmentImg}
            />
            {filebasename}&nbsp;
            <a
              href={apiUrl + "api/download/file/" + filebasenameUrl}
              target="_blank"
            >
              <SaveAltIcon />
            </a>
          </div>
        );
      }
    });
  };

  // Show category list function
  drawCategorySelect = () => {
    const { classes } = this.props;

    if (
      typeof this.props.initialResponse.data != "undefined" &&
      this.props.initialResponse.data.status
    ) {
      var categGroup = this.props.initialResponse.data.categGroup;
      return categGroup.map((item, key) => {
        return (
          <option key={key + 1} value={item.categ.name}>
            {item.categ.name}
          </option>
        );
      });
    } else {
      return null;
    }
  };

  // Form input chnage event
  handleChangeValue = (e, valueType) => {
    const dataOject = {};
    if (typeof e == "undefined" || e === null) {
      dataOject[valueType] = "";
    } else if (typeof e.value != "undefined") {
      dataOject[valueType] = e.value;
    } else if (
      typeof e.target.type != "undefined" &&
      e.target.type == "checkbox"
    ) {
      if (
        typeof this.state.detailData[valueType] != "undefined" &&
        this.state.detailData[valueType] == true
      ) {
        dataOject[valueType] = false;
      } else {
        dataOject[valueType] = true;
      }
    } else {
      dataOject[valueType] = e.target.value;
    }
    if (valueType == "category") {
      dataOject["sub_category"] = "";
    }
    this.setState({
      detailData: Object.assign(this.state.detailData, dataOject),
    });

    if (valueType == "key") {
      this.searchKeyExisting(e.target.value);
    }
  };

  // Show new added attachments
  renderAttachments = () => {
    const { classes } = this.props;

    let fileNames = [];
    if (this.state.attachments) {
      for (let i = 0; i < this.state.attachments.length; i++) {
        fileNames.push(this.state.attachments.item(i).name);
      }
    }
    return fileNames.map((filename, key) => {
      return (
        <div className={classes.attachmentContainer} key={key}>
          <img
            src="/assets/img/attachment-inactive.png"
            alt="attachment"
            className={classes.attachmentImg}
          />
          {filename}
        </div>
      );
    });
  };

  // Show Error Or Succes Message
  checkResponseOrError = () => {
    const { classes } = this.props;
    if (this.state.submitErrorMessage != "") {
      return (
        <span style={{ color: "#CC3333" }}>
          &nbsp;&nbsp;{this.state.submitErrorMessage}
        </span>
      );
    } else if (this.state.submitSuccessMessage != "") {
      return (
        <span style={{ color: "#33CC33" }}>
          &nbsp;&nbsp;{this.state.submitSuccessMessage}
        </span>
      );
    }
  };

  handleChangeQuery = (event) => {
    this.setState({ query: event.target.value })
}

  render() {
    const { classes, t } = this.props;
    const { invoiceData } = this.state;

    const userType = this.state.loginInfo["user"]["profile"]["user_type"];

    let pageType = this.props.match.params["type"];
    let showOnlyPending = false;
    if (pageType == "list") {
      showOnlyPending = true;
    }

    const tblColumns = [
        { id: "details", label: t("Details"), minWidth: 400, width: '40%' },
        { id: "name", label: t("Vendor Name"), align: 'center' },
        { id: "date", label: t("Date") },
        { id: "value", label: t("$")},
        { id: "view", label: t("View")},
        {
            id: "action",
            label: t("Actions"),
        },
    ];

    if (invoiceData.length === 0 && !this.state.loading) {
      return (
        <div className={`w-full min-h-full pb-8 ${classes.container}`} style={{ background: '#F2F2FF' }}>
          {this.showDetailDialog()}
          {this.renderPdfModal()}
          <div className={ classes.headerContainer }>
            <div className={ classes.titleContainer }>
              <div className={ classes.pageTitle }>
                  {t('Raise Invoice')}
              </div>
            </div>
            {userType == "VENDOR" ? (
                  <NavLink to="/buyer/buy-request">
                    <button
                      className={ classes.btnHeaderNav }
                    >
                      {t('Raise New Invoice')}
                    </button>
                  </NavLink>
                ) : null}
          </div>
          <div className={ classes.tabs }>
              {
                showOnlyPending ? null : (
                  <div className={ classes.tabButtons }>
                    <button
                      onClick={(e) => this.clickListType(e, "requested")}
                      className={`font-bold text-sm leading-4 py-2 px-3 rounded-md mr-3 focus:outline-none ${
                        this.state.pageListType == "requested"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Request for Approval')}
                    </button>
                    <button
                      onClick={(e) => this.clickListType(e, "submitted")}
                      className={`font-bold text-sm leading-4 py-2 px-3 rounded-md mr-3 focus:outline-none ${
                        this.state.pageListType == "submitted"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Payment Processing')}
                    </button>
                    <button
                      onClick={(e) => this.clickListType(e, "payed")}
                      className={`font-bold text-sm leading-4 py-2 px-3 rounded-md mr-3 focus:outline-none ${
                        this.state.pageListType == "payed"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Payment completed')}
                    </button>
                  </div>
                )
              }
              <div className={ classes.search }>
                <input
                  className={`w-32 border border-gray-200 rounded-sm h-8 ml-3`}
                />
              </div>
          </div>
          <div className={`w-full flex flex-col pt-3`}>
            <div style={{ background: '#FDF9F9' }} className='rounded-lg'>
            {/* Page List Type */}
            <div style={{
              minHeight: 250,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: 100,
              color: '#EE2337'
            }}>
              <ErrorOutlineIcon
                style={{ fontSize: "2.8rem", marginRight: "1rem" }}
              />
              There is not any invoice.
            </div>
          </div>
        </div>
      </div>
      );
    }

    if (true) {
      return (
        <div className={`w-full min-h-full pb-8 ${ classes.container }`} style={{ background: '#F2F2FF' }}>
          {this.showDetailDialog()}
          {this.renderPdfModal()}
          <div className={ classes.headerContainer }>
              <div className={ classes.pageTitle }>
                  {t('Raise Invoice')}
              </div>
              {
                userType === 'VENDOR' &&
                  <NavLink
                    to={'/buyer/buy-request'}
                    className={ classes.btnHeaderNav }
                  >
                    {t('Create Invoice')}
                  </NavLink>
              }
          </div>
          <div className={ classes.tabs }>
              {
                showOnlyPending ? null : (
                  <div className={ classes.tabButtons }>
                    <button
                      onClick={(e) => this.clickListType(e, "requested")}
                      className={`leading-4 px-3 mr-3 focus:outline-none ${
                        this.state.pageListType == "requested"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Request for Approval')}
                    </button>
                    <button
                      onClick={(e) => this.clickListType(e, "submitted")}
                      className={`leading-4 px-3 mr-3 focus:outline-none ${
                        this.state.pageListType == "submitted"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Payment Processing')}
                    </button>
                    <button
                      onClick={(e) => this.clickListType(e, "payed")}
                      className={`leading-4 px-3 mr-3 focus:outline-none ${
                        this.state.pageListType == "payed"
                          ? classes.btnTabActive
                          : classes.btnTab
                      } `}
                    >
                      {t('Payment completed')}
                    </button>
                  </div>
                )
              }
              <div className={ classes.search }>
                <SearchBar onChange={ this.handleChangeQuery } value={this.state.query}  />
              </div>
          </div>
          {
              userType === 'ADMIN' && this.state.pageListType == "requested" &&
                <div className={ classes.bulkActionContainer }>
                  <Button
                    className={ classes.btnBulkAction }
                    onClick={ this.approveAll }
                  >
                    Approve All
                  </Button>
                  <Button
                    className={ classes.btnBulkAction }
                    onClick={ this.rejectAll }
                  >
                    Reject All
                  </Button>
                </div>
            }
          <div className={`w-full flex flex-col`}>
            <div>
            {/* Page List Type */}
            {/* Invoice List */}
            <div className={`w-full flex-grow mt-3`}>
              <TableContainer className={ classes.tableContainer }>
                <Table
                  style={{
                    tableLayout: "fixed",
                    width: "100%",
                    minWidth: "1000px",
                  }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow style={{ fontWeight: '600 !important'}}>
                      {tblColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.headerAlign}
                          style={Object.assign(
                            column.minWidth
                              ? { minWidth: column.minWidth, fontSize: 16 }
                              : { fontSize: 16},
                            column.maxWidth
                              ? { maxWidth: column.maxWidth, fontSize: 16 }
                              : { fontSize: 16},
                            column.width ? { width: column.width, fontSize: 16 } : { fontSize: 16}
                          )}
                          className='bg-white text-center font-semibold'
                          width={ column.width }
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.renderContent()
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                labelRowsPerPage={t(`Invoices per Page`)}
                rowsPerPageOptions={[8, 10, 15, 20, 25, 30]}
                component="div"
                count={this.state.invoiceCount}
                rowsPerPage={this.state.paginationInfo.perPage}
                page={this.state.paginationInfo.pageNum}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              /> */}
            </div>
            </div>
          </div>
          {
            this.handleShowDetailDialog()
          }
        </div>
      );
    }
  }


  showInvoiceDetail = (e, invoice) => {
    this.setState({detailData: invoice});
    this.setState({detailModalOpen: true});
  }
  closeInvoiceDetail = () => {
    this.setState({detailData: null});
    this.setState({detailModalOpen: false});
  }

  // Invoice Detail Dialog
  handleShowDetailDialog = () => {
    const { classes, t } = this.props;
    if(typeof this.state.detailData == 'undefined' || this.state.detailData == null) return null;
    var invoice = this.state.detailData;
    let buyerName = typeof invoice.buyer != "undefined" ? invoice.buyer.first_name + ' ' + invoice.buyer.last_name : "";

    return (
        <Dialog
            open={this.state.detailModalOpen}
            onClose={this.closeInvoiceDetail}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="form-dialog-title">{t('Invoice Details')}: { (invoice.key == null || invoice.key == '')? t('No hash key yet'): '#' + invoice.key }</DialogTitle>
            <DialogContent>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        {t('Event ID')}: { invoice.eventId }
                    </Grid>
                    <Grid item xs={3}>
                        {t('Category')}: { invoice.category }
                    </Grid>
                    <Grid item xs={3}>
                        {t('SubCategory')}: { invoice.sub_category }
                    </Grid>
                    <Grid item xs={3}>
                        {t('Buyer')}: { buyerName }
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        {t('Vendor')}: { invoice.vendor.first_name } { invoice.vendor.last_name }
                    </Grid>
                    <Grid item xs={3}>
                        {t('Value')}: ${ invoice.invoiceValue }
                    </Grid>
                    <Grid item xs={3}>
                        {t('Status')}: { invoice.approved_status == 'Requested'? t('Requested for approval'): ( invoice.approved_status == 'Rejected'? t('Rejected by Admin') : (invoice.is_pay_hold ? t('Holded') : (invoice.is_payed)? t('Paid'): ( invoice.is_submitted? t('Submitted to pay'): t('Pending')))) }
                    </Grid>
                    <Grid item xs={3}>
                        {t('Attachments')}: { this.showAttachments(invoice) }
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <p>{t('Description')}</p>
                        <p>{ invoice.description }</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MatButton onClick={this.closeInvoiceDetail} color="primary">
                    {t('Close')}
                </MatButton>
            </DialogActions>
        </Dialog>
    );
}

  filteredInvoices = () => {
    const { invoiceData } = this.state;
    const { query } = this.state;

    if (query.length === 0) {
        return invoiceData;
    }

    const loweredQuery = query.toLowerCase();
    return invoiceData.filter(invoice => {
        if (invoice.category.toLowerCase().includes(loweredQuery)) {
            return true;
        }

        if (invoice.eventId.toLowerCase().includes(loweredQuery)) {
            return true;
        }

        if (invoice.key) {
            if (invoice.key.toLowerCase().includes(loweredQuery)) {
                return true;
            }
        }

        if (invoice.vendor.first_name) {
            if (invoice.vendor.first_name.toLowerCase().includes(loweredQuery)) {
                return true;
            }
        }

        if (invoice.vendor.last_name) {
            if (invoice.vendor.last_name.toLowerCase().includes(loweredQuery)) {
                return true;
            }
        }

        if (invoice.invoiceValue.toString().includes(loweredQuery)) {
            return true;
        }

        return false;
    });
  }
  renderContent = () => {
    const { loading, invoiceData } = this.state;

    if (loading) {
      return (
          <Loading uploading={ loading }/>
      );
    }

    return this.filteredInvoices().map((row, key) => {
      return this.renderInvoice(row, key);
    })
  }

  showAttachments = (invoice) => {
    var files = invoice.attachments.split(',');
    if(typeof files != 'undefined' && files != null){
        return files.map((file, key) => {
            if(file == '') return null;
            return (
                <span key={key} style={{paddingLeft: '0.5rem'}}>
                    { this.showEachAttachFiles(file) }
                </span>
            )
        })
    }else {
        return null;
    }
  }

  // Invoice Detail Dialog
  showDetailDialog = () => {
    const { classes } = this.props;
    if (!this.state.detailModalOpen) return null;
    if (
      typeof this.state.detailData == "undefined" ||
      this.state.detailData == null
    )
      return null;

    var invoice = this.state.detailData;

    var files = invoice["attachments"].split(",");

    var catOptions = this.drawCategorySelect();
    if (typeof catOptions == "undefined" || catOptions == null) {
      catOptions = [];
    } else {
      catOptions = catOptions.map((item) => ({
        label: item.props.value,
        value: item.props.value,
      }));
    }

    var files = invoice.attachments.split(",");
    return (
      <Dialog
        open={false}
        onClose={this.closeDetail}
        aria-labelledby="form-dialog-title"
        fullWidth={false}
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">
          {invoice.type} Details{" "}
          {invoice.type != "Invoice"
            ? " - Related Invoice Key: " + invoice.parent.key
            : ""}
        </DialogTitle>
        <DialogContent>
          <div
            className={classes.formContainer}
            ref={(ref) => {
              this.form = ref;
            }}
          >
            <GridContainer>
              <GridItem xs={12}>
                Enter key provided by your contact, you would have received an
                email
              </GridItem>
              <GridItem xs={12} className={classes.marginVertical_25}>
                <BaseInput
                  className={(classes.input, classes.fullInput, `w-full`)}
                  inputRef={(ref) => (this.fields["key"] = ref)}
                  onChange={(e) => this.handleChangeValue(e, "key")}
                  value={this.state.detailData.key}
                />
              </GridItem>
            </GridContainer>
            <GridContainer spacing={40}>
              <GridItem xs={3}>
                <GridContainer className={`pr-3`}>
                  <GridItem xs={12}>Event ID, Name</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    {/* <NativeSelect
                                          className={ `${classes.marginVertical_25} ${classes.select_elem} form-select` }
                                          inputRef={ ref => this.fields['eventId'] = ref }
                                          value={this.state.detailData.eventId}
                                          onChange={e => this.handleChangeValue(e, 'eventId')}
                                          name="eventId"
                                      >
                                          <option value={''}></option>
                                          <option value={123456}>123456</option>
                                          <option value={123457}>123457</option>
                                          <option value={123458}>123458</option>
                                          <option value={123459}>123459</option>
                                          <option value={123460}>123460</option>
                                      </NativeSelect> */}
                    <AutocompleteSelect
                      className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                      value={this.state.detailData.eventId}
                      options={this.getEventOptions()}
                      onChange={(e) => this.handleChangeValue(e, "eventId")}
                      name="event"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={3}>
                <GridContainer className={`pr-3`}>
                  <GridItem xs={12}>Product or Service Category</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    <AutocompleteSelect
                      className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                      value={this.state.detailData.category}
                      options={catOptions}
                      onChange={(selectedOption) =>
                        this.handleChangeValue(selectedOption, "category")
                      }
                      name="category"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={3}>
                <GridContainer className={`pr-3`}>
                  <GridItem xs={12}>Sub category</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    <AutocompleteSelect
                      className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                      value={this.state.detailData.sub_category}
                      options={this.getSubCategorySelect()}
                      onChange={(selectedOption) =>
                        this.handleChangeValue(selectedOption, "sub_category")
                      }
                      name="subCateg"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={3}>
                <GridContainer>
                  <GridItem xs={12}>Buyer/requestor</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    <AutocompleteSelect
                      className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                      value={this.state.detailData.buyer}
                      options={this.getBuyerOptions()}
                      onChange={(e) => this.handleChangeValue(e, "buyer")}
                      name="buyer"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={40}>
              <GridItem xs={9}>
                <GridContainer className={`pr-3`}>
                  <GridItem xs={12}>Short Decription</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    <BaseInput
                      className={(classes.input, classes.fullInput, `w-full`)}
                      inputRef={(ref) => (this.fields["description"] = ref)}
                      onChange={(e) => this.handleChangeValue(e, "description")}
                      value={this.state.detailData.description}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={3}>
                <GridContainer>
                  <GridItem xs={12}>Order value</GridItem>
                  <GridItem xs={12} className={classes.marginVertical_25}>
                    <BaseInput
                      type="number"
                      className={(classes.input, `w-full`)}
                      inputRef={(ref) => (this.fields["invoiceValue"] = ref)}
                      onChange={(e) =>
                        this.handleChangeValue(e, "invoiceValue")
                      }
                      value={this.state.detailData.invoiceValue}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem
                className={`${classes.marginVertical_25} ${classes.uploadAttachmentContainer}`}
                onClick={this.handleClickUploadAttachment}
              >
                <span className={classes.uploadAttachment}>
                  Upload attachment
                </span>
                <AddCircleOutlineIcon
                  className={classes.addIcon}
                  onClick={this.handleClick}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.attachmentSection}>
              {this.renderExistingAttachments(files)}
              {this.renderAttachments()}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          className={(classes.marginVertical_15, classes.action_bar)}
        >
          <GridContainer>
            <GridItem xs={8} className={classes.marginVertical_15}>
              <Button
                className={`ml-3 mt-3 text-white ml-3 w-24 bg-blue-800 text-base leading-4 py-2 px-3 rounded-sm disabled:opacity-25`}
                onClick={this.handleClickSubmit}
                ref={(ref) => {
                  this.submitButton = ref;
                }}
              >
                Submit
              </Button>
              {this.checkResponseOrError()}
            </GridItem>
            <GridItem
              xs={2}
              className={classes.marginVertical_15}
              style={{ textAlign: "right" }}
            >
              <Fab
                color="primary"
                onClick={this.showPrintModal}
                aria-label="Delete"
                className={(classes.fab, `focus:outline-none`)}
              >
                <PrintIcon />
              </Fab>
            </GridItem>
            <GridItem
              xs={2}
              className={classes.marginVertical_15}
              style={{ textAlign: "right" }}
            >
              <button
                onClick={this.closeDetail}
                className={`mt-3 text-white w-24 bg-red-800 text-base leading-4 py-2 px-3 rounded-sm disabled:opacity-25`}
              >
                Close
              </button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    );
  };

  handleClickSubmit = async () => {
    if (!this.checkFormValidation()) return;

    this.setState({ submitErrorMessage: "" });

    await this.props.uploadFiles(this.state.attachments, "invoice_attachments");
    this.handleFileUploadResponse(this.props.uploadResult);
  };

  // Check Form Validtion
  checkFormValidation = () => {
    let loginInfo = this.state.loginInfo;

    let formData = {};
    for (var field in this.fields) {
      formData[field] = this.fields[field].value;
    }

    formData.eventId =
      typeof this.state.detailData["eventId"] != "undefined"
        ? this.state.detailData["eventId"]
        : "";
    formData.category =
      typeof this.state.detailData["category"] != "undefined"
        ? this.state.detailData["category"]
        : "";
    formData.sub_category =
      typeof this.state.detailData["sub_category"] != "undefined"
        ? this.state.detailData["sub_category"]
        : "";
    formData.buyer =
      typeof this.state.detailData["buyer"] != "undefined"
        ? this.state.detailData["buyer"]
        : "";

    if (formData["key"].length < 1) {
      this.setState({
        submitErrorMessage: "Please input key provided by your contact",
      });
      return false;
    }
    /* if(formData['eventId'].length < 1){
          this.setState({'submitErrorMessage': 'Please input Event ID, Name'}); return false;
      } */
    if (formData["category"].length < 1) {
      this.setState({
        submitErrorMessage: "Please input Product or Service Category",
      });
      return false;
    }
    if (
      loginInfo.user.customer.permalink != "wic" &&
      formData["sub_category"].length < 1
    ) {
      this.setState({ submitErrorMessage: "Please select Sub Category" });
      return false;
    }
    if (formData["buyer"].length < 1) {
      this.setState({ submitErrorMessage: "Please input Buyer/requestor" });
      return false;
    }
    if (formData["description"].length < 1) {
      this.setState({ submitErrorMessage: "Please input Short Decription" });
      return false;
    }
    if (formData["invoiceValue"].length < 1) {
      this.setState({ submitErrorMessage: "Please input Invoice value" });
      return false;
    }
    /*if(this.state.attachments.length < 1 && this.state.existing_attachments.length < 1){
          this.setState({'submitErrorMessage': 'Please upload attachment file'}); return false;
      }*/

    return true;
  };

  // Form Submitting
  handleFileUploadResponse = async (response) => {
    var res = response["uploadResult"];
    if (res.status) {
      let formData = {};
      for (var field in this.fields) {
        formData[field] = this.fields[field].value;
      }
      formData["eventId"] =
        typeof this.state.detailData["eventId"] != "undefined"
          ? this.state.detailData["eventId"]
          : "";
      formData["category"] =
        typeof this.state.detailData["category"] != "undefined"
          ? this.state.detailData["category"]
          : "";
      formData["sub_category"] =
        typeof this.state.detailData["sub_category"] != "undefined"
          ? this.state.detailData["sub_category"]
          : "";
      formData["buyer"] =
        typeof this.state.detailData["buyer"] != "undefined"
          ? this.state.detailData["buyer"]
          : "";
      formData["existing_attachments"] = this.state.existing_attachments.join(
        ","
      );
      formData["attachments"] = res.files ? res.files.join(",") : "";

      await this.props.submitInvoice(formData);

      this.handleSubmitResponse(this.props.commonResponse);
    } else {
      this.setState({ submitErrorMessage: res["message"] });
    }
  };

  // Form Submit Result Processing
  handleSubmitResponse = (res) => {
    if (res.commonResponse.status) {
      var newItemId = res.commonResponse.affected_id;
      this.setState({ submitSuccessMessage: res.commonResponse.message });
      confirm(
        "Success",
        "Invoice submitted with the hashkey <" +
          this.state.detailData.key +
          ">",
        false
      ).then(
        async () => {
          // Click OK
          this.setState({ detailModalOpen: false });
          this.loadingData();
        },
        () => {
          // Click Cancel
        }
      );

      setTimeout(() => {
        this.setState({ submitSuccessMessage: "" });
      }, 4000);
    } else {
      this.setState({ submitErrorMessage: res.commonResponse.message });
    }
  };

  renderPdfModal() {
    const { classes } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.isPrintModalOpened}
        onClose={this.handlePrintModalClose}
      >
        <div style={getPrintModalStyle()} className={classes.paper}>
          <Grid container spacing={24}>
            <Grid item xs={10} style={{ marginLeft: 8, flex: 1 }}>
              <Typography variant="h6" id="modal-title">
                PDF Print
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <IconButton
                className={classes.iconButton}
                type="submit"
                onClick={(e) => this.handleDownloadPdfButton(e)}
                aria-label="Download"
              >
                <SaveAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          {this.renderPdfPrintResult()}
          <Typography
            variant="subtitle1"
            id="simple-modal-description"
            style={{ marginTop: "0.5rem" }}
          >
            Input emails that you want to send pdf file
          </Typography>
          <form onSubmit={(e) => this.addEmailToPDFSendList(e)}>
            <Paper
              elevation={1}
              style={{
                padding: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "0.5rem",
              }}
            >
              <InputBase
                className={classes.input}
                type="email"
                name="email"
                inputRef={(ref) => {
                  this.send_email = ref;
                }}
                placeholder="Input Email"
                style={{ marginLeft: 8, flex: 1 }}
                required
              />
              <IconButton
                className={classes.iconButton}
                type="submit"
                aria-label="Search"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Paper>
          </form>
          {this.renderPdfSendEmailList()}
          {this.renderPdfSendEmailButton()}
        </div>
      </Modal>
    );
  }

  // Print Modal show action
  showPrintModal = async () => {
    var invoiceKey = this.state.detailData.key;
    await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
    this.setState({ isPrintModalOpened: true });
  };
  // Print Modal closed
  handlePrintModalClose = () => {
    this.setState({ isPrintModalOpened: false });
  };

  // Pdf Send Email List Rendor function
  renderPdfSendEmailList = () => {
    const { classes } = this.props;
    if (this.state.pdfSendEmailList.length > 0) {
      return (
        <div style={{ minWidth: "100%" }} style={{ paddingTop: "1rem" }}>
          {this.renderPdfSendEmailListOptions()}
        </div>
      );
    } else {
      return null;
    }
  };
  renderPdfSendEmailListOptions = () => {
    const { classes } = this.props;
    return this.state.pdfSendEmailList.map((email, key) => {
      return (
        <Grid container spacing={24} key={key}>
          <Grid item xs={10} style={{ marginLeft: 8, flex: 1 }}>
            {email}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
              onClick={(e) => this.removeEmailToPDFSendList(e, key)}
              style={{ padding: "0" }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    });
  };

  renderPdfPrintResult = () => {
    const { classes } = this.props;
    if (
      typeof this.props.printResult.data.pdfUrl != "undefined" &&
      this.props.printResult.data.pdfUrl.length > 0
    ) {
      var pdfUrl =
        this.state.apiUrl + "api/preview/" + this.props.printResult.data.pdfUrl;
      return (
        <iframe
          border="0"
          id="pdfPrint"
          type="application/pdf"
          src={pdfUrl}
          width="100%"
          height="400"
        ></iframe>
      );
    } else {
      return (
        <iframe
          border="0"
          id="pdfPrint"
          type="application/pdf"
          width="100%"
          height="400"
        ></iframe>
      );
    }
  };
  renderPdfSendEmailButton = () => {
    const { classes } = this.props;
    return (
      <div>
        <p style={{ textAlign: "center", marginBottom: "0" }}>
          <Button
            className={(classes.button, classes.emailSendButton)}
            variant="outlined"
            color="primary"
            onClick={(e) => this.handleSendEmailButton(e)}
            disabled={this.state.sendEmailListLoading}
          >
            {this.state.sendEmailListLoading ? "Sending" : "Send"}
          </Button>
        </p>
        <p
          style={{
            display: this.state.sendEmailListError == "" ? "none" : "block",
            textAlign: "center",
            color: "#CC3333",
            marginBottom: "0",
          }}
        >
          {this.state.sendEmailListError}
        </p>
      </div>
    );
  };

  /*
   *
   * The end of Print Related Functions
   *
   **********************************************/

  // Pdf Send Email List Related
  addEmailToPDFSendList = (e) => {
    e.preventDefault();
    var emailList = this.state.pdfSendEmailList;
    emailList.unshift(this.send_email.value);
    this.send_email.value = "";
    this.setState({ pdfSendEmailList: emailList });
  };
  removeEmailToPDFSendList = (e, key) => {
    var emailList = this.state.pdfSendEmailList;
    emailList.splice(key, 1);
    this.setState({ pdfSendEmailList: emailList });
  };

  // Click PDF download button
  handleDownloadPdfButton = async (e) => {
    //var invoiceKey = this.state.formData.key;
    //await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
    this.processPDFPrintResult();
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
    invoiceResult: state.invoiceResult,
    initialResponse: state.initialResponse,
    account: state.account,
  };
};

const mapDispatchToProps = {
  reviewInvoiceList,
  uploadFiles,
  submitInvoice,
  searchInvoiceKey,
  printInvoice,
  printInvoiceSendEmail,
};

export default withRouter(withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(reviewInvoicesPageStyle)(ReviewInvoicesPage))));
