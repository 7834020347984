import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import sortBtnStyle from './sortBtnStyle'

class SortBtn extends Component {

    sortOptionChanged = () => {
        const { sortOptionChanged } = this.props;
        const { sortName, sortBy, isAscSort } = this.props;
        const isActive = sortName == sortBy;
        const newSort = !isActive ? true :
            isAscSort == null ? true :
            isAscSort? false : null;
        sortOptionChanged(sortName, newSort);
    }

    render() {
        const { classes, t } = this.props;
        const { sortName, sortBy, isAscSort } = this.props;
        const isActive = sortName == sortBy;
        return (
            <button className={``} onClick={this.sortOptionChanged}>
                {isActive && isAscSort != null ? 
                    isAscSort ? 
                        (
                            <svg width="16" height="16" viewBox="0 0 64 64"
                                xmlns="http://www.w3.org/2000/svg" style={{fill: 'white'}}>
                                <path d="m32 8c-1.104 0-2 .896-2 2v39.899l-14.552-15.278c-.761-.799-2.026-.832-2.828-.069-.8.762-.831 2.027-.069 2.827l16.62 17.449c.756.756 1.76 1.172 2.829 1.172 1.068 0 2.073-.416 2.862-1.207l16.586-17.414c.762-.8.73-2.065-.069-2.827-.799-.763-2.065-.731-2.827.069l-14.552 15.342v-39.963c0-1.104-.896-2-2-2z"/>
                            </svg>
                        ) : 
                        (
                            <svg width="16" height="16" viewBox="0 0 64 64"
                                xmlns="http://www.w3.org/2000/svg" style={{fill: 'white'}}>
                                <path d="m32 56c1.104 0 2-.896 2-2v-39.899l14.552 15.278c.393.413.92.621 1.448.621.495 0 .992-.183 1.379-.552.8-.762.831-2.028.069-2.828l-16.619-17.448c-.756-.755-1.76-1.172-2.829-1.172s-2.073.417-2.862 1.207l-16.586 17.414c-.762.8-.731 2.066.069 2.828s2.067.731 2.828-.069l14.551-15.342v39.962c0 1.104.896 2 2 2z"/>
                            </svg>
                        )
                    :
                    (
                        <svg width="16" height="16" viewBox="0 0 64 64"
                            xmlns="http://www.w3.org/2000/svg" style={{fill: 'white', opacity: 0.5}}
                        >
                            <path d="m31.414 15.586-7-7c-.78-.781-2.048-.781-2.828 0l-7 7c-.781.781-.781 2.047 0 2.828.78.781 2.048.781 2.828 0l3.586-3.586v39.172c0 1.104.896 2 2 2s2-.896 2-2v-39.172l3.586 3.586c.39.391.902.586 1.414.586s1.024-.195 1.414-.586c.781-.781.781-2.047 0-2.828z" />
                            <path d="m49.414 45.586c-.781-.781-2.047-.781-2.828 0l-3.586 3.586v-39.172c0-1.104-.896-2-2-2s-2 .896-2 2v39.172l-3.586-3.586c-.781-.781-2.048-.781-2.828 0-.781.781-.781 2.047 0 2.828l7 7c.391.391.902.586 1.414.586s1.023-.195 1.414-.586l7-7c.781-.781.781-2.047 0-2.828z" />
                        </svg>
                    )
                }
            </button>
        )
    }
}

export default withStyles(sortBtnStyle)(SortBtn);