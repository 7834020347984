import commonStyle from 'assets/jss/commonStyle'

const certificatesPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        background: "url('/assets/img/bgMain.png')",
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
        width: '100%',
    },
    contentContainer: {
        paddingLeft: '55px',
        paddingRight: '55px',
        paddingBottom: '115px'
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        background: 'white',
        width: '100%',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070'
    },
    uploadAttachment: {
        color: '#A30C0C',
        fontSize: '1.4rem',
    },
    addIcon: {
        marginLeft: '10px',
    },
    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    certificateFormContainer: {
        padding: '15px 30px',
        border: '1px solid #a0a0a0',
        margin: '15px 40px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        borderRadius: '3px',
        border: '1px solid #9e9e9e',
        padding: '3px 0px',
        fontSize: '14px',
        boxShadow: 'none',
        width: '100%',
        '&:focus': {
            border: '1px solid #9e9e9e',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
        '&[disabled]': {
            border: '1px solid #fdfdfd',
            background: '#dedede',
            cursor: 'not-allowed',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
    },
    formLabel: {
        width: '100%',
        fontSize: '14px',
        color: '#000',
        margin: '5px 0px',
    },
    fileInput: {
        fontSize: '14px',
        width: '100%'
    },
    iconRequired: {
        color: '#A30C0C',
    },
    certificateContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #eaeaea',
        padding: '5px 10px',
    },
    certificateViewBtn: {
        fontWeight: 700,
        fontSize: '15px',
        color: '#D93129 !important',
        cursor: 'pointer',
        textShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)'
    }
});
export default certificatesPageStyle;