import commonStyle from 'assets/jss/commonStyle';

const payPageStyle = theme => ({
    ...commonStyle,
    pageContainer: {
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
        width: '100%',
    },
    contentContainer: {
        padding: '20px 0px',
    },
    welcomeDiv: {
        color: '#A30C0C',
        fontFamily: 'Mulish',
        fontSize: '1.75rem',
        margin: '25px 0px',
    },
    sidebar: {
        width: '265px',
        minWidth: '265px',
        minHeight: '680px',
        maxHeight: '680px',
        background: 'white',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        marginRight: '30px'
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        background: 'white',
        width: '100%',
        borderRadius: '8px',
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        paddingTop: 0,
    },
    tableContainer: {
        color: '#757575',
        fontWeight: 500,
    },
    tableHeader: {
        color: 'black',
        background: '#F3F2FF',
        padding: '10px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        '& > div': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        }
    },
    tableRow: {
        color: 'black',
        flexDirection: 'row',
        fontSize: 12,
        background: '#F3F2FF',
        paddingTop: 5,
        paddingBottom: 5,
        '& > div': {
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    tableRow2ndLine: {
        display: 'flex',
        '& > div': {
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    columnDetails: {
        flex: 8,
        display: 'flex',
    },
    columnAmount: {
        flex: 1.5,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
    },
    columnDate: {
        flex: 3,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',

    },
    columnVendor: {
        flex: 3,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
    },
    columnView: {
        flex: 1.5,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        cursor: 'pointer',
    },
    columnActions: {
        flex: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid #D4D4D4',
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#A30C0C',
    },
    tableTitle: {
        color: '#757575',
        fontSize: '1.4rem',
        marginTop: '25px',
        marginBottom: '15px',
    },
    invoiceTitleContainer: {
        flex: 1,
        //borderRight: '1px solid #D4D4D4',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    invoiceSubTitleContainer: {
        flex: 1,
        borderRight: '1px solid #D4D4D4',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    invoiceDetailContainer: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    spacer: {
        height: '80px',
        width: '100%'
    },
    header: {
        background: '#1291F9',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        height: 60,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer',
        marginTop: 30,
    }
});

export default payPageStyle;