import commonStyle from 'assets/jss/commonStyle'

const styles = (theme) => ({
    ...commonStyle,
    ...commonStyle,
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: '1rem',
    },
    addIcon: {
        marginLeft: '10px',
    },
    messageArea: {
        width: '100%',
        height: 140,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        background: '#FFFFFF',
        borderRadius: 8,
        padding: '3px 10px',
        '&:focus': {
            border: '1px solid #9e9e9e',
            outline: 'none !important',
        },
    },
    btnSubmit: {
        background: '#1D8ACF',
        color: 'white',
        paddingTop: 8,
        paddingBottom: 8,
        width: 120,
        margin: '0px auto'
    },
    submitContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    }
});

export default styles;