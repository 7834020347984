const logInPageStyle = theme => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        background: "url('/assets/img/bgSignUp.jpg')",
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
        color: 'white',
        textAlign: 'center',
        justifyContent: 'center',
        fontSize: '2rem'
    }
});
export default logInPageStyle;