import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import accountReconciliationPageStyle from './accountReconciliationPageStyle';
import { accountReconcilList } from '../../../redux/actions/adminActions';

import InvoiceList from 'components/InvoiceList/index';


class AccountReconciliationPage extends Component
{
    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        reconciledInvoices: [],
        openedInvoices: [],
        cannotPayInvoices: []
    }

    constructor(props) {
        super(props);
        this.loadingData();
    }

    loadingData = async () => {
        await this.props.accountReconcilList();
        this.processInvoiceList();
    }

    processInvoiceList = () => {
        var invRlt = this.props.invoiceResult.data;
        console.log(invRlt);
        if(invRlt.status){
            this.setState({reconciledInvoices: invRlt['reconciled']});
            this.setState({openedInvoices: invRlt['opened']});
            this.setState({cannotPayInvoices: invRlt['notPayed']});
            console.log(this.state.reconciledInvoices)
        }
    }

    render(){
        const { classes } = this.props;

        return(
            <div>
                <div className={ classes.tableTitle }>
                    Reconciled items
                </div>
                <div className={ classes.tableContainer }>
                    <div className={ classes.tableHeader }>
                        <div className={ classes.columnDetails }>
                            Details
                        </div>
                        <div className={ classes.columnDate }>
                            Date
                        </div>
                        <div className={ classes.columnAmount }>
                            $
                        </div>
                    </div>
                    <InvoiceList iconUrl="/assets/img/calendar.png" category="Requested for approval" invoices={this.state.reconciledInvoices} />
                </div>
                <div className={ classes.spacer }></div>
                <div className={ classes.tableTitle }>
                    Open items
                </div>
                <div className={ classes.tableContainer }>
                    <div className={ classes.tableHeader }>
                        <div className={ classes.columnDetails }>
                            Details
                        </div>
                        <div className={ classes.columnDate }>
                            Date
                        </div>
                        <div className={ classes.columnAmount }>
                            $
                        </div>
                    </div>
                    <div className={ classes.category }>
                        Buyer requested, not invoiced
                    </div>
                    <InvoiceList iconUrl="/assets/img/calendar.png" category="Requested for approval" invoices={this.state.openedInvoices} />

                    <div className={ classes.spacer40 }></div>
                    <div className={ classes.category }>
                        Invoice raised, can not pay
                    </div>
                    <InvoiceList iconUrl="/assets/img/calendar.png" category="Requested for approval" invoices={this.state.cannotPayInvoices} />

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state, invoiceResult: state.invoiceResult}
}

const mapDispatchToProps = {accountReconcilList};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(accountReconciliationPageStyle)(AccountReconciliationPage));