const baseInputStyle = theme => ({
    baseInput: {
        background: 'rgba(194, 194, 194, 0.06)',
        borderRadius: 10,
        height: 40,
        paddingLeft: '10px',
        fontSize: '14px',
        color: 'black',
        '&:focus': {
            outline: 'none !important',
        },
        '&[disabled]': {
            background: 'rgba(194, 194, 194, 0.2)',
            cursor: 'not-allowed',
            outline: 'none !important',
        },
        border: '1px solid rgba(18, 145, 249, 0.18)',
        '&::placeholder': {
            color: 'black'
        }
    },
    baseSmallInput: {
        background: 'rgba(194, 194, 194, 0.06)',
        borderRadius: 10,
        height: '32px',
        paddingLeft: '10px',
        fontSize: '0.8rem',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        color: 'black',
        '&:focus': {
            outline: 'none !important',
        },
        '&[disabled]': {
            background: 'rgba(194, 194, 194, 0.2)',
            cursor: 'not-allowed',
            outline: 'none !important',
        },
    }
});
export default baseInputStyle;