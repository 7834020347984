import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import setupPageStyle from './setupPageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import { NavLink, Route, Switch } from 'react-router-dom';
import NewUserPage from './NewUserPage.jsx';
import ExistingUserPage from './ExistingUserPage.jsx';
import BudgetsPage from './BudgetsPage.jsx';
import EventsPage from './EventsPage.jsx';
import CategoriesPage from './CategoriesPage.jsx';
import EmailTemplatesPage from './EmailTemplatesPage.jsx';
import { withTranslation  } from 'react-i18next';
import InvoiceEmail from './InvoiceEmail';

class SetupPage extends Component
{
    render(){
        const { classes, match, t } = this.props;
        const { user } = this.props.account.loginStatus;

        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Setup')}
                    </div>
                </div>
                <div className={ classes.pageContentContainer }>
                    <div className={ `setup-tab-container flex flex-row items-center mb-6 bg-white rounded-md py-3 px-3` }>
                        <NavLink
                            to="/admin/setup"
                            className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white text-blue-800 hover:border-blue-700 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav } exact
                        >
                            {t('New User')}
                        </NavLink>
                        <NavLink
                            to="/admin/setup/existing-user"
                            className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-800 hover:border-blue-700 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav }
                        >
                            {t('Existing User')}
                        </NavLink>
                        <NavLink
                            to="/admin/setup/budgets"
                            className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-800 hover:border-blue-700 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav }
                        >
                            {t('Budgets')}
                        </NavLink>
                        <NavLink
                            to="/admin/setup/events"
                            className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-800 hover:border-blue-700 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav }
                        >
                            {t('Events')}
                        </NavLink>
                        <NavLink
                            to="/admin/setup/categories"
                            className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-800 hover:border-blue-700 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav }
                        >
                            {t('Categories')}
                        </NavLink>
                        {
                            (
                                user.customer.name === 'Onesta' || user.customer.name === 'Demo Customer'
                            ) &&
                                <NavLink
                                    to="/admin/setup/invoice-email"
                                    className={ `text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-800 hover:border-blue-800 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                                    activeClassName={ classes.activeNav }
                                >
                                    {t('Invoice Email')}
                                </NavLink>
                        }
                        {/* <NavLink
                            to="/admin/setup/email-templates"
                            className={ `font-bold text-sm leading-4 py-2 px-3  mr-3 focus:outline-none bg-white  text-blue-700 hover:border-blue-800 hover:text-blue-800 hover:decoration-none focus:outline-none` }
                            activeClassName={ classes.activeNav }
                        >
                            {t('Email Templates')}
                        </NavLink> */}
                    </div>
                    <div className="rounded-md" style={{ background: '#FDF9F9' }}>
                        <Switch>
                            <Route path="/admin/setup" component={NewUserPage} exact />
                            <Route path="/admin/setup/existing-user" component={ExistingUserPage} />
                            <Route path="/admin/setup/budgets" component={BudgetsPage} />
                            <Route path="/admin/setup/events" component={EventsPage} />
                            <Route path="/admin/setup/categories" component={CategoriesPage} />
                            <Route path="/admin/setup/email-templates" component={EmailTemplatesPage} />
                            <Route path="/admin/setup/invoice-email" component={InvoiceEmail} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(setupPageStyle)(SetupPage)));