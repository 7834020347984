import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

class DocusignAuthPage extends Component
{
    state = {
        redirect: false,
    }

    componentDidMount() {
        const hash = this.props.location.hash;
        if (typeof hash === 'undefined') {
            window.location.href = '/';
            return;
        }

        let token = "";
        if (hash.indexOf('&') === -1) {
            token = hash.substring('#access_token='.length, );
        } else {
            token = hash.substring('#access_token='.length, hash.indexOf('&'));
        }

    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to="/msa"
                />
            )
        }
        return (
            <div>
                Redirecting to MSA page.
            </div>
        )
    }
}

export default DocusignAuthPage;