import * as Type from '../actions/types';

const initialState = {
    data: {
        pdfUrl: ''
    }
};

export const printResult = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_PRINT_PDF:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
