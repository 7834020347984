import commonStyle from 'assets/jss/commonStyle'

const setupPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        background: "#F5F7F6",
        width: '100%',
    },
    contentContainer: {
        padding: 30,
    },
    formContainer: {
        background: '#FDF9F9',
        width: '100%',
        borderRadius: 7,
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070'
    },
    formHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '110px'
    },
    formLabelContainer: {
        borderRight: '1px solid #E9E9E9',
        padding: '5px 5px',
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    companyTitle: {
        color: '#707070',
        marginTop: '10px'
    },
    formNumberContainer: {
        color: '#707070',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: '-10px'
    },
    formLabel: {
        fontSize: '1.1rem',
        paddingBottom: 6,
    },
    formNumber: {
        marginLeft: '15px',
        marginTop: '-10px',
        color: '#1D8ACF',
        fontSize: 30,
    },
    subTitleContainer: {
        flex: 6,
        borderRight: '1px solid #E9E9E9',
    },
    redTitle: {
        color: '#1D8ACF',
        fontSize: '1.4rem',
        textAlign: 'center',
    },
    infoUrl: {
        color: '#707070',
        textAlign: 'center',
        marginTop: '18px',
    },
    hintContainer: {
        flex: 2,
        color: '#707070',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    divider: {
        marginTop: '30px',
        marginBottom: '30px',
        borderBottom: '1px solid #E9E9E9',
        height: '1px',
        width: '100%',
    },
    colorRed: {
        color: '#1D8ACF',
        fontSize: 18,
    },

    partTitle: {
        fontSize: '1.1rem',
    },
    partNumber: {
        color: '#1D8ACF',
        fontSize: '1.4rem',
        marginRight: '25px',
    },
    oneLetterInput: {
        width: '40px',
        marginRight: '10px',
        fontSize: '1.4rem',
        paddingLeft: '12px'
    },
    orLabel: {
        color: '#1D8ACF',
        marginTop: '20px',
        fontSize: '1.4rem'
    },
    btnSave: {
        background: '#89C34B',
        color: 'white',
        width: 120,
        display: 'inline-block',
        fontSize: 16,
        height: 33,
    },
    btnSubmit: {
        background: '#1D8ACF',
        color: 'white',
        width: 120,
        display: 'inline-block',
        fontSize: 16,
        height: 33,
    },
    signature_pad: {
        display: 'block'
    },
    signature_pad_sign_canvas: {
        border: '1px solid #EEE'
    },
    navRef: {
        position: 'relative'
    },
    navRefLink: {
        position: 'absolute',
        top: '-5.5rem'
    }
});
export default setupPageStyle;