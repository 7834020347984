import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import SelectCore from '@material-ui/core/Select';

import nativeSelectStyle from './nativeSelectStyle.jsx';

class NativeSelect extends Component
{
    state = {
        opened: false
    }
    render() {
        const { children, classes , ...props} = this.props;
        const { opened } = this.state;

        return (
            <SelectCore
                className={ [classes.select, opened ? 'opened' : ''] }
                MenuProps={{
                    classes: { 
                        paper: classes.selectPaper 
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                classes={{ select: classes.selectLabel }}
                onOpen={ this.selectOpened }
                onClose={ this.selectClosed }
                { ...props }
            >
                { children }
            </SelectCore>
        )
    }

    selectOpened = () => {
        this.setState({ opened: true });
    }

    selectClosed = () => {
        this.setState({ opened: false });
    }
}

NativeSelect.defaultProps = {
    color: 'default',
};

export default withStyles(nativeSelectStyle)(NativeSelect);