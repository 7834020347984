import React, { Component } from 'react'
import { connect } from 'react-redux';

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import Button from 'components/Button/index.jsx';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles } from '@material-ui/core';
import setupPageStyle from './setupPageStyle';

import { getGlobalInitialData } from '../../../redux/actions/commonActions';
import { userCreate } from '../../../redux/actions/adminActions';
import { withTranslation  } from 'react-i18next';

class NewUserPage extends Component
{
    state = {
        showResultMessage: false,
        validationErrorMsg: "",
        isInputHasError: false,
        formData: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            company: '',
            phone: '',
            budget_limit: '',
            user_type: 'BUYER'
        },
        formValidation: {
            first_name: {
                valid: true,
                msg: ''
            },
            last_name: {
                valid: true,
                msg: ''
            },
            email: {
                valid: true,
                msg: ''
            },
            password: {
                valid: true,
                msg: ''
            },
            company: {
                valid: true,
                msg: ''
            },
            phone: {
                valid: true,
                msg: ''
            },
            budget_limit: {
                valid: true,
                msg: ''
            },
            user_type: {
                valid: true,
                msg: ''
            }
        }
    }

    constructor(props){
        super(props);
        this.loadData();
    }

    loadData = () => {
        setTimeout(() => {
            this.props.getGlobalInitialData();
        }, 1000);
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {}
        dataOject[valueType] = e.target.value;
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });
        if (this.state.isInputHasError) {
            this.checkValidation();
        }
        if (valueType === "user_type") {
            let val = e.target.value;
            if (val === "VENDOR") {
                this.setState({formData: Object.assign(this.state.formData, {budget_limit: ''})});
            }
        }
    }

    setValidationErrorMsg = (msg) => {
        this.setState({'showResultMessage': true});
        this.setState({'validationErrorMsg': msg});
    }

    bindErrorMessage = (type) => {
        const { classes } = this.props;
        if (!this.state.formValidation[type].valid) {
            return(
                <GridItem xs={12} className={ `input-errror` }>
                    { this.state.formValidation[type].msg }
                </GridItem>
            );
        }
        return null;
    }

    validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    checkValidation = () => {
        let isValid = true;

        let validationInfo = this.state.formValidation;
        Object.keys(validationInfo).forEach(function (item) {
            validationInfo[item] = {
                valid: true,
                msg: ''
            };
        });
        this.setState({formValidation: validationInfo});

        if (this.state.formData.first_name.trim() === '') {
            const dataOject = {
                first_name: {
                    valid: false,
                    msg: 'First name is required *'
                }
            };
            this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
            isValid = false;
        }
        if (this.state.formData.last_name.trim() === '') {
            const dataOject = {
                last_name: {
                    valid: false,
                    msg: 'Last name is required *'
                }
            };
            this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
            isValid = false;
        }
        if (true) {
            let errorMsg = "";
            if (this.state.formData.email.trim() === '') {
                errorMsg = 'Email is required *';
            } else if (!this.validateEmail(this.state.formData.email)) {
                errorMsg = 'Email is not valid email';
            }

            if (errorMsg !== "") {
                const dataOject = {
                    email: {
                        valid: false,
                        msg: errorMsg
                    }
                };
                this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
                isValid = false;
            }
        }
        if (true) {
            let errorMsg = "";
            if (this.state.formData.company.trim() === '') {
                errorMsg = 'Organization is required *';
            }

            if (errorMsg !== "") {
                const dataOject = {
                    company: {
                        valid: false,
                        msg: errorMsg
                    }
                };
                this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
                isValid = false;
            }
        }
        if (true) {
            let errorMsg = "";
            if (this.state.formData.phone.trim() === '') {
                errorMsg = 'Phone is required *';
            } else {
                const phoneNumber = parsePhoneNumberFromString(this.state.formData.phone, 'BR');
                if (typeof phoneNumber == "undefined" || !phoneNumber.isValid()) {
                    errorMsg = 'Phone is not valid number';
                }
                // this.setState({formData: Object.assign(this.state.formData, {phone: phoneNumber.number})});
            }

            if (errorMsg !== "") {
                const dataOject = {
                    phone: {
                        valid: false,
                        msg: errorMsg
                    }
                };
                this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
                isValid = false;
            }
        }
        if (true) {
            let errorMsg = "";
            if (this.state.formData.user_type === "BUYER" && this.state.formData.budget_limit.trim() === "") {
                errorMsg = 'Budget limit is required *';
            }
            else if (this.state.formData.user_type === "BUYER" && isNaN(this.state.formData.budget_limit)) {
                errorMsg = 'Budget limit should be amount';
            }

            if (errorMsg !== "") {
                const dataOject = {
                    budget_limit: {
                        valid: false,
                        msg: errorMsg
                    }
                };
                this.setState({ formValidation: Object.assign(this.state.formValidation, dataOject) });
                isValid = false;
            }
        }
        return isValid;
    }

    handleSubmit = async (e) => {

        e.preventDefault();
        this.setState({'showResultMessage': false});

        if (!this.checkValidation()) {
            this.setState({isInputHasError: true});
            return;
        }
        this.setState({isInputHasError: false});

        var signupData = Object.assign({}, this.state.formData);
        const phoneNumber = parsePhoneNumberFromString(signupData.phone, 'BR');
        signupData.phone = phoneNumber.number;

        this.setState({'isBackendLoading': true});
        await this.props.userCreate(signupData);
        this.setState({'isBackendLoading': false});
        if(typeof this.props.commonResult.result.status != 'undefined'){
            this.setState({'showResultMessage': true});
            if(this.props.commonResult.result.status){
                this.setState({
                    'formData': {
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        company: '',
                        phone: '',
                        budget_limit: '',
                        user_type: 'BUYER'
                    }
                });
            }
        }
    }

    submitResultMessage = () => {
        const { classes, t } = this.props;
        if(typeof this.props.commonResult.result.status != 'undefined' && this.state.showResultMessage){
            if(this.props.commonResult.result.status){
                return(
                    <p className={`success-message`}>
                        {t('User created successfully!')}
                    </p>
                );
            }else{
                return(
                    <p className={`error-message`}>
                        {this.props.commonResult.result.message}
                    </p>
                );
            }
        } else if (this.state.validationErrorMsg != '' && this.state.showResultMessage) {
            return(
                <p className={`error-message`}>
                    { this.state.validationErrorMsg }
                </p>
            );
        }else {
            return null;
        }
    }

    render(){
        const { classes, t } = this.props;
        let disabled = this.state.formData.user_type !== "BUYER";
        return(
            <div className="px-3 flex justify-center" style={{ background: '#F3F2FF' }}>
                <form className={ classes.signUpForm } id="newuserForm" name="newuserForm"  onSubmit={e => this.handleSubmit(e)}>
                    <h2 style={{ fontSize: 25, textAlign: 'center', color: '#000', marginBottom: 30, }}>Fill up this form to create a new user</h2>
                    <GridContainer spacing={6}>
                        <GridItem xs={12} md={6}>
                            <GridContainer className={`md:pr-3`}>
                                <GridItem xs={12}>
                                    <BaseInput
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'first_name')}
                                        value={this.state.formData.first_name}
                                        placeholder="Firstname"
                                    />
                                </GridItem>
                                { this.bindErrorMessage('first_name') }
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <GridContainer className={`lg:pr-3`}>
                                <GridItem xs={12}>
                                    <BaseInput
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'last_name')}
                                        value={this.state.formData.last_name}
                                        placeholder="Lastname"
                                    />
                                </GridItem>
                                { this.bindErrorMessage('last_name') }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={6} >
                        <GridItem xs={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <BaseInput
                                        type="email"
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'email')}
                                        value={this.state.formData.email}
                                        placeholder="Email"
                                    />
                                </GridItem>
                                { this.bindErrorMessage('email') }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={6}>
                        <GridItem xs={12} md={6}>
                            <GridContainer className={`md:pr-3`}>
                                <GridItem xs={12}>
                                    <BaseInput
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'company')}
                                        value={this.state.formData.company}
                                        placeholder="Company"
                                    ></BaseInput>
                                </GridItem>
                                { this.bindErrorMessage('company') }
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} md={6} >
                            <GridContainer>
                                <GridItem xs={12}>
                                    <BaseInput
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'phone')}
                                        value={this.state.formData.phone}
                                        placeholder="Phone"
                                    />
                                </GridItem>
                                { this.bindErrorMessage('phone') }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={6}>
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <BaseInput
                                        disabled={disabled}
                                        className={ classes.input }
                                        onChange={e => this.handleChangeValue(e, 'budget_limit')}
                                        value={this.state.formData.budget_limit}
                                        placeholder="Approval Budget if buyer"
                                    />
                                </GridItem>
                                { this.bindErrorMessage('budget_limit') }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer spacing={6}>
                        <GridItem xs={12}>
                            <RadioGroup aria-label="user_type" name="user_type"
                                value={ this.state.formData.user_type }
                                onChange={e => this.handleChangeValue(e, 'user_type')} row>
                                <FormControlLabel
                                    value="VENDOR"
                                    label={t("Vendor")}
                                    control={<Radio color="primary" classes={{ colorPrimary: classes.check, checked: classes.check }} />}
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="BUYER"
                                    label={t("Buyer")}
                                    control={<Radio color="primary" classes={{ colorPrimary: classes.check, checked: classes.check }} />}
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} className={ classes.submitContainer }>
                            <Button className={ `${classes.btnSubmit}` }>
                                {t('Submit')}
                            </Button>
                        </GridItem>
                        <GridItem xs={12} style={{paddingTop: '18px'}}>
                            {this.submitResultMessage()}
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    commonResult: state.commonResult
});
const mapDispatchToProps = {userCreate, getGlobalInitialData}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(setupPageStyle)(NewUserPage)));