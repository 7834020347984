import React, { Component } from 'react'
import signatureStyle from './signatureStyle.jsx';
import { withStyles } from '@material-ui/core';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Draggable from 'components/Draggable';
import { withTranslation  } from 'react-i18next';

const COLORS = [
    'rgba(251, 220, 128, 0.8)',
    'rgba(189, 225, 232, 0.8)'
];

class Signature extends Component
{
    render() {
        const { classes, signature, onClick, signed, imageData, refCallback, t } = this.props;
        if (signed) {
            return (
                <div style={{ top: signature.y, left: signature.x, position: 'absolute' }} ref={ refCallback }>
                    <img src={ imageData } style={{ transform: 'translate(0px, -30%)', width: 220 }} />
                </div>
            );
        }

        return (
            <div style={{ top: signature.y, left: signature.x, position: 'absolute' }} ref={ refCallback }>
            <Draggable type="control" data={signature} onEnd={ this.handleDragEnd }>
                <div className={ classes.container } style={{ backgroundColor: COLORS[signature.signer_order] }} onClick={ onClick }>
                    <div style={{ fontWeight: 700 }}>
                        { t('Sign') }
                    </div>
                    <PlayForWorkIcon />
                </div>
            </Draggable>
            </div>
        );
    }
}

export default withTranslation()(withStyles(signatureStyle)(Signature));