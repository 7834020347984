import * as Type from './types';
import Cookies from 'js-cookie';

export const saveUiSettings = (leftSidebarOpened) => (dispatch, getState) => {

    // const uiSettings = getState()['uiSettings'];
    dispatch({
        type: Type.UI_SETTING,
        payload: {
            leftSidebarOpened: leftSidebarOpened
        }
    });

    return;
};

export const toggleLeftSidebar = () => (dispatch, getState) => {

    const uiSetting = getState()['uiSetting'];
    dispatch({
        type: Type.UI_SETTING,
        payload: {
            ...uiSetting.data,
            leftSidebarOpened: !uiSetting.data.leftSidebarOpened
        }
    });

    return;
};

export const toggleContactSidebar = () => (dispatch, getState) => {

    const uiSetting = getState()['uiSetting'];
    dispatch({
        type: Type.UI_SETTING,
        payload: {
            ...uiSetting.data,
            contactSidebarOpened: !uiSetting.data.contactSidebarOpened
        }
    });

    return;
};
