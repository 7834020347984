import React from 'react';
import { useDrop } from 'react-dnd';

const Droppable = (props) => {
    const { children, onDrop } = props;
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ['draggable', 'control'],
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    return (
        <div ref={drop} style={{ width: '100%' }}>
            { children }
        </div>
    )
}

export default Droppable;

