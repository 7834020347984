import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import reportPageStyle from './reportPageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';
import { NavLink, Route, Switch } from 'react-router-dom';
import BankReconciliationPage from './BankReconciliationPage.jsx';
import AccountReconciliationPage from './AccountReconciliationPage.jsx';
import BudgetsPage from './BudgetsPage.jsx';
import DatabaseDumpPage from './DatabaseDumpPage.jsx';


class ReportPage extends Component
{
    render(){
        const { classes, match } = this.props;

        return(
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.contentContainer }>
                    <div className={ classes.pageContent }>
                        <div className={ classes.formContainer }>
                            <div className={ classes.tabContainer }>
                                <div className={ classes.tabLeftWrapper }>
                                    <span className={ classes.user }>Payments</span>
                                    <NavLink to="/report" className={ classes.inactiveNav } activeClassName={ classes.activeNav } exact>
                                        Bank Reconciliation
                                    </NavLink>
                                    <NavLink to="/report/account-reconciliation" className={ classes.inactiveNav } activeClassName={ classes.activeNav }>
                                        Account Reconciliation
                                    </NavLink>
                                </div>
                                <NavLink to="/report/budgets" className={ classes.inactiveBudgetNav } activeClassName={ classes.activeGeyNav }>
                                    Budgets
                                </NavLink>

                                <NavLink to="/report/database-dump" className={ classes.btnDatabaseDump }>
                                    Full Database Dump
                                </NavLink>
                            </div>
                            <div>
                                <Switch>
                                    <Route path="/report" component={BankReconciliationPage} exact />
                                    <Route path="/report/account-reconciliation" component={AccountReconciliationPage} />
                                    <Route path="/report/budgets" component={BudgetsPage} />
                                    <Route path="/report/database-dump" component={DatabaseDumpPage} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(reportPageStyle)(ReportPage));