import React, { Component } from "react";

import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import RecentContactStyle from "./RecentContactStyle.jsx";
import Loading from "components/Loading";

import { userLogout } from "../../../redux/actions/accountActions";
import {
  saveUiSettings,
  toggleLeftSidebar,
} from "../../../redux/actions/uiActions";
import Avatar from "components/Avatar";
import Cookies from "js-cookie";
import { getGlobalInitialData } from "redux/actions/commonActions";
import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class RecentContact extends Component {
  state = {
    loadAgainTemplate: false,
    tokenLoaded: false,
    isDataLoading: true,
    query: "",
    value: 0,
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
      },
    },
    data: {
      contacts: [],
      recentContact: [],
    },
    customerInfo: null,
    showOnlyRecent: true,
  };

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };
  }

  componentDidMount() {
    const { chat } = this.props;

    setTimeout(() => {
      if (chat.socket) {
        chat.socket.on("join.anotherUser", (data) => {
          this.setState({
            loadAgainTemplate: !this.state.loadAgainTemplate,
          });
        });

        chat.socket.on("left.anotherUser", (data) => {
          this.setState({
            loadAgainTemplate: !this.state.loadAgainTemplate,
          });
        });
      }
    }, 300);
  }

  setChatTargetUser(userId) {
    this.props.onUserSelected(userId);
  }

  toggleRecentOrNot() {
    this.setState({ showOnlyRecent: !this.state.showOnlyRecent });
  }

  render() {
    const recentContact = this.state.data.recentContact;

    const { classes } = this.props;
    const { query, showOnlyRecent } = this.state;
    const { t } = this.props;

    const loginStatus = this.props.account.loginStatus;
    if (!loginStatus.isLogedIn) {
      return null;
    }

    let contacts = Object.values(this.props.chat.contacts);

    if (showOnlyRecent) {
      contacts = contacts.filter((contact) => {
        return contact.profile.is_recentContact == true;
      });
    }

    return (
      <div
        className={[
          classes.RecentContactContainer,
          `RecentContact pt-4 pb-4 px-3 border-b`,
        ].join(" ")}
      >
        {showOnlyRecent ? (
          <h6 className={`text-lg pb-3`}>
            { t('Recent Contacts') }
          </h6>
        ) : (
          <h6 className={`text-lg pb-3`}>
            { t('All Contacts') }
          </h6>
        )}

        <div className={`contacts-container`}>
          {contacts.map((contact, index) => {
            return (
              <a
                key={`contact-${index}`}
                onClick={(e) => this.setChatTargetUser(contact.id)}
                style={{ cursor: "pointer" }}
                className={`flex mb-3 hover:no-underline`}
              >
                <div className={`w-10`}>
                  <div className={`w-full h-8`}>
                    <Avatar
                      user={contact}
                      size={"small"}
                      topBubble={false}
                      active={contact.status === "active"}
                      hideBubble={contact.status === ""}
                    />
                  </div>
                </div>
                <div className={`text-sm ml-3`}>
                  <div className={`leading-none`}>{contact.name}</div>
                  <div className={`text-xs opacity-75`}>{contact.job}</div>
                </div>
              </a>
            );
          })}
        </div>

        <button
          onClick={(e) => this.toggleRecentOrNot()}
          className={`w-full bg-blue-900 hover:bg-blue-800 text-white text-sm leading-4 py-2 px-4 rounded-sm mt-3`}
        >
          {showOnlyRecent ? (
            <span>
              { t('More Contacts') }
            </span>
          ) : (
            <span>
              { t('Recent Contacts') }
            </span>
          )}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList,
    chat: state.chat,
  };
};

const mapDispatchToProps = {
  getGlobalInitialData,
  userLogout,
  saveUiSettings,
  toggleLeftSidebar,
};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(RecentContactStyle)(RecentContact)));
