import { CM_INVOICE_LIST } from '../actions/types';

const initialState = {
  data: {
    payed: [],
    pendings: [],
    submitted: []
  }
};

export const invoiceResult = (state = initialState, action) => {
  switch (action.type) {
    case CM_INVOICE_LIST:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
