import { FILE_UPLOAD } from './types';
import Cookies from 'js-cookie';

export const uploadFiles = (filelist, type="invoice_attachments") => (dispatch, getState) => {

  if(filelist.length == 0){
    dispatch({
      type: FILE_UPLOAD,
      payload: {
        status: true,
        message: 'No file to upload',
        files: []
      }
    });
  }else{

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const loginStatus = getState()['account']['loginStatus'];

    var formData = new FormData();
    formData.append("fileType", type);
    Array.prototype.forEach.call(filelist, function(file, index) {
      formData.append("files_" + index, file);
    });

    return fetch(apiUrl + 'api/upload/files', {
        method: 'POST',
        headers: (loginStatus.token != '')? {
          'Authorization': 'Token ' + loginStatus.token,
          'X-CSRFToken': Cookies.get('csrftoken')
        }: {
          'X-CSRFToken': Cookies.get('csrftoken')
        },
        body: formData
    })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
        if(result.status){
          dispatch({
            type: FILE_UPLOAD,
            payload: result
          });
        }else{
          dispatch({
            type: FILE_UPLOAD,
            payload: result
          });
        }
    })
    .catch(err => {
      dispatch({
        type: FILE_UPLOAD,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
    });
  }

};

export const saveMsaFile = (msa) => {
    return {
        type: 'MSA_FILE',
        msa: msa
    };
}
