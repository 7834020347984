import React, {Component} from 'react'
import { connect } from 'react-redux';
import { Link, NavLink, Redirect  } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import invoiceDropDownStyle from './invoiceDropDownStyle.jsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Moment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import Modal from '@material-ui/core/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MatButton from 'components/MatButton/index.jsx';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import InputBase from '@material-ui/core/InputBase';
import BaseInput from 'components/BaseInput/index.jsx';
import NativeSelect from '@material-ui/core/NativeSelect';
import AutocompleteSelect from 'components/AutocompleteSelect/index.jsx';
import Button from 'components/Button/index.jsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { confirm } from "../../components/Confirm/index";
import cloneDeep from 'lodash/cloneDeep';

import { uploadFiles } from '../../redux/actions/fileActions';
import { submitInvoice, searchInvoiceKey, printInvoice, printInvoiceSendEmail } from '../../redux/actions/commonActions';


function getPrintModalStyle() {
    const top = 5;
    const left = 50;
    return {
      position: 'absolute',
      maxWidth: '40rem',
      width: '34rem',
      /* maxHeight: '300px', */
      margin: '0 auto',
      top: '5%',
      left: 'calc(50% - 17rem)',
      maxHeight: '90%',
      overflowY: 'auto',
      backgroundColor: 'white',
      padding: '1.5rem 2rem'
    };
  }

class InvoiceDropDown extends Component
{

    fields = {
    }

    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        isCollapsed: true,
        loginInfo: {
            user: {
                first_name: '',
                last_name: '',
                profile: {
                    user_type: ''
                }
            }
        },
        detailData: null,
        detailModalOpen: false,
        attachments: [],
        pdfSendEmailList: [],
        existing_attachments: [],
        isPrintModalOpened: false
    }

    constructor(props) {
        super(props);
        this.state.loginInfo = {...this.state.loginInfo, ...this.props['account']['loginStatus']};
    }

    handleClickHeader = () => {
        this.setState({isCollapsed: !this.state.isCollapsed});
    }

    showAttachFiles = (files) => {
        if(typeof files != 'undefined' && files != null){
            return files.map((file, key) => {
                if(file == '') return null;
                return (
                    <span key={key} style={{paddingLeft: '0.5rem'}}>
                        { this.showEachAttachFiles(file) }
                    </span>
                )
            })
        }else {
            return null;
        }
    }
    showEachAttachFiles = (file) => {
        var filebasename = file.substring(file.lastIndexOf('/') + 1);
        var filebasenameUrl = encodeURIComponent(filebasename);
        return (
            <a href={this.state.apiUrl + 'api/download/file/' + filebasenameUrl} style={{color: '#333'}} target="_blank">
                <AttachFileIcon style={{transform: 'rotate(45deg)'}} />
            </a>
        );
    }

    // Show details dialog
    showDetail = (e, invoice) => {
        let canRaiseType = this.props['canRaiseInvoice']? this.props['canRaiseInvoice'] : '';

        if (this.state.loginInfo['user']['profile']['user_type'] != canRaiseType) {
            return;
        }

        let detailData = cloneDeep(invoice);
        let buyerId = detailData['buyer']['id'];
        detailData.buyer = buyerId;
        this.setState({detailData: detailData});
        this.setState({detailModalOpen: true});
    }
    closeDetail = () => {
        this.setState({detailData: null});
        this.setState({detailModalOpen: false});
    }

    renderInvoices = () => {
        if(!this.state.isCollapsed)
            return null;
        var invoices = this.props.invoices;
        if(typeof invoices == 'undefined' || invoices == null) return null;

        const { classes } = this.props;
        return invoices.map((invoice, key) => {
            var files = invoice.attachments.split(',');
            var type = invoice.type;
            if(type == 'DebitNote') type = 'Debit Note'
            if(type == 'CreditNote') type = 'Credit Note'
            return (
                <div key={key} className={ classes.invoiceContainer }>
                    <div className={ classes.invoiceRowItem }>
                        <div className={ classes.invoiceNumberContainer } style={{ display: (invoice.approved_status == 'Approved'? 'block': 'none') }}>
                            <span style={{paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{type}</span>
                            <span>
                                <a onClick={e => this.showDetail(e, invoice)}>Inv#{invoice.key}</a>
                            </span>
                            <span style={{paddingLeft: '1rem'}}>{this.showAttachFiles(files)}</span>
                        </div>
                        <div className={ classes.invoiceNumberContainer } style={{ display: (invoice.approved_status == 'Approved'? 'none': 'block') }}>
                            <span style={{paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{type}</span>
                            <span> {invoice.approved_status}</span>
                            <span style={{ display: this.state.loginInfo['user']['profile']['user_type'] == 'BUYER' && invoice.approved_status == 'Rejected'? 'inline': 'none'}}>
                                <Link to={'/buyer/review-invoices/request/' + invoice.id}>
                                    <IconButton aria-label="Edit" color="secondary" className={classes.margin}>
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                            </span>
                            <span style={{paddingLeft: '2rem'}}>{this.showAttachFiles(files)}</span>
                        </div>
                    </div>
                    <div className={ classes.invoiceRowItem }>
                        <div className={ classes.invoiceSubNumberContainer }>
                            Ev#{invoice.eventId}
                        </div>
                        <div className={ classes.invoiceTitle }>
                            {invoice.sub_category} / {invoice.category}
                        </div>
                        <div className={ classes.invoiceAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.invoiceRequestor }>
                            Requested by { invoice.creator.first_name + ' ' + invoice.creator.last_name }
                        </div>
                        <div className={ classes.invoiceDate }>
                            <Moment format="MMM, D, YYYY">{invoice.created_at}</Moment>
                        </div>
                    </div>
                </div>
            )
        });
    }

    // Form input chnage event
    handleChangeValue = (e, valueType) => {
        const dataOject = {};
        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.detailData[valueType] != 'undefined' && this.state.detailData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }
        if (valueType == "category") {
            dataOject["sub_category"] = "";
        }
        this.setState({ detailData: Object.assign(this.state.detailData, dataOject) });

        if(valueType == 'key'){
            this.searchKeyExisting(e.target.value);
        }
    }

    // Show new added attachments
    renderAttachments = () => {
        const {classes} = this.props;

        let fileNames = [];
        for(let i = 0; i < this.state.attachments.length;i ++)
        {
            fileNames.push(this.state.attachments.item(i).name);
        }
        return fileNames.map((filename, key) => {
            return (
                <div className={ classes.attachmentContainer } key={key}>
                    <img src="/assets/img/attachment-inactive.png" alt="attachment" className={ classes.attachmentImg } />
                        { filename }
                </div>
            )
        })
    }


    // Show category list function
    drawCategorySelect = () => {
        const { classes } = this.props;

        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status){
            var categGroup = this.props.initialResponse.data.categGroup;
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.categ.name}>
                        {item.categ.name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }
    // Get current sub category list
    getSubCategorySelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            if (typeof this.state.detailData.category == 'undefined' || this.state.detailData.category == null || this.state.detailData.category == '') return null;
            var mainCateg = this.state.detailData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if (item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            var retVal = categGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
            if (typeof retVal == "undefined" || retVal == null) {
                retVal = [];
            }
            return retVal;
        } else {
            return [];
        }
    }

    // Show existing attachments
    renderExistingAttachments = (existing_attachments) => {
        const {classes} = this.props;
        return existing_attachments.map((filename, key) => {
            var filebasename = filename.substring(filename.lastIndexOf('/') + 1);
            var filebasenameUrl = encodeURIComponent(filebasename);
            var apiUrl = this.state.apiUrl;
            if(filebasename == ''){
                return null;
            }else{
                return (
                    <div className={ classes.attachmentContainer } key={key}>
                        <img src="/assets/img/attachment-active.png" alt="attachment" className={ classes.attachmentImg }/>
                            { filebasename }&nbsp;
                            <a href={apiUrl + 'api/download/file/' + filebasenameUrl} target="_blank"><SaveAltIcon /></a>
                    </div>
                )
            }
        })
    }


    // Show buyer list function
    getEventOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.events != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.events;
            return optjionGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
        }else{
            return [];
        }
    }


    // Show buyer list function
    getBuyerOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.buyers != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.buyers;
            return optjionGroup.map((item, key) => ({
                label: item.first_name + ' ' + item.last_name,
                value: item.id
            }));
        }else{
            return [];
        }
    }

    // Show Error Or Succes Message
    checkResponseOrError = () => {
        const { classes } = this.props;
        if(this.state.submitErrorMessage != ''){
            return (
                <span style={{color: '#CC3333'}}>
                    &nbsp;&nbsp;{this.state.submitErrorMessage}
                </span>
            );
        }
        else if(this.state.submitSuccessMessage != ''){
            return (
                <span style={{color: '#33CC33'}}>
                    &nbsp;&nbsp;{this.state.submitSuccessMessage}
                </span>
            );
        }
    }

    handleClickSubmit = async () => {
        if(!this.checkFormValidation()) return;

        this.setState({'submitErrorMessage': ''});

        await this.props.uploadFiles(this.state.attachments, 'invoice_attachments');
        this.handleFileUploadResponse(this.props.uploadResult);
    }

    // Check Form Validtion
    checkFormValidation = () => {

        let loginInfo = this.state.loginInfo;

        let formData = {}
        for (var field in this.fields) {
            formData[field] = this.fields[field].value;
        }

        formData.eventId = (typeof this.state.detailData['eventId'] != 'undefined') ?
            this.state.detailData['eventId'] : '';
        formData.category = (typeof this.state.detailData['category'] != 'undefined') ?
            this.state.detailData['category'] : '';
        formData.sub_category = (typeof this.state.detailData['sub_category'] != 'undefined') ?
            this.state.detailData['sub_category'] : '';
        formData.buyer = (typeof this.state.detailData['buyer'] != 'undefined') ?
            this.state.detailData['buyer'] : '';

        if(formData['key'].length < 1){
            this.setState({'submitErrorMessage': 'Please input key provided by your contact'}); return false;
        }
        /* if(formData['eventId'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Event ID, Name'}); return false;
        } */
        if(formData['category'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Product or Service Category'}); return false;
        }
        if (loginInfo.user.customer.permalink != 'wic' && formData['sub_category'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please select Sub Category' }); return false;
        }
        if(formData['buyer'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Buyer/requestor'}); return false;
        }
        if(formData['description'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Short Decription'}); return false;
        }
        if(formData['invoiceValue'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Invoice value'}); return false;
        }
        /*if(this.state.attachments.length < 1 && this.state.existing_attachments.length < 1){
            this.setState({'submitErrorMessage': 'Please upload attachment file'}); return false;
        }*/

        return true;
    }

    // Form Submitting
    handleFileUploadResponse = async (response) => {
        var res = response['uploadResult'];
        if(res.status){
            let formData = {}
            for (var field in this.fields) {
                formData[field] = this.fields[field].value;
            }
            formData['eventId'] = (typeof this.state.detailData['eventId'] != 'undefined') ?
                this.state.detailData['eventId'] : '';
            formData['category'] = (typeof this.state.detailData['category'] != 'undefined') ?
                this.state.detailData['category'] : '';
            formData['sub_category'] = (typeof this.state.detailData['sub_category'] != 'undefined') ?
                this.state.detailData['sub_category'] : '';
            formData['buyer'] = (typeof this.state.detailData['buyer'] != 'undefined') ?
                this.state.detailData['buyer'] : '';
            formData['existing_attachments'] = this.state.existing_attachments.join(",");
            formData['attachments'] = res.files.join(",");

            await this.props.submitInvoice(formData);

            this.handleSubmitResponse(this.props.commonResponse);
        }else{
            this.setState({'submitErrorMessage': res['message']});
        }
    }


    // Form Submit Result Processing
    handleSubmitResponse = (res) => {
        if(res.commonResponse.status){
            var newItemId = res.commonResponse.affected_id;
            this.setState({'submitSuccessMessage': res.commonResponse.message});
            confirm("Success", "Invoice submitted with the hashkey <" + this.state.detailData.key + ">", false).then(
                async () => {
                    // Click OK
                    this.setState({detailModalOpen: false});
                    this.props.onInvoiceChanged();
                },
                () => {
                    // Click Cancel
                }
            );

            setTimeout(() => {
                this.setState({'submitSuccessMessage': ''});
            }, 4000);
        }else{
            this.setState({'submitErrorMessage': res.commonResponse.message});
        }
    }

    /**********************************************
     *
     * Print Related Functions
     *
     */

    // Print Action
    handleSendEmailButton = async (e) => {
        console.log(this.state.pdfSendEmailList);
        if(this.state.pdfSendEmailList.length < 1){
            this.setState({sendEmailListError: 'Please add send email list!'});
        }else{
            this.setState({sendEmailListError: ''});
            this.setState({sendEmailListResult: ''});
            this.setState({sendEmailListLoading: true});
            var invoiceKey = this.state.detailData.key;
            await this.props.printInvoiceSendEmail(invoiceKey, this.state.pdfSendEmailList, this.props.printResult.data.pdfUrl);
            this.setState({sendEmailListLoading: false});
        }
    }

    // Click PDF download button
    handleDownloadPdfButton = async (e) => {
        //var invoiceKey = this.state.formData.key;
        //await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
        this.processPDFPrintResult();
    }
    processPDFPrintResult = () => {
        console.log(this.state.apiUrl);
        console.log('api/download/' + this.props.printResult.data.pdfUrl);
        window.location.href = this.state.apiUrl + 'api/download/' + this.props.printResult.data.pdfUrl;
    }

    // Print Modal show action
    showPrintModal = async () => {
        var invoiceKey = this.state.detailData.key;
        await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
        this.setState({'isPrintModalOpened': true});
    }
    // Print Modal closed
    handlePrintModalClose = () => {
        this.setState({'isPrintModalOpened': false});
    }

    // Pdf Send Email List Rendor function
    renderPdfSendEmailList = () => {
        const { classes } = this.props;
        if(this.state.pdfSendEmailList.length > 0){
            return (
                <div style={{minWidth: '100%'}} style={{paddingTop: '1rem'}}>
                    {this.renderPdfSendEmailListOptions()}
                </div>
            );
        }else{
            return null;
        }
    }
    renderPdfSendEmailListOptions = () => {
        const { classes } = this.props;
        return this.state.pdfSendEmailList.map((email, key) => {
            return (
                <Grid container spacing={24} key={key}>
                    <Grid item xs={10} style={{marginLeft: 8, flex: 1,}}>
                        {email}
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <IconButton className={classes.iconButton} aria-label="Search" onClick={e => this.removeEmailToPDFSendList(e, key) } style={{padding: '0'}} >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        });
    }
    renderPdfPrintResult = () => {
        const { classes } = this.props;
        if(typeof this.props.printResult.data.pdfUrl != 'undefined' && this.props.printResult.data.pdfUrl.length > 0){
            var pdfUrl = this.state.apiUrl + 'api/preview/' + this.props.printResult.data.pdfUrl;
            return (
                <iframe border="0" id="pdfPrint" type="application/pdf" src={pdfUrl} width="100%" height="400"></iframe>
            );
        }else{
            return (
                <iframe border="0" id="pdfPrint" type="application/pdf" width="100%" height="400"></iframe>
            );
        }
    }
    renderPdfSendEmailButton = () => {
        const { classes } = this.props;
        return (
            <div>
                <p style={{textAlign: 'center', marginBottom: '0'}}>
                    <Button className={classes.button, classes.emailSendButton} variant="outlined" color="primary"  onClick={e => this.handleSendEmailButton(e)} disabled={this.state.sendEmailListLoading}>
                        {this.state.sendEmailListLoading? 'Sending': 'Send'}
                    </Button>
                </p>
                <p style={{display: (this.state.sendEmailListError == ''? 'none': 'block'), textAlign: 'center', color: '#CC3333', marginBottom: '0'}}>{this.state.sendEmailListError}</p>
            </div>
        );
    }

    /*
     *
     * The end of Print Related Functions
     *
    **********************************************/

    // Pdf Send Email List Related
    addEmailToPDFSendList = (e) => {
        e.preventDefault();
        var emailList = this.state.pdfSendEmailList;
        emailList.unshift(this.send_email.value);
        this.send_email.value = '';
        this.setState({pdfSendEmailList: emailList});
    }
    removeEmailToPDFSendList = (e, key) => {
        var emailList = this.state.pdfSendEmailList;
        emailList.splice(key,1);
        this.setState({pdfSendEmailList: emailList});
    }

    // User Detail Dialog
    showDetailDialog = () => {
        const { classes } = this.props;
        if (!this.state.detailModalOpen) return null;
        if(typeof this.state.detailData == 'undefined' || this.state.detailData == null) return null;

        var invoice = this.state.detailData;

        var files = invoice['attachments'].split(',');

        var catOptions = this.drawCategorySelect();
        if (typeof catOptions == "undefined" || catOptions == null) {
            catOptions = [];
        } else {
            catOptions = catOptions.map((item) => ({
                label: item.props.value,
                value: item.props.value
            }));
        }

        var files = invoice.attachments.split(',');
        return (
            <Dialog
                open={this.state.detailModalOpen}
                onClose={this.closeDetail}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="form-dialog-title">{invoice.type} Details {invoice.type != 'Invoice'? ' - Related Invoice Key: ' + invoice.parent.key: ''}</DialogTitle>
                <DialogContent>
                    <div className={ classes.formContainer } ref={ ref => { this.form = ref } }>
                        <GridContainer>
                            <GridItem xs={12}>
                                Enter key provided by your contact, you would have received an email
                            </GridItem>
                            <GridItem xs={12} className={ classes.marginVertical_25 }>
                                <BaseInput className={ classes.input, classes.fullInput } inputRef={ ref => this.fields['key'] = ref } onChange={e => this.handleChangeValue(e, 'key')} value={this.state.detailData.key} />
                            </GridItem>
                        </GridContainer>
                        <GridContainer spacing={40}>
                            <GridItem xs={3}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Event ID, Name
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        {/* <NativeSelect
                                            className={ `${classes.marginVertical_25} ${classes.select_elem} form-select` }
                                            inputRef={ ref => this.fields['eventId'] = ref }
                                            value={this.state.detailData.eventId}
                                            onChange={e => this.handleChangeValue(e, 'eventId')}
                                            name="eventId"
                                        >
                                            <option value={''}></option>
                                            <option value={123456}>123456</option>
                                            <option value={123457}>123457</option>
                                            <option value={123458}>123458</option>
                                            <option value={123459}>123459</option>
                                            <option value={123460}>123460</option>
                                        </NativeSelect> */}
                                        <AutocompleteSelect
                                            className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                            value={this.state.detailData.eventId}
                                            options={ this.getEventOptions() }
                                            onChange={e => this.handleChangeValue(e, 'eventId')}
                                            name="event"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={3}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Product or Service Category
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        <AutocompleteSelect
                                            className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                            value={this.state.detailData.category}
                                            options={catOptions}
                                            onChange={selectedOption => this.handleChangeValue(selectedOption, 'category')}
                                            name="category"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={3}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Sub category
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        <AutocompleteSelect
                                            className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                            value={this.state.detailData.sub_category}
                                            options={this.getSubCategorySelect()}
                                            onChange={selectedOption => this.handleChangeValue(selectedOption, 'sub_category')}
                                            name="subCateg"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={3}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Buyer/requestor
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        <AutocompleteSelect
                                            className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                            value={this.state.detailData.buyer}
                                            options={ this.getBuyerOptions() }
                                            onChange={e => this.handleChangeValue(e, 'buyer')}
                                            name="buyer"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <GridContainer spacing={40}>
                            <GridItem xs={9}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Short Decription
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        <BaseInput className={ classes.input, classes.fullInput } inputRef={ ref => this.fields['description'] = ref } onChange={e => this.handleChangeValue(e, 'description')} value={this.state.detailData.description}  />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={3}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        Value
                                    </GridItem>
                                    <GridItem xs={12} className={ classes.marginVertical_25 }>
                                        <BaseInput type="number" className={ classes.input } inputRef={ ref => this.fields['invoiceValue'] = ref } onChange={e => this.handleChangeValue(e, 'invoiceValue')} value={this.state.detailData.invoiceValue} />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className={ `${classes.marginVertical_25} ${classes.uploadAttachmentContainer}` } onClick={this.handleClickUploadAttachment}>
                                <span className={ classes.uploadAttachment }>Upload attachment</span><AddCircleOutlineIcon className={ classes.addIcon } onClick={ this.handleClick } />
                            </GridItem>
                        </GridContainer>
                        <div className={ classes.attachmentSection }>
                            { this.renderExistingAttachments(files) }
                            { this.renderAttachments() }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={ classes.marginVertical_15, classes.action_bar }>
                    <GridContainer>
                        <GridItem xs={8} className={ classes.marginVertical_15 }>
                            <Button className={ classes.btnSubmit } onClick={ this.handleClickSubmit } ref={ ref => { this.submitButton = ref } }>
                                Submit
                            </Button>
                            {this.checkResponseOrError()}
                        </GridItem>
                        <GridItem xs={2} className={ classes.marginVertical_15 } style={{textAlign: 'right'}}>
                            <Fab color="primary" onClick={ this.showPrintModal } aria-label="Delete" className={classes.fab}>
                                <PrintIcon />
                            </Fab>
                        </GridItem>
                        <GridItem xs={2} className={ classes.marginVertical_15 } style={{textAlign: 'right'}}>
                            <MatButton onClick={this.closeDetail} className={ classes.marginVertical_15, classes.closeDetail } color="primary" style={{padding: '12px 1rem'}}>
                                Close
                            </MatButton>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </Dialog>
        );
    }

    render(){

        const {classes, iconUrl, category} = this.props;
        const {isCollapsed} = this.state;
        let totalValues = 0;
        if (this.props.invoices) {
            this.props.invoices.forEach(invoice => {
                totalValues += invoice.invoiceValue;
            });
        }
        let invoiceCount = this.props.invoices ? this.props.invoices.length : 0;
        let dropDownIconClasses = "";
        if(isCollapsed == true)
            dropDownIconClasses = classes.dropDownIcon + " " + classes.iconTransform;
        else
            dropDownIconClasses = classes.dropDownIcon;

        return(
            <div className={classes.dropDownContainer}>
                <div className={ classes.header } onClick={this.handleClickHeader}>
                    <img src={ iconUrl } className={ classes.iconImage} />
                    <div className={ classes.invoiceCategory }>
                        { category }
                    </div>
                    <div className={ classes.invoiceCount }>
                        { totalValues }
                    </div>
                    <img src="/assets/img/dropdown-icon.png" className={ dropDownIconClasses }/>
                </div>
                <div className={ classes.invoices }>
                    { this.renderInvoices() }
                </div>
                { this.showDetailDialog() }
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.isPrintModalOpened}
                    onClose={this.handlePrintModalClose}
                >
                    <div style={getPrintModalStyle()} className={classes.paper}>
                        <Grid container spacing={24}>
                            <Grid item xs={10} style={{marginLeft: 8, flex: 1,}}>
                                <Typography variant="h6" id="modal-title">
                                    PDF Print
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'center'}}>
                                <IconButton className={classes.iconButton} type="submit" onClick={e => this.handleDownloadPdfButton(e)} aria-label="Download">
                                    <SaveAltIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {this.renderPdfPrintResult()}
                        <Typography variant="subtitle1" id="simple-modal-description" style={{marginTop: '0.5rem'}}>
                        Input emails that you want to send pdf file
                        </Typography>
                        <form onSubmit={e => this.addEmailToPDFSendList(e)}>
                            <Paper elevation={1} style={{padding: '2px 4px', display: 'flex', alignItems: 'center',width: '100%', marginTop: '0.5rem'}}>
                                <InputBase className={classes.input} type="email" name="email"  inputRef={ ref => { this.send_email = ref } } placeholder="Input Email" style={{marginLeft: 8, flex: 1,}} required />
                                <IconButton className={classes.iconButton} type="submit" aria-label="Search">
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Paper>
                        </form>
                        {this.renderPdfSendEmailList()}
                        {this.renderPdfSendEmailButton()}
                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {...state, initialResponse: state.initialResponse, account: state.account}
}

const mapDispatchToProps = {uploadFiles, submitInvoice, searchInvoiceKey, printInvoice, printInvoiceSendEmail}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(invoiceDropDownStyle)(InvoiceDropDown));