const logInPageStyle = theme => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& input': {
            marginLeft: 10,
            marginRight: 10,
        }
    },
    form: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        maxWidth: 400,
        minWidth: 280
    },
    input: {
        '&::placeholder': {
            color: '#000',
            fontSize: 18,
            opacity: 1,
        },
        background: 'rgba(194, 194, 194, 0.06)',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 10,
        fontSize: 18,
        color: '#000',
        boxSizing: 'border-box',
        height: 43,
        borderWidth: 0,
        paddingLeft: 10,
        marginLeft: '0px !important',
        marginRight: '0px !important',
    },
    inputRoot: {
        width: '100%',
        '&::before': {
            display: 'none'
        },
        '&::after': {
            display: 'none'
        },
        border: '1px solid rgba(18, 145, 249, 0.18)',
        background: 'rgba(194, 194, 194, 0.06)',
        borderRadius: 10,
        marginBottom: 17,
    },
    btnLogin: {
        background: '#37BFFF',
        borderRadius: 30,
        fontSize: 18,
        fontWeight: 700,
        color: 'white',
        width: 146,
        height: 46,
        marginBottom: 33,
        marginTop: 33,
    },
    btnSignInGoogle: {
        marginTop: 24,
        width: 250,
        height: 46,
        lineHeight: '46px',
        borderRadius: 30,
        fontSize: 18,
        fontWeight: 700,
        color: 'white',
        background: 'rgba(55, 191, 255, 0.4)',
        textAlign: 'center',
        '&:hover': {
            color: 'white',
        }
    },
    forgotPasswordLabel: {
        marginTop: 26,
        color: '#000000',
        textAlign: 'center',
        '& a': {
            textDecoration: 'underline',
            color: '#37BFFF',
        }
    },
    termsLabel: {
        marginTop: 26,
        color: '#000000',
        textAlign: 'center',
        '& a': {
            textDecoration: 'underline',
            color: '#37BFFF',
        }
    },
    errorMsg: {
        fontSize: 18,
        textDecoration: 'underline',
        color: '#F10101',
        fontWeight: 300,
    },
    sideBanner: {
        flex: 2,
        display: 'flex',
        '& img': {
            height: '100%',
            marginLeft: -2,
        },
        background: 'url("/assets/img/newui/login/bg-login.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    signInLabel: {
        color: '#000000',
        fontSize: 30,
        fontWeight: 900,
        marginBottom: 45,
    },
    logoContainer: {
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '30px'
    },
    orContainer: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: '30px'
    },
    underline: {
        borderBottom: '1px solid #e1e1e1',
        flex: 1,
        height: '12px',
    },
    spanOr: {
        padding: '0px 15px',
        fontSize: '.9375rem',
        color: '#757575',

    },
    btnSignUp: {
        marginTop: '45px',
        marginBottom: '5px',
        background: '#e1e1e1',
        padding: '20px 0px',
        width: '100%',
    },
    copyrightLabel: {
        textAlign: 'center',
        marginTop: '40px',
    },
    footerNav: {
        textDecoration: 'none',
        color: 'black',
        margin: '0px 10px'
    },
    footerNavContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    login_input: {
        backgroundColor: '#fafafa'
    },
    copyrightLabel: {
        color: '#DDDDDD',
        textAlign: 'center'
    },
    footerNavContainer: {
        color: '#DDDDDD',
        textAlign: 'center',
    },
    footerNav: {
        color: '#DDDDDD',
        marginLeft: '10px',
        marginRight: '10px'
    },
    forgotPassword: {
        textAlign: 'right',
        fontSize: '0.95rem'
    },
    forgotPasswordButton: {
        color: '#b13131'
    },
    resetCloseBtn: {
        borderRadius: 30,
        backgroundColor: '#666',
        color: 'white',
        width: 100,
        padding: '0.3rem 0.8rem',
        marginRight: 20,
    },
    resetSubmitBtn: {
        borderRadius: 30,
        backgroundColor: '#37BFFF',
        color: 'white',
        fontSize: 18,
        padding: '0.3rem 0.8rem',
        width: 200,
    },
    customerContainer: {
        background: 'rgba(55, 191, 255, 0.17)',
        borderRadius: 20,
        width: 260,
        height: 179,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        fontSize: 24,
        fontWeight: 600,
        flexBasis: 'calc(25% - 40px)',
        color: '#1291F9',
        marginTop: 70,
        marginLeft: 20,
        marginRight: 20,
        alignItems: 'center',
        opacity: 0.75,
        minWidth: 260,
        '&:hover': {
            opacity: 1,
            cursor: 'pointer'
        },
        '& > div': {
            borderRadius: 500,
            border: '3px solid #1291F9',
            width: 100,
            height: 100,
            top: 0,
            transform: 'translateY(-50%)',
            position: 'absolute',

            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                
            }
        }
    },
    customerLabel: {
        fontWeight: 800,
        fontSize: 40,
        lineHeight: '50px',
        color: '#1291F9',
        marginTop: 33,
        marginBottom: 20,
    },
    inputPwdModalRoot: {
        width: 400,
        borderRadius: 20,
        minHeight: 260,
    },
    backIconContainer: {
        color: '#1291F9',
        borderRadius: 500,
        padding: 0,
        position: 'absolute',
        top: 30,
        left: 30,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    }
});
export default logInPageStyle;