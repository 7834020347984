import React, { Component } from "react";

import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import ContactSidebarStyle from "./ContactSidebarStyle.jsx";

import { userLogout } from "../../redux/actions/accountActions";
import {
  saveUiSettings,
  toggleContactSidebar
} from "../../redux/actions/uiActions";
import {
  saveContacts, setUserOnline, setTargetUser, setSocket, saveChatMessage, clearChatMessage
} from "../../redux/actions/chatActions";
import { getGlobalInitialData } from "redux/actions/commonActions";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import RecentContact from "./RecentContact/index";

import ContactChat from "./ContactChat/index";

import socketIOClient from "socket.io-client";

import { withTranslation  } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className={`tab-main-content`}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const socketUrl =
  process.env.NODE_ENV === "production"
    ? (
      process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_SOCKET_PROD : process.env.REACT_APP_SOCKET_TEST
    )
    : process.env.REACT_APP_SOCKET_LOCAL;

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class ContactSidebar extends Component {
  state = {
    tokenLoaded: false,
    query: "",
    value: 0,
    loginInfo: {
      user: {
        first_name: "",
        last_name: ""
      }
    },
    customerInfo: null
  };

  //socket = socketIOClient(socketUrl);

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"]
    };
  }

  componentDidMount() {
    //this.configChat();
  }

  configChat() {

    this.props.setSocket(this.socket);

    const loginStatus = this.props.account.loginStatus;
    let userId = loginStatus.user.id;

    this.socket.emit("join", userId);

    this.socket.on('disconnect', () => {
      console.log('you have been disconnected');
    });

    this.socket.on('reconnect', () => {
      this.socket.emit("join", userId);
    });

    this.socket.on("join.return", (data) => {
      console.log("Client joined", data);
      this.props.saveContacts(data['contacts']);
    });

    this.socket.on("join.anotherUser", (data) => {
      console.log("Another user joined", data.user.id);
      this.props.setUserOnline(data['user']['id'], true);
    });

    this.socket.on("left.anotherUser", (data) => {
      console.log("Another user left", data.userId);
      this.props.setUserOnline(data['userId'], false);
    });

    this.socket.on("chat.start", (data) => {
      console.log("Chat started", data);
      let userId = data.userId;
      let messages = data.messages;
      this.props.setTargetUser(userId);
      this.props.clearChatMessage(userId);
      messages.forEach(m => {
        this.props.saveChatMessage(userId, m.fromUserId, m.targetUserId, m.message, m.time);
      });
      this.setState({value: 0});
      this.setState({value: 1});
    });

    this.socket.on("chat.start.another", (data) => {
      console.log("Chat with Another user started", data);
      let userId = data.userId;
      let messages = data.messages;
      this.props.clearChatMessage(userId);
      messages.forEach(m => {
        this.props.saveChatMessage(userId, m.fromUserId, m.targetUserId, m.message, m.time);
      });
    });

    this.socket.on("chat.sendMessage", (data) => {
      console.log("Chat with send message to target user", data);
      let targetUserId = String(data.userId);
      let fromUserId = String(userId);
      let message = data.message;
      let time = data.time;
      this.props.saveChatMessage(targetUserId, fromUserId, targetUserId, message, time);
    });

    this.socket.on("chat.receiveMessage", (data) => {
      console.log("Chat with send message to target user", data);
      let targetUserId = String(userId);
      let fromUserId = String(data.fromUserId);
      let message = data.message;
      let time = data.time;
      this.props.saveChatMessage(fromUserId, fromUserId, targetUserId, message, time);

      this.props.setTargetUser(fromUserId);
      this.setState({value: 1});
    });
  }

  userSelected = (userId) => {
    this.socket.emit("chat.start", {userId});
  }

  handleChange = (event, newValue) => {
    console.log(event, newValue);
    this.setState({ value: newValue });
  };

  a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    const { query } = this.state;

    const loginStatus = this.props.account.loginStatus;
    if (!loginStatus.isLogedIn) {
      return null;
    }

    let isOpened = this.props.uiSetting.data.contactSidebarOpened;

    return (
      <div
        className={[classes.ContactSidebarContainer, `ContactSidebar`].join(
          " "
        )}
        style={{ width: isOpened ? 240: 0 }}
      >
        <AppBar position="static" className={`tabs-wrapper`}>
          <Tabs
            className={`tabs-wrapper-header`}
            indicatorColor="primary"
            TabIndicatorProps={{className: `MuiTabsIndicator`}}
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              className={`tab-item`}
              icon={
                <img src="/assets/img/newui/contactBar/contact.png" alt="C" />
              }
              {...this.a11yProps(0)}
            />
            <Tab
              className={`tab-item`}
              icon={
                <img src="/assets/img/newui/contactBar/message.png" alt="C" />
              }
              {...this.a11yProps(1)}
            />
            {/* <Tab
              className={`tab-item`}
              icon={
                <img src="/assets/img/newui/contactBar/settings.png" alt="C" />
              }
              {...this.a11yProps(1)}
            /> */}
          </Tabs>
        </AppBar>
        <TabPanel className={`tabpanel border-t`} value={this.state.value} index={0}>
          <RecentContact onUserSelected={this.userSelected} />
        </TabPanel>
        <TabPanel className={`tabpanel border-t`} value={this.state.value} index={1}>
          <ContactChat></ContactChat>
        </TabPanel>
        {/* <TabPanel className={`tabpanel border-t`} value={this.state.value} index={2}>
          <div className={`p-3`}>
            { t('Settings') }
          </div>
        </TabPanel> */}
        <div className={ classes.sidebarPuller } onClick={ this.props.toggleContactSidebar }>
              <div className={ classes.verticalLine }>
              </div>
              <div className={ classes.verticalLine }>
              </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList
  };
};

const mapDispatchToProps = {
  getGlobalInitialData,
  userLogout,
  saveUiSettings,
  toggleContactSidebar,
  saveContacts,
  setUserOnline,
  setTargetUser,
  setSocket,
  saveChatMessage,
  clearChatMessage
};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ContactSidebarStyle)(ContactSidebar)));
