import React, { Component } from 'react'
import { connect } from 'react-redux';

import setupPageStyle from './setupPageStyle'
import { withStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import BaseInput from 'components/BaseInput';
import Button from 'components/Button';

import NativeSelect from 'components/NativeSelect';
import AutocompleteSelect from 'components/AutocompleteSelect/index.jsx';

import { saveBudgetSettings } from '../../../redux/actions/adminActions';

import { withTranslation  } from 'react-i18next';
import Cookies from "js-cookie";

class RequestorForm extends Component
{
    state = {
        formData: {
            eventId: '',
            category: '',
            sub_category: '',
            buyer_id: '',
            vendor_id: '',
            description: '',
            value: ''
        },
        submitErrorMessage: '',
        submitSuccessMessage: '',
        attachments: [],
        isSuccess: false,
    }

    componentDidMount() {
        const { budget } = this.props;
        if (budget) {
            this.setState({ formData: Object.assign({...budget, buyer: budget.buyer_id }) });
        }
    }

    // Show category list function
    drawCategorySelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status){
            var categGroup = this.props.initialResponse.data.categGroup;
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.categ.name}>
                        {item.categ.name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }
    // Show sub-category list function
    drawSubCategorySelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status){
            if(typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if(item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.name}>
                        {item.name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }

    // Show buyer list function
    getEventOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.events != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.events;
            return optjionGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
        }else{
            return [];
        }
    }

    // Show buyer list function
    drawBuyerSelect = () => {
        const { classes } = this.props;

        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.buyers != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.buyers;
            return optjionGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.id}>
                        {item.first_name + ' ' + item.last_name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }

    drawVendorSelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.vendors != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.vendors;
            return optjionGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.id}>
                        {item.first_name + ' ' + item.last_name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {};
        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.formData[valueType] != 'undefined' && this.state.formData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }
        if (valueType == "category") {
            dataOject["sub_category"] = "";
        }
        this.setState({ formData: Object.assign(this.state.formData, dataOject), isSuccess: false });
    }

    submitData = async (e) => {
        const { t } = this.props;

        this.setState({submitErrorMessage: ''});
        if(this.state.formData.eventId.length < 1 && this.state.formData.category.length < 1 && this.state.formData.buyer_id.length < 1){
            this.setState({submitErrorMessage: t('Event ID/Category/Buyer is required')});
            return;
        }
        if(this.state.formData.description.length < 1){
            this.setState({submitErrorMessage: t('Description is required')});
            return;
        }
        if(this.state.formData.value < 1){
            this.setState({submitErrorMessage: t('Budget value is required')});
            return;
        }
        this.setState({submitErrorMessage: ''});
        let { formData } = this.state;
        const { user } = this.props.account.loginStatus;

        if (user.profile.user_type === 'BUYER') {
            formData['buyer_id'] = user.id;
        }

        if (formData.id) {
            const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
            const loginStatus = this.props.account.loginStatus;

            let result = await fetch(apiUrl + 'api/admin/update_budget', {
                method: 'POST',
                headers: {
                    Authorization: `Token ${loginStatus.token}`,
                    "X-CSRFToken": Cookies.get("csrftoken"),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then(result => {
                if (result['status']) {
                    this.setState({ formData: {
                        eventId: '',
                        category: '',
                        sub_category: '',
                        buyer_id: '',
                        description: '',
                        value: ''
                    } });
                    this.props.submitted();
                    return true;
                }

                this.setState({submitErrorMessage: result['message']});
                return false;
            });
            return;
        }

        const result = await this.props.saveBudgetSettings(this.state.formData);
        if (result) {
            this.setState({ isSuccess: true });
            await this.props.submitted();
        }
    }

    submitResultMessage = () => {
        const { classes, t } = this.props;
        const { isSuccess } = this.state;
        if(typeof this.props.commonResult.result.status != 'undefined'){
            if(this.props.commonResult.result.status && isSuccess){
                return(
                    <p className={`success-message`}>
                        {t('Saved Successfully!')}
                    </p>
                );
            }
            if(!this.props.commonResult.result.status) {
                return(
                    <p className={`error-message`}>
                        {this.props.commonResult.result.message}
                    </p>
                );
            }
        }else {
            return null;
        }
    }

    validationErrorCheck = () => {
        const { classes } = this.props;

        if(typeof this.state.submitErrorMessage != 'undefined' && this.state.submitErrorMessage.length > 0){
            return(
                <p className={`error-message`}>
                    {this.state.submitErrorMessage}
                </p>
            );
        }else {
            return null;
        }
    }

    renderBuyerSelect = () => {
        const { classes, account, t } = this.props;
        const user = account.loginStatus.user;
        if (user.profile.user_type === 'ADMIN') {
            return (
                <GridItem xs={12} md={6} lg={4}>
                    <GridContainer className={`md:pr-3`}>
                        <GridItem xs={12} className={ classes.inputLabel }>
                            {t('Buyer')}
                        </GridItem>
                        <GridItem xs={12}>
                            <NativeSelect
                                value={this.state.formData.buyer_id}
                                onChange={e => this.handleChangeValue(e, 'buyer_id')}
                                name="eventId"
                            >
                                <option key={0} value={''}></option>
                                {this.drawBuyerSelect()}
                            </NativeSelect>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            );
        }

        return null;
    }

    renderVendorSelect = () => {
        const { classes, account, t } = this.props;
        const user = account.loginStatus.user;

        return (
            <GridItem xs={12} md={6} lg={4}>
                <GridContainer className={`md:pr-3`}>
                    <GridItem xs={12} className={ classes.inputLabel }>
                        {t('Vendor')}
                    </GridItem>
                    <GridItem xs={12}>
                        <NativeSelect
                            value={this.state.formData.vendor_id}
                            onChange={e => this.handleChangeValue(e, 'vendor_id')}
                            name="eventId"
                        >
                            <option key={0} value={''}></option>
                            {this.drawVendorSelect()}
                        </NativeSelect>
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }

    render(){
        const { classes, t } = this.props;
        const { formData } = this.state;

        return(
            <div
                className={ classes.subFormContainer }
                style={{ padding: '20px 15px', borderRadius: 8,}}>
                <GridContainer>
                    <GridItem xs={12} className={ classes.subFormTitle }>
                        {
                            isNaN(formData['id']) ? t('Add Budget Setting') : t('Edit Budget Setting')
                        }
                    </GridItem>
                </GridContainer>
                <GridContainer spacing={16}>
                    <GridItem xs={12} md={6} lg={4}>
                        <GridContainer className={`md:pr-3`}>
                            <GridItem xs={12} className={ classes.inputLabel }>
                                {t('Event ID, Name')}
                            </GridItem>
                            <GridItem xs={12}>
                                <AutocompleteSelect
                                    className={`${classes.marginVertical_10} ${classes.select_elem} form-select`}
                                    value={this.state.formData.eventId}
                                    options={ this.getEventOptions() }
                                    onChange={e => this.handleChangeValue(e, 'eventId')}
                                    name="event"
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={4}>
                        <GridContainer className={`lg:pr-3`}>
                            <GridItem xs={12} className={ classes.inputLabel }>
                                {t('Category Name')}
                            </GridItem>
                            <GridItem xs={12}>
                                {/* <BaseInput className={ classes.input }  /> */}
                                <NativeSelect
                                    value={this.state.formData.category}
                                    onChange={e => this.handleChangeValue(e, 'category')}
                                    name="eventId"
                                >
                                    <option key={0} value={''}></option>
                                    {this.drawCategorySelect()}
                                </NativeSelect>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={4}>
                        <GridContainer className={`lg:pr-3`}>
                            <GridItem xs={12} className={ classes.inputLabel }>
                                {t('Subcategory')}
                            </GridItem>
                            <GridItem xs={12}>
                                {/* <BaseInput className={ classes.input }  /> */}
                                <NativeSelect
                                    value={this.state.formData.sub_category}
                                    onChange={e => this.handleChangeValue(e, 'sub_category')}
                                    name="eventId"
                                >
                                    <option key={0} value={''}></option>
                                    {this.drawSubCategorySelect()}
                                </NativeSelect>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    {
                        this.renderBuyerSelect()
                    }
                    {
                        this.renderVendorSelect()
                    }

                    <GridItem xs={12} md={6} lg={4}>
                        <GridContainer className={`sm:pr-0 md:pr-3`}>
                            <GridItem xs={12} className={ classes.inputLabel }>
                                {t('Short Description')}
                            </GridItem>
                            <GridItem xs={12}>
                                <BaseInput className={ classes.input }
                                    onChange={e => this.handleChangeValue(e, 'description')} value={this.state.formData.description} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={6} lg={4}>
                        <GridContainer className={`md:pr-3`}>
                            <GridItem xs={12} className={ classes.inputLabel }>
                                {t('Budget Value')}
                            </GridItem>
                            <GridItem xs={12}>
                                <BaseInput type="number" className={ classes.input }
                                    onChange={e => this.handleChangeValue(e, 'value')} value={this.state.formData.value} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4} className={ classes.marginTop_40 }>
                        <Button className={ `${classes.btnSubmit}` } onClick={e => this.submitData(e)}>
                            {t('Submit')}
                        </Button>
                        <Button className={ `${classes.btnSubmit}` } onClick={ this.props.cancelled } style={{ marginLeft: 20, background: '#FFC700' }}>
                            {t('Cancel')}
                        </Button>
                    </GridItem>
                    <GridItem xs={8} style={{paddingTop: '40px'}}>
                        {this.validationErrorCheck()}
                        {this.submitResultMessage()}
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state, initialResponse: state.initialResponse}
}
const mapDispatchToProps = {saveBudgetSettings};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(setupPageStyle)(RequestorForm)));