import React, { Component } from 'react'

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {  Redirect  } from 'react-router-dom';

import { withStyles, Checkbox } from '@material-ui/core'
import qbCallbackPageStyle from './qbCallbackPageStyle.jsx'
import Button from 'components/Button/index.jsx';
import RadioButton from 'components/RadioButton/index.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import Check from "@material-ui/icons/Check";
import LongCheckBox from 'components/LongCheckBox/index.jsx';
import { Link } from 'react-router-dom';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { ClipLoader } from 'react-spinners';

import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';
import CustomAutocomplete from 'components/CustomAutocomplete/index.jsx';

import { submitQbAuth } from '../../redux/actions/adminActions';
import { getOrganizationList } from '../../redux/actions/commonActions';

const queryString = require('query-string');

class QbCallbackPage extends Component {

    state = {
        status: '',
        isLoading: true
    }

    constructor(props){
        super(props);
    }

    componentDidMount = async() => {
        var status = this.props.match.params.status;
        console.log("status", status);
        this.setState({status: status});
        const parsed = queryString.parse(this.props.location.search);
        parsed['action'] = status;
        if(status == "create"){
            await this.props.submitQbAuth(parsed);
            this.setState({'isLoading': false});
            setTimeout(() => {
                window.self.close();
            }, 600);
        }else if(status == "check"){
            await this.props.submitQbAuth(parsed);
            this.setState({'isLoading': false});
            setTimeout(() => {
                window.self.close();
            }, 600);
        }
    }

    renderSelectUserType = () => {
        const { classes } = this.props;
        const { userType } = this.state;
        
        var status = this.props.match.params.status;

        var label = 'Granted from QuickBook';
        if(status == 'check'){
            label = 'Checking QuickBook Bill Status';
        }

        if(this.state.userTypeSelected == true)
            return null;
        if(this.state.isLoading){
            return(
                <div className={classes.container}>
                    <div className={ classes.title }>
                        { label }
                    </div>
                    <div className={ classes.doneIcon }>
                        <ClipLoader
                            sizeUnit={"rem"}
                            size={6}
                            color={'#123abc'}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            )
        }else{
            return(
                <div className={classes.container}>
                    <div className={ classes.title }>
                        Granted from QuickBook
                    </div>
                    <div className={ classes.doneIcon }>
                        <DoneAllIcon />
                    </div>
                </div>
            )
        }

    }

    processDone = () => {
        if(this.state.isLoading) return null;
        else{
            return (
                <Redirect to='/admin/pay' />
            )
        }
    }

    render(){
        const { classes } = this.props;
        const { userTypeSelected } = this.state;
        const mainContainerClass = userTypeSelected ? classes.pageContainer2 : classes.pageContainer;
        const logImage = userTypeSelected ? "/assets/img/Logo_color.png": "/assets/img/Logo_white.png";
        return (
            <div className={ mainContainerClass }>
                { this.processDone() }
                <GlobalProcessor />
                <div className={ classes.signUpHeader }>
                    <a href="/">
                        <img src={ logImage } className={ classes.logoImage } />
                    </a>
                </div>
                <div className={ classes.contentContainer }>
                {
                    this.renderSelectUserType()
                }
                </div>
                <Footer theme={ userTypeSelected ? 'black' : 'white' } showSimple={true} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    commonResult: state.commonResult, actionResult: state.actionResult, orgList: state.orgList
});

const mapDispatchToProps = { getOrganizationList, submitQbAuth }

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(qbCallbackPageStyle)(QbCallbackPage));