import React, { Component } from 'react'
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import $ from 'jquery';
import Button from 'components/Button/index.jsx';
import SortBtn from 'components/SortBtn';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core';
import existingUserPageStyle from './existingUserPageStyle.jsx';
import { confirm } from "../../../components/Confirm/index";
import { successConfirm } from "components/SuccessConfirm/index";

import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import Moment from 'react-moment';

import { getGlobalInitialData } from "redux/actions/commonActions";
import { userBuyerList, userBuyerStatus, userBuyerDelete } from '../../../redux/actions/adminActions';
import { withTranslation } from 'react-i18next';

import SearchInputType2 from "components/SearchInputType2";

const override = css`
    posistion: absolute;
    bottom: 0;
    left: 0;
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

class ExistingUserPage extends Component {
    btnSendRefs = [];

    setRef = (ref) => {
        this.btnSendRefs.push(ref);
    };

    state = {
        userViewModalOpen: false,
        userlist: [],
        userDetail: null,
        query: '',
        sortOption: {
            sortBy: 'name',
            sortAsc: null
        }
    }

    constructor(props) {
        super(props);
        this.loadData();
    }

    loadData = async () => {
        this.setState({ userlist: [] });
        await this.props.userBuyerList();
        let res = this.props.commonResult.result;
        this.setState({ userlist: res.buyers });
        while (res.nextPage !== -1) {
            await this.props.userBuyerList(res.nextPage);
            res = this.props.commonResult.result;
            const userlist = [...this.state.userlist, ...res.buyers];
            this.setState({ userlist });
        }
    }

    // User Detail Dialog
    showUserDetailDialog = () => {
        const { classes } = this.props;
        if (this.state.userDetail == null) return null;
        var user = this.state.userDetail;
        return (
            <Dialog
                open={this.state.userViewModalOpen}
                onClose={this.closeUserDetail}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="form-dialog-title">User Details: {user.first_name} {user.last_name}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={3}>
                            First Name: {user.first_name}
                        </Grid>
                        <Grid item xs={3}>
                            Last Name: {user.last_name}
                        </Grid>
                        <Grid item xs={3}>
                            Email: {user.email}
                        </Grid>
                        <Grid item xs={3}>
                            Phone: {user.phone}
                        </Grid>
                        <Grid item xs={3}>
                            Category: {user.profile.user_type}
                        </Grid>
                        <Grid item xs={9}>
                            Company: {user.profile.company}
                        </Grid>
                        <Grid item xs={3}>
                            Status: {user.profile.is_approved ? 'Yes' : 'No'}
                        </Grid>
                        <Grid item xs={3}>
                            Register: <Moment format="MMM, D, YYYY">{user.date_joined}</Moment>
                        </Grid>
                        <Grid item xs={3}>
                            Last login: <Moment format="MMM, D, YYYY">{user.last_login}</Moment>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={this.closeUserDetail} style={{ width: 130, height: 30, color: 'white', background: '#1D8ACF' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    showUserDetail = (e, user) => {
        this.setState({ userDetail: user });
        this.setState({ userViewModalOpen: true });
    }
    closeUserDetail = () => {
        this.setState({ userDetail: null });
        this.setState({ userViewModalOpen: false });
    }


    // User approve
    actionApproveUser = async (e, user, flag) => {
        await this.props.userBuyerStatus(user.id, flag);
        this.loadData();
    }

    // User delete
    actionDeleteUser = async (e, user) => {
        confirm("Confirm", "Are you sure delete this user \"" + user.first_name + " " + user.last_name + "\"?").then(
            async () => {
                console.log("Click OK");
                await this.props.userBuyerDelete(user.id);
                await this.props.getGlobalInitialData();
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    actionSendPasswordLinkUser = async (e, user, key) => {
        if ($(".send-btn-" + key).hasClass("is-loading")) {
            return;
        }
        let userIds = [];
        if (user === null) {
            userIds = this.state.userlist.map(user => user.id);
        } else {
            userIds = [user.id];
        }
        $(".send-btn-" + key).addClass("is-loading");
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        return fetch(apiUrl + 'api/account/send_regenerate', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Cookie': '',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                userids: userIds
            })
        })
            .then(res => {
                $(".send-btn-" + key).removeClass("is-loading");
                return res.json();
            })
            .then(res => {
                if (res.status) {
                    if (user == null) {
                        successConfirm("Success", "Email link sent to all users", false);
                    }
                    else {
                        successConfirm("Success", "Email link sent to user", false);
                    }
                } else {
                    confirm("Error", res.message, false);
                }
            })
            .catch(err => {
                $(".send-btn-" + key).removeClass("is-loading");
                confirm("Error", "Server error occured. Please try later!", false);
            });
    }

    showUserList = () => {
        const { classes, t } = this.props;
        var userlist = this.state.userlist;
        const { sortBy, sortAsc } = this.state.sortOption;

        // Sort users
        let filteredUserlist = [...userlist];
        filteredUserlist.sort((a, b) => {
            if (sortBy == '' || sortAsc == null) {
                return 1;
            }

            let retVal = 1;
            if (sortBy === 'name') {
                let aName = a.first_name + ' ' + a.last_name;
                let bName = b.first_name + ' ' + b.last_name;
                if (aName.toLowerCase() < bName.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'category') {
                if (a.profile.user_type.toLowerCase() < b.profile.user_type.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'company_name') {
                if (a.profile.company.toLowerCase() < b.profile.company.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'status') {
                if (a.profile.is_approved < b.profile.is_approved) {
                    retVal = -1;
                }
            }

            retVal = !sortAsc ? -1 * retVal : retVal;
            return retVal;
        });

        // Filter users
        if (this.state.query != '') {
            filteredUserlist = filteredUserlist.filter(user => {
                let userName = user.first_name + ' ' + user.last_name;
                if (userName.toLowerCase().includes(this.state.query.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }

        return filteredUserlist.map((user, key) => {
            return (
                <div key={key} className={classes.tableRow}>
                    <div className={classes.columnName}>
                        <Tooltip title={user.email}>
                            <span>{user.first_name} {user.last_name}</span>
                        </Tooltip>
                    </div>
                    <div className={classes.columnCategory}>
                        <span>{t(user.profile.user_type)}</span>
                    </div>
                    <div className={classes.columnCompany}>
                        <span>{user.profile.company}</span>
                    </div>
                    <div className={classes.columnView}>
                        <div className={classes.suspendContainer} style={{ 'display': user.profile.is_approved == true ? 'flex' : 'none' }}>
                            <Tooltip title={t('Suspend user')}>
                                <Button className={classes.button} onClick={e => this.actionApproveUser(e, user, false)} style={{ background: 'rgba(255, 0, 46, 0.2)', color: '#EB5757' }}>{t('Suspend')}</Button>
                            </Tooltip>
                        </div>
                        <div className={classes.suspendContainer} style={{ 'display': user.profile.is_approved == false ? 'flex' : 'none' }}>
                            <Tooltip title={t('Activate user')}>
                                <Button className={classes.button} onClick={e => this.actionApproveUser(e, user, true)} style={{ background: 'rgba(0, 153, 255, 0.2)', color: '#1291F9' }}>{t('Activate')}</Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.columnActions}>
                        <div className={classes.deleteContainer}>
                            <Tooltip title={t('View details')}>
                                <a onClick={e => this.showUserDetail(e, user)}>
                                    <img src="/assets/img/black-eye.svg" style={{ cursor: 'pointer', marginRight: 10 }} />
                                </a>
                            </Tooltip>
                            <Tooltip title={t('Delete user')}>
                                <button onClick={e => this.actionDeleteUser(e, user)}>
                                    <img src={'/assets/img/black-trash.svg'} />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.columnView}>
                        <Tooltip className={classes.columnViewResend + ` send-btn-` + key} title={t('Send regenerate password link')}>
                            <div onClick={e => this.actionSendPasswordLinkUser(e, user, key)}>
                                <button className={classes.btnSendLink}>
                                    Send link
                                </button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            );
        });
    }

    sortOptionChanged = (sortBy, sortAsc) => {
        this.setState({
            sortOption: {sortBy, sortAsc}
        })
    }

    queryChanged = (value) => {
      this.setState({ query: value });
    }

    render() {
        const { classes, t } = this.props;
        const { query } = this.state;
        const { sortBy, sortAsc } = this.state.sortOption;
        return (
            <div className={classes.totalWrapper}>
                <div className={`search-bar absolute`}>
                    <SearchInputType2
                        placeholder={t('Search') + "..."}
                        value={query}
                        onChange={(event) => this.queryChanged(event.target.value)}
                        onClose={() => this.queryChanged("")}
                    />
                </div>
                <div className={classes.tableContainer}>
                    <div className={classes.tableHeader}>
                        <div className={classes.columnName}>
                            {t('Name')}
                            <SortBtn sortOptionChanged={this.sortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'name'}
                            />
                        </div>
                        <div className={classes.columnCategory}>
                            {t('Category')}
                            <SortBtn sortOptionChanged={this.sortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'category'}
                            />
                        </div>
                        <div className={classes.columnCompany}>
                            {t('Company Name')}
                            <SortBtn sortOptionChanged={this.sortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'company_name'}
                            />
                        </div>
                        <div className={classes.columnView}>
                            {t('Status')}
                            <SortBtn sortOptionChanged={this.sortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'status'}
                            />
                        </div>
                        <div className={classes.columnActions}>
                            {t('Actions')}
                        </div>
                        <div className={classes.columnView}>
                            {t('Reset Password')}
                        </div>
                    </div>
                    { this.showUserList()}
                    <div className={classes.bottomAction}>
                        <Tooltip className={classes.columnViewResend + ` send-btn-000`} title={'Send regenerate password link to all users'}>
                            <div onClick={e => this.actionSendPasswordLinkUser(e, null, '000')}
                                style={{
                                    width: '9rem',
                                    float: 'right',
                                    paddingTop: '1rem',
                                    marginBottom: 30,
                                    textAlign: 'right',
                                    marginRight: 0,
                                }}>
                                <VpnKeyIcon ref={this.setRef} />
                                <ClipLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={24}
                                    color={'#0072BB !important'}
                                    loading={true}
                                />&nbsp;
                                {t('Send to All')}
                            </div>
                        </Tooltip>
                    </div>
                    { this.showUserDetailDialog()}
                </div>
            </div>
        )

    }

}

const mapStateToProps = state => ({
    commonResult: state.commonResult
});
const mapDispatchToProps = { userBuyerList, userBuyerStatus, userBuyerDelete, getGlobalInitialData }
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(existingUserPageStyle)(ExistingUserPage)));