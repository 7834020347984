
const loadingStyle = theme => ({
    overlay: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    progress: {
        width: '50px',
    },
    svg: {
        color: '#1D8ACF'
    }
});
export default loadingStyle;