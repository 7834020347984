import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from "./utils/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { withRouter } from 'react-router'
import { withStyles } from "@material-ui/core";
import AppStyle from "./AppStyle";

import store from './redux/store';
import HomePage from './views/HomePage'
import SelectCustomerPage from './views/SelectCustomerPage';
import LoginPage from './views/LoginPage';
import SignUpPage from 'views/SignUpPage/SignUpPage';
import SetupPage from 'views/SetupPage';
import RaiseInvoicePage from 'views/RaiseInvoicePage';
import TrackPaymentPage from 'views/TrackPaymentPage';
import BuyRequestPage from 'views/BuyRequestPage';
import ReviewInvoicesPage from 'views/ReviewInvoicesPage';
import TrackPerformancePage from 'views/TrackPerformancePage';
import AdminSetupPage from 'views/Admin/SetupPage';
import ApprovePage from 'views/Admin/ApprovePage';
import TrackPage from 'views/Admin/TrackPage';
import PayPage from 'views/Admin/PayPage';
import ReportPage from 'views/Admin/ReportPage/ReportPage';
import ProfilePage from 'views/ProfilePage/ProfilePage';
import QbCallbackPage from 'views/QbCallbackPage/QbCallbackPage';
import ContactUsPage from 'views/Others/ContactUsPage/ContactUsPage';
import PrivacyPage from 'views/Others/PrivacyPage/PrivacyPage';
import TermsPage from 'views/Others/TermsPage/TermsPage';
import Moment from 'react-moment';
import 'moment-timezone';
import UserActivatePage from 'views/UserActivatePage/UserActivatePage';
import MsaPage from 'views/MsaPage';
import DocusignAuthPage from 'views/DocusignAuthPage';
import CertificatesPage from 'views/CertificatesPage';
import LeanDocumentPage from 'views/LeanDocumentPage'
import LeanDocumentListPage from 'views/LeanDocumentListPage';
import SignLeanDocumentPage from 'views/SignLeanDocumentPage';
import MsaUploadPage from 'views/MsaUpload';
import LeanDocumentViewPage from 'views/LeanDocumentViewPage';
import SenderViewPage from 'views/SenderViewPage';
import SignViewPage from 'views/SignViewPage/SignViewPage';
import MessageBoard from 'views/MessageBoard';

import LeftSidebar from 'components/LeftSidebar/index';
import WhiteHeader from 'components/WhiteHeader/index';
import DashboardHome from 'views/DashboardHome/DashboardHome';
import MsaEdit from 'views/MsaEdit';
import LogoutPage from 'views/LogoutPage';
import Landing from 'views/Landing';
import BuyerBudgetsPage from 'views/BuyerBudgets';
import ApproveBudgets from 'views/ApproveBudgets';
import i18n from './i18n';
import BudgetsReportPage from 'views/BudgetsReport';
import InsuranceList from 'views/InsuranceList/InsuranceList';
import InsuranceUpload from 'views/InsuranceUpload/InsuranceUpload';
import InsuranceVerify from 'views/InsuranceVerify/InsuranceVerify';
import InsuranceView from 'views/InsuranceView/InsuranceView';

class App extends Component {

  state = {
    loginInfo: {
      user: {
        first_name: "",
        last_name: ""
      }
    }
  }

  constructor(props) {
    super(props);
    if (typeof this.props["account"] != 'undefined') {
      this.state.loginInfo = {
        ...this.state.loginInfo,
        ...this.props["account"]["loginStatus"]
      };
    }

    // Route change event
    this.props.history.listen((location, action) => {
      try {
        let pages = document.getElementsByClassName('main-container');
        if (typeof pages !== 'undefined' && pages.length > 0 && pages[0]) {
          setTimeout(() => {
            try {
              pages[0].scrollTop = 0;
            } catch (error) {
              console.log(error);
            }
          }, 100);
        }
      } catch (err) {
      }
    });
  }

  componentDidMount() {
    
  }

  render() {

    let isLogggedIn = true;
    const loginStatus = this.props.account ? this.props.account.loginStatus : {isLogedIn: false};
    if (!loginStatus.isLogedIn || loginStatus.needChangePassword) {
      isLogggedIn = false;
    }

    let isContactSidebarOpened = isLogggedIn ? this.props.uiSetting.data.contactSidebarOpened : false;
    const user = this.props["account"]["loginStatus"]["user"];

    const { classes } = this.props;
    if (!isLogggedIn) {
      return (
        <Switch>
          <Route exact path="/" component={ Landing } />
          <Route path="/login" component={ LoginPage } />
          <Route path="/register" component={SignUpPage} />
          <Route path="/contact-us" component={ContactUsPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/user/activate/:token" component={UserActivatePage} />
          <Route path="/certificates/sign/:token" component={ SignLeanDocumentPage } />
          <Route path="/msa/sign/:token" component={ SignViewPage } />
          <Route path='/insurances/verify/:onboardToken' component={ InsuranceVerify } />
        </Switch>
      )
    }
    return (
      <div className={`w-full h-full flex flex-row`}>
          <LeftSidebar />
          <div className={`main-container`}>
            <WhiteHeader />
            <div
              style={{ height: 'calc(100% - 76px)' }}
              className={`${(!isLogggedIn || !isContactSidebarOpened || !user) ? 'page-full w-full': 'page'}`}
            >
            <Switch>
              <Route path="/" component={ DashboardHome } exact />
              {/* Dashboard Page */}
              <Route path="/dashboard" component={ DashboardHome } />

              {/* Mailbox Page */}
              <Route path="/mailbox" component={ MessageBoard } />

              {/* Login Page */}
              <Route path="/login" component={ LoginPage } />

              {/* Register Page */}
              <Route path="/register" component={SignUpPage} />

              {/* Activate User Page */}
              <Route path="/user/activate/:token" component={UserActivatePage} />

              {/* Logout Page */}
              <Route path="/logout" component={LogoutPage} />

              {/* Contact US Page */}
              <Route path="/:customer/contact-us" component={ContactUsPage} />

              {/* Privacy Page */}
              <Route path="/:customer/privacy" component={PrivacyPage} />

              {/* Terms Page */}
              <Route path="/:customer/terms" component={TermsPage} />

              {/* Vendor Setup Page */}
              <Route path="/vendor/setup" component={SetupPage} />

              {/* Vendor Raise Invoice Page */}
              <Route path="/vendor/raise-invoice/list/new" component={RaiseInvoicePage} />
              <Route path="/vendor/raise-invoice/:type" component={ReviewInvoicesPage} />

              {/* Track Invoice Page */}
              <Route path="/common/track-payment" component={ReviewInvoicesPage} /> {/* ReviewInvoicesPage TrackPaymentPage */}

              {/* Raise Invoice Page */}
              <Route path="/buyer/buy-request" component={BuyRequestPage} />

              {/* Edit Invoice Page (Admin) */}
              <Route path="/invoice/edit/:invoiceId" component={BuyRequestPage} />
              
              {/* Buyer Raise Invoice Page */}
              <Route path="/buyer/review-invoices" component={ReviewInvoicesPage} exact/>
              {/* Buyer Track Performance Page */}
              <Route path="/buyer/track-performance" component={TrackPerformancePage} />
              {/* Admin Setup Page */}
              <Route path="/admin/setup" component={AdminSetupPage} />
              {/* Admin Approve Page */}
              <Route path="/admin/approve/vendors" component={ApprovePage} />
              <Route path="/admin/approve/budgets" component={ApproveBudgets} />
              {/* <Route path="/track" component={TrackPage} /> */}
              {/* Admin Pay Page */}
              <Route path="/admin/pay" component={PayPage} />
              {/* Report Page */}
              <Route path="/report" component={ReportPage} />
              {/* Profile Page */}
              <Route path="/profile" component={ProfilePage} />
              <Route path="/buyer/review-invoices/request/:invoiceId" component={BuyRequestPage} />
              <Route path="/intuit_callback/:status" component={QbCallbackPage} />
              <Route path="/msa/list" component={MsaPage} />
              <Route path="/msa/upload" component={MsaUploadPage} />
              <Route path="/msa/sender-view" component={ SenderViewPage } />
              <Route path="/docusign-auth" component={ DocusignAuthPage } />
              <Route path="/certificates/upload" component={ LeanDocumentPage } />
              <Route path="/certificates/list" component={ LeanDocumentListPage } />
              <Route path="/certificates/view/:leanId" component={ LeanDocumentViewPage } />
              <Route path="/certificates/sign/:token" component={ SignLeanDocumentPage } />
              <Route path="/msa/sign/:token" component={ SignViewPage } />
              <Route path="/msa/view/:msaId" component={ MsaEdit } />
              <Route path="/buyer/budgets" component={ BuyerBudgetsPage } />
              <Route path="/admin/report/budgets" component={ BudgetsReportPage } />
              <Route path="/admin/report/spent" component={ BudgetsReportPage } />
              <Route path="/insurances/list" component={ InsuranceList } />
              <Route path='/insurances/upload' component={ InsuranceUpload } />
              <Route path='/insurances/view/:insuranceId' component={ InsuranceView } />
              <Route path='/insurances/verify/:onboardToken' component={ InsuranceVerify } />
            </Switch>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(AppStyle)(App))
);
