const searchInputStyle = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        maxHeight: '100%',
        '& .avatar-img': {
            width: 40,
            height: 40,
            paddingBottom: 0,
            marginBottom: 0,
            position: 'relative',
            '& .avatar-img-inner': {
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                '&.cursor': {
                    cursor: 'pointer',
                },
                '& .avatar-initial': {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    borderRadius: '50%',
                    backgroundColor: '#555',
                    color: 'white',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                }
            },
            '& .user-activity': {
                position: 'absolute',
                bottom: 'calc(25% - 6px)',
                right: -1,
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: '#D4D4D4',
                '&.active': {
                    backgroundColor: '#37BFFF',
                }
            },
            '&:hover': {
                '& .profile-menu': {
                    display: 'block',
                },
            },
            '& .profile-menu': {
                position: 'absolute',
                display: 'none',
                listStyle: 'none',
                width: '9rem',
                top: 'calc(100% + 1px)',
                right: '0',
                padding: '0.5rem 1rem',
                margin: 0,
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                boxShadow: '2px 0 5px 0px rgba(0, 0, 0, 0.4);',
                '&:before': {
                    position: 'absolute',
                    content: `''`,
                    top: '-4px',
                    right: 10,
                    width: 0,
                    height: 0,
                    borderStyle: 'solid',
                    borderWidth: '0 6px 6px 6px',
                    borderColor: 'transparent transparent #ffffff transparent'
                },
                '& svg': {
                    display: 'inline',
                    width: 24,
                    height: 24,
                    marginRight: '0.5rem'
                },
                '& a': {
                    color: 'inherit',
                    '&:hover': {
                        textDecoration: 'none'
                    }
                }
            }
        },
        '& .avatar-img-medium': {
            width: 48,
            height: 48,
            maxHeight: '100%'
        }
    },
    statusBubble: {
        position: 'absolute',
        top: -1,
        right: -1,
        width: 16,
        height: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#fff',
        '& div': {
            width: 7,
            height: 7,
            borderRadius: '50%',
            backgroundColor: '#D4D4D4',
            '&.active': {
                backgroundColor: '#37BFFF',
            },
        }
    }
});

export default searchInputStyle;