const msaItemStyle = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '10px 20px',
        marginTop: 5,
        marginBottom: 5,
        fontSize: 12,
        color: 'black',
    },
    icon: {
        color: '#1D8ACF',
    },
    detailContainer: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
    },
    signerName: {
        marginLeft: 20,
    },
    btnView: {
        background: '#1D8ACF',
        color: 'white',
        padding: '5px 25px',
        fontSize: 12,
        borderRadius: 2,
        '&:hover': {
            color: 'white'
        }
    },
    statusContainer: {
        marginRight: 20,
    },
    dateContainer: {
        marginRight: 20,
    },
    itemData: {
        padding: '3px 0.5rem',
        marginTop: '0',
        marginBottom: '0',
        
        cursor: 'pointer',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
        }
    },
});

export default msaItemStyle;