import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import landingStyle from './landingStyle.jsx';
import Question from './Question.jsx';
import LandingHeader from './LandingHeader.jsx';
import LandingFooter from './LandingFooter.jsx';

class Landing extends Component
{
    render() {
        const { classes } = this.props;

        return (
            <div class="w-full flex-col">
                <LandingHeader />
                <div className={ classes.mainContainer }>
                    <div className="w-full flex-col flex px-16" style={{ paddingTop: 116, paddingBottom: 116, maxWidth: 510 }}>
                        <div className="flex items-center" style={{ background: 'rgba(245, 245, 245, 0.3)' }}>
                            <div style={{ width: 5, height: 62, background: '#37BFFF', marginRight: 14, }}>
                            </div>
                            <div style={{ fontSize: 22 }}>
                                <strong>NEW TIMES. NEW SOLUTIONS</strong>
                            </div>
                        </div>
                        <div className='text-5xl mt-2'>
                            <strong>
                                Start Up with <br/>
                                Your Team Now
                            </strong>
                        </div>
                        <div>
                            Signup for our easy to use, blockchain based application for discovering, on-boarding and managing vendors. Easy to integrate with different systems in your eco-system
                        </div>
                        <button className={ classes.btnBuy }>
                            Buy now and save 50%
                        </button>
                    </div>
                </div>
                <div className="w-full flex-col flex" style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(55, 130, 190, 0.19)', paddingBottom: 97, paddingTop: 86, }}>
                    <div className="w-full text-center" style={{ marginBottom: 85 }}>
                        <strong style={{ fontSize: 55, color: '#1291F9' }}>Our Pricing Plans</strong>
                    </div>
                    <div className="w-full flex items-end justify-center">
                        <div className={`flex flex-col flex-1 items-center rounded-lg mx-8 bg-white ${classes.pricePlan}`} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <div className="text-5xl" style={{ color: '#1291F9' }}>
                                <strong>Basic</strong>
                            </div>
                            <div style={{ fontSize: 68, color: '#1291F9' }}>
                                <strong>$200</strong>
                            </div>
                            <ul style={{ textAlign: 'center' }}>
                                <li>Vendor, Buyer and Admin portal</li>
                                <li>W9, budgets, Contracts & Payments</li>
                                <li>Dashboard and analytics</li>
                            </ul>
                            <button className={ classes.btnPurchase }>
                                Purchase
                            </button>
                        </div>
                        <div className={`relative flex flex-col flex-1 items-center rounded-lg mx-8 bg-white ${classes.pricePlan}`} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <div style={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center' }}>
                                <img src='/assets/img/newui/premium_mark.png' style={{ width: 50, marginRight: 15, }} />
                                Most Prefered
                            </div>
                            
                            <div className="text-5xl" style={{ color: '#1291F9' }}>
                                <strong>Premium</strong>
                            </div>
                            <div style={{ fontSize: 68, color: '#1291F9', marginBottom: 20, }}>
                                <strong>$300</strong>
                            </div>
                            <ul style={{ textAlign: 'center' }}>
                                <li>Basic +</li>
                                <li>Manage Insurance & Notarization</li>
                                <li>Discover new Vendors</li>
                            </ul>
                            <button className={ classes.btnPurchase }>
                                Purchase
                            </button>
                        </div>
                        <div className={`flex flex-col flex-1 items-center rounded-lg mx-8 bg-white ${classes.pricePlan}`} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                            <div className="text-5xl" style={{ color: '#1291F9' }}>
                                <strong>Gold</strong>
                            </div>
                            <div style={{ fontSize: 28, color: '#1291F9', textAlign: 'center' }}>
                                <strong>Email us at sales@ipayflow.com</strong>
                            </div>
                            <ul style={{ textAlign: 'center' }}>
                                <li>Premium +</li>
                                <li>Customize features as needed</li>
                                <li>Qualify vendors and commission research</li>
                            </ul>
                            <button className={ classes.btnPurchase }>
                                Purchase
                            </button>
                        </div>
                    </div>
                </div>
                <div 
                    className="w-full flex"
                    style={{
                        paddingLeft: 70,
                        paddingRight: 70,
                        paddingTop: 46,
                        paddingBottom: 80,
                        background: 'url("/assets/img/newui/landing/more-than-think.jpg")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <div style={{ flex: 3 }}>
                        <div style={{ fontSize: 55, color: '#1291F9' }}>
                            <strong>
                                We are more than <br/>you think
                            </strong>
                        </div>
                        <div className="text-xl" style={{ marginTop: 27, marginBottom: 27 }}>
                            <strong>
                                Now get to check all our features
                            </strong>
                        </div>
                        <div className="px-3">
                            <div className="flex w-full">
                                <div className="flex items-center" style={{ marginRight: 40, minWidth: 230 }}>
                                    <div className={ classes.featureBullet }>
                                        <img src="/assets/img/newui/landing/support-icon.svg" />
                                    </div>
                                    <strong style={{ fontSize: 25, color: '#1291F9' }}>24X7 support</strong>
                                </div>
                                <div className="flex items-center">
                                    <div className={ classes.featureBullet }>
                                        <img src="/assets/img/newui/landing/file-icon.svg" />
                                    </div>
                                    <strong style={{ fontSize: 25, color: '#1291F9' }}>Easy to Integrate</strong>
                                </div>
                            </div>
                            <div className="flex w-full" style={{ marginTop: 55, }}>
                                <div className="flex items-center" style={{ marginRight: 40, minWidth: 230 }}>
                                    <div className={ classes.featureBullet }>
                                        <img src="/assets/img/newui/landing/customer-icon.svg" />
                                    </div>
                                    <strong style={{ fontSize: 25, color: '#1291F9' }}>Fully Secure</strong>
                                </div>
                                <div className="flex items-center">
                                    <div className={ classes.featureBullet }>
                                        <img src="/assets/img/newui/landing/goal-icon.svg" />
                                    </div>
                                    <strong style={{ fontSize: 25, color: '#1291F9' }}>Scalable and Customizable</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 108, flex: 2 }}>
                        <div style={{ borderRadius: 15, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', overflow: 'hidden', maxWidth: 600, }}>
                            <div style={{ background: '#1291F9', height: 184, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src="/assets/img/newui/landing/doc-icon.svg" />
                            </div>
                            <div style={{ paddingLeft: 35, paddingRight: 35, paddingTop: 40, paddingBottom: 40, background: 'white' }}>
                                <div style={{ color: '#1291F9', fontSize: 25, textAlign: 'center', marginBottom: 20, }}>
                                    <strong>Integrate with your ecosystems</strong>
                                </div>
                                <div>
                                    Integrate iPayflow with your ERP, PO systems, Payment systems or providers, audit application and more...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex w-full flex-col items-center">
                    <div style={{ maxWidth: 750, textAlign: 'center', marginBottom: 46, marginTop: 87 }}>
                        <div style={{ fontSize: 55, color: '#1291F9' }}>
                            <strong>Setup a demo</strong>
                        </div>
                        <div style={{ fontWeight: 600, letterSpacing: '0.005em', fontSize: 20, }}>
                            Talk to our experts and explored all our features as well as integration and customization models. You will be amazed at our feature rich application
                        </div>
                    </div>
                    <div className="flex w-full">
                        <div className={ classes.bgSide }>
                            <div className={ classes.videoTag }>
                                <img src="/assets/img/newui/landing/rounded-check.svg" />
                                <div>
                                    Click to checkout our explainer video, for more details please click on Setup a demo to talk to our experts in detail
                                </div>
                            </div>
                        </div>
                        <div
                            className="flex flex-1 justify-center items-center relative"
                        >
                            <img src="/assets/img/newui/landing/video-bg.jpg" className={ classes.videoThumb } />
                            <div className="w-full absolute inset-0 flex justify-center items-center">
                                <img src="/assets/img/newui/landing/video-play-icon.svg"style={{ width: 200, height: 200 }} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="flex w-full flex-col items-center" style={{ paddingTop: 90 }}>
                    <div style={{ maxWidth: 1100, paddingLeft: 90, paddingRight: 90, width: '100%' }} className={ classes.ourCompany }>
                        <div style={{ fontSize: 55, color: '#1291F9', textAlign: 'center', paddingBottom: 46, borderBottom: '1px solid #37BFFF', marginBottom: 46, marginLeft: 50, marginRight: 50}}>
                            <strong>
                                We are a proud company
                            </strong>
                        </div>
                        <div className="flex w-full justify-between text-center text-lg" style={{ color: '#1291F9', paddingLeft: 110, paddingRight: 110 }}>
                            <div className="flex flex-col items-center" >
                                <img src="/assets/img/newui/landing/rocket-icon.svg" />
                                <div style={{ marginTop: 28 }}>
                                    <strong>7 years on the<br/> market</strong>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <img src="/assets/img/newui/landing/earth-icon.svg"  />
                                <div style={{ marginTop: 28 }}>
                                    <strong>60+ clients <br/> globally</strong>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <img src="/assets/img/newui/landing/business-icon.svg" />
                                <div style={{ marginTop: 28 }}>
                                    <strong>1M+<br/> Transactions</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'none' }}>
                    <div style={{ fontSize: 55, color: '#1291F9', textAlign: 'center' }}>
                        <strong>
                            Frequently asked questions
                        </strong>
                    </div>
                    <div>
                        <div style={{ paddingLeft: 170, paddingRight: 170, marginTop: 15, paddingBottom: 120,}}>
                            <Question 
                                question="Lorem ipsum dolor sit amet consectetur adipiscing elit?"
                                answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            />
                            <Question 
                                question="Lorem ipsum dolor sit amet consectetur adipiscing elit?"
                                answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            />
                            <Question 
                                question="Lorem ipsum dolor sit amet consectetur adipiscing elit?"
                                answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            />
                            <Question 
                                question="Lorem ipsum dolor sit amet consectetur adipiscing elit?"
                                answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                style={{ borderBottom: '1px solid rgba(30, 30, 30, 0.2)' }}
                            />
                        </div>
                    </div>
                </div>
                <LandingFooter />
            </div>
        )
    }
}

export default withStyles(landingStyle)(Landing);