import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import AutocompleteSelect from 'components/AutocompleteSelect/index.jsx';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PrintIcon from '@material-ui/icons/Print';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import raiseInvoicePageStyle from './raiseInvoicePageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import Button from 'components/Button/index.jsx';
import Footer from 'components/Footer/index.jsx';

import { confirm } from "../../components/Confirm/index";

import { uploadFiles } from '../../redux/actions/fileActions';
import { submitInvoice, searchInvoiceKey, printInvoice, printInvoiceSendEmail } from '../../redux/actions/commonActions';


function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '*.*');
    return fileSelector;
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getPrintModalStyle() {
  const top = 5;
  const left = 50;
  return {
    position: 'absolute',
    maxWidth: '40rem',
    width: '34rem',
    /* maxHeight: '300px', */
    margin: '0 auto',
    top: '5%',
    left: 'calc(50% - 17rem)',
    maxHeight: '90%',
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: '1.5rem 2rem'
  };
}

class RaiseInvoicePage extends Component
{

    fields = {
    }

    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        initialData: {
            buyers: [],
            categGroup: [],
        },
        isPrintModalOpened: false,
        correctKey: false,
        formData: {
            savedId: null,
            key: '',
            eventId: '',
            category: '',
            buyer: '',
            description: '',
            invoiceValue: ''
        },
        submitErrorMessage: '',
        submitSuccessMessage: '',
        attachments: [],
        existing_attachments: [],
        pdfSendEmailList: [],
        sendEmailListError: '',
        sendEmailListResult: '',
        sendEmailListLoading: false,
        loginInfo: {
            user: {
                first_name: '',
                last_name: ''
            }
        },
    }

    constructor(props) {
        super(props);
        this.state.loginInfo = { ...this.state.loginInfo, ...this.props['account']['loginStatus'] };
    }

    componentDidMount(){
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.handleCloseFileDialog(e));
        setTimeout(() => {
            this.getInitialData();
        }, 1000);
    }

    getInitialData = () => {

    }

    handleCloseFileDialog = () => {
        if(this.fileSelector.files.item(0) === null)
            return;
        let attachments = this.fileSelector.files;
        this.setState({ attachments });
        /* console.log(this.fileSelector.files.item(0));
        var file = this.fileSelector.files.item(0); */

    }

    // Check Form Validtion
    checkFormValidation = () => {

        let loginInfo = this.state.loginInfo;

        let formData = {}
        for (var field in this.fields) {
            formData[field] = this.fields[field].value;
        }

        formData.eventId = (typeof this.state.formData['eventId'] != 'undefined') ?
            this.state.formData['eventId'] : '';
        formData.category = (typeof this.state.formData['category'] != 'undefined') ?
            this.state.formData['category'] : '';
        formData.sub_category = (typeof this.state.formData['sub_category'] != 'undefined') ?
            this.state.formData['sub_category'] : '';
        formData.buyer = (typeof this.state.formData['buyer'] != 'undefined') ?
            this.state.formData['buyer'] : '';

        /* if(formData['key'].length < 1){
            this.setState({'submitErrorMessage': 'Please input key provided by your contact'}); return false;
        } */
        if(formData['eventId'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Event ID, Name'}); return false;
        }
        if(formData['category'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Product or Service Category'}); return false;
        }
        if (loginInfo.user.customer.permalink != 'wic' && formData['sub_category'].length < 1) {
            this.setState({ 'submitErrorMessage': 'Please select Sub Category' }); return false;
        }
        if(formData['buyer'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Buyer/requestor'}); return false;
        }
        if(formData['description'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Short Decription'}); return false;
        }
        if(formData['invoiceValue'].length < 1){
            this.setState({'submitErrorMessage': 'Please input Invoice value'}); return false;
        }
        /*if(this.state.attachments.length < 1 && this.state.existing_attachments.length < 1){
            this.setState({'submitErrorMessage': 'Please upload attachment file'}); return false;
        }*/

        return true;
    }

    handleClickSubmit = async () => {
        if(!this.checkFormValidation()) return;

        this.setState({'submitErrorMessage': ''});

        await this.props.uploadFiles(this.state.attachments, 'invoice_attachments');
        this.handleFileUploadResponse(this.props.uploadResult);
    }

    // Form Submitting
    handleFileUploadResponse = async (response) => {
        var res = response['uploadResult'];
        if(res.status){
            let formData = {}
            for (var field in this.fields) {
                formData[field] = this.fields[field].value;
            }
            formData['eventId'] = (typeof this.state.formData['eventId'] != 'undefined') ?
                this.state.formData['eventId'] : '';
            formData['category'] = (typeof this.state.formData['category'] != 'undefined') ?
                this.state.formData['category'] : '';
            formData['sub_category'] = (typeof this.state.formData['sub_category'] != 'undefined') ?
                this.state.formData['sub_category'] : '';
            formData['buyer'] = (typeof this.state.formData['buyer'] != 'undefined') ?
                this.state.formData['buyer'] : '';
            formData['existing_attachments'] = this.state.existing_attachments.join(",");
            formData['attachments'] = res.files.join(",");

            await this.props.submitInvoice(formData);

            this.handleSubmitResponse(this.props.commonResponse);
        }else{
            this.setState({'submitErrorMessage': res['message']});
        }
    }


    // Form Submit Result Processing
    handleSubmitResponse = (res) => {
        if(res.commonResponse.status){
            var newItemId = res.commonResponse.affected_id;
            this.setState({'submitSuccessMessage': res.commonResponse.message});
            /*
            this.setState({'existing_attachments': []});
            this.setState({'attachments': []});
            this.setState({formData: {
                key: '',
                eventId: '',
                sub_category: '',
                category: '',
                buyer: '',
                description: '',
                invoiceValue: ''
            }}); */


            confirm("Success", "Invoice submitted with the hashkey <" + this.state.formData.key + ">", false).then(
                async () => {
                    // Click OK
                },
                () => {
                    // Click Cancel
                }
            );

            setTimeout(() => {
                this.setState({'submitSuccessMessage': ''});
            }, 4000);
        }else{
            this.setState({'submitErrorMessage': res.commonResponse.message});
        }
    }

    // Key Search request function
    searchKeyExisting = async (key) => {
        await this.props.searchInvoiceKey(key);
        this.handleKeySearchResponse(this.props.commonResponse);
    }

    // Key search Response process
    handleKeySearchResponse = (res) => {
        if(res.commonResponse.status){
            var invoice = res.commonResponse.data;
            if(typeof invoice != 'undefined' && invoice != null){
                this.setState({'correctKey': true});
                this.setState({formData: {
                    savedId: invoice['id'],
                    key: invoice['key'],
                    eventId: invoice['eventId'],
                    category: invoice['category'],
                    sub_category: invoice['sub_category'],
                    buyer: invoice['buyer_id'],
                    description: invoice['description'],
                    invoiceValue: invoice['invoiceValue']
                }});
                var files = invoice['attachments'].split(',');
                this.setState({'existing_attachments': files});
            }else{
                this.setState({'correctKey': false});
                this.setState({formData: {
                    savedId: null,
                    eventId: '',
                    sub_category: '',
                    category: '',
                    buyer: '',
                    description: '',
                    invoiceValue: ''
                }});
                this.setState({'existing_attachments': []});
            }
        }
    }

    handleClickUploadAttachment = () => {
        this.fileSelector.click();
    }

    // Form input chnage event
    handleChangeValue = (e, valueType) => {
        const dataOject = {};
        console.log("=======", valueType, e);
        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.formData[valueType] != 'undefined' && this.state.formData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }
        if (valueType == "category") {
            dataOject["sub_category"] = "";
        }
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });

        if(valueType == 'key'){
            this.searchKeyExisting(e.target.value);
        }
    }

    // Show existing attachments
    renderExistingAttachments = () => {
        const {classes} = this.props;
        return this.state.existing_attachments.map((filename, key) => {
            var filebasename = filename.substring(filename.lastIndexOf('/') + 1);
            var filebasenameUrl = encodeURIComponent(filebasename);
            var apiUrl = this.state.apiUrl;
            if(filebasename == ''){
                return null;
            }else{
                return (
                    <div className={ classes.attachmentContainer } key={key}>
                        <img src="/assets/img/attachment-active.png" alt="attachment" className={ classes.attachmentImg }/>
                            { filebasename }&nbsp;
                            <a href={apiUrl + 'api/download/file/' + filebasenameUrl} target="_blank"><SaveAltIcon /></a>
                    </div>
                )
            }
        })
    }

    // Show new added attachments
    renderAttachments = () => {
        const {classes} = this.props;

        let fileNames = [];
        for(let i = 0; i < this.state.attachments.length;i ++)
        {
            fileNames.push(this.state.attachments.item(i).name);
        }
        return fileNames.map((filename, key) => {
            return (
                <div className={ classes.attachmentContainer } key={key}>
                    <img src="/assets/img/attachment-inactive.png" alt="attachment" className={ classes.attachmentImg }/>
                        { filename }
                </div>
            )
        })
    }

    // Show Error Or Succes Message
    checkResponseOrError = () => {
        const { classes } = this.props;
        if(this.state.submitErrorMessage != ''){
            return (
                <span style={{color: '#CC3333'}}>
                    &nbsp;&nbsp;{this.state.submitErrorMessage}
                </span>
            );
        }
        else if(this.state.submitSuccessMessage != ''){
            return (
                <span style={{color: '#33CC33'}}>
                    &nbsp;&nbsp;{this.state.submitSuccessMessage}
                </span>
            );
        }
    }

    // Show category list function
    drawCategorySelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status){
            var categGroup = this.props.initialResponse.data.categGroup;
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.categ.name}>
                        {item.categ.name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }

    // Show sub-category list function
    drawSubCategorySelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status){
            if(typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if(item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            return categGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.name}>
                        {item.name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }

    // Show buyer list function
    getEventOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.events != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.events;
            return optjionGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
        }else{
            return [];
        }
    }

    // Get current sub category list
    getSubCategorySelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            if (typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if (item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            var retVal = categGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
            if (typeof retVal == "undefined" || retVal == null) {
                retVal = [];
            }
            return retVal;
        } else {
            return [];
        }
    }

    // Show buyer list function
    drawBuyerSelect = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.buyers != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.buyers;
            return optjionGroup.map((item, key) => {
                return (
                    <option key={key + 1} value={item.id}>
                        {item.first_name + ' ' + item.last_name}
                    </option>
                )
            })
        }else{
            return null;
        }
    }


    // Show buyer list function
    getBuyerOptions = () => {
        const { classes } = this.props;
        if(typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status && typeof this.props.initialResponse.data.buyers != 'undefined'){
            var optjionGroup = this.props.initialResponse.data.buyers;
            return optjionGroup.map((item, key) => ({
                label: item.first_name + ' ' + item.last_name,
                value: item.id
            }));
        }else{
            return [];
        }
    }

    /**********************************************
     *
     * Print Related Functions
     *
     */

    // Print Action
    handleSendEmailButton = async (e) => {
        console.log(this.state.pdfSendEmailList);
        if(this.state.pdfSendEmailList.length < 1){
            this.setState({sendEmailListError: 'Please add send email list!'});
        }else{
            this.setState({sendEmailListError: ''});
            this.setState({sendEmailListResult: ''});
            this.setState({sendEmailListLoading: true});
            var invoiceKey = this.state.formData.key;
            await this.props.printInvoiceSendEmail(invoiceKey, this.state.pdfSendEmailList, this.props.printResult.data.pdfUrl);
            this.setState({sendEmailListLoading: false});
        }
    }

    // Click PDF download button
    handleDownloadPdfButton = async (e) => {
        //var invoiceKey = this.state.formData.key;
        //await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
        this.processPDFPrintResult();
    }
    processPDFPrintResult = () => {
        console.log(this.state.apiUrl);
        console.log('api/download/' + this.props.printResult.data.pdfUrl);
        window.location.href = this.state.apiUrl + 'api/download/' + this.props.printResult.data.pdfUrl;
    }

    // Print Modal show action
    showPrintModal = async () => {
        var invoiceKey = this.state.formData.key;
        console.log(this.state.formData);
        console.log(invoiceKey);
        await this.props.printInvoice(invoiceKey, this.state.pdfSendEmailList);
        this.setState({'isPrintModalOpened': true});
    }
    // Print Modal closed
    handlePrintModalClose = () => {
        this.setState({'isPrintModalOpened': false});
    }

    // Pdf Send Email List Related
    addEmailToPDFSendList = (e) => {
        e.preventDefault();
        var emailList = this.state.pdfSendEmailList;
        emailList.unshift(this.send_email.value);
        this.send_email.value = '';
        this.setState({pdfSendEmailList: emailList});
    }
    removeEmailToPDFSendList = (e, key) => {
        var emailList = this.state.pdfSendEmailList;
        emailList.splice(key,1);
        this.setState({pdfSendEmailList: emailList});
    }

    // Pdf Send Email List Rendor function
    renderPdfSendEmailList = () => {
        const { classes } = this.props;
        if(this.state.pdfSendEmailList.length > 0){
            return (
                <div style={{minWidth: '100%'}} style={{paddingTop: '1rem'}}>
                    {this.renderPdfSendEmailListOptions()}
                </div>
            );
        }else{
            return null;
        }
    }
    renderPdfSendEmailListOptions = () => {
        const { classes } = this.props;
        return this.state.pdfSendEmailList.map((email, key) => {
            return (
                <Grid container spacing={24} key={key}>
                    <Grid item xs={10} style={{marginLeft: 8, flex: 1,}}>
                        {email}
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        <IconButton className={classes.iconButton} aria-label="Search" onClick={e => this.removeEmailToPDFSendList(e, key) } style={{padding: '0'}} >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        });
    }

    renderPdfPrintResult = () => {
        const { classes } = this.props;
        if(typeof this.props.printResult.data.pdfUrl != 'undefined' && this.props.printResult.data.pdfUrl.length > 0){
            var pdfUrl = this.state.apiUrl + 'api/preview/' + this.props.printResult.data.pdfUrl;
            return (
                <iframe border="0" id="pdfPrint" type="application/pdf" src={pdfUrl} width="100%" height="400"></iframe>
            );
        }else{
            return (
                <iframe border="0" id="pdfPrint" type="application/pdf" width="100%" height="400"></iframe>
            );
        }
    }

    renderPdfSendEmailButton = () => {
        const { classes } = this.props;
        return (
            <div>
                <p style={{textAlign: 'center', marginBottom: '0'}}>
                    <Button className={ `${classes.button} ${classes.emailSendButton}` } variant="outlined" color="primary"  onClick={e => this.handleSendEmailButton(e)} disabled={this.state.sendEmailListLoading}>
                        {this.state.sendEmailListLoading? 'Sending': 'Send'}
                    </Button>
                </p>
                <p style={{display: (this.state.sendEmailListError == ''? 'none': 'block'), textAlign: 'center', color: '#CC3333', marginBottom: '0'}}>{this.state.sendEmailListError}</p>
            </div>
        );
    }


    /**********************************************
     *
     * Page Html Render Function
     *
     */
    render(){
        const { classes } = this.props;

        var catOptions = this.drawCategorySelect();
        if (typeof catOptions == "undefined" || catOptions == null) {
            catOptions = [];
        } else {
            catOptions = catOptions.map((item) => ({
                label: item.props.value,
                value: item.props.value
            }));
        }

        return(
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.contentContainer }>
                    <div className={ classes.pageContent }>
                        <div className={ classes.formContainer } ref={ ref => { this.form = ref } }>
                            {/* <GridContainer>
                                <GridItem xs={12}>
                                    Enter key provided by your contact, you would have received an email
                                </GridItem>
                                <GridItem xs={12} className={ classes.marginVertical_25 }>
                                    <BaseInput className={ classes.input } inputRef={ ref => this.fields['key'] = ref } onChange={e => this.handleChangeValue(e, 'key')} value={this.state.formData.key} />
                                </GridItem>
                            </GridContainer> */}
                            <GridContainer spacing={40}>
                                <GridItem xs={3}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Event ID, Name
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <AutocompleteSelect
                                                className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                                value={this.state.formData.eventId}
                                                options={ this.getEventOptions() }
                                                onChange={e => this.handleChangeValue(e, 'eventId')}
                                                name="event"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={3}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Product or Service Category
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <AutocompleteSelect
                                                className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                                value={this.state.formData.category}
                                                options={catOptions}
                                                onChange={selectedOption => this.handleChangeValue(selectedOption, 'category')}
                                                name="category"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={3}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Sub category
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <AutocompleteSelect
                                                className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                                value={this.state.formData.sub_category}
                                                options={this.getSubCategorySelect()}
                                                onChange={selectedOption => this.handleChangeValue(selectedOption, 'sub_category')}
                                                name="subCateg"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={3}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Buyer/requestor
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <AutocompleteSelect
                                                className={`${classes.marginVertical_25} ${classes.select_elem} form-select`}
                                                value={this.state.formData.buyer}
                                                options={ this.getBuyerOptions() }
                                                onChange={e => this.handleChangeValue(e, 'buyer')}
                                                name="buyer"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                            <GridContainer spacing={40}>
                                <GridItem xs={9}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Short Decription
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <BaseInput className={ classes.input } inputRef={ ref => this.fields['description'] = ref } onChange={e => this.handleChangeValue(e, 'description')} value={this.state.formData.description}  />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={3}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            Order value
                                        </GridItem>
                                        <GridItem xs={12} className={ classes.marginVertical_25 }>
                                            <BaseInput type="number" className={ classes.input } inputRef={ ref => this.fields['invoiceValue'] = ref } onChange={e => this.handleChangeValue(e, 'invoiceValue')} value={this.state.formData.invoiceValue} />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem className={ `${classes.marginVertical_25} ${classes.uploadAttachmentContainer}` } onClick={this.handleClickUploadAttachment}>
                                    <span className={ classes.uploadAttachment }>Upload attachment</span><AddCircleOutlineIcon className={ classes.addIcon } onClick={ this.handleClick } />
                                </GridItem>
                            </GridContainer>
                            <div className={ classes.attachmentSection }>
                                { this.renderExistingAttachments() }
                                { this.renderAttachments() }
                            </div>
                            <GridContainer>
                                <GridItem xs={10} className={ classes.marginVertical_40 }>
                                    <Button className={ classes.btnSubmit } onClick={ this.handleClickSubmit } ref={ ref => { this.submitButton = ref } } >
                                        Submit
                                    </Button>
                                    {/* <input type="submit" value="Submit" className={ `${classes.btnSubmit}` } /> */}
                                    {this.checkResponseOrError()}
                                </GridItem>
                                <GridItem xs={2} className={ classes.marginVertical_40 } style={{textAlign: 'right'}}>
                                    <Fab color="primary" disabled={this.state.formData.savedId == null} onClick={ this.showPrintModal } aria-label="Delete" className={classes.fab}>
                                        <PrintIcon />
                                    </Fab>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.isPrintModalOpened}
                    onClose={this.handlePrintModalClose}
                >
                    <div style={getPrintModalStyle()} className={classes.paper}>
                        <Grid container spacing={24}>
                            <Grid item xs={10} style={{marginLeft: 8, flex: 1,}}>
                                <Typography variant="h6" id="modal-title">
                                    PDF Print
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'center'}}>
                                <IconButton className={classes.iconButton} type="submit" onClick={e => this.handleDownloadPdfButton(e)} aria-label="Download">
                                    <SaveAltIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {this.renderPdfPrintResult()}
                        <Typography variant="subtitle1" id="simple-modal-description" style={{marginTop: '0.5rem'}}>
                        Input emails that you want to send pdf file
                        </Typography>
                        <form onSubmit={e => this.addEmailToPDFSendList(e)}>
                            <Paper elevation={1} style={{padding: '2px 4px', display: 'flex', alignItems: 'center',width: '100%', marginTop: '0.5rem'}}>
                                <InputBase className={classes.input} type="email" name="email"  inputRef={ ref => { this.send_email = ref } } placeholder="Input Email" style={{marginLeft: 8, flex: 1,}} required />
                                <IconButton className={classes.iconButton} type="submit" aria-label="Search">
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Paper>
                        </form>
                        {this.renderPdfSendEmailList()}
                        {this.renderPdfSendEmailButton()}
                    </div>
                </Modal>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state, initialResponse: state.initialResponse, uploadResult: state.uploadResult, printResult: state.printResult, printSendEmailResult: state.printSendEmailResult}
}

const mapDispatchToProps = {uploadFiles, submitInvoice, searchInvoiceKey, printInvoice, printInvoiceSendEmail};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(raiseInvoicePageStyle)(RaiseInvoicePage));