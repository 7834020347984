import commonStyle from 'assets/jss/commonStyle'

const leanDocumentStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        backgroundColor: '#F5F7F6',
        width: '100%',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        background: 'white',
        borderRadius: 3,
        padding: '30px 20px',
        width: '100%',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        color: '#707070',
        position: 'relative',
    },
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: '1rem',
    },
    addIcon: {
        marginLeft: '10px',
    },
    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    leanTitle: {
        color: '#1D8ACF',
        textAlign: 'center'
    },
    input: {
        width: '100%',
    },
    description: {
        padding: '0px 10px',
        margin: '15px 0px'
    },
    notifyWrapper: {
        margin: '10px 0px'
    },
    btnSubmit: {
        background: '#1D8ACF',
        color: 'white',
        paddingTop: 8,
        paddingBottom: 8,
        width: 120,
        margin: '0px auto'
    },
    selectContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50,
    },
    btnSelect: {
        background: 'white',
        borderRadius: 1000,
        width: 200,
        height: 200,
        color: '#1D8ACF',
        boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 20px',
        opacity: 0.85,
        fontSize: 18,
        '&:hover': {
            opacity: 1,
        }
    },
    messageArea: {
        width: '100%',
        height: 140,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        background: '#FFFFFF',
        borderRadius: 8,
        padding: '3px 10px',
        '&:focus': {
            border: '1px solid #9e9e9e',
            outline: 'none !important',
        },
    }
});
export default leanDocumentStyle;