import * as Type from '../actions/types';

const initialState = {
    data: {
      approvePendings: []
    }
};

export const getApprovalsResult = (state = initialState, action) => {
  let { data } = state;
  switch (action.type) {
    case Type.CM_APPRV_RESULT:
      data.approvePendings = [ ...data.approvePendings, ...action.payload.approvePendings ]
      return {
        ...state,
        data
      };
    case Type.CM_APPRV_CLEAR:
      return {
        data: {
          approvePendings: []
        }
      }
    default:
      return state;
  }
};
