import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import budgetsPageStyle from './budgetsPageStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import RangeSlider from 'components/RangeSlider/index.jsx';

import { reportBudgetInfo } from '../../../redux/actions/adminActions';

class BudgetsPage extends Component
{
    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        budgetInfo: {
            status: false,
            totalSpendValue: 0,
            totalBudgetValue: 0,
            buyerBudget: [],
            categBudget: [],
            eventsBudget: []
        }
    }

    constructor(props) {
        super(props);
        this.loadingData();
    }

    loadingData = async () => {
        await this.props.reportBudgetInfo();
        this.processInvoiceList();
    }

    processInvoiceList = () => {
        var result = this.props.budgetInfoResult.result;
        if(result.status){
            if(result.totalBudgetValue < result.totalSpendValue) result.totalBudgetValue = result.totalSpendValue;
            this.setState({budgetInfo: result});
        }
    }

    renderEventsBudget = () => {
        const { classes } = this.props;
        return this.state.budgetInfo.eventsBudget.map((budget, key) => {
            var budgetValue = budget.budget;
            if(budget.budget < budget.totalInvoiceValue) budgetValue = budget.totalInvoiceValue;
            return (
                <div className={ classes.itemContainer } key={key}>
                    <RangeSlider max={ budgetValue } current={ budget.totalInvoiceValue } label={budget.eventId} />
                </div>
            );
        });
    }

    renderCategBudget = () => {
        const { classes } = this.props;
        return this.state.budgetInfo.categBudget.map((budget, key) => {
            var budgetValue = budget.budget;
            if(budget.budget < budget.totalInvoiceValue) budgetValue = budget.totalInvoiceValue;
            return (
                <div className={ classes.itemContainer } key={key}>
                    <RangeSlider max={ budgetValue } current={ budget.totalInvoiceValue } label={budget.category} />
                </div>
            );
        });
    }

    renderRequesterBudget = () => {
        const { classes } = this.props;
        return this.state.budgetInfo.buyerBudget.map((budget, key) => {
            var budgetValue = budget.budget;
            if(budget.budget < budget.totalInvoiceValue) budgetValue = budget.totalInvoiceValue;
            return (
                <div className={ classes.itemContainer } key={key}>
                    <RangeSlider max={ budgetValue } current={ budget.totalInvoiceValue } label={budget.requesterName} />
                </div>
            );
        });
    }

    render(){
        const { classes } = this.props;
        var today = new Date();

        return(
            <div className={ classes.budgetsContainer }>
                <div className={ classes.dateContainer }>
                    <img src="/assets/img/money-bag.png" className={ classes.iconImage } />
                    <span className={ classes.colorPrimary }>Budget vs Spend |</span>&nbsp;<Moment format="MMM Do YYYY">{today}</Moment>
                </div>
                <GridContainer>
                    <GridItem xs={12}>
                        <RangeSlider max={ this.state.budgetInfo.totalBudgetValue } current={ this.state.budgetInfo.totalSpendValue } className={ classes.marginVertical_40 } />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={6} className={ classes.leftWrapper }>
                        <div className={ classes.itemContainer } >
                            <span className={ classes.colorPrimary }>Events</span>
                        </div>
                        {this.renderEventsBudget()}
                    </GridItem>
                    <GridItem xs={6}>
                        <div className={ classes.itemContainer } >
                            <span className={ classes.colorPrimary }>Categories</span>
                        </div>
                        {this.renderCategBudget()}
                    </GridItem>
                </GridContainer>
                <div className={ classes.spacer60 }></div>
                <GridContainer justify="center">
                    <GridItem xs={6}>
                        <div className={ classes.itemContainer } >
                            <span className={ classes.colorPrimary }>Requestors</span>
                        </div>
                        {this.renderRequesterBudget()}
                    </GridItem>
                </GridContainer>
                <div className={ classes.spacer40 }></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {...state, budgetInfoResult: state.budgetInfoResult}
}

const mapDispatchToProps = {reportBudgetInfo};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(budgetsPageStyle)(BudgetsPage));