const ContactUsPageStyle = theme => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(175deg, #1D2B36 54%, white 54.2%)',
        flexDirection: 'column'
    },
    pageheader: {
        display: 'flex',
        width: '100%',
        color: 'white',
        padding: '0.5rem 2rem 0 2rem',
        flexDirection: 'row',
        minHeight: '65px'
    },
    pageheaderLogo: {
        display: 'flex',
        flex: '1',
        justifyContent: 'right'
    },
    pageheaderNav: {
        display: 'flex',
        paddingTop: '18px',
        paddingRight: '3rem',
        flex: '10',
        justifyContent: 'flex-end'
    },
    headerNav: {
        color: 'white',
        paddingLeft: '2rem',
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
        },
    },
    pageBody: {
        display: 'block',
        width: '100%',
        height: 'calc(100% - 115px)',
        '& p': {
            textAlign: 'center',
            color: 'white'
        }
    },
    labelPara: {
        fontSize: '110%',
        paddingTop: '6rem',
        fontWeight: 'bold'
    },
    panelWrapper: {
        display: 'flex',
        width: '90%',
        maxWidth: '1500px',
        margin: '0 auto',
        flexDirection: 'row'
    },
    panelItem: {
        margin: '5rem 2rem 0 2rem',
        flex: '1',
    },
    panelItemInner: {
        position: 'relative',
        backgroundColor: 'white',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        paddingTop: '6rem',
        borderLeft: '1px solid #EEE',
        borderTop: '1px solid #EEE',
        borderRight: '1px solid #EEE',
    },
    panelLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '7rem',
        height: '7rem',
        borderRadius: '50%',
        left: 'calc(50% - 3.5rem)',
        top: '-2rem',
        background: 'white',
        boxShadow: '0px 1px 3px 2px rgba(173,173,173,0.5)',
    },
    panelTitle: {
        textAlign: 'center',
        color: '#1d2b36',
        fontWeight: 'bold'
    },
    panelDesc: {
        padding: '0 2rem',
        color: '#777',
        textAlign: 'center'
    },
    panelAction: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '20px',
        cursor: 'pointer',
        color: '#1d2b36',
        fontSize: '92%',
        backgroundColor: '#EEE',
        padding: '1rem 1.2rem',
        marginTop: '2rem',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: '#F8F8F8',
            color: '#1d2b36',
            textDecoration: 'none'
        },
    },
    pageFooter: {
        display: 'flex',
        fontSize: '1.0rem',
        width: '98%',
        height: '55px',
        paddingTop: '15px',
        borderTop: '1px solid #212529',
    },
    pageFooterCopyright: {
        display: 'flex',
        flex: '1',
    },
    pageFooterMain: {
        display: 'flex',
        flex: '5',
        justifyContent: 'flex-end',
        '& a': {
            paddingLeft: '2rem',
            color: '#212529',
        },
        '& a:hover': {
            textDecoration: 'none',
        }
    },
    modalHeader: {
        position: 'relative'
    },
    closeIconButton: {
        position: 'absolute',
        padding: '0',
        top: '-5px',
        right: '-15px',
        color: '#A30C0C',
    },
    modalTitle: {
        textAlign: 'center',
        color: '#A30C0C',
        paddingTop: '1rem'
    },
    formFirstLine: {
        paddingTop: '2rem',
    },
    formLine: {
        paddingTop: '0.5rem',
    },
    input: {
        backgroundColor: '#FBFBFB',
        border: '1px solid #BEBEBE',
        width: '96%',
        padding: '0.4rem 0.8rem',
        borderRadius: '3px',
        color: 'rgba(0, 0, 0, 0.87);',
    },
    inputSelect: {
        backgroundColor: '#FBFBFB',
        border: '1px solid #BEBEBE',
        width: '100%',
        padding: '0.4rem 0.8rem',
        borderRadius: '3px',
        color: 'rgba(0, 0, 0, 0.87);',
    },
    inputText: {
        backgroundColor: '#FBFBFB',
        border: '1px solid #BEBEBE',
        width: '96%',
        padding: '0.4rem 0.8rem',
        borderRadius: '3px',
        width: '100%',
        minHeight: '6rem',
        color: 'rgba(0, 0, 0, 0.87);',

        '&::placeholder': {
            color: '#BEBEBE'
        }
    },
    submitBtn: {
        backgroundColor: '#A30C0C',
        color: 'white',
        borderRadius: '0.3rem',
        padding: '0.5rem 0.8rem',
        fontSize: '90%',
    },
    errorMsg: {
        color: '#A30C0C',
        fontSize: '90%'
    }
});
export default ContactUsPageStyle;