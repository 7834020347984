import React, { Component } from 'react'
import dateSignedStyle from './dateSignedStyle.jsx';
import { withStyles } from '@material-ui/core';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Draggable from 'components/Draggable';
import moment from 'moment';
import { withTranslation  } from 'react-i18next';

const COLORS = [
    'rgba(251, 220, 128, 0.8)',
    'rgba(189, 225, 232, 0.8)'
];

class DateSigned extends Component
{
    render() {
        const { classes, data, onClick, signed, imageData, refCallback, t } = this.props;
        if (signed) {
            return (
                <div style={{ top: data.y, left: data.x, position: 'absolute' }} ref={ refCallback }>
                    { moment().format('DD/MM/YYYY') }
                </div>
            );
        }

        return (
            <div style={{ top: data.y, left: data.x, position: 'absolute' }} ref={ refCallback }>
            <Draggable type="control" data={data} onEnd={ this.handleDragEnd }>
                <div className={ classes.container } style={{ backgroundColor: COLORS[data.signer_order] }} onClick={ onClick }>
                    <div style={{ fontWeight: 700, textAlign: 'center' }}>
                        { t('Date Signed') }
                    </div>
                </div>
            </Draggable>
            </div>
        );
    }
}

export default withTranslation()(withStyles(dateSignedStyle)(DateSigned));