import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import trackPaymentPageStyle from './trackPaymentPageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';
import InvoiceDropDown from 'components/InvoiceDropDown/index.jsx';

class TrackPaymentPage extends Component
{
    render(){
        const { classes } = this.props;

        return(
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.contentContainer }>
                    <div className={ classes.pageContent }>
                        <div className={ classes.formContainer }>
                            <InvoiceDropDown iconUrl="/assets/img/calendar.png" category="Pending awaiting submission" />
                            <InvoiceDropDown iconUrl="/assets/img/shopping-list.png" category="Submitted and to be paid" />
                            <InvoiceDropDown iconUrl="/assets/img/tick-inside-circle.png" category="Paid Invoices" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(trackPaymentPageStyle)(TrackPaymentPage));