import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import { NavLink, Link } from 'react-router-dom';

class LandingHeader extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={ classes.headerContainer }>
                <Link to="/">
                    <img src="/assets/img/newui/header/blue_logo.png" style={{ width: 114, height: 43, }} />
                </Link>
                
                <div className='flex items-center flex-1 pl-5'>
                    <div className='mx-4'>
                        Employees
                    </div>
                    <div className='mx-4'>
                        Enterprise
                    </div>
                    <div className='mx-4'>
                        Accountant
                    </div>
                </div>
                <div className={ classes.authNavContainer }>
                    <NavLink to="/login">
                        Log In
                    </NavLink>
                    <NavLink to="/register">
                        Sign Up
                    </NavLink>
                </div>
            </div>
        )
    }
}

const styles = (theme) => ({
    headerContainer: {
        height: 66,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        background: '#F1F1F1',
        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 12,
        paddingBottom: 12,
        color: '#1E1E1E',
        fontWeight: 300,
    },
    authNavContainer: {
        '& a': {
            '&:first-child': {
                marginRight: 40,
            }
        }
    },
});

export default withStyles(styles)(LandingHeader);