import commonStyle from 'assets/jss/commonStyle'

const signLeanDocumentStyle = theme => ({
    ...commonStyle,
    leanTitle: {
        color: '#1D8ACF',
        textAlign: 'center'
    },
    btnUpload: {
        background: '#1D8ACF',
        color: 'white',
        borderRadius: 20,
        width: 200,
        height: 35,
        fontWeight: 500,
        marginTop: 40,
    }
});
export default signLeanDocumentStyle;