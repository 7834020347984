import commonStyle from 'assets/jss/commonStyle'

const reviewInvoicesPageStyle = theme => ({
    ...commonStyle,
    'table': {
        width: '100%'
    },
    btnTab: {
        padding: '9px 15px',
        color: '#1291F9',
        fontSize: 14,
    },
    btnTabActive: {
        background: '#1291F9',
        padding: '9px 15px',
        color: 'white',
        fontSize: 14,
    },
    btnBulkAction: {
        width: 125,
        height: 28,
        borderRadius: 20,
        fontSize: 13,
        color: 'white',
        background: '#1291F9',
        margin: '5px 10px'
    },
    bulkActionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 15,
        marginTop: 15,
    },
    attachFile: {
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
        }
    },
    tabs: {
        background: '#fff',
        borderRadius: 7,
        padding: '15px 10px',
        marginTop: 20,
        display: 'flex'
    },
    search: {
        marginLeft: 'auto'
    },
    tableContainer: {
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 7,
    },
});
export default reviewInvoicesPageStyle;