import React, {Component} from 'react'

import { connect } from 'react-redux';
import { NavLink, Redirect  } from 'react-router-dom'

import { withStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GlobalProcessorStyle from './GlobalProcessorStyle.jsx';

import { userLogout } from '../../redux/actions/accountActions';

class GlobalProcessor extends Component
{
    state = {
        open: false,
        startTime: null,
        error: {
        },
        loginInfo: {
            user: {
                first_name: '',
                last_name: ''
            }
        }
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if(nextProps.error.info.status != 401 && typeof this.props.error.info.status != 'undefined'){
            this.setState({ open: true });
        }
    }

    handleClose = (event, reason) => {
        this.setState({ open: false });
    };

    render(){
        const { classes } = this.props;
        if(this.props.error.info.status < 400) return null;

        if (this.props.error.info.status == 401) {
            /* this.props.userLogout();
            return <Redirect to='/login' /> */
            return null;
        } else {
            var info = this.props.error.info;
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{"Backend Error " + info.status + " - " + info.statusText}</span>}
                    action={[
                        <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                        >
                        <CloseIcon />
                        </IconButton>,
                    ]}
                />
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {...state, error: state.error}
}

const mapDispatchToProps = {userLogout}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(GlobalProcessorStyle)(GlobalProcessor));