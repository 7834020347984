import { CM_EVT_RESULT } from '../actions/types';

const initialState = {
    evtCreateRes: {}
};

export const eventResult = (state = initialState, action) => {
  switch (action.type) {
    case CM_EVT_RESULT:
      return {
        ...state,
        evtCreateRes: action.payload
      };
    default:
      return state;
  }
};
