import React, {Component} from 'react'
import { withStyles } from '@material-ui/core';
import rangeSliderStyle from './rangeSliderStyle'
class RangeSlider extends Component
{

    renderLabel = () => {
        const { classes, label }  = this.props;
        if(this.props.label == null)
            return;
        return(
            <div className={ classes.labelContainer }>
                {label}
            </div>
        )
    }

    convertShortStringVal = (val) => {
        var mxFr1 = new Intl.NumberFormat("en", { maximumFractionDigits: 1});
        var unit = '';
        var retval = val;
        var divider = 1;
        if(val > 1000 * 1000 * 1000){
            divider = 1000 * 1000 * 1000;
            unit = "G";
        }
        if(val > 1000 * 1000){
            divider = 1000 * 1000;
            unit = "M";
        }
        else if(val > 1000){
            divider = 1000;
            unit = "K";
        }

        var shortStr = mxFr1.format(val / divider);
        return shortStr + unit;
    }

    render(){
        let { classes, max, current, className } = this.props;
        var mxFr1 = new Intl.NumberFormat("en", { maximumFractionDigits: 1});
        var maxShortVal = this.convertShortStringVal(max);
        var currentShortVal = this.convertShortStringVal(current);
        return(
            <div className={ `${ classes.container } ${ className }` }>
                <div className={ classes.componentContainer }>
                    { this.renderLabel() }     
                    <div className={ classes.rangeBarContainer }>
                        <div className={ classes.rangeBar }>
                            <div className={ classes.greyBackground }>

                            </div>
                            <div className={ classes.redRange } style={{ width: `${current/max * 100}%` }}>
                                { currentShortVal }
                            </div>
                        </div>
                        <div  className={ classes.edgeValuesContainer }>
                            <span>0</span>
                            <span className={ classes.maxValue }>{ maxShortVal }</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(rangeSliderStyle)(RangeSlider);