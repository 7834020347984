import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import GlobalProcessor from 'components/GlobalProcessor/index';
import signLeanDocumentStyle from './signLeanDocumentStyle';
import { withStyles } from '@material-ui/core';
import { withTranslation  } from 'react-i18next';
import { successConfirm } from "components/SuccessConfirm/index";
import { confirm } from 'components/Confirm/index';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from 'components/Button';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.pdf,');

    return fileSelector;
}

class SignLeanDocumentPage extends Component
{
    state = {
        loading: true,
        existing_attachments: [],
        lean_document: null,
        uploading: false,
        uploadDisabled: false,
    }

    componentDidMount = async ()  => {
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        await this.fetchLeanDocument();
    }

    fetchLeanDocument = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const token = this.props.match.params["token"];
        return fetch(apiUrl + 'api/lean_release/get/by_token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({token})
        })
        .then(res => {
            if (!res.ok) {
                this.setState({ expired: true });
            }
            return  res.json();
        })
        .then(result => {
            this.setState({ lean_document: result });
            this.setState({
                existing_attachments: document['attachments'] ?
                    document['attachments'].split(',').filter((i) => { return i })
                    : []
            });
        });
    }

    openFileModal = () => {
        this.fileSelector.click();
    }

    fileChanged = (event) => {
        if(event.target.files.item(0) === null)
            return;
        
        const { lean_document } = this.state;
        const file = event.target.files.item(0);
        confirm("Confirm", `Are you sure to upload ${file.name}?`).then(
            async () => {
                const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

                let formData = new FormData();
                formData.append('file', file);
                formData.append('token', lean_document.onboard_token);
                this.setState({ uploadDisabled: true });
                await fetch(apiUrl + 'api/lean_release/notary/upload', {
                    method: 'POST',
                    headers:{
                        'X-CSRFToken': Cookies.get('csrftoken')
                    },
                    body: formData
                })
                .then(res => res.json())
                .then(data => {
                    if (data.status) {
                        successConfirm("Uploaded Successfully", "The document is uploaded successfuly and the confirmation email has been sent.", false).then(
                            async () => {
                                window.location.href = '/';
                            }
                        );
    
                        return;
                    }
                });
            },
            () => {
                this.fileSelector = buildFileSelector();
                this.fileSelector.addEventListener('change',e => this.fileChanged(e));
            }
        );
    }

    render() {
        const { classes, t } = this.props;
        const { lean_document, uploadDisabled } = this.state;

        if (this.state.expired) {
            return (
                <div className={ classes.container }>
                    <GlobalProcessor />
                    <div className={ classes.headerContainer }>
                        <div className={ classes.pageTitle }>
                            { t('Lien Document') }
                        </div>
                    </div>
                    <div className={ classes.pageContentContainer } style={{ padding: 30, }}>
                        <div className={ classes.contentContainer } style={{ paddingTop: 50 }}>
                            <h4 className={ classes.leanTitle } style={{ fontWeight: 600, fontSize: 20, }}>
                                {t('Upload Insurance Document')}
                            </h4>
                            <p style={{ marginTop: 30, paddingLeft: 10, }}>
                                { t('Insurance policy already uploaded.') }
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        if (lean_document) {
            return (
                <div className={ classes.container }>
                    <GlobalProcessor />
                    <div className={ classes.headerContainer }>
                        <div className={ classes.pageTitle }>
                            { t('Lien Document') }
                        </div>
                    </div>
                    <div className={ classes.pageContentContainer } style={{ padding: 30, backgroundColor: '#F3F2FF', }}>
                        <div className={ classes.contentContainer } style={{ textAlign: 'center' }}>
                            <h4 className={ classes.leanTitle } style={{ fontWeight: 600, fontSize: 20, }}>
                                {t('Upload Insurance Document')}
                            </h4>
                            <p style={{ marginTop: 30 }}>
                                { lean_document['requester']['first_name'] } { lean_document['requester']['last_name'] } requested you to upload an insurance document for a Lien document.
                            </p>
                            <Button
                                className={ classes.btnUpload }
                                onClick={ this.openFileModal }
                                disabled={ uploadDisabled }
                            >
                                <CloudUploadIcon 
                                    style={{ marginRight: 10 }}
                                />
                                UPLOAD
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
            </div>
        );
    }
    
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(signLeanDocumentStyle)(SignLeanDocumentPage)));