import { isAbsolute } from "path";

const existingUserPageStyle = theme => ({
    totalWrapper: {
        position: 'relative',
        '& .search-bar': {
            right: '0.8rem',
            top: '-4.6rem',
            '& input': {
                width: '12rem',
                borderRadius: '4px',
                padding: '0 0.5rem 0 0.5rem'
            }
        }
    },
    tableContainer: {
        borderRadius: 3,
        marginBottom: 30,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    columnName: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center',
    },
    columnCategory: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center',
    },
    columnCompany: {
        flex: 4,
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center',
    },
    columnView: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnActions: {
        flex: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableHeader: {
        background: '#1291F9',
        padding: '18px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        border: '1px solid #1291F9',
        fontWeight: 600,
        color: '#fff',
        '& $columnActions': {
            justifyContent: 'center'
        }
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12, 
        '&:nth-child(odd)': {
            background: '#fff'
        },
        '&:nth-child(even)': {
            background: 'rgba(18, 145, 249, 0.06)'
        },
        paddingTop: 5,
        paddingBottom: 5,
    },
    columnViewResend: {
        color: '#7C7C7C',
        position: 'relative',
        cursor: 'pointer',
        marginRight: '0.5rem',
        lineHeight: '12px',
        '& > div': {
            position: 'absolute',
            left: '0',
            bottom: '0'
        },
        '&.is-loading svg': {
        },
        '& button': {
            position: 'relative',
        },
        '& div': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            opacity: '0'
        },
        '&.is-loading > div': {
            opacity: '1'
        }
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        color: 'white',
        background: '#0072BB',
        width: 85,
        height: 23,
        fontSize: 13,
    },
    btnDel: {
        background: '#FFFFFF',
        border: '1px solid #E1E1E1',
        borderRadius: 3,
        '& img': {
            height: '100%',
            width: 15,
            height: 15,
            marginLeft: 7,
        },
        width: 31,
        height: 28,
        marginLeft: 10,
        marginRight: 10,
    },
    btnSendLink: {
        color: '#1291F9',
        fontSize: 12,
        width: 80,
        height: 22,
        background: 'rgba(0, 153, 255, 0.2)',
        borderRadius: 7,
    }
})

export default existingUserPageStyle;