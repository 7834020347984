import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import BrushIcon from '@material-ui/icons/Brush';

import setupPageStyle from './setupPageStyle.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import CheckBox from 'components/CustomCheckBox/index.jsx';
import Button from 'components/Button/index.jsx';
import Footer from 'components/Footer/index.jsx';

import ReactCodeInput from 'react-code-input';
import SignaturePad from 'react-signature-pad-wrapper';

import { setupW9Form } from '../../redux/actions/commonActions';
import { withTranslation  } from 'react-i18next';

class SetupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginInfo: {
                user: {
                    first_name: '',
                    last_name: '',
                    profile: {
                        user_type: ''
                    }
                }
            },
            w9FormData: {
                name: "",
                business_name: "",
                federal_tax: "",
                tax_limited_detail: "",
                tax_other_detail: "",
                exempt_payee_code: "",
                exempt_fatch: "",
                address: "",
                requester_name_address: "",
                city_state_zipcode: "",
                list_amount_number: "",
                tin_security_number: "",
                tin_employer_id: "",
                payment_mode: "paypal",
                paypal_email: "",
                paypal_id: "",
                bank_account_number: "",
                bank_routing_number: "",
                bank_name_on_account: "",
                bank_name_bank: "",
                bank_address_bank: "",
                googlepay_email: "",
                googlepay_id: "",
            },
            w9FormDataValidationSuccess: '',
            w9FormDataValidationError: '',
            w9FormDataValidationTag: '',
            signType: 'amandasignature',
            height: window.innerHeight,
            width: window.innerWidth,
            startingNumber: 1,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.state.loginInfo = {...this.state.loginInfo, ...this.props['account']['loginStatus']};
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
        this.loadExistingW9();
    }

    loadExistingW9() {
        const loginStatus = this.props.account.loginStatus;
        const apiUrl = process.env.NODE_ENV === 'production' ?
            (
                process.env.REACT_APP_SERVER_MODE == 'LIVE' ?
                    process.env.REACT_APP_BACKEND_LIVE :
                    process.env.REACT_APP_BACKEND_TEST
            ) :
            process.env.REACT_APP_DEV_BACKEND;

        return fetch(apiUrl + 'api/common/w9form_setup', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${loginStatus.token}`,
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        })
        .then(res => res.json())
        .then(res => {
            if (res.status) {
                let data = res.data;
                let w9Data = {
                    name: data.name,
                    business_name: data.business_name,
                    federal_tax: data.federal_tax,
                    tax_limited_detail: data.tax_limited_detail,
                    tax_other_detail: data.tax_other_detail,
                    exempt_payee_code: data.exempt_payee_code,
                    exempt_fatch: data.exempt_fatch,
                    address: data.address,
                    requester_name_address: data.requester_name_address,
                    city_state_zipcode: data.city_state_zipcode,
                    list_amount_number: data.list_amount_number,
                    tin_security_number: data.tin_security_number,
                    tin_employer_id: data.tin_employer_id,
                    payment_mode: data.payment_mode,
                    paypal_email: data.paypal_email,
                    paypal_id: data.paypal_id,
                    bank_account_number: data.bank_account_number,
                    bank_routing_number: data.bank_routing_number,
                    bank_name_on_account: data.bank_name_on_account,
                    bank_name_bank: data.bank_name_bank,
                    bank_address_bank: data.bank_address_bank,
                    googlepay_email: data.googlepay_email,
                    googlepay_id: data.googlepay_id
                };
                this.setState({w9FormData: w9Data});

                let socialInputArr = [...data.tin_security_number];
                if (socialInputArr.length < 9) {
                    let counts = 9 - socialInputArr.length;
                    for (let i = 0; i < counts; i++) {
                        socialInputArr.push('');
                    }
                }

                let employerInputArr = [...data.tin_employer_id];
                console.log("canvas", employerInputArr);
                if (employerInputArr.length < 9) {
                    let counts = 9 - employerInputArr.length;
                    for (let i = 0; i < counts; i++) {
                        employerInputArr.push('');
                    }
                }
                console.log("canvas", employerInputArr);

                this.codeInputSocial.setState({input: socialInputArr});
                this.codeInputEmployerId.setState({input: employerInputArr});

                // Draw Signature image
                let canvas = this.signaturePad._canvas;
                let ctx = canvas.getContext('2d');
                let img = new Image;
                img.onload = function() {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Or at whatever offset you like
                };
                img.src = data.signature;
            }
        });
    }

    checkValidNumber(value) {
        var digits = value.split('');
        var invalid = true;

        if (value.length == 0) {
            return false;
        }

        value = value.trim();

        var charFoundMap = {};//object defined
        var charCount = 0;
        for (var i = 0; i < value.length; i++) {
            if (!charFoundMap[value[i]]) {
                charFoundMap[value[i]] = 1;
                charCount += 1;
            } else {
                charFoundMap[value[i]] += 1;
            }
        }

        if (charCount == 1) {
            return false;
        }

        var prevNumber = null;
        var direction = 0;
        for (var i = 0; i < value.length; i++) {
            var number = parseInt(value.charAt(i));
            if (prevNumber == null) {
                prevNumber = number;
                continue;
            }

            if (direction == 0) {
                direction = number - prevNumber;
                direction = direction == -9 ? 1 : direction;
                direction = direction == 9 ? -1 : direction;
            }

            if (direction != 1 && direction != -1) {
                return true;
            }

            if (number - prevNumber != direction && number - prevNumber != direction * -9) {
                return true;
            }

            prevNumber = number;
        }


        return false;
    }

    updateDimensions() {
        window.scrollTo(0, 0);
        try {
            var heightRatio = 0.33;

            this.setState({
                height: window.innerHeight,
                width: window.innerWidth
            });

            var ratio = Math.max(window.devicePixelRatio || 1, 1);
            var canvas = this.signaturePad._canvas;
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetWidth * ratio * heightRatio;
            canvas.getContext("2d").scale(ratio, ratio);
            //this.signaturePad.clear();
        } catch (e) {

        }
    }

    cleanSignPad = (e) => {
        this.signaturePad.clear();
        this.signaturePad.isEmpty();
    }

    saveSignPad = (e) => {
        console.log(this.signaturePad);
    }

    drawNameOnSignPad = (name, font = 'sign-amandasignature', fontSize = 56) => {
        if (name.length < 1) return;
        this.signaturePad.clear();
        this.signaturePad.isEmpty();

        var canvas = this.signaturePad._canvas;
        var ctx = canvas.getContext('2d');
        var cWidth = canvas.width;
        var cHeight = canvas.height;
        var nLength = name.length;
        ctx.font = fontSize + 'px "' + font + '"';
        ctx.fillText(name, 30, canvas.offsetHeight * 0.5 + (cHeight / canvas.offsetHeight) * fontSize * 0.5);
    }

    isSignBlank = () => {
        var canvas = this.signaturePad._canvas;
        return !canvas.getContext('2d')
            .getImageData(0, 0, canvas.width, canvas.height).data
            .some(channel => channel !== 0);
    }

    setPaymentType = (paymentMode) => {
        const dataOject = {}
        dataOject["payment_mode"] = paymentMode;
        this.setState({ w9FormData: Object.assign(this.state.w9FormData, dataOject) });
    }

    renderPaypalInputs = () => {
        const { classes } = this.props;
        const { t } = this.props;

        if (this.state.w9FormData.payment_mode == 'paypal') {
            return (
                <div className={`${classes.width_100}`} style={{'padding': '15px 0'}}>
                    <GridContainer>
                        <GridItem xs={5} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Paypal Email Address")}
                                onChange={e => this.handleChangeValue(e, 'paypal_email')} value={this.state.w9FormData.paypal_email} />
                        </GridItem>
                        <GridItem xs={2} sm={1} className={classes.marginVertical_15} style={{'paddingTop': '15px', 'paddingLeft': '15px'}}>
                            Or
                        </GridItem>
                        <GridItem xs={5} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder="Paypal ID"
                                onChange={e => this.handleChangeValue(e, 'paypal_id')} value={this.state.w9FormData.paypal_id} />
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
        return null;
    }

    renderBankInputs = () => {
        const { classes, t } = this.props;
        if (this.state.w9FormData.payment_mode == 'bank') {
            return (
                <div className={`${classes.width_100}`} style={{'padding': '15px 0'}}>
                    <GridContainer>
                        <GridItem xs={4} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Bank Account Number")}
                                onChange={e => this.handleChangeValue(e, 'bank_account_number')} value={this.state.w9FormData.bank_account_number} />
                        </GridItem>
                        <GridItem xs={4} sm={4} className={classes.marginVertical_15} style={{'paddingLeft': '15px'}}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Routing Number")}
                                onChange={e => this.handleChangeValue(e, 'bank_routing_number')} value={this.state.w9FormData.bank_routing_number} />
                        </GridItem>
                        <GridItem xs={4} sm={4} className={classes.marginVertical_15} style={{'paddingLeft': '15px'}}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Name on Account")}
                                onChange={e => this.handleChangeValue(e, 'bank_name_on_account')} value={this.state.w9FormData.bank_name_on_account} />
                        </GridItem>
                    </GridContainer>
                    <div className={`${classes.width_100}`} style={{'padding': '8px 0'}}>
                    </div>
                    <GridContainer>
                        <GridItem xs={4} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Bank Name")}
                                onChange={e => this.handleChangeValue(e, 'bank_name_bank')} value={this.state.w9FormData.bank_name_bank} />
                        </GridItem>
                        <GridItem xs={8} sm={8} className={classes.marginVertical_15} style={{'paddingLeft': '15px'}}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Bank Address")}
                                onChange={e => this.handleChangeValue(e, 'bank_address_bank')} value={this.state.w9FormData.bank_address_bank} />
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
        return null;
    }

    renderGooglePayInputs = () => {
        const { classes, t } = this.props;
        if (this.state.w9FormData.payment_mode == 'googlepay') {
            return (
                <div className={`${classes.width_100}`} style={{'padding': '15px 0'}}>
                    <GridContainer>
                        <GridItem xs={5} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Google Pay Email Address")}
                                onChange={e => this.handleChangeValue(e, 'googlepay_email')} value={this.state.w9FormData.googlepay_email} />
                        </GridItem>
                        <GridItem xs={2} sm={1} className={classes.marginVertical_15} style={{'paddingTop': '15px', 'paddingLeft': '15px'}}>
                            {t('Or')}
                        </GridItem>
                        <GridItem xs={5} sm={4} className={classes.marginVertical_15}>
                            <BaseInput className={`${classes.width_100}`}
                                placeholder={t("Google Pay ID")}
                                onChange={e => this.handleChangeValue(e, 'googlepay_id')} value={this.state.w9FormData.googlepay_id} />
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }
        return null;
    }

    setSignType = (signType) => {
        this.setState({ signType: signType });
        if (signType != '') {
            //var name = (this.state.w9FormData.business_name != '')?  this.state.w9FormData.business_name: this.state.w9FormData.name;
            var name = this.state.w9FormData.name;
            this.drawNameOnSignPad(name, 'sign-' + signType);
        } else {
            this.signaturePad.clear();
            this.signaturePad.isEmpty();
        }
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {}
        if (valueType == 'tin_security_number' || valueType == 'tin_employer_id') {
            var value = e;
            if (value.length == 9) dataOject[valueType] = value;
            else dataOject[valueType] = '';
        } else {
            dataOject[valueType] = e.target.value;
        }
        this.setState({ w9FormData: Object.assign(this.state.w9FormData, dataOject) });

        if (this.state.signType != '' && (valueType == 'name' || valueType == 'business_name')) {
            //var name = (this.state.w9FormData.business_name != '')?  this.state.w9FormData.business_name: this.state.w9FormData.name;
            var name = this.state.w9FormData.name;
            this.drawNameOnSignPad(name, 'sign-' + this.state.signType);
        }
    }


    saveW9Form = (e) => {

    }

    submitW9Form = async (e) => {

        this.setState({ 'w9FormDataValidationError': '', 'w9FormDataValidationTag': '' });

        if (this.state.w9FormData.name.length < 4) {
            this.setState({
                'w9FormDataValidationError': 'Name is required and need more than 4 letters',
                'w9FormDataValidationTag': 'name'
            }); return;
        }
        if (this.state.w9FormData.business_name.length < 4  && this.state.w9FormData.federal_tax !== 'individual') {
            this.setState({
                'w9FormDataValidationError': 'Business Name is required and need more than 4 letters',
                'w9FormDataValidationTag': 'name'
            }); return;
        }
        if (this.state.w9FormData.federal_tax == '') {
            this.setState({
                'w9FormDataValidationError': 'Please select one of Federal Tax Classification',
                'w9FormDataValidationTag': 'tax'
            }); return;
        }
        if (this.state.w9FormData.federal_tax == 'limited' && this.state.w9FormData.tax_limited_detail.length < 1) {
            this.setState({
                'w9FormDataValidationError': 'Enter the tax classification of Limited liability company'
            }); return;
        }
        if (this.state.w9FormData.federal_tax == 'other' && this.state.w9FormData.tax_other_detail.length < 1) {
            this.setState({
                'w9FormDataValidationError': 'Enter the tax classification other`s details',
                'w9FormDataValidationTag': 'name'
            }); return;
        }
        if (this.state.w9FormData.address.length < 10) {
            this.setState({
                'w9FormDataValidationError': 'Address is required and more than 10 letters',
                'w9FormDataValidationTag': 'address'
            }); return;
        }
        /* if (this.state.w9FormData.requester_name_address.length < 10) {
            this.setState({
                'w9FormDataValidationError': 'Requester’s name and address is required and more than 10 letters',
                'w9FormDataValidationTag': 'address'
            }); return;
        } */
        if (this.state.w9FormData.city_state_zipcode.length < 2) {
            this.setState({
                'w9FormDataValidationError': 'City, state, and ZIP code is Required',
                'w9FormDataValidationTag': 'city'
            }); return;
        }
        /* if(this.state.w9FormData.list_amount_number.length < 1){
            this.setState({
                'w9FormDataValidationError': 'Business is Required',
                'w9FormDataValidationTag': 'name'
            }); return;
        } */
        if (this.state.w9FormData.tin_security_number.length < 1 && this.state.w9FormData.tin_employer_id.length < 1) {
            this.setState({
                'w9FormDataValidationError': 'TIN: Social security number / Employer identification number is Required',
                'w9FormDataValidationTag': 'tin'
            }); return;
        }

        if (this.state.w9FormData.tin_security_number.length > 0) {
            if (!this.checkValidNumber(this.state.w9FormData.tin_security_number)) {
                this.setState({
                    'w9FormDataValidationError': 'TIN: Social security number is not correct',
                    'w9FormDataValidationTag': 'tin'
                });
                return;
            }
        }

        if (this.state.w9FormData.tin_employer_id.length > 0) {
            if (!this.checkValidNumber(this.state.w9FormData.tin_employer_id)) {
                this.setState({
                    'w9FormDataValidationError': 'Employer identification number is not correct',
                    'w9FormDataValidationTag': 'tin'
                });
                return;
            }
        }

        /* if (this.state.w9FormData.payment_mode == "paypal") {
            if (this.state.w9FormData.paypal_email.trim().length < 1 && this.state.w9FormData.paypal_id.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Paypal informaiton required',
                    'w9FormDataValidationTag': 'paypal'
                });
                return;
            }
        }

        if (this.state.w9FormData.payment_mode == "bank") {

            if (this.state.w9FormData.bank_account_number.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Bank Account Number required',
                    'w9FormDataValidationTag': 'bank'
                });
                return;
            }

            if (this.state.w9FormData.bank_routing_number.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Bank Routing Number required',
                    'w9FormDataValidationTag': 'bank'
                });
                return;
            }

            if (this.state.w9FormData.bank_name_on_account.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Bank Account Name required',
                    'w9FormDataValidationTag': 'bank'
                });
                return;
            }

            if (this.state.w9FormData.bank_name_bank.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Bank Name required',
                    'w9FormDataValidationTag': 'bank'
                });
                return;
            }

            if (this.state.w9FormData.bank_address_bank.trim().length < 1) {
                this.setState({
                    'w9FormDataValidationError': 'Bank Address required',
                    'w9FormDataValidationTag': 'bank'
                });
                return;
            }
        } */


        if (this.isSignBlank()) {
            this.setState({
                'w9FormDataValidationError': 'Signature is Required',
                'w9FormDataValidationTag': 'signature'
            }); return;
        }

        var canvas = this.signaturePad._canvas;
        var signImgData = {
            'signature': canvas.toDataURL()
        };

        let data = { ...this.state.w9FormData };
        if (this.state.federal_tax === 'individual') {
            data.name = '';
            data.business_name = '';
        }

        await this.setState({ w9FormData: Object.assign(data, signImgData) });

        await this.props.setupW9Form(this.state.w9FormData);

        this.handleSubmitResponse(this.props.commonResponse);

    }

    // Process Response
    handleSubmitResponse = (res) => {
        if (res.commonResponse.status) {
            var newItemId = res.commonResponse.affected_id;
            this.setState({ 'w9FormDataValidationSuccess': res.commonResponse.message });
            /* this.setState({'w9FormData': {
                name: "",
                business_name: "",
                federal_tax: "",
                tax_limited_detail: "",
                tax_other_detail: "",
                exempt_payee_code: "",
                exempt_fatch: "",
                address: "",
                requester_name_address: "",
                city_state_zipcode: "",
                list_amount_number: "",
                tin_security_number: "",
                tin_employer_id: "",
            }});
            this.cleanSignPad();
            this.clearCodeInput(this.codeInputSocial); */
            setTimeout(() => {
                this.setState({ 'w9FormDataValidationSuccess': '' });
                window.location.reload();
            }, 2000);
        } else {
            this.setState({ 'w9FormDataValidationError': res.commonResponse.message });
        }
    }

    clearCodeInput = (instance) => {
        if (typeof instance['textInput'] != 'object') return;
        var inputs = instance.textInput;
        inputs.forEach((input) => {
            input.value = '';
        })
    }

    checkFormValidationError = () => {
        const { classes } = this.props;
        if (this.state.w9FormDataValidationError != '') {
            return (
                <a href={'#' + this.state.w9FormDataValidationTag}>
                    <span style={{ color: '#CC3333' }}>
                        &nbsp;&nbsp;{this.state.w9FormDataValidationError}
                    </span>
                </a>
            );
        }
        else if (this.state.w9FormDataValidationSuccess != '') {
            return (
                <span style={{ color: '#33CC33' }}>
                    &nbsp;&nbsp;{this.state.w9FormDataValidationSuccess}
                </span>
            );
        }
    }


    // Props change test
    loadInitialData = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            return (
                <span>Initial Loading</span>
            );
        } else {
            return (
                <span>Not Loaded yet</span>
            );
        }
    }

    render() {
        const { classes, t } = this.props;
        const { startingNumber }  = this.state;
        let loginInfo = this.state.loginInfo;

        return (
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                {this.setState()}
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        W-9
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.pageContent}>
                        <div className={classes.formContainer}>
                            <div className={classes.formHeaderContainer}>
                                <div className={classes.formLabelContainer}>
                                    <div className={classes.formNumberContainer}>
                                        <div className={classes.formLabel}>{ t('Form') }</div>
                                        <div className={classes.formNumber}>W-9</div>
                                    </div>
                                    <div className={classes.companyTitle}>
                                        (Rev. October 2018) <br />
                                        { t('Department of the Treasury Internal Revenue Service') }
                                    </div>
                                </div>
                                <div className={classes.subTitleContainer}>
                                    <div className={classes.redTitle}>
                                        { t('Request for Taxpayer') } <br /> { t('Identification Number and Certification') }
                                    </div>
                                    <div className={classes.infoUrl}>
                                        { t('Go to') } <a href="https://www.irs.gov/FormW9" target="_blank">www.irs.gov/FormW9</a> { t('for instruction and the latest information.') }
                                    </div>
                                </div>
                                <div className={classes.hintContainer}>
                                    <div>
                                        { t('Give Form to the requester.') }<br /> { t('Do not send to the IRS') }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.divider}> </div>

                            {/* Name & Business Name */}
                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="name"></a>
                            </div>
                            <GridContainer spacing={32} >
                                <GridItem xs={12} sm={12} md={6} lg={5} xl={4}
                                    className={`md:pr-3`}
                                >
                                    <div>
                                        <span className={classes.colorRed}>{ startingNumber }.</span> { t('Name') } ({ t('as shown on your income tax return') })
                                    </div>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`} onChange={e => this.handleChangeValue(e, 'name')} value={this.state.w9FormData.name} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={7} xl={8}>
                                    <div>
                                        <span className={classes.colorRed}>{ startingNumber + 1 }.</span> { t('Business name/disregarded entity name, if different from above') }
                                    </div>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`} onChange={e => this.handleChangeValue(e, 'business_name')} value={this.state.w9FormData.business_name} />
                                </GridItem>
                            </GridContainer>
                            {/* Tax */}
                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="tax"></a>
                            </div>
                            <GridContainer className={ `${classes.marginTop_25} ${classes.marginBottom_10}` }>
                                <GridItem>
                                    <span className={classes.colorRed}>{ startingNumber + 2 }.</span> { t('Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes.') }
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <GridContainer alignItems="center">
                                        <CheckBox name="federal_tax" label="Individual/sole proprietor or single-member LLC" className={classes.marginRight_40}
                                            value="individual"
                                            checked={this.state.w9FormData.federal_tax == 'individual'}
                                            onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                        <CheckBox name="federal_tax" label="C Corporation" className={classes.marginRight_40}
                                            value="c_corporation"
                                            checked={this.state.w9FormData.federal_tax == 'c_corporation'}
                                            onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                        <CheckBox name="federal_tax" label="S Corporation" className={classes.marginRight_40}
                                            value="s_corporation"
                                            checked={this.state.w9FormData.federal_tax == 's_corporation'}
                                            onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                        <CheckBox name="federal_tax" label="Partnership" className={classes.marginRight_40}
                                            value="partnership"
                                            checked={this.state.w9FormData.federal_tax == 'partnership'}
                                            onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                        <CheckBox name="federal_tax" label="Trust/estate"
                                            value="trust"
                                            checked={this.state.w9FormData.federal_tax == 'trust'}
                                            onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>
                                    <CheckBox name="federal_tax" label="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership) ▶"
                                        value="limited"
                                        checked={this.state.w9FormData.federal_tax == 'limited'}
                                        onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                    <BaseInput type="text" className={classes.marginLeft_25} disabled={this.state.w9FormData.federal_tax != 'limited'}
                                        onChange={e => this.handleChangeValue(e, 'tax_limited_detail')} value={this.state.w9FormData.tax_limited_detail} />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem className={`${classes.paddingLeft_12} ${classes.marginTop_10}`}>
                                    <span className={classes.colorRed} >{t('Note')}:</span> {t('Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.')}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem className={classes.marginTop_10}>
                                    <CheckBox name="federal_tax" label={ t('Other (see instructions)') + " ▶" } className={classes.marginRight_25}
                                        value="other"
                                        checked={this.state.w9FormData.federal_tax == 'other'}
                                        onChange={e => this.handleChangeValue(e, 'federal_tax')} />
                                    <BaseInput type="text" className={classes.marginLeft_25} disabled={this.state.w9FormData.federal_tax != 'other'}
                                        onChange={e => this.handleChangeValue(e, 'tax_other_detail')} value={this.state.w9FormData.tax_other_detail} />
                                </GridItem>
                            </GridContainer>
                            {/* Exemptions Code  */}
                            <GridContainer>
                                <GridItem className={`${classes.marginBottom_10} ${classes.marginTop_30}`}>
                                    <span className={classes.colorRed} >{ startingNumber + 3 }.</span> {t('Exemptions (codes apply only to certain entities, not individuals; see instructions on page 3)')}:
                                </GridItem>
                            </GridContainer>
                            <GridContainer className={`${classes.marginTop_10} ${classes.paddingLeft_12}`}>
                                <GridItem xs={12} lg={4}>
                                    {t('Exempt payee code (if any)')}
                                    <BaseInput className={classes.marginLeft_25} type="text"
                                        onChange={e => this.handleChangeValue(e, 'exempt_payee_code')} value={this.state.w9FormData.exempt_payee_code} />
                                </GridItem>
                                <GridItem xs={12} lg={8}>
                                    {t('Exemption from FATCA reporting code (if any)')}
                                    <BaseInput className={classes.marginLeft_25} type="text"
                                        onChange={e => this.handleChangeValue(e, 'exempt_fatch')} value={this.state.w9FormData.exempt_fatch} />
                                </GridItem>
                                <GridItem xs={12} className={classes.marginVertical_10}>
                                    <span className={`${classes.colorRed} `} >({t('Applies to accounts maintained outside the U.S.')})</span>
                                </GridItem>
                            </GridContainer>

                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="address"></a>
                            </div>
                            <GridContainer spacing={40} className={classes.marginTop_10} >
                                <GridItem xs={12} md={5} className={`md:pr-3`}>
                                    <div>
                                        <span className={classes.colorRed} >{ startingNumber + 4 }.</span> {t('Address (number, street, and apt. or suite no.) See instructions.')}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={7}>
                                    <div>
                                        {t('Requester’s name and address (optional)')}
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer spacing={40} className={classes.marginTop_10} >
                                <GridItem xs={12} md={5} className={`md:pr-3`}>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`}
                                        onChange={e => this.handleChangeValue(e, 'address')} value={this.state.w9FormData.address} />
                                </GridItem>
                                <GridItem xs={12} md={7}>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`}
                                        onChange={e => this.handleChangeValue(e, 'requester_name_address')} value={this.state.w9FormData.requester_name_address} />
                                </GridItem>
                            </GridContainer>

                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="city"></a>
                            </div>
                            <GridContainer spacing={40} className={classes.marginTop_10}>
                                <GridItem xs={12} md={4} className={`md:pr-3`}>
                                    <div>
                                        <span className={classes.colorRed} >{ startingNumber + 5 }.</span> {t('City, state, and ZIP code')}
                                    </div>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`}
                                        onChange={e => this.handleChangeValue(e, 'city_state_zipcode')} value={this.state.w9FormData.city_state_zipcode} />
                                </GridItem>
                                <GridItem xs={12} md={8}>
                                    <div>
                                        <span className={classes.colorRed} >{ startingNumber + 6 }.</span> {t('List account number(s) here (optional)')}
                                    </div>
                                    <BaseInput className={`${classes.marginVertical_10} ${classes.width_100}`}
                                        onChange={e => this.handleChangeValue(e, 'list_amount_number')} value={this.state.w9FormData.list_amount_number} />
                                </GridItem>
                            </GridContainer>

                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="tin"></a>
                            </div>
                            <GridContainer>
                                <GridItem xs={12} className={`${classes.marginTop_30} ${classes.marginBottom_25}`}>
                                    <span className={classes.partNumber} >{t('PART 1')}</span><span className={classes.partTitle} >{t('Taxpayer Identification Number (TIN)')}</span>
                                </GridItem>
                                <GridItem xs={12}>
                                    {t('Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is')}
                                </GridItem>
                                <GridItem xs={12} className={classes.marginVertical_10}>
                                    <span className={classes.colorRed}>{t('Note')}:</span> {t('If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.')}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={5} className={classes.marginVertical_10}>
                                    {t('Social security number')}
                                </GridItem>
                                <GridItem xs={2}>
                                </GridItem>
                                <GridItem xs={5} className={classes.marginVertical_10}>
                                    {t('Employer identification number')}
                                </GridItem>
                                <GridItem xs={5} className={`social_number`}>
                                    <ReactCodeInput type='number' fields={9}
                                        onChange={e => this.handleChangeValue(e, 'tin_security_number')} value={this.state.w9FormData.tin_security_number} ref={ref => this.codeInputSocial = ref} />
                                </GridItem>
                                <GridItem xs={2}>
                                    <div className={classes.orLabel}>
                                        {t('OR')}
                                    </div>
                                </GridItem>
                                <GridItem xs={5} className={`employee_number`}>
                                    <ReactCodeInput type='number' fields={9}
                                        onChange={e => this.handleChangeValue(e, 'tin_employer_id')} value={this.state.w9FormData.tin_employer_id} ref={ref => this.codeInputEmployerId = ref} />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} className={`${classes.marginTop_30} ${classes.marginBottom_25}`}>
                                    <span className={classes.partNumber} >{t('PART')} 2</span><span className={classes.partTitle} >{t('Certification')}</span>
                                </GridItem>
                                <GridItem xs={12} className={classes.marginBottom_25}>
                                    {t('Under penalties of perjury, I certify that:')}
                                </GridItem>
                                <GridItem xs={12}>
                                    1. {t('The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and')} <br />
                                    2. {t('I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and')} <br />
                                    3. {t('I am a U.S. citizen or other U.S. person (defined below); and')} <br />
                                    4. {t('The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.')}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} className={classes.marginVertical_25}>
                                    <span className={classes.colorRed}>{t('Certification instructions')}.</span><br /> {t('You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.')}
                                </GridItem>
                            </GridContainer>

                            {/* Signature Pad */}
                            <div className={classes.navRef}>
                                <a className={classes.navRefLink} name="signature"></a>
                            </div>
                            <GridContainer spacing={40}>
                                <GridItem xs={8} style={{ paddingBottom: 0 }} className={ classes.colorRed }>
                                    {t('Signature')}
                                </GridItem>
                                <GridItem xs={8} style={{ paddingRight: 0 }}>
                                    <div className={`${classes.signature_pad} sign_pad_wrapper`}>
                                        <SignaturePad ref={ref => this.signaturePad = ref} options={{ dotSize: 5, minWidth: 1, maxWidth: 1.5, maxHeight: 1, penColor: 'rgb(0, 0, 0)' }}  />
                                        <div style={{ textAlign: 'right' }}>
                                            <Button className={`button-sm`} onClick={e => this.cleanSignPad(e)} style={{ fontSize: 16 }}>
                                                {t('Clear')}
                                            </Button>
                                        </div>
                                        {/* <button onClick={e => this.saveSignPad(e)}>Save</button> */}
                                        <div className={`preload_font type1`}>Preloading font</div>
                                        <div className={`preload_font type2`}>Preloading font</div>
                                        <div className={`preload_font type3`}>Preloading font</div>
                                        <div className={`preload_font type4`}>Preloading font</div>
                                    </div>
                                </GridItem>
                                <GridItem xs={4}>
                                    <List component="nav">
                                        <ListItem button onClick={e => this.setSignType('')} selected={this.state.signType == ''}>
                                            <ListItemIcon><BrushIcon /></ListItemIcon>
                                            <ListItemText primary={t("Draw")} />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('amandasignature')} selected={this.state.signType == 'amandasignature'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Amanda Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('geovana')} selected={this.state.signType == 'geovana'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Geovana Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('julietta')} selected={this.state.signType == 'julietta'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Julietta Font" />
                                        </ListItem>
                                        <ListItem button onClick={e => this.setSignType('vtks-hunt')} selected={this.state.signType == 'vtks-hunt'}>
                                            <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                            <ListItemText primary="Vtks Hunt Font" />
                                        </ListItem>
                                    </List>
                                </GridItem>
                            </GridContainer>
                            {/* <GridContainer>
                                <GridItem xs={12} className={classes.marginVertical_5}>
                                    <ListItem button onClick={e => this.setPaymentType('paypal')} selected={this.state.w9FormData.payment_mode == 'paypal'}>
                                        <ListItemText primary="Paypal" />
                                    </ListItem>
                                </GridItem>
                                <div className={classes.navRef}>
                                    <a className={classes.navRefLink} name="paypal"></a>
                                </div>
                                { this.renderPaypalInputs() }

                                {
                                    loginInfo.user.customer.permalink == 'tedex' ?
                                    null :
                                    (
                                        <React.Fragment>
                                            <GridItem xs={12} className={classes.marginVertical_5}>
                                                <ListItem button onClick={e => this.setPaymentType('bank')} selected={this.state.w9FormData.payment_mode == 'bank'}>
                                                    <ListItemText primary={t("Bank Transfer")} />
                                                </ListItem>
                                            </GridItem>
                                            <div className={classes.navRef}>
                                                <a className={classes.navRefLink} name="bank"></a>
                                            </div>
                                            { this.renderBankInputs() }
                                            <GridItem xs={12} className={classes.marginVertical_5}>
                                                <ListItem button onClick={e => this.setPaymentType('googlepay')} selected={this.state.w9FormData.payment_mode == 'googlepay'}>
                                                    <ListItemText primary={t("Google Pay")} />
                                                </ListItem>
                                            </GridItem>
                                            <div className={classes.navRef}>
                                                <a className={classes.navRefLink} name="googlepay"></a>
                                            </div>
                                            { this.renderGooglePayInputs() }
                                        </React.Fragment>
                                    )
                                }
                            </GridContainer> */}

                            <GridContainer>
                                <GridItem xs={12} className={classes.marginTop_30}>
                                    {/* <Button className={`${classes.btnSave} ${classes.marginRight_25}`} onClick={e => this.saveW9Form(e)}>
                                        Save
                                    </Button> */}
                                    <Button className={classes.btnSubmit} onClick={e => this.submitW9Form(e)}>
                                        {t('Submit')}
                                    </Button>
                                    {this.checkFormValidationError()}
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        account: state.account,
        commonResponse: state.commonResponse,
        initialResponse: state.initialResponse
    }
}

const mapDispatchToProps = { setupW9Form };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(setupPageStyle)(SetupPage)));
