import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import GlobalProcessor from 'components/GlobalProcessor/index';
import msaPageStyle from './msaPageStyle';
import { withStyles } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from "@material-ui/core/Grid";

import MenuItem from '@material-ui/core/MenuItem';
import MsaItem from 'components/MsaItem';
import SearchInput from 'components/SearchInput/SearchInput';
import NativeSelect from 'components/NativeSelect';
import moment from 'moment';
import lodash from 'lodash';
import Button from 'components/Button';

import { withTranslation  } from 'react-i18next';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc');

    return fileSelector;
}

class MsaPage extends Component
{
    state = {
        documents:[],
        filteredDocs: [],
        loading: false,
        groupBy: 'name',
        query: ''
    }

    componentDidMount = async ()  => {
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        await this.fetchMsaDocuments();
    }

    fetchMsaDocuments = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + 'api/msa/list', {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + loginStatus.token,
                'X-CSRFToken': Cookies.get('csrftoken')
            }
        })
        .then(res => res.json())
        .then(documents => {
            documents = documents.map(doc => {
                doc['signer'] = doc.signers.filter(signer => signer.user_type !== 'VENDOR')[0];
                if (doc['created_at']) {
                    doc['created_at'] = moment(doc['created_at']).format('YYYY-MM-DD');
                }

                if (doc['completed_at']) {
                    doc['completed_at'] = moment(doc['completed_at']).format('YYYY-MM-DD');
                }

                if (loginStatus.user.profile.user_type === 'ADMIN') {
                    doc['signer'] = doc.signers.filter(signer => signer.user_type === 'VENDOR')[0];
                }

                if (typeof doc['signer'] == 'undefined') {
                    return null;
                }

                return doc;
            })
            documents = documents.filter((doc) => { return doc !== null; });

            documents = lodash.sortBy(documents, [(doc) => { return doc.signer ? doc.signer.id : 0 } ]);
            this.setState({ filteredDocs:documents, documents, loading: false });
        });
    }

    filterDocuments = () => {
        const { documents, query:value } = this.state;

        let newFiltered = [...documents];
        if (!value.length) {
            return newFiltered;
        }
        newFiltered = newFiltered.filter(doc => {
            const signers = [doc.signer];

            let searchStr = [
                doc.signer.first_name.toLowerCase(),
                doc.signer.last_name.toLowerCase(),
                doc.signer.email.toLowerCase(),
            ];

            for (let str of searchStr) {
                if (str.includes(value.toLowerCase())) {
                    return true;
                }
            }
            return false;
        })

        return newFiltered;
    }

    render() {
        const { classes, t } = this.props;
        const { loading, query, groupBy } = this.state;

        if (loading) {
            return (
                <div className={classes.setupPageContainer}>
                    <GlobalProcessor />
                    <div className={ classes.contentContainer }>
                        <div className={ classes.pageContent }>
                            <div className={ classes.formContainer }>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={ classes.container }>
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        { t('MSA Documents') }
                    </div>
                </div>
                <div className={ classes.pageContentContainer } style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <GridContainer style={{ marginTop: 20, marginBottom: 20 }}>
                        <GridItem md={12} className={ classes.uploadIconContainer } lg={4} style={{ marginBottom: 10 }}>
                            <Button className={ classes.btnAdd }>
                                <span className={ classes.uploadAttachment } onClick={this.uploadIconClicked}>{ t('Upload') } MSA { t('document') }</span>
                                <AddCircle className={ classes.addIcon } onClick={this.uploadIconClicked}/>
                            </Button>
                        </GridItem>
                        <GridItem xs={8} style={{ marginBottom: 10 }} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div style={{ width: '50%', display: 'inline-block' }}>
                                <SearchInput value={ query } onChange={ event => this.queryChanged(event.target.value) } onClose={ () => this.queryChanged('') } />
                            </div>
                            <span className={ classes.labelGroupBy }>
                                { t('Group By') }
                            </span>
                            <NativeSelect
                                id="demo-simple-select"
                                value={ groupBy }
                                onChange={ this.groupByChanged }
                                style={{ width: 110 }}
                                color='blue'
                            >
                                <MenuItem value="name">{ t('Name') }</MenuItem>
                                <MenuItem value="status">{ t('Status') }</MenuItem>
                                <MenuItem value="date">{ t('Date') }</MenuItem>
                            </NativeSelect>
                        </GridItem>
                    </GridContainer>
                    <div className={ classes.contentContainer } style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {
                            this.renderEmptyState()
                        }
                        {
                            this.renderMsaTable()
                        }
                    </div>
                </div>
            </div>
        )
    }

    renderBannerTemplate = (msa) => {
        const { classes, t } =  this.props;
        const { groupBy } = this.state;
        const types = {
            'VENDOR': 'Vendor',
            'BUYER': 'Buyer',
            'ADMIN': 'Admin'
        };

        if (groupBy === 'name') {
            return (
                <Grid container className={ classes.itemLabel } spacing={16} key={ msa.id * 1000 }>
                    <Grid item sm={12} md={12}>
                        <span className={ classes.type }>{ t(types[msa.signer.user_type]) }:</span>{" "}
                        <span className={ classes.value }>{ msa.signer.first_name + ' ' + msa.signer.last_name }</span>
                    </Grid>
                </Grid>
            )
        }

        if (groupBy === 'date') {
            let date = msa.completed_at ? msa.completed_at : msa.created_at;
            return (
                <Grid container className={ classes.itemLabel } spacing={16}>
                    <Grid item sm={12} md={12}>
                        <span className={ classes.type }>Date:</span>{" "}
                        <span className={ classes.value }>{ moment(date).format('DD/MM/YYYY') }</span>
                    </Grid>
                </Grid>
            )
        }

        if (groupBy === 'status') {
            return (
                <Grid container className={ classes.itemLabel } spacing={16}>
                    <Grid item sm={12} md={12}>
                        <span className={ classes.type }>Status:</span>{" "}
                        <span className={ classes.value }>{ this.renderMsaStatus(msa) }</span>
                    </Grid>
                </Grid>
            )
        }
    }

    renderMsaStatus = (msa) => {
        switch (msa.envelope_status) {
            case 'created':
                return 'Not sent to sign';
            case 'sent':
                return 'Not signed';
            case 'completed':
                return 'Signed';
            default:
                return 'Not signed';
        }
    }

    renderGroupBanner = (currentOne, nextOne) => {
        const { classes } =  this.props;
        const { groupBy } = this.state;
        if (!isNaN(nextOne) || typeof nextOne === 'undefined') {
            return null;
        }
        if (groupBy === 'name') {
            if (currentOne.signer.id !== nextOne.signer.id) {
                return this.renderBannerTemplate(nextOne)
            }
        }

        if (groupBy === 'date') {
            let currentDate = currentOne.completed_at ? currentOne.completed_at : currentOne.created_at;
            let nextDate = nextOne.completed_at ? nextOne.completed_at : nextOne.created_at;

            if (currentDate !== nextDate) {
                return this.renderBannerTemplate(nextOne)
            }
        }

        if (groupBy === 'status') {
            if (currentOne.envelope_status !== nextOne.envelope_status) {
                return this.renderBannerTemplate(nextOne)
            }
        }

        return null;
    }

    renderMsaTable = () => {
        const { classes, t } = this.props;
        const { documents } = this.state;
        if (!documents.length) {
            return null;
        }

        return [
            <GridContainer key="111111">
                <GridItem className={ classes.itemHeader } xs={12}>
                    <GridContainer spacing={16}>
                        <GridItem xs={4} style={{ paddingLeft: 16 }}>
                            {t('Vendor')}
                        </GridItem>
                        <GridItem xs={3}>
                            {t('Status')}
                        </GridItem>
                        <GridItem xs={3}>
                            {t('Date')}
                        </GridItem>
                        <GridItem xs={2}>

                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>,
            <GridContainer key="222222">
                <GridItem xs={12} className={ classes.itemList }>
                    {
                        this.renderMsaDocuments()
                    }
                </GridItem>
            </GridContainer>
        ]
    }

    renderEmptyState =  () => {
        const { classes, t } = this.props;
        const { documents } = this.state;
        if (!documents.length) {
            return (
                <div className={`${classes.coiContainer} ${classes.NoList}`}>
                    <ErrorOutlineIcon
                        style={{ fontSize: "2.8rem", marginRight: "1rem" }}
                    />
                    { t("There isn't any MSA Document for now!") }
                </div>
            )
        }

        return null;
    }

    renderMsaDocuments = () => {
        const { documents } = this.state;
        const { classes } = this.props;
        const filteredDocs = this.filterDocuments();

        let documentViews = [];
        filteredDocs.forEach((doc, key) =>
        {
            let results = [];

            // Render banner for first item
            if (key === 0) {
                results.push(this.renderBannerTemplate(doc));
            }

            results.push(<MsaItem msa={ doc } key={key} onChange={this.fetchMsaDocuments} />);

            let groupBanner = this.renderGroupBanner(doc, filteredDocs[key + 1]);
            if (groupBanner) {
                results.push(groupBanner);
            }

            documentViews = [...documentViews, ...results];
        })

        return documentViews;
    }

    groupByChanged = (event) => {
        this.setState({groupBy: event.target.value});
        let { documents } = this.state;
        switch (event.target.value) {
            case 'name':
                documents = lodash.sortBy(documents, [(doc) => doc.signer.id ]);
                this.setState({documents});
                break;
            case 'date':
                documents = lodash.sortBy(documents, [
                    (doc) => {
                        if (doc.completed_at) {
                            return doc.completed_at;
                        }

                        return doc.created_at;
                    }
                ]);

                this.setState({documents});
                break;
            case 'status':
                documents = lodash.sortBy(documents, [
                    (doc) => {
                        return doc.envelope_status;
                    }
                ]);

                this.setState({documents});
                break;
        }
    }

    queryChanged = (value) => {

        this.setState({ query: value });
    }

    uploadIconClicked = () => {
        window.location.href = '/msa/upload';
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(msaPageStyle)(MsaPage)));