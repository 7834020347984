import commonStyle from 'assets/jss/commonStyle'

const approveBudgetsStyle = (theme) => ({
    ...commonStyle,
    approvalContainer: {
        background: 'white',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 7,
        padding: '22px 19px',
        marginTop: 40,
        minHeight: 400,
    },
    tableHeader: {
        fontSize: '1rem',
        paddingTop: 20,
        padding: '4px 18px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        fontWeight: 600,
        borderBottom: '1px solid #CDCDCD',

        '& > div': {
            justifyContent: 'center',
        },
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: 18,
        paddingRight: 18,
        fontSize: 12,

        '& > div': {
            justifyContent: 'center',
        },
    },
    columnName: {
        flex: 2,
        minWidth: 0,
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
    },
    columnCategory: {
        flex: 2,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnCompany: {
        flex: 2,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnDate: {
        flex: 3,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnView: {
        flex: 2,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            cursor: 'pointer'
        }
    },
    columnActions: {
        flex: 3,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            marginLeft: 10,
            marginRight: 10,
        }
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        color: '#A30C0C',
    },
    tableTitle: {
        color: '#757575',
        fontSize: '1.4rem',
        marginBottom: '40px',
    },
    blockLabel: {
        color: '#A30C0C'
    },
    buyerContainer: {
        marginTop: 10,
        display: 'flex',
        width: '100%',
        padding: '20px 18px',
        justifyContent: 'space-between',
        cursor: 'pointer',
        background: '#1291F9',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        color: 'white',
    },
    filterContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 5,
            marginRight: 5,
        }
    },
    btnAll: {
        background: 'rgba(194, 194, 194, 0.06)',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 8,
        width: '100%',

        '&.active': {
            background: '#1291F9',
            color: 'white',
        }
    }
});

export default approveBudgetsStyle;