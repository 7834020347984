import commonStyle from 'assets/jss/commonStyle'

const msaUploadPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        background: "url('/assets/img/bgMain.png')",
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
        width: '100%',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        background: 'white',
        width: '100%',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070',
        position: 'relative',
    },
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: 20,
    },
    addIcon: {
        color: '#1D8ACF',
        marginLeft: '10px',
    },
    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    fileContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px'
    },
    attachmentImg: {
        width: '30px',
        marginRight: '5px',
        marginLeft: '50px',
    },
    btnComplete: {
        padding: '5px 14px',
        background: '#1D8ACF',
        color: 'white',
    }
});
export default msaUploadPageStyle;