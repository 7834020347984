import React, { Component } from 'react';
import GlobalProcessor from 'components/GlobalProcessor/index';
import signViewStyle from './signViewStyle';
import { withStyles } from '@material-ui/core';
import { Document, Page, pdfjs  } from 'react-pdf';
import { connect } from 'react-redux';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import Signature from 'components/Signature';
import Button from 'components/Button';
import Cookies from 'js-cookie';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BrushIcon from '@material-ui/icons/Brush';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SignaturePad from 'react-signature-pad-wrapper';
import Initial from 'components/Initial';
import DateSigned from 'components/DateSigned';
import BaseInput from 'components/BaseInput/index.jsx';
import Loading from 'components/Loading';
import { withTranslation  } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const COLORS = [
    'rgba(251, 220, 128, 0.5)',
    'rgba(189, 225, 232, 0.8)'
];
const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class SignViewPage extends Component
{
    state = {
        numPages: 0,
        editingRecipient: 0,
        signatures: [],
        goToList: false,
        doc: null,
        openModal: false,
        signed: false,
        dataUrl: null,
        successModal: false,
        initialSigned: false,
        canvasWidth: 600,
        singerName: '',
        signType: '',
        uploading: false,
        expired: false,
    }

    originalDoc = null;
    signatureRefs = []
    refIndex = 0;

    async componentDidMount() {
        await this.fetchMsaDocument();
        window.addEventListener("resize", this.windowResized);
    }

    regulatePositions( doc ) {
        let newDoc = JSON.parse(JSON.stringify(doc));

        let { recipient } = newDoc['meta'];
        recipient['signatures'] = recipient['signatures'].map(signature => {
            let newItem = Object.assign({...signature});
            const PDF_STATIC_WIDTH = 1000;
            const ratio = this.pdf.getBoundingClientRect().width / PDF_STATIC_WIDTH;
            newItem.x *= ratio;
            newItem.y *= ratio;
            return newItem;
        })

        recipient['initials'] = recipient['initials'].map(signature => {
            let newItem = Object.assign({...signature});
            const PDF_STATIC_WIDTH = 1000;
            const ratio = this.pdf.getBoundingClientRect().width / PDF_STATIC_WIDTH;
            newItem.x *= ratio;
            newItem.y *= ratio;
            return newItem;
        })

        recipient['signed_dates'] = recipient['signed_dates'].map(signature => {
            let newItem = Object.assign({...signature});
            const PDF_STATIC_WIDTH = 1000;
            const ratio = this.pdf.getBoundingClientRect().width / PDF_STATIC_WIDTH;
            newItem.x *= ratio;
            newItem.y *= ratio;
            return newItem;
        })

        newDoc['meta']['recipient'] = recipient;

        return newDoc;
    }

    windowResized = () => {
        let { recipient } = this.state.doc.meta;
        const doc = this.regulatePositions(this.originalDoc);

        this.setState({ canvasWidth: this.pdf.getBoundingClientRect().width - 12, doc});
    }

    goClicked = () => {
        this.signatureRefs[this.refIndex ++].scrollIntoView({behavior: 'smooth', block: 'center'});
        this.refIndex = this.refIndex % this.signatureRefs.length;
    }

    signerNameChanged = (event) => {
        this.setState({ singerName: event.target.value });
        this.drawNameOnSignPad(event.target.value);
    }

    render() {
        const { classes, uploadResult, t } = this.props;
        const { numPages, signatures, goToList, doc, openModal, signed, dataUrl, successModal, initialSigned, canvasWidth, singerName, signType, uploading } = this.state;
        const { msa } = uploadResult;

        if (this.state.expired) {
            return (
                <DndProvider backend={Backend}>
                <div className={classes.setupPageContainer}>
                    <GlobalProcessor />
                    <div className={ classes.topWrapper }>
                        <Button className={ classes.btnGo } onClick={ this.goClicked }>
                            { t('GO TO SIGNATURE') }
                        </Button>
                        <Button className={ classes.btnSend } onClick={ this.finishClicked } disabled={ !signed || !initialSigned }>
                            { t('FINISH') }
                        </Button>
                    </div>
                    <div className={ classes.contentContainer } style={{ justifyContent: 'center', paddingTop: 40 }}>
                        { t('The document is already signed.') }
                    </div>
                </div>
                </DndProvider>
            )
        }

        return (
            <DndProvider backend={Backend}>
            <div className={classes.setupPageContainer}>
                <GlobalProcessor />
                <div className={ classes.topWrapper }>
                    <Button className={ classes.btnGo } onClick={ this.goClicked }>
                        { t('GO TO SIGNATURE') }
                    </Button>
                    <Button className={ classes.btnSend } onClick={ this.finishClicked } disabled={ !signed || !initialSigned }>
                        { t('FINISH') }
                    </Button>
                </div>
                <div className={ classes.contentContainer }>
                    <div className={ classes.pdfContainer } ref={ ref => this.pdfContainer = ref } >
                        <div ref= { ref => this.pdf = ref } style={{ position: 'relative', opacity: uploading ? 0.5 : 1, width: '100%', height: '100%' }}>
                            {
                                doc &&
                                    <Document
                                        file={ `data:application/pdf;base64,${doc.original_document}`}
                                        onLoadSuccess={this.onDocumentLoadSuccess}

                                    >
                                        {
                                            Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                width={canvasWidth}
                                                />
                                            ),
                                            )
                                        }
                                    </Document>
                            }
                            {
                                doc &&
                                    doc.meta.recipient.signatures.map((signature, key) => <Signature signature = {signature} key={ `signature-${key}` } onClick={ this.signatureClicked } signed={signed} imageData={ dataUrl } refCallback={ ref => this.signatureRefs[key] = ref } />)
                            }
                            {
                                doc &&
                                    doc.meta.recipient.initials.map((initial, key) => <Initial data = {initial} key={ `initial-${key}` } onClick={ this.initialClicked } signed={initialSigned} imageData={ dataUrl } refCallback={ ref => this.signatureRefs[ key + doc.meta.recipient.signatures.length ] = ref } name={ doc.meta.recipient.name } />)
                            }
                            {
                                doc &&
                                    doc.meta.recipient.signed_dates.map((date, key) => <DateSigned data = {date} key={ `date_signed-${key}` } onClick={ this.signatureClicked } signed={true} />)
                            }
                        </div>
                    </div>
                    <Loading uploading={ uploading }/>
                </div>
                <Modal
                    open={ openModal }
                    onClose={this.handleCloseModal}
                >
                    <div className={ classes.modalWrapper }>
                    <div className={ classes.modalContainer }>
                        <div className={ classes.modalTitle }>
                            <div>
                                Adopt Your Signature
                            </div>
                            <div>
                                {
                                    signType !== '' &&
                                        <BaseInput small style={{ width: 200 }} value={ singerName } onChange={ event => this.signerNameChanged(event) } />
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                                <div className={`${classes.signature_pad} sign_pad_wrapper`} style={{ paddingLeft: 70, paddingTop: 10, }} >
                                    <SignaturePad ref={ref => this.signaturePad = ref} width={430} height={225} options={{ dotSize: 5, penColor: 'rgb(0, 0, 0)' }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexGrow: 1 }}>
                                <List component="nav">
                                    <ListItem button onClick={e => this.setSignType('')} selected={this.state.signType == ''}>
                                        <ListItemIcon><BrushIcon /></ListItemIcon>
                                        <ListItemText primary={t("Draw")} />
                                    </ListItem>
                                    <ListItem button onClick={e => this.setSignType('amandasignature')} selected={this.state.signType == 'amandasignature'}>
                                        <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                        <ListItemText primary="Amanda Font" />
                                    </ListItem>
                                    <ListItem button onClick={e => this.setSignType('geovana')} selected={this.state.signType == 'geovana'}>
                                        <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                        <ListItemText primary="Geovana Font" />
                                    </ListItem>
                                    <ListItem button onClick={e => this.setSignType('julietta')} selected={this.state.signType == 'julietta'}>
                                        <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                        <ListItemText primary="Julietta Font" />
                                    </ListItem>
                                    <ListItem button onClick={e => this.setSignType('vtks-hunt')} selected={this.state.signType == 'vtks-hunt'}>
                                        <ListItemIcon><FontDownloadIcon /></ListItemIcon>
                                        <ListItemText primary="Vtks Hunt Font" />
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div style={{ padding: '15px 70px', borderTop: '1px solid #E9E9E9' }}>
                            <Button className={ classes.btnAdopt } onClick={e => this.signed()}>
                                Adopt and Sign
                            </Button>
                            <Button className={ classes.btnModal } onClick={e => this.cleanSignPad(e)}>
                                Clear Board
                            </Button>
                            <Button className={ classes.btnModal } onClick={e => this.cancelModal(e)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                    </div>
                </Modal>
                <Modal
                    open={ successModal }
                >
                    <div className={ classes.modalWrapper }>
                        <div className={ classes.successModalContainer }>
                            <div style={{ textAlign: 'center' }}>
                                <CheckCircleOutlineIcon style={{ color: '#4caf50', marginRight: '10px', fontSize: 30, marginBottom: 5 }} />
                                <div style={{ color: 'rgb(30, 70, 32)', fontSize: 18 }}>
                                    Successfully signed the document.
                                </div>
                            </div>
                            <Button className={ classes.modalBtnConfirm } onClick={ this.successModalClosed }>OK</Button>
                        </div>
                    </div>
                </Modal>
            </div>
            </DndProvider>
        )
    }

    signed = () => {
        this.setState({ openModal: false, signed: true, dataUrl: this.signaturePad._canvas.toDataURL() });
    }

    onDocumentLoadSuccess = (document) => {
        const { numPages } = document;
        this.setState({numPages, canvasWidth: this.pdf.getBoundingClientRect().width - 12});
    }

    recipientChanged = (event) => {
        this.setState({editingRecipient: event.target.value});
    }

    cancelModal = () => {
        this.setState({ openModal: false });
    }

    prepareSignatureData = () => {
        const PDF_STATIC_WIDTH = 1000;
        const ratio = this.pdf.getBoundingClientRect().width / PDF_STATIC_WIDTH;
        let signatureData = [];
        const { uploadResult } = this.props;
        const { signatures } = this.state;
        const { msa } = uploadResult;

        signatures.forEach(signature => {
            if (!signatureData[signature.signer_order]) {
                signatureData[signature.signer_order] = [];
            }

            signatureData[signature.signer_order].push(signature);
        });

        return signatureData;
    }

    fetchMsaDocument = () => {
        const token = this.props.match.params["token"];

        return fetch(apiUrl + 'api/msa/get/by_token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({ token })
        })
        .then(res => {
            if (!res.ok) {
                this.setState({ expired: true });
            }
            return res.json();
        })
        .then(result => {
            if (result['status'] === 'success') {
                let { recipient } = result['doc_json']['meta'];
                const doc = this.regulatePositions(result['doc_json']);
                this.originalDoc = result['doc_json'];
                this.setState({ doc, singerName: doc.meta.recipient.name, initialSigned: recipient['initials'].length === 0 });
                return;
            }

            this.setState({ expired: true });
        });
    }

    signatureClicked = () => {
        this.setState({ openModal: true });
    }

    cleanSignPad = (e) => {
        this.setState({signed: false});
        this.signaturePad.clear();
        this.signaturePad.isEmpty();
    }

    setSignType = (signType) => {
        this.setState({ signType: signType });
        if (signType != '') {
            const { doc } = this.state;
            const name = this.state.singerName;
            this.drawNameOnSignPad(name, 'sign-' + signType);
        } else {
            this.signaturePad.clear();
            this.signaturePad.isEmpty();
        }
    }

    drawNameOnSignPad = (name, font = 'sign-amandasignature', fontSize = 56) => {
        if (name.length < 1) return;
        this.signaturePad.clear();
        this.signaturePad.isEmpty();

        var canvas = this.signaturePad._canvas;
        var ctx = canvas.getContext('2d');
        var cWidth = canvas.width;
        var cHeight = canvas.height;
        var nLength = name.length;
        ctx.font = fontSize + 'px "' + font + '"';
        ctx.fillText(name, 30, canvas.offsetHeight * 0.5 + (cHeight / canvas.offsetHeight) * fontSize * 0.5);
        this.setState({signed: true});
    }

    finishClicked = () => {
        const {doc} = this.state;
        const token = this.props.match.params["token"];
        this.setState({uploading: true});
        return fetch(apiUrl + 'api/msa/signed', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({ token, image_data: this.state.dataUrl, id: doc.id })
        })
        .then(res => res.json())
        .then(result => {
            if (result.status === 'success') {
                this.setState({ uploading: false, successModal: true })
            }
        });
    }

    successModalClosed = () => {
        this.setState({ successModal: false });
        window.location.href = '/msa/list';
    }

    initialClicked = () => {
        this.setState({initialSigned: true});
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(signViewStyle)(SignViewPage)));