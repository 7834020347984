import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import BaseInput from 'components/BaseInput';
import { withStyles } from '@material-ui/core';
import searchInputType2Style from './searchInputType2Style.jsx';

class SearchInputType2 extends Component
{
    render() {
        const { classes, placeholder, onClose, value, onChange } = this.props;
        return (
            <div className={ classes.container }>
                <BaseInput small className={ classes.input } onChange={ onChange } value={ value } placeholder={ placeholder } />
                {
                    value && value.length &&
                        <CloseIcon className={ classes.closeIcon } fontSize="small" onClick={ onClose } />
                }
                <SearchIcon className={ classes.searchIcon } fontSize="small" />
            </div>
        )
    }
}

export default withStyles(searchInputType2Style)(SearchInputType2);