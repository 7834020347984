const CaegoriesPageStyle = theme => ({
    cardWrapper: {
        padding: '0.5rem 0 1rem 0'
    },
    tableContainer: {
        position: 'relative',
        background: '#F2F2FF'
    },
    tableHeader: {
        clear: 'both',
        background: 'rgba(18, 145, 249, 0.18)',
        padding: '10px 20px',
        display: 'flex',
        height: '3.2rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        border: '1px solid #E8E8E8',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    tableHeaderLabel: {
        float: 'left',
        width: 'calc(100% - 6em)',
        fontWeight: 600
    },
    tableHeaderAction: {
        display: 'flex',
        alignItems: 'center',
        color: '#2D7DD2',
        '& svg': {
            width: 18,
            height: 18,
            marginLeft: 5,
        },
        '& button': {
            marginLeft: 5,
            marginRight: 5,
        }
    },
    subCategItem: {
        position: 'relative',
        paddingRight: '2em',
        width: 'auto',
        minWidth: 120,
        height: 36,
        lineHeight: '1.8rem',
        borderRadius: 20,
        float: 'left',
        padding: '0 1rem',
        margin: '0.5rem',
        border: '1px solid #1291F9',
        color: '#1291F9',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    subCategItemContent: {

    },
    subCategItemContentInput: {
        height: '1.4rem',
    },
    subCategItemClose: {
        cursor: 'pointer',
        width: '0.6em',
        height: '0.6em',
        border: '1px solid #1291F9',
        borderRadius: '0.5em',
        marginLeft: 20,
    },
    columnName: {
        flex: 2,
        right: '0.5em',
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px solid #D4D4D4',
    },
    columnDescription: {
        flex: 6,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid #D4D4D4',
    },
    columnDate: {
        flex: 2,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid #D4D4D4',
    },
    columnActions: {
        flex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '25px 0px',
        justifyContent: 'left',
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid #D4D4D4',
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#0072BB',
    },
    addEventWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& svg': {
            width: '2.5rem',
            height: '2.5rem',
            color: '#0072BB'
        }
    },
    addCategDialog: {
    },
    inputName: {
        width: '100%'
    },
    inputDescription: {
        width: '100%',
        minHeight: '8rem'
    },
    subCategWrapper: {
        padding: '1rem 0.5rem',
        background: '#fff',
        minHeight: 95,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    btnSave: {
        background: '#0072BB',
        color: 'white',
        width: 120,
        height: 30,
    },
    btnCancel: {
        background: '#FFC801',
        color: 'white',
        width: 120,
        height: 30,
    },
    btnAdd: {
        fontSize: 14,
        color: '#1291F9',
        background: 'rgba(0, 153, 255, 0.2)',
        width: 155,
        height: 34, 
        marginBottom: 10,
        '& svg': {
            width: '1.5rem',
            height: '1.5rem',
            marginLeft: 5,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #1291F9'
    }
})

export default CaegoriesPageStyle;