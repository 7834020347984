import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import dashboardHomeStyle from './dashboardHomeStyle';
import { PieChart, Pie, Cell, ResponsiveContainer, AreaChart, Area } from 'recharts';
import CloseIcon from '@material-ui/icons/Close';
import { Link, NavLink } from 'react-router-dom';
import Cookies from "js-cookie";
import { withTranslation  } from 'react-i18next';
import moment from 'moment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class DashboardHome extends Component
{
    state = {
        selectedDuration: 'week',
        invoiceData: null,
        contacts: [],
        loading: true,
        chartData: [],
        activities: [],
        accountStatus: null
    }

    componentDidMount = async () => {
        const loginStatus = this.props.account.loginStatus;
        this.loadDashboardData();

        await fetch(apiUrl + "api/message/contacts", {
            method: "GET",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(result => {
            if (result.status) {
                this.setState({contacts: result.contacts});
            }
            return result;
        });

        this.setState({loading: false});
    }

    loadDashboardData = async () => {
        const loginStatus = this.props.account.loginStatus;
        const { selectedDuration } = this.state;
        await fetch(apiUrl + "api/common/dashboard_data", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "X-CSRFToken": Cookies.get("csrftoken"),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chartPeriod: selectedDuration
            }),
        })
        .then(res => res.json())
        .then(result => {
            this.setState({invoiceData: result});
            this.setState({chartData: result['chartData']});
            this.setState({ activities: result['activities'] });
            this.setState({ accountStatus: result['accountStatus'] });
            return result;
        });
    }

    changeChartPeriod = async (period) => {
        await this.setState({ selectedDuration: period });
        this.loadDashboardData();
    }


    userSelected = (userId) => {
        const { chat } = this.props;
        chat.socket.emit("chat.start", {userId});
    }

    renderActivityContent = (activity) => {
        const { user } = this.props.account.loginStatus;
        const userName = user.profile.user_type === 'ADMIN' ? `${activity.actor.first_name} ${activity.actor.last_name}` : 'You';

        switch(activity.log_type) {
            case 'W9_FORM_SUBMITTED':
                return `${userName} submitted W9 Form.`;
            case 'BUY_REQUEST_SUBMITTED':
                return `${userName} submitted buy request.`;
            case 'MSA_UPLOADED':
                return `${userName} uploaded MSA Document.`;
            case 'LEIN_UPLOADED':
                    return `${userName} uploaded LIEN Document.`;
            default:
                return `${userName} requested invoice.`;
        }
    }

    renderActivityAvatar = (activity) => {
        if (activity.actor.avatar === null) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#555', color: '#fff', fontSize: 15, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', width: 40, height: 40, borderRadius: 40 }}>
                    { activity.actor.first_name[0].toUpperCase() }{ activity.actor.last_name[0].toUpperCase() }
                </div>
            );
        }
        return <img src={ activity.actor.avatar } style={{ width: 40, height: 40, borderRadius: 40, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} />
    }

    renderAccountStatusIcon = (status = 2) => {
        switch (status) {
            case 2:
                return (
                    <CheckBoxIcon style={{ color: 'rgb(99, 165, 39)', marginRight: 10, }} />
                );
            case 1:
                return (
                    <AutorenewIcon style={{ color: '#FF8000', marginRight: 10, }} />
                );
            default:
                return (
                    <ErrorIcon style={{ color: '#ED1C24', marginRight: 10, }} />
                );
        }
    }

    renderAccountStatusLabel = (status = 2, redirectLink = '') => {
        switch (status) {
            case 2:
                return (
                    <div style={{
                        background: 'rgb(99, 165, 39)',
                        color: 'white',
                        width: 150,
                        height: 30,
                        lineHeight: '30px',
                        textAlign: 'center',
                        borderRadius: 6,
                    }}>
                        Complete
                    </div>
                );
            case 1:
                return (
                    <div style={{
                        background: '#FF8000',
                        color: 'white',
                        width: 150,
                        height: 30,
                        lineHeight: '30px',
                        textAlign: 'center',
                        borderRadius: 6,
                    }}>
                        To Be Completed
                    </div>
                );
            default:
                return (
                    <NavLink
                        style={{
                            background: '#ED1C24',
                            color: 'white',
                            width: 150,
                            height: 30,
                            lineHeight: '30px',
                            textAlign: 'center',
                            borderRadius: 6,
                        }}
                        to={ redirectLink }
                    >
                        Not Started
                    </NavLink>
                );
        }
    }

    chartColor = (completed) => {
        switch (completed) {
            case 4:
                return '#63A527';
            case 3:
                return 'rgb(99, 165, 39)';
            case 2:
                return '#FF8000';
            default:
                return '#ED1C24';
        }
    }

    renderAccountStatus = () => {
        const { classes } = this.props;
        const { loginStatus } = this.props.account;
        const { user } = loginStatus;
        const { accountStatus } = this.state;

        let completed = 0;
        for (const key in accountStatus)
            if (accountStatus[key] === 2) {
                completed ++;
            }
        if (user.profile.user_type === 'VENDOR'
            && (user.customer.name === 'Onesta' || user.customer.name === 'Demo Customer')
            && accountStatus !== null
        ) {
            return (
                <div className={ classes.tileContainer }>
                    <div className={ classes.leftTile } >
                        <div style={{ fontSize: 20, fontWeight: 600, paddingLeft: 30, marginTop: 30, marginBottom: 20 }}>
                            Your account is { Math.floor( (completed/4) * 100 ) }% completed.
                        </div>

                        <div className={ classes.statusContainer }>
                            <div>
                                {
                                    this.renderAccountStatusIcon(accountStatus.w9)
                                }
                                W9
                            </div>
                            {
                                this.renderAccountStatusLabel(accountStatus.w9, '/vendor/setup')
                            }
                        </div>
                        <div className={ classes.statusContainer }>
                            <div>
                                {
                                    this.renderAccountStatusIcon(accountStatus.msa)
                                }
                                MSA
                            </div>
                            {
                                this.renderAccountStatusLabel(accountStatus.msa, '/msa/upload')
                            }
                        </div>
                        <div className={ classes.statusContainer }>
                            <div>
                                {
                                    this.renderAccountStatusIcon(accountStatus.insurance)
                                }
                                Insurance
                            </div>
                            {
                                this.renderAccountStatusLabel(accountStatus.insurance, '/insurances/upload')
                            }
                        </div>
                        <div className={ classes.statusContainer }>
                            <div>
                                {
                                    this.renderAccountStatusIcon(accountStatus.lien)
                                }
                                Lien
                            </div>
                            {
                                this.renderAccountStatusLabel(accountStatus.lien, '/certificates/upload')
                            }
                        </div>
                    </div>
                    <div className={ classes.rightTile } >
                        <div className={ classes.chartWrapper }>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        fill={ this.chartColor(completed) }
                                        startAngle={90}
                                        endAngle={-450}
                                        innerRadius={'55%'}
                                        outerRadius={'80%'}
                                        data={
                                            [
                                                {
                                                    value: (completed/4) * 100
                                                },
                                                {
                                                    value: (1 - completed/4) * 100
                                                }
                                            ]
                                        }
                                        dataKey="value"
                                    >
                                        {/* <Cell fill={ this.chartColor(completed) } /> */}
                                        <Cell fill='#63A527' />
                                        <Cell fill='#ED1C24' />
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className={ classes.pieLabel }>
                                    <div style={{ fontSize: 24, color: this.chartColor(completed), fontWeight: 600 }}>
                                        { Math.floor( (completed/4) * 100 ) }%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    render() {
        const { invoiceData, loading, activities, accountStatus } = this.state;
        let contacts = Object.values(this.props.chat.contacts);
        const { t } = this.props;

        if (loading) {
            return null;
        }

        const user = this.props["account"]["loginStatus"]["user"];
        var userType =
          typeof user != "undefined" && user != null ? user.profile.user_type : "";
        if (userType == "") {
          return null;
        }

        const invoiceStatus = [
            {
                name: t('Completed'),
                value: invoiceData ? invoiceData.payed : 0
            },
            {
                name: t('Requested'),
                value: invoiceData ? invoiceData.total : 0
            },
            {
                name: t('Processing'),
                value: invoiceData ? invoiceData.submitted : 0
            }
        ]
        const invoiceStatusColor = ['#C4C4C4', '#CCEBFF', '#1291F9']

        const { classes } = this.props;
        const { selectedDuration, chartData } = this.state;

        const histories = ['aaaa', 'bbbb', 'cccc', 'dddd', 'eeee'];
        const { invoiceResult } = this.props;

        return (
            <div className={ classes.container }>
                <div className={ classes.headerContainer }>
                    <div className={ classes.titleContainer }>
                        <div className={ classes.pageTitle }>
                            { t('Dashboard') }
                        </div>
                        <div className={ classes.pageSubTitle }>
                            { t('Dashboard and statistics') }
                        </div>
                    </div>
                </div>
                <div className={ classes.pageContentContainer }>
                    <div className={ classes.statBtnContainer }>
                        <div className={ classes.statCard }>
                            <div className={ classes.statCatgContainer }>
                                <div>
                                    {t('Invoice Received')}
                                </div>
                                <div>
                                    {t('Amount Invoiced')}
                                </div>
                                <div>
                                    {t('Requested')}
                                </div>
                                <div>
                                    {t('Processing Request')}
                                </div>
                            </div>
                            <div className={ classes.numericContainer }>
                                <div>
                                    { invoiceData ? invoiceData.total : 0 }
                                </div>
                                <div>
                                    ${ invoiceData ? invoiceData.total_invoiced.toFixed(2) : 0 }
                                </div>
                                <div>
                                    { invoiceData ? invoiceData.submitted : 0 }
                                </div>
                                <div>
                                    { invoiceData ? invoiceData.submitted : 0 }
                                </div>
                            </div>
                        </div>
                        {(userType == "VENDOR" || userType == "BUYER") &&
                            <div class="flex content-center flex-col px-3">
                                <img src="/assets/img/newui/header/raise-invoice.svg" />
                                <Link className={ classes.btnRaiseInvoice } to="/buyer/buy-request">
                                    {t('Raise an Invoice')}
                                </Link>
                            </div>
                        }
                    </div>
                    <div className={ classes.tileContainer } style={{ display: 'none' }}>
                        <div className={ classes.leftTile }>
                            <div className={ classes.statusTitle }>
                                <div>
                                    <strong style={{ fontSize: 20 }}>{ t('Status') }</strong>
                                </div>
                                {/* <Select
                                    className={ classes.select }
                                    value={1}
                                >
                                    <MenuItem value={1}>{ t('Last 24 Hours') } </MenuItem>
                                    <MenuItem value={2}>{ t('Last Week') }</MenuItem>
                                    <MenuItem value={3}>{ t('Last Month') }</MenuItem>
                                </Select> */}
                            </div>
                            <div className={ classes.chartContainer }>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, }}>
                                    <div style={{ minWidth: 50, }}  class="flex items-center justify-center">
                                        <img src="/assets/img/newui/header/nav-invoices-active.svg" style={{ width: 30 }} />
                                    </div>
                                    <div className={ classes.bulletLabel }>Invoices</div>
                                </div>
                                {
                                    invoiceStatusColor.map((color, index) => {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16, }} key={`bulet-${index}`}>
                                                <div style={{ minWidth: 50 }} class="justify-center flex">
                                                    <i className={ classes.bullet } style={{ background: color }}></i>
                                                </div>
                                                <div className={ classes.bulletLabel }>{ invoiceStatus[index].name }</div>
                                            </div>
                                        );
                                    })
                                }
                                {/* <div className={ classes.chartWrapper }>
                                    <ResponsiveContainer>
                                        <PieChart>
                                            <Pie
                                                data={invoiceStatus}
                                                startAngle={90}
                                                endAngle={-450}
                                                innerRadius={'55%'}
                                                outerRadius={'80%'}
                                                fill="#8884d8"
                                                paddingAngle={0}
                                                dataKey="value"
                                            >
                                            {
                                                invoiceStatus.map((entry, index) => <Cell key={`cell-${index}`} fill={invoiceStatusColor[index % invoiceStatusColor.length]} />)
                                            }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={ classes.pieLabel }>
                                            <div style={{ fontSize: 20, color: '#565656' }}>
                                                { invoiceData ? invoiceData.total : 0 }
                                            </div>
                                            <div style={{ color: '#555555', fontSize: 15, opacity: 0.8 }}>
                                                { t('INVOICES') }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={ classes.bulletContainer }>
                                    {
                                        invoiceStatusColor.map((color, index) => {
                                            return (
                                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, }} key={`bulet-${index}`}>
                                                    <i className={ classes.bullet } style={{ background: color, marginRight: 10, }}></i>
                                                    <div className={ classes.bulletLabel }>{ invoiceStatus[index].name }</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div> */}
                            </div>
                        </div>

                        <div className={ classes.rightTile }>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30, }}>
                                <div className={ classes.rightTileTitle }>
                                    { t('Amount Invoiced') }
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className={ classes.btnDuration + ' ' + (selectedDuration === 'week' ? 'selected' : '') }
                                        onClick={ () => this.changeChartPeriod('week') }>
                                        { t('Week') }
                                    </div>
                                    <div className={ classes.btnDuration + ' ' + (selectedDuration === 'month' ? 'selected' : '') }
                                        onClick={ () => this.changeChartPeriod('month') }>
                                        { t('Month') }
                                    </div>
                                    <div className={ classes.btnDuration + ' ' + (selectedDuration === 'year' ? 'selected' : '') }
                                        onClick={ () => this.changeChartPeriod('year') }>
                                        { t('Year') }
                                    </div>
                                </div>
                            </div>
                            <div className={ classes.chartContainer } style={{ padding: 0, position: 'relative'}}>
                                <div style={{ position: 'absolute', top: 0, bottom: 4, left: 4, right: 3, background: 'rgba(242, 246, 248, 0.8)' }}>
                                </div>
                                <ResponsiveContainer>
                                    <AreaChart data={ chartData } file="#F5F8F9">
                                        <Area type="monotone" dataKey="requested" stroke="#CCEBFF" fillOpacity={1} fill="#CCEBFF" />
                                        <Area type="monotone" dataKey="paid" stroke="#1291F9" fillOpacity={1} fill="#1291F9" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                            {/* <div className={ classes.statTotalContainer }>
                                <div style={{ textAlign: 'center', marginRight: 40 }}>
                                    <div style={{ fontSize: 20 }}>
                                        { invoiceData ? invoiceData.total : 0 }
                                    </div>
                                    <div style={{ fontSize: 14, opacity: 0.7 }}>
                                        { t('Requested') }
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ fontSize: 20 }}>
                                        { invoiceData ? invoiceData.payed : 0 }
                                    </div>
                                    <div style={{ fontSize: 14, opacity: 0.7 }}>
                                        { t('Paid') }
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {
                        this.renderAccountStatus()
                    }
                    <div className={ classes.contactTitle }>
                        { t('Activity') }
                        {/* <div className={ classes.tileBadge }>
                            { activities.length }
                        </div> */}
                    </div>
                    <div className={ classes.tileContainer } style={{ minHeight: 380, marginTop: 24 }}>
                        {/* <div className={ classes.leftTile } style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 15 }}>
                            <div className={ classes.contactTitle }>
                                <strong>{ t('Contacts') }</strong>
                                <div className={ classes.tileBadge }>
                                    { contacts.length }
                                </div>
                            </div>
                            <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30 }}>
                                {
                                    contacts.map((contact, index) => {
                                        return (
                                            <div key={`contact-${index}`} className={ [classes.contactContainer, `cursor-pointer`].join(' ') }
                                                onClick={e => this.userSelected(contact.id)}
                                                >
                                                <div style={{ fontSize: 14, marginLeft: 20, minWidth: 120 }}>
                                                    <div>
                                                        { contact.name }
                                                    </div>
                                                </div>
                                                <Avatar user={ contact } active={ contact.status === 'active' } hideBubble={ contact.status === '' } />
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div> */}
                        <div className={ classes.rightTile } style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 15, paddingBottom: 0 }}>
                            <div style={{ padding: 25, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, overflow: 'hidden' }}>
                                <div style={{ padding: '2px 15px', background: 'rgba(241, 235, 235, 0.25)', color: '#63A527', display: 'flex', justifyContent: 'space-between', borderRadius: 10, fontSize: 16, marginBottom: 30 }}>
                                    <div>
                                        { activities.length > 0 ? activities.length : 'No' } {t('recent activities')}
                                    </div>
                                    <CloseIcon fontSize="small" style={{ paddingTop: 1 }}/>
                                </div>
                                <div className={ classNames(classes.activityScroll, 'budget-scroll-bar') }>
                                {
                                    activities.map((activity, index) => {
                                        return (
                                            <div key={`activity-${index}`} className={ classes.activityContainer }>
                                                {
                                                    this.renderActivityAvatar(activity)
                                                }

                                                <div className={ classes.activityDescription }>
                                                    <div style={{ fontWeight: 600, color: (moment(activity.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) ? '#1291F9' : '#000000' }}>
                                                        { this.renderActivityContent(activity) }
                                                    </div>
                                                    <div>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontWeight: 600, }}>{ moment(activity.created_at).fromNow() }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={ classes.historyContainer }>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #EBEBEB', paddingLeft: 30, paddingRight: 30, paddingBottom: 15, paddingTop: 15, }}>
                            <div>
                                <strong style={{ fontSize: 18 }}>{ t('History') }</strong>
                            </div>
                            <SettingsIcon style={{ color: '#939393' }}/>
                        </div>
                        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30, display: 'flex', width: '100%', borderBottom: '1px solid #EBEBEB' }}>
                            <FormControl style={{ minWidth: 150 }}>
                                <Select
                                    value={'bulk'}
                                    className={ classes.select + ' ' +  classes.outlinedSelect}
                                >
                                    <MenuItem value={'bulk'}>{t('Bulk Action')}</MenuItem>
                                    <MenuItem value={20}>{t('Bulk Action')}</MenuItem>
                                    <MenuItem value={30}>{t('Bulk Action')}</MenuItem>
                                </Select>
                            </FormControl>
                            <button className={ classes.btnApply }>
                                { t('Apply') }
                            </button>
                            <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                <input className={ classes.searchInput } placeholder={ t('Search') } />
                                <button className={ classes.btnSearchGo }>
                                    { t('Go') }
                                </button>
                            </div>
                        </div>
                        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <div className={ classes.historyTableHeader }>
                                <div className={ classes.checkBoxCell }></div>
                                <div className={ classes.nameCell }>
                                    { t('NAME') }
                                </div>
                                <div className={ classes.invoiceCell }>
                                    { t('INVOICE') } #
                                </div>
                                <div className={ classes.dateCell }>
                                    { t('DATE') }
                                </div>
                                <div className={ classes.statusCell }>
                                    { t('STATUS') }
                                </div>
                                <div className={ classes.actionCell }>
                                    { t('ACTION') }
                                </div>
                            </div>
                            <div className={ classes.historyTableContent }>
                                {
                                    histories.map((history, index) => {
                                        return (
                                            <div className={ classes.historyTableRow } key={`history-${index}`}>
                                                <div className={ classes.checkBoxCell }>
                                                    <Checkbox
                                                        checked={true}
                                                    />
                                                </div>
                                                <div className={ classes.nameCell }>
                                                <Avatar user={{ profile: {avatar: '/assets/img/avatar.png', }}} hideBubble={ true } />
                                                <span style={{ marginLeft: 16 }}>Shaunta Pollan</span>
                                                </div>
                                                <div className={ classes.invoiceCell }>
                                                    8993003938839930
                                                </div>
                                                <div className={ classes.dateCell }>
                                                    Feb 22, 2020
                                                </div>
                                                <div className={ classes.statusCell }>
                                                    <span style={{ background: '#FFC700', color: 'white', padding: '1px 5px', borderRadius: 2 }}>
                                                        { t('Processing') }
                                                    </span>
                                                </div>
                                                <div className={ classes.actionCell }>
                                                    <button>
                                                        <img src={ require('../../assets/img/icon-edit.svg') } />
                                                    </button>
                                                    <button>
                                                        <img src={ require('../../assets/img/icon-bin.svg') } />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={ classes.historyTableFooter }>
                                <div className={ classes.itemsCount }>
                                    { t('Showing') } 1-5 of 50 { t('items') }
                                </div>
                                <div className={ classes.pagination }>
                                    <button>
                                        { '<<' }
                                    </button>
                                    <button>
                                        1
                                    </button>
                                    <button>
                                        2
                                    </button>
                                    <button>
                                        3
                                    </button>
                                    <button>
                                        4
                                    </button>
                                    <button>
                                        5
                                    </button>
                                    <button>
                                        { '>>' }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        chat: state.chat
    };
}

const mapDispatchToProps = {};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardHomeStyle)(DashboardHome)));