import commonStyle from 'assets/jss/commonStyle'

const approvePageStyle = theme => ({
    ...commonStyle,
    tableContainer: {
        marginTop: 30,
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 7,
        background: 'white',
        color: 'black',
        marginBottom: 100,
        paddingBottom: 30,
    },
    tableHeader: {
        fontSize: '1rem',
        paddingTop: 44,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #CDCDCD',
        fontSize: 16,
        fontWeight: 600,

        '& > div': {
            justifyContent: 'flex-start',
            '& > a': {
                marginLeft: 20
            }
        }
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderTopWidth: 0,
        fontSize: 12,
        marginTop: 10,
        marginBottom: 10,
        '& > div': {
            justifyContent: 'flex-start'
        }
    },
    columnName: {
        flex: 2,
        minWidth: 0,
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
    },
    columnCategory: {
        flex: 2,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnCompany: {
        flex: 3,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnDate: {
        flex: 1.5,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    columnView: {
        flex: 2,
        minWidth: 0,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '0 !important',
        justifyContent: 'center !important',
        '& img': {
            cursor: 'pointer'
        }
    },
    columnActions: {
        flex: 3,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0 !important',
        justifyContent: 'center !important',
    },
    suspendContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    deleteContainer: {
        flex: 1,
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableTitle: {
        fontSize: '1.4rem',
        marginBottom: '40px',
    },
    blockLabel: {
        color: '#A30C0C'
    },
    w9DetailsItem: {
        paddingTop: 20,
        '& .status': {
            textAlign: 'right',
        }
    }
})

export default approvePageStyle;