import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import budgetProgressStyle from './budgetProgressStyle';

class BudgetProgress extends Component
{
    render() {
        const { classes, color, available, spent, bgColor, startLabel, endLabel } = this.props;
        const percent = Math.min(((spent)/ (spent + available)) * 100, 100);

        return (
            <div className={ classes.container }>
                <div className={ classes.spent }>
                    ${ spent.toFixed(2) } { startLabel }
                </div>
                <div
                    className={ classes.bar }
                    style={{ background: bgColor }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: `${percent}%`,
                            background: color
                        }}
                    >

                    </div>
                </div>
                <div className={ classes.available }>
                    {available < 0 ? '-' : ''}${ available < 0 ? (available * -1).toFixed(2) : available.toFixed(2) } { endLabel }
                </div>

            </div>
        )
    }
}

export default withStyles(budgetProgressStyle)(BudgetProgress);