import commonStyle from 'assets/jss/commonStyle'

const trackPaymentPageStyle = theme => ({
    ...commonStyle,
    dropDownContainer: {
        marginTop: '15px',
        marginBottom: '15px',
    },
    header: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        background: '#A30C0C',
        padding: '15px 20px',
        color: 'white',
        borderRadius: '4px',
    },
    dropDownIcon: {
        marginLeft: 'auto',
        width: '27px',
        height: '27px'
    },
    iconImage: {
        width: '27px',
        height: '27px',
        marginRight: '20px'
    },
    invoices: {
        paddingTop: '10px',
        paddingBottom: '25px',
    },

    invoiceContainer: {
        width: '100%',
        marginTop: '20px',
        marginBottom: '20px',
    },
    invoiceRowItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    invoiceNumberContainer: {
        flex: 2,
        minWidth: '100%',
        paddingLeft: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    invoiceSubNumberContainer: {
        flex: 2,
        paddingLeft: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRight: '1px solid #E4E4E4',
    },
    invoiceTitle: {
        flex: 4,
        paddingLeft: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRight: '1px solid #E4E4E4',
    },
    invoiceRequestor: {
        flex: 4,
        paddingLeft: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRight: '1px solid #E4E4E4',
    },
    invoiceAmount: {
        flex: 2,
        paddingLeft: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRight: '1px solid #E4E4E4',
    },
    invoiceDate: {
        flex: 2,
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '30px',
    },
    iconTransform: {
        transform: 'rotate(180deg)'
    },
    invoiceCategory: {
        flexGrow: '10'
    },
    invoiceCount: {
        marginLeft: 'auto',
        textAlign: 'right',
        flexGrow: '1',
        paddingRight: '0.5rem'
    },
    fullInput: {
        width: '100%'
    },
    btnSubmit: {
        padding: '12px 1rem',
        minWidth: '150px',
        color: 'white',
        backgroundColor: '#A30C0C'
    },
    action_bar: {
        padding: '0 20px 10px 20px'
    },
    closeDetail: {
        padding: '12px 1rem',
        backgroundColor: 'rgba(63, 81, 181, 0.04)'
    },
    emailSendButton: {
        marginTop: '1rem',
        backgroundColor: '#3F51B5',
        color: 'white',
        padding: '0.5rem 1rem',
        fontSize: '1rem'
    }
});
export default trackPaymentPageStyle;