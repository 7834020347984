import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseInput from 'components/BaseInput/index.jsx';
import Button from 'components/Button/index.jsx';
import Footer from 'components/Footer/index.jsx';
import GlobalProcessor from 'components/GlobalProcessor/index';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import PaypalExpressBtn from 'react-paypal-express-checkout';

import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MatButton from 'components/MatButton/index.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { confirm } from "../../../components/Confirm/index";
import payPageStyle from './payPageStyle.jsx';

import { Dropdown } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';

import { getAdminPaysDue, holdInvoicePay, payInvoiceViaPaypal, payInvoiceViaPaypalApi, qbGetAuthUrl } from '../../../redux/actions/adminActions';
import Moment from 'react-moment';
import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class PayPage extends Component
{
    state = {
        apiUrl: process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND,
        expand: {
            'pendings': true,
            'submitted': true,
            'payed': true,
            'dueToday': true,
            'holded': true,
        },
        notifyOpen: false,
        notifyTime: 2000,
        notifyMessage: '',
        userlist: [],
        query: ''
    }

    paypalMode = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PAYPAL_MODE : process.env.REACT_APP_PAYPAL_MODE_DEV;
    paypalCurrency = 'USD';
    paypalSetting = {
        sandbox:    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PAYPAL_SANDBOX_ID : process.env.REACT_APP_PAYPAL_SANDBOX_ID_DEV,
        production: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PAYPAL_LIVE_ID : process.env.REACT_APP_PAYPAL_LIVE_ID_DEV,
    }

    constructor(props){
        super(props);
        this.loadData();
    }

    loadData = async () => {
        await this.props.getAdminPaysDue();
    }

    actionApproveInvoicePay = async (e, invoice, flag) => {
        await this.props.holdInvoicePay(invoice.id, false);
        this.loadData();
    }

    actionHoldInvoice = async (e, invoice, flag = true) => {
        confirm("Confirm", "Are you sure hold this invoice?").then(
            async () => {
                console.log("Click OK");
                await this.props.holdInvoicePay(invoice.id);
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    showInvoiceDetail = (e, invoice) => {
        this.setState({detailData: invoice});
        this.setState({detailModalOpen: true});
    }
    closeInvoiceDetail = () => {
        this.setState({detailData: null});
        this.setState({detailModalOpen: false});
    }


    // Invoice Detail Dialog
    showDetailDialog = () => {
        const { classes, t } = this.props;
        if(typeof this.state.detailData == 'undefined' || this.state.detailData == null) return null;
        var invoice = this.state.detailData;
        let buyerName = typeof invoice.buyer != "undefined" ? invoice.buyer.first_name + ' ' + invoice.buyer.last_name : "";

        return (
            <Dialog
                open={this.state.detailModalOpen}
                onClose={this.closeInvoiceDetail}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="form-dialog-title">{t('Invoice Details')}: { (invoice.key == null || invoice.key == '')? t('No hash key yet'): '#' + invoice.key }</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={3}>
                            {t('Event ID')}: { invoice.eventId }
                        </Grid>
                        <Grid item xs={3}>
                            {t('Category')}: { invoice.category }
                        </Grid>
                        <Grid item xs={3}>
                            {t('SubCategory')}: { invoice.sub_category }
                        </Grid>
                        <Grid item xs={3}>
                            {t('Buyer')}: { buyerName }
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={3}>
                            {t('Vendor')}: { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </Grid>
                        <Grid item xs={3}>
                            {t('Value')}: ${ invoice.invoiceValue }
                        </Grid>
                        <Grid item xs={3}>
                            {t('Status')}: { invoice.approved_status == 'Requested'? t('Requested for approval'): ( invoice.approved_status == 'Rejected'? t('Rejected by Admin') : (invoice.is_pay_hold ? t('Holded') : (invoice.is_payed)? t('Paid'): ( invoice.is_submitted? t('Submitted to pay'): t('Pending')))) }
                        </Grid>
                        <Grid item xs={3}>
                            {t('Attachments')}: { this.showAttachments(invoice) }
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <p>{t('Description')}</p>
                            <p>{ invoice.description }</p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <MatButton onClick={this.closeInvoiceDetail} color="primary">
                        {t('Close')}
                    </MatButton>
                </DialogActions>
            </Dialog>
        );
    }

    // Paypal success listener
    paypalOnSuccess = async (invoice, payment) => {
        if(payment.paid){
            await this.props.payInvoiceViaPaypal(invoice, payment);
            if(this.props.commonResult.result.status){
                this.setState({notifyMessage: "Successfully paid via Paypal"});
                this.setState({notifyTime: 2000});
                this.setState({notifyOpen: true});
                this.loadData();
            }else{
                if(typeof this.props.commonResult.result.message != 'undefined' && this.props.commonResult.result.message.length > 1){
                    this.setState({notifyMessage: this.props.commonResult.result.message});
                    this.setState({notifyTime: 6000});
                    this.setState({notifyOpen: true});
                }
            }
        }
    }

    qbPayWindow = null;

    // Quickbook button click function
    processQuickBook = async (e, invoice) => {
        e.preventDefault();
        await this.props.qbGetAuthUrl(invoice);
        var data = this.props.actionResult.data;
        if(typeof data['status'] != 'undefined' && data.status){
            var authUrl = data['auth_url'];
            window.location.href = authUrl;
            //this.qbPayWindow = this.popupCenter(authUrl,'QuickBook','800','740');
        }
    }

    processPaypal = async (e, invoice) => {
        e.preventDefault();
        await this.props.payInvoiceViaPaypalApi(invoice);
        var data = this.props.actionResult.data;
        if(typeof data['status'] != 'undefined' && data.status){
            var authUrl = data['auth_url'];
            window.location.href = authUrl;
            //this.qbPayWindow = this.popupCenter(authUrl,'QuickBook','800','740');
        }
    }

    popupCenter = (url, title, w, h) => {
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var systemZoom = 1; // width / window.screen.availWidth;
        var left = (width - w) / 2 / systemZoom + dualScreenLeft
        var top = (height - h) / 2 / systemZoom + dualScreenTop

        console.log(systemZoom, height);
        var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

        // Puts focus on the newWindow
        if (window.focus) newWindow.focus();

        return newWindow;
    }

    showInvoicesOfDueToday = () => {
        const { classes, t } = this.props;

        const list = this.filteredInvoices();

        return list.map((invoice, key) => {
            return (
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.invoiceTitleContainer }>
                        <span style={{ marginLeft: '20px', paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{ this.getTextLabelOfType(invoice) }</span>
                        <span style={{ marginLeft: '20px' }}>Inv# {invoice.key}</span>
                        { this.showAttachments(invoice) }
                    </div>
                    <div className={ classes.tableRow2ndLine }>
                        <div className={ classes.columnDetails }>
                            <div className={ classes.invoiceSubTitleContainer }>
                                <span style={{ marginLeft: '20px' }}>Ev#{invoice.eventId}</span>
                            </div>
                            <div className={ classes.invoiceDetailContainer }>
                                <span style={{ marginLeft: '20px' }}>{invoice.category}</span>
                            </div>
                        </div>
                        <div className={ classes.columnVendor }>
                            { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </div>
                        <div className={ classes.columnDate }>
                            <span style={{ marginLeft: '20px' }}>
                                {t('To be paid')} <Moment format="Do, MMM">{invoice.due_to}</Moment>
                            </span>
                        </div>
                        <div className={ classes.columnAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.columnView }>
                            <a onClick={e => this.showInvoiceDetail(e, invoice)}><img src="/assets/img/newui/eye.png" /></a>
                        </div>
                        <div className={ classes.columnActions }>
                            <div className={ classes.suspendContainer } style={{'display': (invoice.is_pay_hold? 'flex': 'none')}}>
                                <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)}>{t('Approve')}</MatButton>
                            </div>
                            <div className={ classes.deleteContainer } style={{'display': (invoice.is_pay_hold? 'none': 'flex')}}>
                                <MatButton className={classes.button} onClick={e => this.actionHoldInvoice(e, invoice)} style={{color: '#A30C0C'}}>{t('Hold')}</MatButton>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    showInvoicesOfHolded = () => {
        const { classes, t } = this.props;
        const list = this.filteredInvoices();

        return list.map((invoice, key) => {
            return (
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.invoiceTitleContainer }>
                        <span style={{ marginLeft: '20px', paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{ this.getTextLabelOfType(invoice) }</span>
                        <span style={{ marginLeft: '20px' }}>Inv# {invoice.key}</span>
                        { this.showAttachments(invoice) }
                    </div>
                    <div className={ classes.tableRow2ndLine }>
                        <div className={ classes.columnDetails }>
                            <div className={ classes.invoiceSubTitleContainer }>
                                <span style={{ marginLeft: '20px' }}>Ev#{invoice.eventId}</span>
                            </div>
                            <div className={ classes.invoiceDetailContainer }>
                                <span style={{ marginLeft: '20px' }}>{invoice.category}</span>
                            </div>
                        </div>
                        <div className={ classes.columnVendor }>
                            { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </div>
                        <div className={ classes.columnDate }>
                            <span style={{ marginLeft: '20px' }}>
                                {t('To be paid')} <Moment format="Do, MMM">{invoice.due_to}</Moment>
                            </span>
                        </div>
                        <div className={ classes.columnAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.columnView }>
                            <a onClick={e => this.showInvoiceDetail(e, invoice)}><img src="/assets/img/newui/eye.png" /></a>
                        </div>
                        <div className={ classes.columnActions }>
                            <div className={ classes.suspendContainer } style={{'display': (invoice.is_pay_hold? 'flex': 'none')}}>
                                <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)}>{t('Approve')}</MatButton>
                            </div>
                            <div className={ classes.deleteContainer } style={{'display': (invoice.is_pay_hold? 'none': 'flex')}}>
                                <MatButton className={classes.button} onClick={e => this.actionHoldInvoice(e, invoice)} style={{color: '#A30C0C'}}>{t('Hold')}</MatButton>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    showInvoicesOfPendings = () => {
        const { classes, t } = this.props;

        const list = this.filteredInvoices();

        return list.map((invoice, key) => {
            return (
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.invoiceTitleContainer }>
                        <span style={{ marginLeft: '20px', paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{ this.getTextLabelOfType(invoice) }</span>
                        <span style={{ marginLeft: '20px' }}>Inv# {invoice.key}</span>
                        { this.showAttachments(invoice) }
                    </div>
                    <div className={ classes.tableRow2ndLine }>
                        <div className={ classes.columnDetails }>
                            <div className={ classes.invoiceSubTitleContainer }>
                                <span style={{ marginLeft: '20px' }}>Ev#{invoice.eventId}</span>
                            </div>
                            <div className={ classes.invoiceDetailContainer }>
                                <span style={{ marginLeft: '20px' }}>{invoice.category}</span>
                            </div>
                        </div>
                        <div className={ classes.columnVendor }>
                            { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </div>
                        <div className={ classes.columnDate }>
                            <span style={{ marginLeft: '20px' }}>
                                {t('To be paid')} <Moment format="Do, MMM">{invoice.due_to}</Moment>
                            </span>
                        </div>
                        <div className={ classes.columnAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.columnView }>
                            <a onClick={e => this.showInvoiceDetail(e, invoice)}><img src="/assets/img/newui/eye.png" /></a>
                        </div>
                        {/* <div className={ classes.columnActions }>
                            <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)} style={{'display': (invoice.is_payed? 'none': 'flex')}}>Approve</MatButton>
                            <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)} style={{'display': (invoice.is_payed? 'flex': 'none')}}>Suspend</MatButton>
                        </div> */}
                    </div>
                </div>
            );
        });
    }

    showInvoicesOfSubmitted = () => {
        const { classes, t } = this.props;

        const list = this.filteredInvoices();

        const client = {
			sandbox:    'AUn8E447FLKNmtZTowjyaw5gAizKvFkJU1hcU4NXnl_hP0ASQC2pgBQg4_9ay3_3qTcX0-cCh2rjoRJP',
			production: 'Your-Production-Client-ID',
		}

        return list.map((invoice, key) => {
            return (
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.invoiceTitleContainer }>
                        <span style={{ marginLeft: '20px', paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{ this.getTextLabelOfType(invoice) }</span>
                        <span style={{ marginLeft: '20px' }}>Inv# {invoice.key}</span>
                        { this.showAttachments(invoice) }
                    </div>
                    <div className={ classes.tableRow2ndLine }>
                        <div className={ classes.columnDetails }>
                            <div className={ classes.invoiceSubTitleContainer }>
                                <span style={{ marginLeft: '20px' }}>Ev#{invoice.eventId}</span>
                            </div>
                            <div className={ classes.invoiceDetailContainer }>
                                <span style={{ marginLeft: '20px' }}>{invoice.category}</span>
                            </div>
                        </div>
                        <div className={ classes.columnVendor }>
                            { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </div>
                        <div className={ classes.columnDate }>
                            <span style={{ marginLeft: '20px' }}>
                                To be paid <Moment format="Do, MMM">{invoice.due_to}</Moment>
                            </span>
                        </div>
                        <div className={ classes.columnAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.columnView }>
                            <a onClick={e => this.showInvoiceDetail(e, invoice)}><img src="/assets/img/newui/eye.png" /></a>
                        </div>
                        <div className={ classes.columnActions }>
                            <Dropdown>
                            <Dropdown.Toggle variant="success">
                                {t('Pay with')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                    <PaypalExpressBtn env={this.paypalMode} client={this.paypalSetting} onSuccess={payment => this.paypalOnSuccess(invoice, payment)} currency={this.paypalCurrency} total={Number(invoice.invoiceValue)} />
                                    {/* <button className={`paypal-button`} onClick={ e => this.processPaypal(e, invoice) } style={{'display': invoice.paymentMethod == 'Paypal' && invoice.is_waiting_pay == true? 'none': 'block' }}></button>
                                    <button className={`paypal-button pending`} onClick={ e => this.processPaypal(e, invoice) } style={{'display': invoice.paymentMethod == 'Paypal' && invoice.is_waiting_pay == true? 'block': 'none' }}></button> */}
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                    <button className={`qb-button`} onClick={ e => this.processQuickBook(e, invoice) } style={{'display': invoice.paymentMethod == 'QuickBook' && invoice.is_waiting_pay == true? 'none': 'block' }}></button>
                                    <button className={`qb-button pending`} onClick={ e => this.processQuickBook(e, invoice) } style={{'display': invoice.paymentMethod == 'QuickBook' && invoice.is_waiting_pay == true? 'block': 'none' }}></button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                            <Tooltip title="Mark as paid">
                                <Button style={{ marginLeft: 10, }} onClick={ () => this.markAsPaid(invoice) }>
                                    <img src="/assets/img/newui/checked.png"  style={{ width: 15, height: 15 }} />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            );
        });
    }

    markAsPaid = (invoice) => {
        confirm(
          "Confirm",
          'Do you want to mark the invoice as paid?',
          true,
          'Yes',
          'No'
        ).then(
          async () => {
            const data = {
              'invoice_id': invoice.id
            }
            const { loginStatus } = this.props.account;
            return fetch(apiUrl + 'api/admin/invoice/mark_as_paid', {
              'method': 'POST',
              'headers': {
                'Authorization': 'Token ' + loginStatus.token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            })
            .then(result => result.json())
            .then(result => {
              if (result['status']) {
                this.loadData();
              }
            });
          },
          () => {
          }
        );
      }

    showInvoicesOfPayed = () => {
        const { classes, t } = this.props;

        const list = this.filteredInvoices();

        return list.map((invoice, key) => {
            return (
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.invoiceTitleContainer }>
                        <span style={{ marginLeft: '20px', paddingRight: '1rem', minWidth: '6rem', display: 'inline-block'}}>{ this.getTextLabelOfType(invoice) }</span>
                        <span style={{ marginLeft: '20px' }}>Inv# {invoice.key}</span>
                        { this.showAttachments(invoice) }
                    </div>
                    <div className={ classes.tableRow2ndLine }>
                        <div className={ classes.columnDetails }>
                            <div className={ classes.invoiceSubTitleContainer }>
                                <span style={{ marginLeft: '20px' }}>Ev#{invoice.eventId}</span>
                            </div>
                            <div className={ classes.invoiceDetailContainer }>
                                <span style={{ marginLeft: '20px' }}>{invoice.category}</span>
                            </div>
                        </div>
                        <div className={ classes.columnVendor }>
                            { invoice.vendor.first_name } { invoice.vendor.last_name }
                        </div>
                        <div className={ classes.columnDate }>
                            <span style={{ marginLeft: '20px' }}>
                                {t('To be paid')} <Moment format="Do, MMM">{invoice.due_to}</Moment>
                            </span>
                        </div>
                        <div className={ classes.columnAmount }>
                            ${invoice.invoiceValue}
                        </div>
                        <div className={ classes.columnView }>
                            <a onClick={e => this.showInvoiceDetail(e, invoice)}><img src="/assets/img/newui/eye.png" /></a>
                        </div>
                        {/* <div className={ classes.columnActions }>
                            <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)} style={{'display': (invoice.is_payed? 'none': 'flex')}}>Approve</MatButton>
                            <MatButton className={classes.button} onClick={e => this.actionApproveInvoicePay(e, invoice, true)} style={{'display': (invoice.is_payed? 'flex': 'none')}}>Suspend</MatButton>
                        </div> */}
                    </div>
                </div>
            );
        });
    }

    getTextLabelOfType = (invoice) => {
        var type = invoice.type;
        if(type == 'DebitNote') type = 'Debit Note'
        if(type == 'CreditNote') type = 'Credit Note'
        return type;
    }

    showAttachments = (invoice) => {
        var files = invoice.attachments.split(',');
        if(typeof files != 'undefined' && files != null){
            return files.map((file, key) => {
                if(file == '') return null;
                return (
                    <span key={key} style={{paddingLeft: '0.5rem'}}>
                        { this.showEachAttachFiles(file) }
                    </span>
                )
            })
        }else {
            return null;
        }
    }

    showEachAttachFiles = (file) => {
        var filebasename = file.substring(file.lastIndexOf('/') + 1);
        var filebasenameUrl = encodeURIComponent(filebasename);
        return (
            <a href={this.state.apiUrl + 'api/download/file/' + filebasenameUrl} style={{color: '#333'}} target="_blank">
                <AttachFileIcon style={{transform: 'rotate(45deg)'}} />
            </a>
        );
    }

    clickExpand = (panel) => {
        var data = {}
        data[panel] = !this.state.expand[panel];
        this.setState({expand: {...this.state.expand, ...data}});

        setTimeout(() => {
            console.log(panel, data, this.state.expand)
        }, 1000);
    }

    handleNotifyClose = () => {
        this.setState({notifyOpen: false});
    }

    handleChangeQuery = (event) => {
        this.setState({ query: event.target.value })
    }

    filteredInvoices = () => {
        let list = this.props.paylistResult.data.submitted;
        const { query } = this.state;
        if(typeof list == 'undefined') return [];

        if (query.length === 0) {
            return list;
        }

        const loweredQuery = query.toLowerCase();
        return list.filter(invoice => {
            if (invoice.category.toLowerCase().includes(loweredQuery)) {
                return true;
            }

            if (invoice.eventId.toLowerCase().includes(loweredQuery)) {
                return true;
            }

            if (invoice.key) {
                if (invoice.key.toLowerCase().includes(loweredQuery)) {
                    return true;
                }
            }

            if (invoice.vendor.first_name) {
                if (invoice.vendor.first_name.toLowerCase().includes(loweredQuery)) {
                    return true;
                }
            }

            if (invoice.vendor.last_name) {
                if (invoice.vendor.last_name.toLowerCase().includes(loweredQuery)) {
                    return true;
                }
            }

            if (invoice.invoiceValue.toString().includes(loweredQuery)) {
                return true;
            }

            return false;
        });
    }

    render(){
        const { classes, t } = this.props;

        const {isCollapsed, query } = this.state;
        let dropDownIconClasses = "";
        if(isCollapsed == true)
            dropDownIconClasses = classes.dropDownIcon + " " + classes.iconTransform;
        else
            dropDownIconClasses = classes.dropDownIcon;

        return(
            <div className={classes.container}>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Budget setup approvals')}
                    </div>
                    <div>
                        <BaseInput
                            type="search"
                            style={{ background: 'rgba(255,255,255, 0.6)', width: 230 }}
                            placeHolder="Search..."
                            onChange={ this.handleChangeQuery }
                            value={ query }
                        />
                    </div>
                </div>
                <div className={ classes.contentContainer }>
                    <div className={ classes.pageContent }>
                        <div className={ [classes.formContainer, `text-base`].join(' ') }>
                            {/* <div className={ classes.tableTitle } style={{display: 'flex'}}>
                                <div style={{ flexGrow: '8' }}>
                                    Pending awaiting submission
                                </div>
                                <div>
                                    <ArrowDropDownCircleIcon style={{ color: '#a30c0c', transform: this.state.expand.pendings? 'rotate(180deg)': '' }} onClick={e => this.clickExpand('pendings')} />
                                </div>
                            </div>
                            <div className={ classes.tableContainer } style={{ display: this.state.expand.pendings? 'block': 'none' }}>
                                <div className={ classes.tableHeader }>
                                    <div className={ classes.columnDetails }>
                                        Details
                                    </div>
                                    <div className={ classes.columnDate }>
                                        Date
                                    </div>
                                    <div className={ classes.columnAmount }>
                                        $
                                    </div>
                                    <div className={ classes.columnView }>
                                        View
                                    </div>
                                </div>
                                { this.showInvoicesOfPendings() }
                            </div> */}


                            <div className={ classes.header } onClick={e => this.clickExpand('submitted')}>
                                <div style={{ flexGrow: '8' }}>
                                    {t('Submitted and to be paid')}
                                </div>
                                <div>
                                    <ExpandLessIcon style={{ color: 'white', transform: this.state.expand.submitted? 'rotate(180deg)': '' }}  />
                                </div>
                            </div>
                            <div className={ classes.tableContainer } style={{ display: this.state.expand.submitted? 'block': 'none' }}>
                                <div className={ [classes.tableHeader, ``].join(' ') }>
                                    <div className={ classes.columnDetails }>
                                        {t('Details')}
                                    </div>
                                    <div className={ classes.columnVendor }>
                                        {t('Vendor Name')}
                                    </div>
                                    <div className={ classes.columnDate }>
                                        {t('Date')}
                                    </div>
                                    <div className={ classes.columnAmount }>
                                        $
                                    </div>
                                    <div className={ classes.columnView }>
                                        {t('View')}
                                    </div>
                                    <div className={ classes.columnActions }>
                                        {t('Actions')}
                                    </div>
                                </div>
                                { this.showInvoicesOfSubmitted() }
                            </div>


                            <div className={ classes.header }>
                                <div style={{ flexGrow: '8' }}>
                                    {t('Paid Invoices')}
                                </div>
                                <div>
                                    <ExpandLessIcon style={{ color: '#fff', transform: this.state.expand.payed? 'rotate(180deg)': '' }} onClick={e => this.clickExpand('payed')} />
                                </div>
                            </div>
                            <div className={ [classes.tableContainer, `text-black-300`].join(' ') } style={{ display: this.state.expand.payed? 'block': 'none' }}>
                                <div className={ [classes.tableHeader, `text-black-200`].join(' ') }>
                                    <div className={ classes.columnDetails }>
                                        {t('Details')}
                                    </div>
                                    <div className={ classes.columnVendor }>
                                        {t('Vendor Name')}
                                    </div>
                                    <div className={ classes.columnDate }>
                                        {t('Date')}
                                    </div>
                                    <div className={ classes.columnAmount }>
                                        $
                                    </div>
                                    <div className={ classes.columnView }>
                                        {t('View')}
                                    </div>
                                </div>
                                { this.showInvoicesOfPayed() }
                            </div>


                            <div className={ classes.header }>
                                <div style={{ flexGrow: '8' }}>
                                    {t('Invoices due today')}
                                </div>
                                <div>
                                    <ExpandLessIcon style={{ color: '#fff', transform: this.state.expand.dueToday? 'rotate(180deg)': '' }} onClick={e => this.clickExpand('dueToday')} />
                                </div>
                            </div>
                            <div className={ classes.tableContainer } style={{ display: this.state.expand.dueToday? 'block': 'none' }}>
                                <div className={ [classes.tableHeader, `text-black-200`].join(' ') }>
                                    <div className={ classes.columnDetails }>
                                        {t('Details')}
                                    </div>
                                    <div className={ classes.columnVendor }>
                                        {t('Vendor Name')}
                                    </div>
                                    <div className={ classes.columnDate }>
                                        {t('Date')}
                                    </div>
                                    <div className={ classes.columnAmount }>
                                        $
                                    </div>
                                    <div className={ classes.columnView }>
                                        {t('View')}
                                    </div>
                                    <div className={ classes.columnActions }>
                                        {t('Actions')}
                                    </div>
                                </div>
                                { this.showInvoicesOfDueToday() }
                            </div>


                            <div className={ classes.header }>
                                <div style={{ flexGrow: '8' }}>
                                    {t('Invoices due Hold')}
                                </div>
                                <div>
                                    <ExpandLessIcon style={{ color: '#fff', transform: this.state.expand.holded? 'rotate(180deg)': '' }} onClick={e => this.clickExpand('holded')} />
                                </div>
                            </div>
                            <div className={ classes.tableContainer } style={{ display: this.state.expand.holded? 'block': 'none' }}>
                                <div className={ [classes.tableHeader, `text-black-200`].join(' ') }>
                                    <div className={ classes.columnDetails }>
                                        {t('Details')}
                                    </div>
                                    <div className={ classes.columnVendor }>
                                        {t('Vendor Name')}
                                    </div>
                                    <div className={ classes.columnDate }>
                                        {t('Date')}
                                    </div>
                                    <div className={ classes.columnAmount }>
                                        $
                                    </div>
                                    <div className={ classes.columnView }>
                                        {t('View')}
                                    </div>
                                    <div className={ classes.columnActions }>
                                        {t('Actions')}
                                    </div>
                                </div>
                                { this.showInvoicesOfHolded() }
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    variant="warning"
                    open={this.state.notifyOpen}
                    autoHideDuration={this.state.notifyTime}
                    onClose={this.handleNotifyClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.notifyMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleNotifyClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
                { this.showDetailDialog() }
                <Footer />
            </div>
        )

    }

}

const mapStateToProps = state => ({
    commonResult: state.commonResult, paylistResult: state.paylistResult, account: state.account, actionResult: state.actionResult
});
const mapDispatchToProps = {getAdminPaysDue, holdInvoicePay, payInvoiceViaPaypal, payInvoiceViaPaypalApi, qbGetAuthUrl}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(payPageStyle)(PayPage)));