import React, { Component } from "react";

import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import whiteHeaderStyle from "./whiteHeaderStyle.jsx";

import { userLogout } from "../../redux/actions/accountActions";
import {
  saveUiSettings,
  toggleLeftSidebar,
  toggleContactSidebar,
} from "../../redux/actions/uiActions";
import { getGlobalInitialData } from "redux/actions/commonActions";

import SearchInputType2 from "../SearchInputType2";
import Avatar from "../Avatar";
import ContactSidebar from "../ContactSidebar";

import i18n from '../../i18n';
import { withTranslation  } from 'react-i18next';
import moment from 'moment';
import classNames from "classnames";

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class WhiteHeader extends Component {
  state = {
    tokenLoaded: false,
    query: "",
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
      },
    },
    customerInfo: null,
    language: 'English',
    notifications: [],
    showNotifications: false,
  };
  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };

    if (typeof this.props.initialResponse.data.categGroup == "undefined") {
      setTimeout(() => {
        this.props.getGlobalInitialData();
      }, 1200);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.documentMouseDown, false);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.documentMouseDown, false);

    const loginStatus = this.props.account.loginStatus;
    return fetch(apiUrl + 'api/notifications/get', {
        method: 'POST',
        headers:{
            'Authorization': 'Token ' + loginStatus.token,
        },
    })
    .then(res => res.json())
    .then(data => {
        this.setState({notifications: data});
    });
  }

  documentMouseDown = (e) => {
    if (this.notificationRef) {
        if (this.notificationRef.contains(e.target)) {
            return;
        }

        this.setState({ showNotifications: false });
    }
  }

  // Logout click function
  handleClickLogout = (e) => {
    let customerInfo = this.props.userLogout();
    this.setState({ customerInfo });
  };

  // Toggle Left Sidebar
  handleToggleLeftSidebar = (e) => {
    this.props.toggleLeftSidebar();
  };

  // Toggle Contact Sidebar
  handleToggleContactSidebar = (e) => {
    this.props.toggleContactSidebar();
  };

  /**
   * Change search input value
   */
  queryChanged = async (value) => {
    await this.setState({ query: value });
    // this.applyFilters();
  };

  checkLogin = () => {
    const loginStatus = this.props.account.loginStatus;
    if (!loginStatus.isLogedIn) {
      return <Redirect to="/login" />;
    }
  };

  setLanguage = (language) => {
    this.setState({ language });
    switch (language) {
        case 'English':
            i18n.changeLanguage('en');
            break;
        case 'Spanish':
            i18n.changeLanguage('sp');
            break;
        case 'Portuguese':
            i18n.changeLanguage('pg');
            break;
    }
  }

  renderNotifications = () => {
      const { notifications, showNotifications } = this.state;
      const { classes } = this.props;
      if (!showNotifications) {
          return null;
      }

      if (notifications.length === 0) {
          return null;
      }
      return (
          <div className={ classes.notificationList }>
            <div className={ classNames(classes.notificationScroll, 'budget-scroll-bar') }>
            {
                notifications.map((notification, key) => {
                    return (
                        <NavLink key={`notification-${key}`} className={ classes.notification } to={ notification['data'] && notification['data']['url'] ? notification.data.url : '#' }>
                            <div className={ classes.notificationTitle }>
                                { notification['verb'] }
                            </div>
                            <div className={ classes.notificationDescription }>
                                { notification['description'] }
                            </div>
                            <div className={ classes.notificationTime }>
                                { moment().diff(moment(notification.timestamp), 'days') < 1 ? moment(notification.timestamp).fromNow() : moment(notification.timestamp).format('MMM D') }
                            </div>
                        </NavLink>
                    )
                })
            }
            </div>
          </div>
      )
  }

  viewNotifications = () => {
    const { notifications } = this.state;
    this.setState({ showNotifications: true });

    const loginStatus = this.props.account.loginStatus;
    if (notifications.filter(notification => notification.unread).length) {
        return fetch(apiUrl + 'api/notifications/mark_all_read', {
            method: 'POST',
            headers:{
                'Authorization': 'Token ' + loginStatus.token,
            },
        })
        .then(res => res.json())
        .then(data => {
        });
    }
  }

  render() {
    const { classes, t } = this.props;
    const { query, language, notifications } = this.state;

    const loginStatus = this.props.account.loginStatus;
    if (!loginStatus.isLogedIn) {
      return null;
    }

    const user = this.props["account"]["loginStatus"]["user"];

    if (user === null || typeof user === 'undefined') {
      return null;
    }

    if (user.customer === null || typeof user.customer === 'undefined') {
      return null;
    }


    return (
      <div
        className={[
          classes.globalHeaderContainer,
          classes.expanded,
          `global-header`,
        ].join(" ")}
      >
        {this.checkLogin()}
        <div className={classes.headerWrapper}>
          <div
            className={[classes.headerLeftWrapper, `items-center`].join(" ")}
          >
            <div className={classes.logoToggle}>
              <a href="#" onClick={this.handleToggleLeftSidebar}>
                <img src="/assets/img/newui/header/menu_toggle.svg" alt="" />
              </a>
            </div>

            {user && user.customer.logo && user.customer.logo != "" ? (
              <div className={`customLogo`}>
                <img
                  src={user.customer.logo}
                  alt="iPayflow"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "47px",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : null}

            <div className={`search`}>
              {/* <SearchInputType2
                placeholder={t('Search') + "..."}
                value={query}
                onChange={(event) => this.queryChanged(event.target.value)}
                onClose={() => this.queryChanged("")}
              /> */}
            </div>
          </div>
          <div className={classes.headerRightWrapper}>
            <div className={`language-wrapper`}>
              { t(language) }
              <ul className={`language-list z-50`}>
                <li className={ language === 'English' ? `active` : '' } onClick={ () => this.setLanguage('English') }>{t('English')}</li>
                <li className={ language === 'Spanish' ? `active` : '' } onClick={ () => this.setLanguage('Spanish') }>{t('Spanish')}</li>
                <li className={ language === 'Portuguese' ? `active` : '' } onClick={ () => this.setLanguage('Portuguese') }>{t('Portuguese')}</li>
              </ul>
            </div>
            <div className={`calendar-wrapper`}>
              <div className={`inner-wrapper icon-with-badge`}>
                {/* <img src="/assets/img/newui/header/calendar.png" alt="c" />
                <span className={`badge`}>5</span> */}
              </div>
            </div>
            <span className="font-semibold pt-2 mr-3">
              { `${user['first_name']} ${user['last_name']}` }
            </span>
            <Avatar showMenu="true" size="small" active={true} />
            <div className={`notification-wrapper`} ref={ ref => this.notificationRef = ref }>
              <div className={`inner-wrapper icon-with-badge`} onClick={ this.viewNotifications }>
                <img src="/assets/img/newui/header/notification.svg" alt="n" style={{ width: 20 }}/>
                {
                  Array.isArray(notifications) ?
                    notifications.filter(notification => notification.unread).length !== 0 &&
                        <div className={`badge`}>{ /* notifications.filter(notification => notification.unread).length */ }</div>
                    : null
                }
              </div>
              {
                  this.renderNotifications()
              }
            </div>
          </div>
        </div>

        <ContactSidebar />
      </div>
      /*

      {this.state.loginInfo["user"]["profile"] != null &&
          this.state.loginInfo["user"]["profile"]["company"] != null
            ? ", " + this.state.loginInfo["user"]["profile"]["company"]
            : ""}
          {this.state.loginInfo["user"]["customer"]
            ? " - " + this.state.loginInfo["user"]["customer"]["name"]
            : ""}

       */
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList,
  };
};

const mapDispatchToProps = {
  getGlobalInitialData,
  userLogout,
  saveUiSettings,
  toggleLeftSidebar,
  toggleContactSidebar,
};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(whiteHeaderStyle)(WhiteHeader)));
