import * as Type from '../actions/types';

const initialState = {
    categories: {},
    addResult: {}
};

export const categoryResponse = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_CAT_RESULT:
      return {
        ...state,
        categories: action.payload
      };
    case Type.CM_CAT_ADD_RESULT:
      return {
        ...state,
        addResult: action.payload
      };
    default:
      return state;
  }
};
