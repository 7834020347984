import React, { Component } from "react";

import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import ContactChatStyle from "./ContactChatStyle.jsx";
import Loading from "components/Loading";

import { userLogout } from "../../../redux/actions/accountActions";
import {
  saveUiSettings,
  toggleLeftSidebar,
} from "../../../redux/actions/uiActions";
import Avatar from "components/Avatar";
import Cookies from "js-cookie";
import { getGlobalInitialData } from "redux/actions/commonActions";

import Moment from 'react-moment';

import SendIcon from "@material-ui/icons/Send";

import { withTranslation  } from 'react-i18next';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class ContactChat extends Component {
  state = {
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
      },
    },
    messages: [],
    message: "",
  };

  constructor(props) {
    super(props);
    const { chat } = this.props;
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"],
    };
  }

  componentDidMount() {
    const { chat } = this.props;

    chat.socket.on("chat.sendMessage", (data) => {
      this.setState({
        messages: {
          ...this.state.messages,
          ...chat.messages[chat.currentTargetUser],
        },
      });
    });

    chat.socket.on("chat.receiveMessage", (data) => {
      this.setState({
        messages: {
          ...this.state.messages,
          ...chat.messages[chat.currentTargetUser],
        },
      });
    });
  }

  onKeyPress(e) {
    if (e.which == 13 || e.keyCode == 13) {
      this.sendChatMessage();
    }
  }

  onChangeMessage(e) {
    this.setState({ message: e.target.value });
  }

  sendChatMessage() {
    const { chat } = this.props;
    let message = this.state.message;
    let targetUserId = chat.currentTargetUser;

    chat.socket.emit("chat.sendMessage", {
      targetUserId: targetUserId,
      message: message,
    });
    
    this.setState({ message: '' });
  }

  scrollToBottom() {
    let pages = document.getElementsByClassName('chat-msg-wrapper');
    if (typeof pages != 'undefined' && pages.length > 0 && pages[0]) {
      setTimeout(() => {
        pages[0].scrollTop = pages[0].scrollHeight;
      }, 100);
    }
  }

  render() {
    const { t } = this.props;

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
    const { classes, chat } = this.props;
    if (chat.currentTargetUser == null) {
      return (
        <div className={`p-3 text-center`}>
          { t('Please select a contact that send chat!') }
        </div>
      );
    }

    let messages =
      typeof chat.messages[chat.currentTargetUser] != "undefined"
        ? chat.messages[chat.currentTargetUser]
        : [];

    const contact = chat.contacts[chat.currentTargetUser];
    const loginStatus = this.props.account.loginStatus;
    let myUserId = loginStatus.user.id;

    return (
      <div
        className={[
          classes.ContactChatContainer,
          `RecentContactChat h-full border-b`,
        ].join(" ")}
      >
        <div
          style={{ cursor: "pointer" }}
          className={`flex py-2 px-2 hover:no-underline border-b bg-gray-200`}
        >
          <div className={`w-10`}>
            <div className={`w-full h-8`}>
              <Avatar
                user={contact}
                size={"small"}
                topBubble={false}
                active={contact.status === "active"}
                hideBubble={contact.status === ""}
              />
            </div>
          </div>
          <div className={`text-sm ml-3`}>
            <div className={`leading-none`}>{contact.name}</div>
            <div className={`leading-none pt-1 text-xs opacity-75`}>
              {contact.job}
            </div>
          </div>
        </div>
        <div
          className={`chat-msg-wrapper scrollbar scroll-y overflow-y-auto border-b px-2 py-2`}
          style={{ height: "calc(100% - 90px)" }}
        >
          {messages.map((msg, key) => {
            return (
              <div key={key}>
                {msg.fromUserId == myUserId ? (
                  <div className={`mt-2 w-full pl-2`}>
                    <div className={`text-right break-all leading-tight`}>{msg.message}</div>
                    <div className={`text-right text-xs text-gray-400`}>
                      <Moment format="MMM, D HH:mm">{msg.time}</Moment>
                    </div>
                  </div>
                ) : (
                  <div className={`mt-2 w-full flex`}>
                    <div className={`mr-2`}>
                      <Avatar
                        user={contact}
                        size={"small"}
                        topBubble={false}
                        active={contact.status === "active"}
                        hideBubble={contact.status === ""}
                      />
                    </div>
                    <div className={`p-0 pr-2 -mt-1`}>
                      <div className={`text-left break-all leading-tight`}>{msg.message}</div>
                      <div className={`text-left text-xs text-gray-400`}>
                        <Moment format="MMM, D HH:mm">{msg.time}</Moment>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={`chat-msg-form pl-2 pr-10 py-2`} style={{ height: 40 }}>
          <input
            type="text"
            className={`focus:outline-none w-full`}
            name="message"
            value={this.state.message}
            onKeyPress={(e) => this.onKeyPress(e)}
            onChange={(e) => this.onChangeMessage(e)}
          />
          <button
            onClick={(e) => this.sendChatMessage()}
            className={`focus:outline-none absolute right-0 mr-2`}
            style={{ color: "#1291f9" }}
          >
            <SendIcon />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    account: state.account,
    customersList: state.customersList,
    chat: state.chat,
  };
};

const mapDispatchToProps = {
  getGlobalInitialData,
  userLogout,
  saveUiSettings,
  toggleLeftSidebar,
};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ContactChatStyle)(ContactChat)));
