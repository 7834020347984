import React, { Component, useState } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/core';
import budgetsReportStyle from './budgetsReportStyle';
import { withTranslation } from 'react-i18next';
import Button from 'components/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Grid from '@material-ui/core/Grid';
import BudgetProgress from 'components/BudgetProgress/BudgetProgress';
import classNames from "classnames";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DatePicker from "react-datepicker";
import MomentTag from 'react-moment';
import moment from 'moment';

import SortBtn from 'components/SortBtn';
import { DataGrid } from '@material-ui/data-grid';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

const mapToAttributes = {
    'eventId': 'EVENT',
    'category': 'CATEGORY',
    'vendor_id': 'VENDOR',
    'buyer_id': 'BUYER'
};


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];


class BudgetsReport extends Component {
    state = {
        duration: 'monthly',
        customDateRange: {
            start: null,
            end: null,
        },
        startDate: null, // new Date("2014/02/08"),
        endDate: null, // new Date("2014/02/10"),
        tab: 'all',
        totalBudget: 0,
        totalInvoiced: 0,
        events: [],
        categories: [],
        vendors: [],
        buyers: [],
        filter: {
            EVENT: null,
            CATEGORY: null,
            VENDOR: null,
            BUYER: null
        },
        showDetails: false,
        loading: true,
        sortOption: {
            sortBy: 'name',
            sortAsc: null,
        },
    }

    constructor(props) {
        super(props);

        this.refDetailsContainer = React.createRef()
    }

    fetchBudgets = (duration = 'monthly') => {
        const { loginStatus } = this.props.account;
        this.setState({ loading: true });

        let postData = { duration };
        if (duration === 'custom') {
            postData = {
                duration,
                start: moment(this.state.startDate).format('YYYY-MM-DD'),
                end: moment(this.state.endDate).format('YYYY-MM-DD'),
            }
        }

        return fetch(apiUrl + "api/admin/report/budgets", {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then((res) => {
                return res.json();
            })
            .then((result) => {
                if (result['status']) {
                    this.originalData = result;
                    const totalBudget = result['budgets'].map(budget => budget.value).reduce((total, current) => total + current, 0);
                    const totalInvoiced = result['invoices'].map(invoice => invoice.invoiceValue).reduce((total, current) => total + current, 0);

                    const events = this.prepareDataToRender('eventId', result['budgets'], result['invoices']);
                    const categories = this.prepareDataToRender('category', result['budgets'], result['invoices']);
                    const vendors = this.prepareDataToRender('vendor_id', result['budgets'], result['invoices']);
                    const buyers = this.prepareDataToRender('buyer_id', result['budgets'], result['invoices']);
                    this.setState({ totalBudget, totalInvoiced, events, categories, vendors, buyers, loading: false });
                }
            });
    }

    componentDidMount() {
        this.fetchBudgets();
    }

    isBudgetFiltered = (budget, filter) => {
        for (const key in mapToAttributes) {
            if (filter[mapToAttributes[key]] === null || typeof filter[mapToAttributes[key]] === 'undefined') {
                continue;
            }

            if (budget[key] !== filter[mapToAttributes[key]]) {
                return false;
            }
        }

        return true;
    }

    prepareDataToRender = (field, budgets, invoices, filter = {}) => {
        let events = budgets.map(budget => budget[field]);
        // Remove duplicated ones
        events = events.filter(
            (eventId, index) =>
                events.indexOf(eventId) === index
                && eventId !== null
                && (
                    filter[mapToAttributes[field]] == null
                    || (filter[mapToAttributes[field]] !== null && eventId === filter[mapToAttributes[field]]))
        )

        events = events.map(eventId => ({ label: eventId }));
        events.forEach((event, index) => {
            let allocated = 0, invoiced = 0, vendorName, buyerName;
            let vendorId, buyerId;
            budgets.forEach(budget => {
                if (budget[field] === event.label) {
                    allocated += budget.value;
                    if (budget.vendor) {
                        vendorName = budget.vendor.first_name + ' ' + budget.vendor.last_name;
                        vendorId = budget.vendor_id;
                    }
                    if (budget.buyer) {
                        buyerName = budget.buyer.first_name + ' ' + budget.buyer.last_name;
                        buyerId = budget.buyer_id;
                    }
                }
            });

            invoices.forEach(invoice => {
                if (invoice[field] === event.label) {
                    invoiced += invoice.invoiceValue;
                }
            })

            events[index]['available'] = allocated;
            events[index]['spent'] = invoiced;
            events[index]['key'] = events[index]['label'];;
            if (field === 'vendor_id') {
                events[index]['label'] = vendorName;
                events[index]['key'] = vendorId;
            }
            if (field === 'buyer_id') {
                events[index]['label'] = buyerName;
                events[index]['key'] = buyerId;
            }
        });

        return events;
    }

    durationClicked = (duration) => {
        if (this.state.duration == 'custom' && duration !== 'custom') {
            this.setStartDate(null);
            this.setEndDate(null);
        }
        this.setState({ duration });
        if (duration !== 'custom') {
            this.fetchBudgets(duration);
        }
    }

    tabClicked = (tab) => {
        if (tab === this.state.tab) {
            this.setState({ tab: 'all' });
            return;
        }
        this.setState({ tab });

    }

    createBudgetClicked = () => {
        const user = this.props.account.loginStatus.user;
        if (user.profile.user_type === 'ADMIN') {
            this.props.history.push('/admin/setup/budgets', [true]);
            return;
        }

        this.props.history.push('/buyer/budgets', [true]);
    }

    clearFilter = () => {
        const filter = {
            EVENT: null,
            CATEGORY: null,
            VENDOR: null,
            BUYER: null
        };

        let invoices = this.originalData['invoices'].filter(budget => this.isBudgetFiltered(budget, filter));
        let budgets = this.originalData['budgets'].filter(budget => this.isBudgetFiltered(budget, filter));
        const events = this.prepareDataToRender('eventId', budgets, invoices, filter);
        const categories = this.prepareDataToRender('category', budgets, invoices, filter);
        const vendors = this.prepareDataToRender('vendor_id', budgets, invoices, filter);
        const buyers = this.prepareDataToRender('buyer_id', budgets, invoices, filter);

        const totalBudget = budgets.map(budget => budget.value).reduce((total, current) => total + current, 0);
        const totalInvoiced = invoices.map(invoice => invoice.invoiceValue).reduce((total, current) => total + current, 0);

        this.setState({ events, categories, vendors, buyers, filter, totalBudget, totalInvoiced });
    }

    filterApplied = () => {
        const { filter } = this.state;
        for (const key in filter) {
            if (filter[key] !== null) {
                return true;
            }
        }

        return false;
    }

    searchCustomRange = (dates) => {
        if (this.state.startDate && this.state.endDate) {
            this.fetchBudgets(this.state.duration);
        }
    }

    setStartDate = (date) => {
        this.setState({startDate: date}, () => {
            this.searchCustomRange();
        });
    }

    setEndDate = (date) => {
        this.setState({endDate: date}, () => {
            this.searchCustomRange();
        });
    }
    
    render() {
        const { classes, t, history } = this.props;
        const { duration, tab, totalBudget, totalInvoiced, events, categories, vendors, buyers, loading, customDateRange } = this.state;
        const isSpent = history.location.pathname === '/admin/report/spent';
        const chartTitle = isSpent ? 'Allocated' : 'Budget Allocated';

        let hasData = false;
        if (this.originalData && this.originalData['invoices'] && this.originalData['budgets']) {
            if (this.originalData['invoices'].length > 0
                || this.originalData['budgets'].length > 0
            ) {
                hasData = true;
            }
        }

        const CustomDateRangeInput = React.forwardRef(
            ({ value, onClick }, ref) => (
            <button className={`${classes.btnCustomDuration}`}
                onClick={onClick}
                ref={ref}
            >
                {
                    value &&
                    <MomentTag format="DD/MM/YYYY">{value}</MomentTag>
                }
                {
                    !value &&
                    <>
                        &nbsp;
                    </>
                }
            </button>
            ),
        );

        const { startDate, endDate } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.pageTitle}>
                        {isSpent ? t('Spend Reports') : t('Budget Reports')}
                        {
                            !isSpent &&
                            <div className={classes.btnHeaderNav} onClick={this.createBudgetClicked}>
                                Create Budget
                            </div>
                        }
                    </div>
                </div>
                <div className={classes.pageContentContainer}>
                    <div className={classes.durationContainer}>
                        <Button className={classNames(classes.btnHeaderNav, classes.btnClearFilter)}
                            onClick={this.clearFilter}
                            style={{ fontSize: 12, width: 120, height: 28, lineHeight: '28px' }}
                            disabled={!this.filterApplied()}
                        >

                            Clear Filters
                        </Button>
                        <div className={classes.durationFilter}>
                            <Button onClick={this.exportClicked} className={classes.exportButton}>
                                <img src="/assets/img/newui/icon-save.png" style={{ width: 30, height: 30, display: 'inline-block' }} />
                            </Button>
                            <Button
                                className={`${classes.btnDuration} ${duration === 'monthly' ? 'active' : ''}`}
                                onClick={() => this.durationClicked('monthly')}
                            >
                                Monthly
                            </Button>
                            <Button
                                className={`${classes.btnDuration} ${duration === 'quarterly' ? 'active' : ''}`}
                                onClick={() => this.durationClicked('quarterly')}
                            >
                                Quarterly
                            </Button>
                            <Button
                                className={`${classes.btnDuration} ${duration === 'annual' ? 'active' : ''}`}
                                onClick={() => this.durationClicked('annual')}
                            >
                                Annual
                            </Button>

                            <Button
                                className={`${classes.btnDuration} ${duration === 'custom' ? 'active' : ''}`}
                                onClick={() => this.durationClicked('custom')}
                            >
                                Custom
                            </Button>
                            {
                                duration === 'custom' &&
                                <>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => this.setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate}
                                        customInput={<CustomDateRangeInput />}
                                    />
                                    <span> ~ </span>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={date => this.setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        popperPlacement="top-end"
                                        customInput={<CustomDateRangeInput />}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    {/* <div className={ classes.tabContainer }>
                        <Button
                            className={ `${classes.btnTab} ${tab === 'event' ? 'active' : ''}` }
                            onClick={ () => this.tabClicked('event') }
                        >
                            Event
                        </Button>
                        <Button
                            className={ `${classes.btnTab} ${tab === 'category' ? 'active' : ''}` }
                            onClick={ () => this.tabClicked('category') }
                        >
                            Category
                        </Button>
                        <Button
                            className={ `${classes.btnTab} ${tab === 'buyer' ? 'active' : ''}` }
                            onClick={ () => this.tabClicked('buyer') }
                        >
                            Buyer
                        </Button>
                        <Button
                            className={ `${classes.btnTab} ${tab === 'vendor' ? 'active' : ''}` }
                            onClick={ () => this.tabClicked('vendor') }
                        >
                            Vendor
                        </Button>
                    </div> */}
                    {
                        !loading &&
                        <div
                            style={{ paddingTop: 20, }}
                        >
                            {
                                !hasData &&
                                <span
                                    style={{
                                        paddingLeft: 10,
                                        color: 'red'
                                    }}
                                >
                                    No data for current filters
                                </span>
                            }
                            <GridContainer
                                style={{ height: '100%' }}
                            >
                                {
                                    this.renderOverallChart([{ spent: totalInvoiced, available: totalBudget }])
                                }
                                {
                                    (tab === 'all' || tab === 'event') &&
                                    this.renderOverallBudget(chartTitle, events, '#FF8000', 'rgba(255, 128, 0, 0.5)', 'EVENT')
                                }
                                {
                                    (tab === 'all' || tab === 'category') &&
                                    this.renderOverallBudget(chartTitle, categories, '#2A9E1A', 'rgba(42, 158, 26, 0.5)', 'CATEGORY')
                                }
                                {
                                    (tab === 'all' || tab === 'vendor') &&
                                    this.renderOverallBudget(chartTitle, vendors, '#FF8000', 'rgba(255, 128, 0, 0.5)', 'VENDOR')
                                }
                                {
                                    (tab === 'all' || tab === 'buyer') &&
                                    this.renderOverallBudget(chartTitle, buyers, '#2A9E1A', 'rgba(42, 158, 26, 0.5)', 'BUYER')
                                }
                            </GridContainer>
                        </div>
                    }
                    <div className={classes.budgetDetailsWrapper} >
                        <button className={[classes.budgetDetailsHeader, 'ml-10 pl-3 pr-2 py-1'].join(' ')}
                            onClick={() => this.showDetailsClicked()}
                        >
                            {this.state.showDetails ? '- Hide details' : '+ Show details'}
                            <ExpandLessIcon
                                style={{
                                    color: '#1291F9',
                                    transform: this.state.showDetails ? 'rotate(180deg)': ''
                                }}
                            />
                        </button>
                        {
                            this.state.showDetails && !loading &&
                            this.renderBudgetDetails()
                        }
                    </div>
                </div>
            </div>
        )
    }


    budgetDetailsSortOptionChanged = (sortBy, sortAsc) => {
        this.setState({
            sortOption: {sortBy, sortAsc}
        })
    }
    
    filteredBudgets = (budgets) => {
        const { sortBy, sortAsc } = this.state.sortOption;

        let filteredBudgets = [...budgets];
        filteredBudgets.sort((a, b) => {
            if (sortBy == '' || sortAsc == null) {
                return 1;
            }

            let retVal = 1;
            try {
                if (sortBy === 'event') {
                    if (a.eventId.toLowerCase() < b.eventId.toLowerCase()) {
                        retVal = -1;
                    }
                }
                if (sortBy === 'category') {
                    if (a.category.toLowerCase() < b.category.toLowerCase()) {
                        retVal = -1;
                    }
                }
                if (sortBy === 'desc') {
                    if (a.description.toLowerCase() < b.description.toLowerCase()) {
                        retVal = -1;
                    }
                }
                if (sortBy === 'value') {
                    if (a.value < b.value) {
                        retVal = -1;
                    }
                }
            } catch(err) {
            }
            

            retVal = !sortAsc ? -1 * retVal : retVal;
            return retVal;
        });

        return filteredBudgets;
    }

    renderBudgetDetails = () => {
        if (!Array.isArray(this.originalData['budgets'])) {
            return null;
        }

        const { classes, t } = this.props;
        
        let { filter } = this.state;
        const budgets = this.originalData['budgets'].filter((budget) => this.isBudgetFiltered(budget, filter));
        const filterdBudgets = this.filteredBudgets(budgets);
        const tPageSize = budgets.length;
        const tHeight = (Math.min(budgets.length, tPageSize) + 1) * 52 + 60;

        const { sortBy, sortAsc } = this.state.sortOption;

        return (
            <div className={[classes.budgetDetailsContent, 'px-10 py-3'].join(' ')}
                ref={this.refDetailsContainer}
            >
                <div style={{ width: '100%' }}>
                    <Grid container className={ `${classes.budgetContainer} text-white` }
                        style={{ background: '#5cb6ff', fontWeight: 600 }}
                        spacing={ 24 }
                    >
                        <Grid item xs={ 2 }>
                            {t('Ev#')}
                            <SortBtn sortOptionChanged={this.budgetDetailsSortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'event'}
                            />
                        </Grid>
                        <Grid item xs={ 3 }>
                            {t('Category')}
                            <SortBtn sortOptionChanged={this.budgetDetailsSortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'category'}
                            />
                        </Grid>
                        <Grid item xs={ 5 }>
                            {t('Desc')}
                            <SortBtn sortOptionChanged={this.budgetDetailsSortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'desc'}
                            />
                        </Grid>
                        <Grid item xs={ 2 }>
                            {t('Value')}
                            <SortBtn sortOptionChanged={this.budgetDetailsSortOptionChanged}
                                sortBy={sortBy} isAscSort={sortAsc} sortName={'value'}
                            />
                        </Grid>
                    </Grid>
                    {filterdBudgets.map((budget) => {
                        return (
                            <Grid container key={`budget-${budget.id}`} className={ classes.budgetContainer } spacing={24}>
                                <Grid item xs={ 2 }>
                                    { budget.eventId }
                                </Grid>
                                <Grid item xs={ 3 }>
                                    { budget.category }
                                </Grid>
                                <Grid item xs={ 5 }>
                                    {budget.description}
                                </Grid>
                                <Grid item xs={2}>
                                    {budget.value}
                                </Grid>
                            </Grid>
                        )
                    })}
                    {/* <DataGrid rows={budgets} columns={columns} pageSize={tPageSize} autoHeight={true} hideFooter={true} hideFooterPagination={true} /> */}
                </div>
            </div>
        );
    }

    renderOverallChart = (valuePairs) => {
        const { classes } = this.props;
        return (
            <GridItem
                xs={4}
                style={{ padding: 10, height: 400, }}
            >
                <div
                    style={{ background: 'white', borderRadius: 7, width: '100%', height: '100%', padding: '16px 20px' }}
                >
                    <div
                        className={classes.tileTitle}
                    >
                        Overall Budget
                    </div>

                    <div
                        className={classes.budgetValue}
                    >
                        ${valuePairs.map(pair => pair.available).reduce((total, current) => total + current, 0).toFixed(2)}
                    </div>
                    <div
                        className={classNames(classes.budgetsContainer, 'budget-scroll-bar')}
                    >
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    fill={'#2D7DD2'}
                                    startAngle={90}
                                    endAngle={-450}
                                    innerRadius={'55%'}
                                    outerRadius={'80%'}
                                    data={
                                        [
                                            {
                                                value: valuePairs[0].spent
                                            },
                                            {
                                                value: valuePairs[0].available
                                            }
                                        ]
                                    }
                                    dataKey="value"
                                >
                                    <Cell fill='#2D7DD2' />
                                    <Cell fill='rgba(45, 125, 210, 0.5)' />
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{ fontSize: 12, textAlign: 'center', color: '#828282' }}>
                        <div className={classes.colorPicContainer}>
                            <div style={{ minWidth: 140, display: 'flex', alignItems: 'center', }}>
                                ${valuePairs[0].spent.toFixed(2)} Allocated <span className={classes.colorPic}></span>
                            </div>

                        </div>
                        <div className={classes.colorPicContainer}>
                            <div style={{ minWidth: 140, display: 'flex', alignItems: 'center', }}>
                                ${valuePairs[0].available.toFixed(2)} Available <span className={classes.lightColorPic}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </GridItem>
        )
    }

    filterClicked = (value, type) => {
        let { filter } = this.state;
        filter[type] = filter[type] === value ? null : value;
        let invoices = this.originalData['invoices'].filter(budget => this.isBudgetFiltered(budget, filter));
        let budgets = this.originalData['budgets'].filter(budget => this.isBudgetFiltered(budget, filter));
        const events = this.prepareDataToRender('eventId', budgets, invoices, filter);
        const categories = this.prepareDataToRender('category', budgets, invoices, filter);
        const vendors = this.prepareDataToRender('vendor_id', budgets, invoices, filter);
        const buyers = this.prepareDataToRender('buyer_id', budgets, invoices, filter);

        const totalBudget = budgets.map(budget => budget.value).reduce((total, current) => total + current, 0);
        const totalInvoiced = invoices.map(invoice => invoice.invoiceValue).reduce((total, current) => total + current, 0);

        this.setState({ events, categories, vendors, buyers, filter, totalBudget, totalInvoiced });
    }

    showDetailsClicked = () => {
        this.setState({ showDetails: !this.state.showDetails });
        setTimeout(() => {
            let container = document.querySelector('.main-container');
            container.scrollTop = container.offsetHeight;
        }, 500);
    }

    renderOverallBudget = (title, valuePairs, color, bgColor, label = "") => {
        const { classes, history } = this.props;
        const { filter } = this.state;
        const isSpent = history.location.pathname === '/admin/report/spent';

        return (
            <GridItem
                xs={4}
                style={{ padding: 10, height: 400, }}
            >
                <div
                    style={{ background: 'white', borderRadius: 7, width: '100%', height: '100%', padding: '16px 20px' }}
                >
                    <div
                        className={classes.tileTitle}
                    >
                        {title}
                    </div>
                    <div
                        className={classes.byLabel}
                    >
                        {
                            label.length ? 'BY ' + label : null
                        }
                    </div>
                    <div
                        className={classes.budgetValue}
                    >
                        ${valuePairs.map(pair => pair.available).reduce((total, current) => total + current, 0).toFixed(2)}
                    </div>
                    <div
                        className={classNames(classes.budgetsContainer, 'budget-scroll-bar')}
                    >
                        {
                            valuePairs.map((pair, key) =>
                                <div key={`${title} - ${key}`}>
                                    {
                                        pair.label ?
                                            <div style={{ fontWeight: 700, color: (filter[label] === pair.key ? '#1291F9' : '#1E1E1E'), marginBottom: 3, cursor: 'pointer' }} onClick={() => this.filterClicked(pair.key, label)}>
                                                {pair.label}
                                            </div> : null
                                    }
                                    <BudgetProgress
                                        bgColor={bgColor}
                                        color={color}
                                        available={pair.available - pair.spent}
                                        spent={pair.spent}
                                        startLabel={isSpent ? 'SPENT' : 'ALLOCATED'}
                                        endLabel='AVAILABLE'
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </GridItem>

        )
    }

    exportClicked = () => {
        const { loginStatus } = this.props.account;
        const { events, categories, vendors, buyers } = this.state;
        const data = {
            'Event': events,
            'Category': categories,
            'Vendor': vendors,
            'Buyer': buyers
        }

        const isSpent = this.props.history.location.pathname === '/admin/report/spent';
        let endpoint = apiUrl + "api/admin/export/budgets";
        if (isSpent) {
            endpoint = apiUrl + "api/admin/export/spent";
        }

        return fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: `Token ${loginStatus.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(result => result.blob())
            .then((result) => {
                const url = URL.createObjectURL(result);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'export.xlsx';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            });
    }
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(budgetsReportStyle)(BudgetsReport))));