import React, { Component } from 'react';
import { connect } from 'react-redux';

import GlobalProcessor from 'components/GlobalProcessor/index';
import leanDocumentStyle from './leanDocumentStyle';
import { withStyles } from '@material-ui/core';

import { withTranslation  } from 'react-i18next';
import { uploadFiles } from '../../redux/actions/fileActions';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import InterimLien from './InterimLien';
import FinalLien from './FinalLien';

class LeanDocument extends Component
{
    state = {
        docType: null
    }

    setDocumentType = (docType) => {
        this.setState({ docType: docType })
    }

    renderContent = () => {
        const { docType } = this.state;
        const { classes, t } = this.props;

        if (docType === null) {
            return (
                <div className={ classes.pageContentContainer } style={{ padding: 30 }}>
                    <p style={{ fontSize: 25, color: '#1D8ACF', fontWeight: 600, textAlign: 'center', marginTop: 100 }}>
                        Which type of lien document do you upload?
                    </p>
                    <div className={ classes.selectContainer }>
                        <button
                            className={ classes.btnSelect }
                            onClick={ () => this.setDocumentType('interim') }
                        >
                            <AssignmentIcon style={{ fontSize: 50 }} />
                            { t('Interim Lien') }
                        </button>
                        <button
                            className={ classes.btnSelect }
                            onClick={ () => this.setDocumentType('final') }
                        >
                            <DescriptionIcon style={{ fontSize: 50 }} />
                            { t('Final Lien') }
                        </button>
                    </div>
                </div>
            );
        }

        if (docType === 'interim') {
            return (
                <div className={ classes.pageContentContainer } style={{ padding: 30 }}>
                    <div className={ classes.contentContainer }>
                        <InterimLien />    
                    </div>
                </div>
            )
        }

        return (
            <div className={ classes.pageContentContainer } style={{ padding: 30 }}>
                <div className={ classes.contentContainer }>
                    <FinalLien />
                </div>
            </div>
        );
    }
    
    render() {
        const { classes, t } = this.props;

        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        { t('Lien Document') }
                    </div>
                </div>
                {
                    this.renderContent()
                }
            </div>
        );
    }   
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {uploadFiles}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(leanDocumentStyle)(LeanDocument)));