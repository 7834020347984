import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import GlobalProcessor from 'components/GlobalProcessor/index';
import leanDocumentViewPageStyle from './leanDocumentViewPageStyle';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { withTranslation  } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FinalLien from 'views/LeanDocumentPage/FinalLien';
import InterimLien from 'views/LeanDocumentPage/InterimLien';

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc');

    return fileSelector;
}

class LeanDocumentViewPage extends Component
{
    state = {
        loading: false,
        signed: false,
        existing_attachments: [],
        lean_document: {
            release_name: '',
            business_address: '',
            city: '',
            project: '',
            project_manager: '',
            project_telephone: '',
            contact_person: '',
            contact_telephone: '',
            conditional_release_amount: '',
            unconditional_release_amount: '',
            date: '',
            title: '',
            state: '',
            acknowledge_date: '',
            notary_state: '',
            notary_email: '',
            notary_name: '',
            appointment_expires_at: '',
        }
    }

    componentDidMount = async ()  => {
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        this.fetchLeanDocument();
    }

    renderNotarySignature = () => {
        const { t } = this.props;
        const { lean_document } = this.state;
        if (lean_document.status !== 'completed') {
            return (
                <span style={{ border: '1px solid rgb(216, 45, 37)', borderRadius: 4, padding: '1px 8px', background: 'rgba(216, 45, 37, 0.1)' }}>
                    {t('Pending')}
                </span>
            );
        }

        return (
            <img src={ lean_document.notary_signature } alt="Signature" style={{ width: '500px', objectFit: 'contain' }} />
        )
    }

    renderDocument = () => {
        const { lean_document } = this.state;
        if (lean_document.release_name.length) {
            if (lean_document.doc_type === 'final') {
                return <FinalLien document={ lean_document } />
            }

            return <InterimLien document={ lean_document } />
        }

        return null;
    }

    render() {
        const { classes, t } = this.props;
        const { loading} = this.state;

        if (loading) {
            return (
                <div className={ classes.container }>
                    <GlobalProcessor />
                    <div className={ classes.headerContainer }>
                        <div className={ classes.pageTitle }>
                            { t('Lien Document') }
                        </div>
                    </div>
                    <div className={ classes.pageContentContainer } style={{ paddingTop: 15, }}>
                        <div className={ classes.contentContainer }>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        { t('Lien Document') }
                    </div>
                </div>
                <div className={ classes.pageContentContainer } style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <div className={ classes.contentContainer }>
                        {
                            this.renderDocument()
                        }
                    </div>
                </div>
            </div>
        )
    }

    // Show existing attachments
    renderExistingAttachments = () => {
        const {classes} = this.props;
        return this.state.existing_attachments.map((filename, key) => {
            var filebasename = filename.substring(filename.lastIndexOf('/') + 1);
            var filebasenameUrl = encodeURIComponent(filebasename);
            const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
            if(filebasename == ''){
                return null;
            }else{
                return (
                    <div className={ classes.attachmentContainer } key={key}>
                        <img src="/assets/img/attachment-active.png" style={{width: 24, height: 24, marginRight: 8}} alt="attachment" className={ [classes.attachmentImg, `inline-block`].join(' ') }/>
                            { filebasename }&nbsp;
                            <a href={apiUrl + 'api/download/file/' + filebasenameUrl} target="_blank"><SaveAltIcon /></a>
                    </div>
                )
            }
        })
    }


    fetchLeanDocument = () => {
        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        const leanId = this.props.match.params["leanId"];
        const loginStatus = this.props.account.loginStatus;
        return fetch(apiUrl + 'api/lean_release/get/by_id', {
            method: 'POST',
            headers: {
                Authorization: 'Token ' + loginStatus.token,
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({lean_id:leanId})
        })
        .then(res => res.json())
        .then(result => {
            let document = JSON.parse(result)[0]['fields'];
            document['date'] = moment(document['date']).format('YYYY-MM-DD');
            document['acknowledge_date'] = moment(document['acknowledge_date']).format('YYYY-MM-DD');
            document['appointment_expires_at'] = moment(document['appointment_expires_at']).format('YYYY-MM-DD');
            document['conditional_date'] = moment(document['conditional_date']).format('YYYY-MM-DD');
            document['unconditional_date'] = moment(document['unconditional_date']).format('YYYY-MM-DD');

            this.setState({ lean_document: document, loading: false });
            this.setState({
                existing_attachments: document['attachments'] ?
                    document['attachments'].split(',').filter((i) => { return i })
                    : []
            });
        });
    }

    textChanged = (event, key) => {
        let { lean_document: doc } = this.state;
        doc[key] = event.target.value;
        this.setState({ lean_document: doc });
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(leanDocumentViewPageStyle)(LeanDocumentViewPage)));