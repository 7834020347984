import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import Button from 'components/Button';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

import msaItemStyle from './msaItemStyle';
import { withTranslation  } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import CheckBox from 'components/CustomCheckBox/index.jsx';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

class MsaItem extends Component
{
    state = {
        isDefault: false,
        goToView: false,
        url: ''
    }

    constructor(props) {
        super(props);
        this.state.loginInfo = {...this.state.loginInfo, ...this.props['account']['loginStatus']};
    }

    changeDefaultCheckbox(e) {
        const loginStatus = this.props.account.loginStatus;
        const apiUrl = process.env.NODE_ENV === 'production' ?
            (
                process.env.REACT_APP_SERVER_MODE == 'LIVE' ?
                    process.env.REACT_APP_BACKEND_LIVE :
                    process.env.REACT_APP_BACKEND_TEST
            ) :
            process.env.REACT_APP_DEV_BACKEND;

        const { msa } = this.props;
        let newValue = !msa.is_default;
        msa.is_default = newValue;

        this.setState({isDefault: newValue});

        return fetch(apiUrl + 'api/msa/set_default', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${loginStatus.token}`,
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({
                id: msa.id,
                value: newValue
            })
        })
        .then(res => res.json())
        .then(data => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange();
            }
            return;
        })
    }

    render() {
        const { classes, msa, t } = this.props;
        const { goToView, url } = this.state;
        const signer = msa.signer;
        const loginStatus = this.props.account.loginStatus;

        var userType = (typeof this.state.loginInfo.user != 'undefined' && this.state.loginInfo.user != null) ?
            this.state.loginInfo.user.profile.user_type : '';

        if (goToView) {
            return (
                <Redirect to={`/msa/view/${msa.id}`} push />
            )
        }

        if (url.length) {
            return (
                <Redirect to={ url } push />
            );
        }

        return (
            <GridContainer spacing={16} className={ classes.itemData }>
                <GridItem xs={4}>
                    {userType == 'ADMIN' ? (
                        <CheckBox name="default_check" label=""
                            checked={msa.is_default}
                            onChange={e => this.changeDefaultCheckbox(e)} />
                    ) : null}
                    {userType != 'ADMIN' ? (
                        <DescriptionIcon classes={{ root: classes.icon }} fontSize="large"/>
                    ) : null}
                    <span className={ classes.signerName } onClick={ this.goToViewPage }>
                        { signer['first_name'] + ' ' + signer['last_name'] } <br/> <span >({ signer['email'] })</span>
                    </span>
                </GridItem>
                <GridItem xs={3} onClick={ this.goToViewPage }>
                    {
                        this.renderStatus()
                    }
                </GridItem>
                <GridItem xs={3} onClick={ this.goToViewPage }>
                    {
                        this.renderDate()
                    }
                </GridItem>
                <GridItem xs={2} style={{ justifyContent: 'center' }}>
                    {
                        this.renderButton()
                    }
                </GridItem>
                <GridItem xs={12} style={{ opacity: 0.9, paddingLeft: 63 }} onClick={ this.goToViewPage }>
                    <span style={{ marginRight: 30 }}>Created by: { msa.uploaded_by.first_name + ' ' + msa.uploaded_by.last_name },</span>
                    { t('Signers') }: {
                        msa.signers.map(signer => signer.first_name + ' ' + signer.last_name + `(${signer.user_type})`).join(', ')
                    }
                </GridItem>

            </GridContainer>
        )
    }

    goToViewPage = () => {
        this.setState({ goToView: true });
    }

    renderStatus = () => {
        const { msa, t } = this.props;
        switch (msa.envelope_status) {
            case 'created':
                return t('Not sent to sign');
            case 'sent':
                return t('Not signed');
            case 'completed':
                return t('Signed');
            default:
                return t('Not signed');
        }
    }

    renderDate = () => {
        const { msa, t } = this.props;
        switch (msa.envelope_status) {
            case 'created':
            case 'sent':
                return t('Submitted on') + ' ' + moment(msa.created_at).format('DD/MM/YYYY');
            case 'completed':
                return t('Completed on') + ' ' + moment(msa.completed_at).format('DD/MM/YYYY');
            default:
                return t('Submitted on') + ' ' + moment(msa.created_at).format('DD/MM/YYYY');
        }
    }

    renderButton = () => {
        const { classes, msa, t } = this.props;
        const loginStatus = this.props.account.loginStatus;
        const userId = loginStatus.user.id;
        const signature = msa.meta.recipients.filter(signer => signer.user_id === userId)[0];
        switch (msa.envelope_status) {
            case 'created':
                return (
                    <Button className={ classes.btnView } onClick={ () => this.sendToDocuSign(msa.id) }>
                        { t('Send') }
                    </Button>
                );
            case 'completed':
                return (
                    <Button className={ classes.btnView } onClick={ () => window.location.href = apiUrl + `api/docusign/download/signed/msa/${msa.id}` }>
                        { t('View') }
                    </Button>
                )
            default:
                if (signature && signature['signed']) {
                    return (
                        <Button className={ classes.btnView } onClick={ () => window.location.href = apiUrl + `api/download/msa/${msa.id}` }>
                            { t('View') }
                        </Button>
                    );
                }

                if (typeof signature === 'undefined') {
                    return (
                        <Button className={ classes.btnView } onClick={ () => window.location.href = apiUrl + `api/download/msa/${msa.id}` }>
                            { t('View') }
                        </Button>
                    );
                }
                
                return (
                    <Button className={ classes.btnView } onClick={ () => this.goToSignPage(`/msa/sign/${signature.token}`) }>
                        { t('Sign') }
                    </Button>
                );

        }
    }
    goToSignPage = (url) => {
        this.setState({url});
    }

    sendToDocuSign = (msaId) => {
        const loginStatus = this.props.account.loginStatus;

        return fetch(apiUrl + 'api/docusign/msa/sender_view', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + loginStatus.token,
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: JSON.stringify({ msa_id: msaId })
        })
        .then(res => res.json())
        .then(result => {
            if (result['status'] === 'success') {
                window.location.href = result['url'];
            }
        });
    }
}

const mapStateToProps = (state) => {
    return {...state, account: state.account};
}

const mapDispatchToProps = {}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(msaItemStyle)(MsaItem)));