import commonStyle from 'assets/jss/commonStyle'

const LeftSidebarStyle = theme => ({
    ...commonStyle,
    /* sidebar: {
        width: '265px',
        minWidth: '265px',
        minHeight: '680px',
        maxHeight: '680px',
        background: 'white',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        marginRight: '30px'
    } */
    sidebar: {
        minWidth: '200px',
        height: '100%',
        overflowY: 'auto',
        backgroundColor: '#F9F9F9',
        fontSize: '0.9rem',
        '& $logoPlacer': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#F9F9F9',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '1.5rem',
            paddingTop: 24,
            paddingBottom: 24,
            paddingLeft: 44,
            paddingRight: 44,
        },
        '& h3': {
            color: '#7792a5',
            fontSize: '0.9rem',
            paddingLeft: '1rem'
        },
        '& .nav-list': {
            marginTop: '0.5rem',
            paddingBottom: '2rem',
            '& .nav-item': {
                '& .nav-item-inner': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    color: '#1E1E1E',
                    padding: '0.8rem 0.5rem 0.8rem 1rem',
                    alignItems: 'center',
                    borderBottom: '1px solid #E4E4E4',
                    '& img.active': {
                        display: 'none'
                    },
                    '&.active': {
                        backgroundColor: 'rgba(196, 196, 196, 0.1)',
                        '& img': {
                            display: 'none',
                        },
                        '& img.active': {
                            display: 'block'
                        },
                    },
                    '&.nav-item-expand': {
                        '& .nav-chevron': {
                            '& .icon': {
                                backgroundImage: 'url(/assets/img/newui/header/chv-down.png)',
                            }
                        },
                    },
                    '&:hover': {
                        color: '#1E1E1E',
                        textDecoration: 'none',
                        backgroundColor: 'rgba(196, 196, 196, 0.1)',
                        '& .nav-sublist-item': {
                            backgroundColor: 'rgba(196, 196, 196, 0.1)',
                        },
                    },
                    '& .nav-icon': {
                        flexGrow: '1',
                        maxWidth: 35,
                        minWidth: 35,
                        '& img': {
                            maxHeight: 20,
                            maxWidth: 25,
                        }
                    },
                    '& .nav-title': {
                        flexGrow: '4',
                        fontWeight: 'bold',
                    },
                    '& .nav-badge': {
                        minWidth: '26px',
                        '& div': {
                            display: 'flex',
                            width: 26,
                            height: 26,
                            borderRadius: '50%',
                            border: '3px solid rgba(255, 255, 255, 0.3)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                        },
                        '&.badge-yellow': {
                            '& div': {
                                backgroundColor: '#ffc700',
                                borderColor: '#5e5a28',
                            }
                        },
                        '&.badge-green': {
                            '& div': {
                                backgroundColor: 'RGB(57,181,74)',
                                borderColor: 'RGB(37,85,60)',
                            }
                        }
                    },
                    '& .nav-chevron': {
                        '& .icon': {
                            width: 20,
                            height: 20,
                            backgroundImage: 'url(/assets/img/newui/header/chv-left.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }
                    },
                    '& .break': {
                        flexBasis: '100%',
                        height: 0
                    },
                },
                '& .nav-sublist': {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '0.5rem',
                    '& .nav-sublist-item': {
                        display: 'flex',
                        fontWeight: 'bold',
                        justifyContent: 'flex-end',
                        paddingRight: '20px',
                        padding: '0.4rem 0',
                        '&.active': {
                            backgroundColor: 'rgba(196, 196, 196, 0.1)',
                        },
                        '&:hover': {
                            textDecoration: 'none',
                            backgroundColor: 'rgba(196, 196, 196, 0.1)',
                        }
                    }
                }
            },
        }
    },
    logoPlacer: {},
    avatarHover: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 15,
        '&:hover': {
            color: 'red',
            '& .logo-replacer-holder': {
                display: 'flex'
            }
        },
        '& .logo-replacer-holder': {
            display: 'none'
        }
    },
});
export default LeftSidebarStyle;