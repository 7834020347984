import React, {Component} from 'react'
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import matbuttonStyle from './matbuttonStyle.jsx';
class MatButton extends Component
{
    render()
    {
        const { classes, className, children, ...rest } = this.props;
        return (
            <Button { ...rest } className={[classes.button, className].join(' ')} onClick={ this.props.onClick } >
                { children }
            </Button>
        )
    }
}

export default withStyles(matbuttonStyle)(MatButton);