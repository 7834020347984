const searchInputStyle = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative'
    },
    input: {
        paddingLeft: 16,
        paddingRight: 30,
        width: '100%',
        height: 40,
        borderRadius: 40,
        backgroundColor: 'white',
        boxShadow: 'none',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 1)',
            fontWeight: 600,
        },
    },
    searchIcon: {
        position: 'absolute',
        right: 10,
        color: 'black',
    },
    closeIcon: {
        position: 'absolute',
        right: 25,
        cursor: 'pointer',
        color: '#a3a3a3'
    }
});

export default searchInputStyle;