import React, { Component } from 'react';
import GlobalProcessor from "components/GlobalProcessor/index";
import insuranceViewStyle from './insuranceViewStyle';
import { withStyles } from '@material-ui/styles';
import { withTranslation  } from 'react-i18next';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BaseInput from 'components/BaseInput/index.jsx';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { successConfirm } from "components/SuccessConfirm/index";
import { confirm } from "components/Confirm/index";
import { withRouter } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

function buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', '.pdf,.docx,.doc,image/*');

    return fileSelector;
}

class InsuranceView extends Component
{
    state = {
        insurance: {
            agent_name: '',
            agent_state: '',
            agent_email: '',
            expiry_date: moment().format('YYYY-MM-DD'),
            message: ''
        },
        errMsg: '',
        fileName: '',
        loading: true,
    }

    componentDidMount() {
        this.fileSelector = buildFileSelector();
        this.fileSelector.addEventListener('change',e => this.fileChanged(e));

        const insuranceId = this.props.match.params['insuranceId'];

        return fetch(apiUrl + 'api/insurance/get/by_id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: insuranceId })
        }).then(result => result.json())
        .then(result => {
            if (result['status']) {
                result['insurance']['expiry_date'] = moment.utc(result['insurance']['expiry_date']).format('YYYY-MM-DD');
                this.setState({ insurance: result['insurance'] });
            }
            this.setState({ loading: false });
        });
    }

    textChanged = (event, field) => {

    }

    fileChanged = (event) => {
        const file = this.fileSelector.files.item(0);
        this.setState({ fileName: file.name });
    }

    removeInsurance = () => {
        confirm('Confirm', 'Do you think the uploaded insurance document is not correct?', true, 'Yes', 'No').then(
            () => {
                let { insurance } = this.state;
                insurance.insurance_file = null;
                this.setState({ insurance });
            }
        )
    }

    renderAttachment = () => {
        const { classes } = this.props;
        const { insurance, fileName } = this.state;
        if (insurance.insurance_file !== null) {
            return (
                <div style={{ color: '#1291F9', marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    Uploaded Document
                    <Tooltip title="Download">
                        <Button>
                            <CloudDownloadIcon style={{ marginLeft: 15 }} onClick={ this.downloadClicked }  />
                        </Button>
                    </Tooltip>
                </div>
            )
        }

        return (
            <GridContainer style={{ marginTop: 20 }}>
                <GridItem xs={12}
                    className={ `${classes.uploadAttachmentContainer}` }
                    style={{cursor: 'pointer', color: '#ee2337'}}
                    onClick={ this.uploadClicked }
                >
                    Document is not uploaded
                </GridItem>
            </GridContainer>
        )
    }

    downloadClicked = () => {
        const { insurance } = this.state;

        window.location.href = apiUrl + "api/insurance/download/" + insurance.id
    }

    render() {
        const { classes, t } = this.props;
        const { insurance, errMsg, fileName } = this.state;
        return (
            <div className={ classes.container }>
                <GlobalProcessor />
                <div className={ classes.headerContainer }>
                    <div className={ classes.pageTitle }>
                        {t('Insurance Document')}
                    </div>
                </div>
                <div className={ classes.pageContentContainer } style={{ padding: 30 }}>
                    <div className={ classes.contentContainer }>
                        <GridContainer spacing={4}>
                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        {t('Insurance Agent Name')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                            small
                                            style={{ width: '80%', float: 'right' }}
                                            value={ insurance.agent_name }
                                            onChange={event => this.textChanged(event, 'agent_name')}
                                            readOnly={ true }
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem xs={4}>
                                        {t('Insurance Agent Public in State of')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                            small
                                            style={{ width: '80%', float: 'right' }}
                                            value={ insurance.agent_state }
                                            onChange={event => this.textChanged(event, 'agent_state')}
                                            readOnly={true}
                                        />
                                    </GridItem>
                                </GridContainer>
                                {
                                    this.renderAttachment()
                                }
                            </GridItem>
                            <GridItem xs={6}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        {t('Insurance Agent Email')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <BaseInput
                                                small
                                                style={{ width: '80%', float: 'right' }}
                                                value={ insurance.agent_email }
                                                onChange={event => this.textChanged(event, 'agent_email')}
                                                type="email"
                                                readOnly={true}
                                            />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem xs={4}>
                                        {t('Expiry Date')}
                                    </GridItem>
                                    <GridItem xs={8}>
                                        <TextField
                                            id="datepicker"
                                            label=""
                                            type="date"
                                            value={ insurance.expiry_date }
                                            onChange={ event => this.textChanged(event, 'expiry_date') }
                                            className={`datepicker common`}
                                            style={{ width: '80%', float: 'right' }}
                                            readOnly={true}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <div style={{ marginTop: 20, }}>
                                    Special message for Insurance agent
                                </div>
                                <div style={{ marginTop: 20, marginBottom: 20 }}>
                                    <textarea
                                        className={ classes.messageArea }
                                        value={ insurance.message }
                                        onChange={ event => this.textChanged(event, 'message') }
                                        readOnly={ true }
                                    >
                                    </textarea>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        )
    }

    verifyClicked = () => {
        confirm("Confirm", "Do you verify that the document and its expiry date is correct ?", true, 'Yes', 'No').then(
            async () => {
                const { loginStatus } = this.props.account;
                const { insurance } = this.state;
                const onboardToken = this.props.match.params['onboardToken'];
                let formData = new FormData();
                formData.append('insurance', JSON.stringify(insurance));
                formData.append('token', onboardToken);
                if (this.fileSelector.files.length) {
                    formData.append('file', this.fileSelector.files.item(0));
                }

                const endpoint = apiUrl + 'api/insurance/verify'
                return fetch(endpoint, {
                    method: "POST",
                    headers: {
                    Authorization: `Token ${loginStatus.token}`,
                    },
                    body: formData
                })
                .then(result => result.json())
                .then((result) => {
                    if (result['status']) {
                        successConfirm('Verified', 'Successfully verified the insurance document.', false)
                            .then(() => {
                                this.props.history.push('/insurances/list');
                        });
                    }
                });
            },
            () => {

            }
        );
    }

    uploadClicked = () => {
        this.fileSelector.click();
    }
}

const mapStateToProps = (state) => ({...state});
const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(insuranceViewStyle)(InsuranceView))));