import React, { Component } from 'react'

import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core'
import signUpPageStyle from './signUpPageStyle.jsx'
import Button from 'components/Button/index.jsx';
import RadioButton from 'components/RadioButton/index.jsx';
import BaseInput from 'components/BaseInput/index.jsx';
import LongCheckBox from 'components/LongCheckBox/index.jsx';
import NativeSelect from 'components/NativeSelect';
import MenuItem from '@material-ui/core/MenuItem';
import GlobalProcessor from 'components/GlobalProcessor/index';
import Footer from 'components/Footer/index.jsx';
import Autocomplete from 'react-autocomplete';
import Input from '@material-ui/core/Input';
import { userRegister, userCheck } from '../../redux/actions/accountActions';
import { getOrganizationList } from '../../redux/actions/commonActions';
import Cookies from 'js-cookie';

class SignUpPage extends Component {

    state = {
        customer: '',
        userTypeSelected: false,
        userType: 'VENDOR',
        errorMsg: '',
        isSubmitted: false,
        formData: {
            first_name: '',
            last_name: '',
            email: '',
            organization: '',
            password: '',
            repassword: '',
            user_type: '',
            type: ''
        },
        customers: []
    }

    constructor(props){
        super(props);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('token_expire');
        this.props.getOrganizationList();
    }

    componentDidMount = async() => {
        let customer = this.props.match.params.customer;
        this.setState({customer});
        this.setState({isSubmitted: false});

        const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
        return fetch(apiUrl + 'api/account/all_customers', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.status) {
                this.setState({ customers: res.data });
            }
        })
        .catch(err => {
            this.setState({otpPassed: false});
        });
    }

    matchCompanyList = () => {
        return this.props.orgList.data.list.filter(item => {
            if (this.state.formData.organization === "") return true;
            return (item.company.search(new RegExp(this.state.formData.organization, "i")) !== -1);
        });
    }

    handleClickNext = () => {
        this.setState({userTypeSelected: true});
    }

    handleClickAdmin = () => {
        this.setState({userType: 'ADMIN'});
    }

    handleClickVendor = () => {
        this.setState({userType: 'VENDOR'});
    }

    handleClickBuyer = () => {
        this.setState({userType: 'BUYER'});
    }

    handleClickBack = () => {
        this.setState({userTypeSelected: false});
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {}

        dataOject[valueType] = e.target.value;
        this.setState({ formData: Object.assign(this.state.formData, dataOject) });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        this.setState({isSubmitted: true});
        var signupData = this.state.formData;
        if (!signupData['first_name'].length) {
            this.setState({ errorMsg: 'First name is required.' });
            return;
        }

        if (!signupData['last_name'].length) {
            this.setState({ errorMsg: 'Last name is required.'});
            return;
        }

        if (!signupData['email'].length) {
            this.setState({ errorMsg: 'Email is required.'});
            return;
        }

        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(signupData['email'])) {
            this.setState({ errorMsg: 'Please, input a valid email.'});
            return;
        }

        signupData['customer'] = signupData['organization'];
        signupData['user_type'] = signupData['type'];

        let password = this.state.formData["password"];
        if (password.length < 8) {
            this.setState({'errorMsg': "Password should be longer than 8 letters"});
            return;
        } else if (!/[a-z]/.test(password)
            || !/[A-Z]/.test(password)
            || !/[0-9]/.test(password)
        ) {
            this.setState({'errorMsg': "Password should be consists of lowercase, uppercase letters and digits"});
            return;
        } else if (this.state.formData["password"] != this.state.formData["repassword"]) {
            this.setState({'errorMsg': "Please make sure Passwords match"});
            return;
        }

        if (!signupData['organization'].length) {
            this.setState({ errorMsg: 'Please, select a valid Organization.'});
            return;
        }

        if (typeof signupData['user_type'] === 'undefined' || !signupData['user_type'].length) {
            this.setState({ errorMsg: 'Please, select a valid user type.'});
        }

        this.setState({'errorMsg': ''});
        this.setState({'isBackendLoading': true});
        this.setState({isSubmitted: false});
        await this.props.userRegister(signupData);
        this.setState({'isBackendLoading': false});
        this.setState({isSubmitted: true});
    }

    submitResultMessage = () => {
        if (!this.state.isSubmitted) {
            return null;
        }

        const { classes } = this.props;
        if(typeof this.props.commonResult.result.status != 'undefined'){
            if(this.props.commonResult.result.status && this.state.isSubmitted){
                return(
                    <p className={`success-message`}>
                        Register Successfully, please check your email!
                    </p>
                );
            }else if(this.state.isSubmitted) {
                return(
                    <p className={ classes.errorMsg }>
                        {this.props.commonResult.result.message}
                    </p>
                );
            }
        } else if (this.state.errorMsg != '') {
            return(
                <p className={ classes.errorMsg }>
                    {this.state.errorMsg}
                </p>
            );
        } else {
            return null;
        }
    }

    renderSelectUserType = () => {
        const { classes } = this.props;
        const { userType } = this.state;
        if(this.state.userTypeSelected == true)
            return null;
        return(
            <div className={classes.container}>
                <h2 className={ classes.title }>
                    iPayFlow is a secure, easy way to pay and get paid. Sign up for free.
                </h2>
                <div className={ classes.radioButtonContainer }>
                    <RadioButton title="Admin" subTitle="Shop online or send and receive money. All without sharing your payment info." selected={ userType == 'ADMIN' } onClick={ this.handleClickAdmin } />
                </div>
                <div className={ classes.radioButtonContainer }>
                    <RadioButton title="Vendor" subTitle="Accept Paypal and all cards online or at the register. Send secure invoices to your customers." selected={ userType == 'VENDOR' } onClick={ this.handleClickVendor }  />
                </div>
                <div className={ classes.radioButtonContainer }>
                    <RadioButton title="Buyer" subTitle="Shop Online or send and receive money. All without sharing your payment info." selected={ userType == 'BUYER' }  onClick={ this.handleClickBuyer } />
                </div>
                <div className={ classes.btnNextContainer }>
                    <Button className={ `px-4 py-2 rounded-lg bg-blue-700 text-white` } onClick={ this.handleClickNext } >{/* classes.btnNext */}
                        Next
                    </Button>
                </div>
            </div>
        )
    }


    // Get current sub category list
    getOrganizationSelect = () => {
        const { classes } = this.props;
        if (typeof this.props.initialResponse.data != 'undefined' && this.props.initialResponse.data.status) {
            if (typeof this.state.formData.category == 'undefined' || this.state.formData.category == null || this.state.formData.category == '') return null;
            var mainCateg = this.state.formData.category;
            var mainCategGroup = this.props.initialResponse.data.categGroup;
            var categGroup = [];
            mainCategGroup.forEach((item, key) => {
                if (item.categ.name == mainCateg) categGroup = item['subCateg'];
            });
            var retVal = categGroup.map((item, key) => ({
                label: item.name,
                value: item.name
            }));
            if (typeof retVal == "undefined" || retVal == null) {
                retVal = [];
            }
            return retVal;
        } else {
            return [];
        }
    }

    renderSignUpForm = () => {

        const { classes } = this.props;
        if(this.state.userTypeSelected == false)
            return null;
        return(
            <div className={classes.signUpFormContainer} style={{marginTop: '1rem', marginBottom: '1rem', paddingTop: '2rem', paddingBottom: '1rem', paddingLeft: '3rem', paddingRight: '3rem'}}>
                <div className={ classes.formTitle }>
                    See for your self why millions of people love iPayFlow - Sign up for free
                </div>
                <form action="#" method="POST" id="signupForm" name="signupForm" className={ classes.signUpForm } onSubmit={e => this.handleSubmit(e)}>
                    <input type="hidden" name="user-type" value={ this.state.userType } />
                    {/* <BaseInput name="full-name" placeholder="Legal Full Name" className={ classes.input}  onChange={e => this.handleChangeValue(e, 'name')} /> */}
                    <BaseInput required name="first-name" placeholder="First Name" className={ classes.input}  onChange={e => this.handleChangeValue(e, 'first_name')} />
                    <BaseInput required name="last-name" placeholder="Last Name" className={ classes.input}  onChange={e => this.handleChangeValue(e, 'last_name')} />
                    <BaseInput required name="email" placeholder="Your Email"  className={ classes.input} type="email"  onChange={e => this.handleChangeValue(e, 'email')} />

                    {/* <CustomAutocomplete required display={ this.state.userType == 'VENDOR'? true: true} placeholder="Your Organiztion" onChange={e => this.handleChangeValue(e, 'organization')} /> */}

                    <div className={ `Autocomplete-Wrapper register-form-input`} >
                        <Autocomplete
                            inputProps={{
                                id: 'organization-autocomplete',
                                className: 'organization-autocomplete',
                                placeHolder: 'Your Organization'
                            }}
                            getItemValue={(item) => item.label}
                            items={this.matchCompanyList()}
                            getItemValue={(item) => item.company}
                            renderItem={(item, isHighlighted) =>
                                <div style={{ padding: '0.5rem 0.2rem', background: isHighlighted ? '#f5f5f5' : 'white' }}>
                                    {item.company}
                                </div>
                            }
                            value={this.state.formData.organization}
                            onChange={(e, val) => this.handleChangeValue(val, 'organization')}
                            onSelect={(val) => this.handleChangeValue(val, 'organization')}
                        />
                    </div>


                    <BaseInput required name="password" placeholder="Create Your Password" className={ classes.input} type="password"  onChange={e => this.handleChangeValue(e, 'password')} />
                    <BaseInput required name="repassword" placeholder="Retype Your Password" className={ classes.input} type="password"  onChange={e => this.handleChangeValue(e, 'repassword')} />
                    <div className={ classes.checkboxContainer }>
                        <LongCheckBox />
                    </div>
                    <div className={ classes.actionButtonContainer }>
                        <input type="button" className={ `px-4 py-2 bg-red-500 hover:bg-red-600 text-white h-10 rounded-lg mr-4 focus:outline-none` } value="Back" onClick={ this.handleClickBack } />
                        <input type="submit" className={ `px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white h-10 rounded-lg` } value="Next" />
                    </div>
                    {this.submitResultMessage()}
                </form>
            </div>
        )
    }

    renderRedirect = () => {
      const loginStatus = this.props.account.loginStatus;
      if(loginStatus.isLogedIn){
        var userInfo = this.props.account.loginStatus.user;
        if(userInfo.profile.user_type == 'ADMIN'){
            return <Redirect to='/admin/setup' />
        }else if(userInfo.profile.user_type == 'BUYER'){
            return <Redirect to='/buyer/buy-request' />
        }else{
            return <Redirect to='/vendor/raise-invoice/list' />
        }
      }
    }

    render(){
        const { classes } = this.props;
        const { userTypeSelected } = this.state;
        const mainContainerClass = userTypeSelected ? classes.pageContainer2 : classes.pageContainer;
        const logImage = userTypeSelected ? "/assets/img/Logo_color.png": "/assets/img/Logo_white.png";

        return (
            <div className={ mainContainerClass } style={{ flexDirection: 'row' }}>
                <GlobalProcessor />
                { this.renderRedirect() }
                <div className={ classes.formContainer }>
                    <div className={ classes.form }>
                        <img src="/assets/img/newui/header/blue_logo.png" style={{ width: 114, height: 43, marginBottom: 20 }} />
                        <div className={ classes.signInLabel }>
                            Create an account
                        </div>
                        <Input variant="outlined" required placeholder="First Name" name="first-name" onChange={e => this.handleChangeValue(e, 'first_name')} classes={{ root: classes.inputRoot, input: classes.input }} />
                        <Input variant="outlined" required placeholder="Last Name"  name="last-name" onChange={e => this.handleChangeValue(e, 'last_name')} classes={{ root: classes.inputRoot, input: classes.input }} />
                        <Input variant="outlined" required placeholder="Email" type="email" name="email" onChange={e => this.handleChangeValue(e, 'email')} classes={{ root: classes.inputRoot, input: classes.input }} />
                        <Input variant="outlined" required placeholder="Create Your Password" type="password" classes={{ root: classes.inputRoot, input: classes.input }} name="password" onChange={e => this.handleChangeValue(e, 'password')} />
                        <Input variant="outlined" required placeholder="Retype Your Password" type="password" classes={{ root: classes.inputRoot, input: classes.input }} name="password" onChange={e => this.handleChangeValue(e, 'repassword')} />
                        <div style={{ marginBottom: 17, width: '100%' }} className={ classes.selectWrapper }>
                            <NativeSelect
                                onChange={e => this.handleChangeValue(e, 'organization')}
                                value={ this.state.formData.organization }
                                name="organization"
                                displayEmpty
                            >
                                <MenuItem value='' disabled style={{ display: 'none' }}>
                                    Organization
                                </MenuItem>
                                {
                                    this.state.customers.map(item => <MenuItem value={ item.permalink } key={ item.permalink }>{ item.name }</MenuItem>)
                                }
                            </NativeSelect>
                        </div>
                        <div style={{ marginBottom: 17, width: '100%' }} className={ classes.selectWrapper }>
                            <NativeSelect
                                value={this.state.formData.type}
                                onChange={e => this.handleChangeValue(e, 'type')}
                                name="type"
                                displayEmpty
                            >
                                <MenuItem value='' disabled style={{ display: 'none' }}>
                                    User Type
                                </MenuItem>
                                <MenuItem value="VENDOR">Vendor</MenuItem>
                                <MenuItem value="BUYER">Buyer</MenuItem>
                                <MenuItem value="ADMIN">Admin</MenuItem>
                            </NativeSelect>
                        </div>

                        {this.submitResultMessage()}

                        <Button className={ classes.btnLogin } onClick={ this.handleSubmit } style={{ width: 161 }}>
                            Create Account
                        </Button>
                        <div className={ classes.forgotPasswordLabel }>
                            Already Have an Account, then <Link to={`/login`}>Log In </Link>
                        </div>
                        <div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 22, paddingBottom: 22 }}>
                            <hr style={{ width: 150, borderWidth: 1, height: 1 }} />
                        </div>
                        <div className={ classes.termsLabel }>
                            By clicking Sing In, you agree to our <Link to={`/terms`}>Terms</Link> and have read and acknowledge our <Link to={`/privacy`}>Privacy Statement.</Link> <br/>
                        </div>
                    </div>
                </div>
                <div className={ classes.sideBanner }>
                    <img src="/assets/img/newui/login/wave-vector.svg" />
                </div>
            </div>
        )
        return (
            <div className={ mainContainerClass }>
                <GlobalProcessor />
                {this.renderRedirect()}
                <div className={ classes.contentContainer }>
                {
                    this.renderSelectUserType()
                }
                {
                    this.renderSignUpForm()
                }
                </div>
                <Footer theme={ userTypeSelected ? 'white' : 'white' } />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    commonResult: state.commonResult,
    orgList: state.orgList,
    account: state.account
});

const mapDispatchToProps = {userRegister, userCheck, getOrganizationList}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(signUpPageStyle)(SignUpPage));