import commonStyle from 'assets/jss/commonStyle'

const msaUploadPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        paddingTop: 30,
        paddingBottom: 30,
    },
    contentContainer: {
        display: 'flex',
        flexGrow: 1,
        marginTop: 20,
        maxHeight: 'calc(100% - 210px)',
        paddingLeft: 30,
        paddingRight: 30,
    },
    topWrapper: {
        display: 'flex',
        marginLeft: 30,
        marginRight: 30,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    toolboxContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#FDFDFD',
        padding: '20px 20px',
    },
    ctrlIcon: {
        marginRight: 10,
        backgroundColor: '#F4E19D',
        borderRadius: 4,
        padding: 2,
    },
    pdfContainer: {
        display: 'flex',
        flex: 5,
        padding: '0px 20px',
        paddingRight: 0,
        overflow: 'auto',

        /* '& canvas': {
            width: '100% !important',
            height: 'auto !important'
        } */
    },
    ctrlContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 16,
        margin: '5px 0px'
    },
    btnSend: {
        textAlign: 'center',
        background: '#1D8ACF',
        color: 'white',
        paddingTop: '6px',
        paddingBottom: '6px',
        width: '120px',
        display: 'inline-block',
    },
    modalContainer: {
        width: '600px',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '20px 25px'
    },
    modalWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalBtnConfirm: {
        background: '#1D8ACF',
        borderRadius: 30,
        fontSize: 16,
        color: 'white',
        padding: '5px 23px',
        marginTop: '15px',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});
export default msaUploadPageStyle;