import { LOGIN_USER, LOGIN_FORGOT, PWD_RESET } from './types';
import * as Type from './types';
import { parse } from 'path';
import Cookies from 'js-cookie';

var moment = require('moment');

export const getMyAccountInfo = () => (dispatch, getState) => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/account/get_info', {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + loginStatus.token,
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
  })
    .then(res => {
      dispatch({
        type: Type.CM_ERROR,
        payload: res
      });
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        dispatch({
          type: Type.PROFILE_INFO,
          payload: {
            status: true,
            isLogedIn: true,
            token: result['token'],
            message: result['message'],
            user: result['user']
          }
        });
      }else{
        dispatch({
          type: Type.PROFILE_INFO,
          payload: {
            status: true,
            isLogedIn: false,
            message: result['message'],
            user: null
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: Type.PROFILE_INFO,
        payload: {
          status: false,
          isLogedIn: false,
          message: "Backend error for now!",
          user: null
        }
      });
    });
}

export const updateCustomerLogo = (logoData) => (dispatch, getState) => {

  const account = getState()['account'];
  const loginStatus = account.loginStatus;
  loginStatus['user']['customer']['logo'] = logoData;

  dispatch({
      type: Type.LOGIN_USER,
      payload: loginStatus
  });

  return;

}

export const userLogin = (postData) => dispatch => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  return fetch(apiUrl + 'api/account/login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Cookie': '',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(postData)
  })
    .then(res => {
      if (res.status >= 400) {
        console.log(res);
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
      if (result.status && !result.needChangePassword) {
        var expMoment = moment().add(12, 'hours');
        window.localStorage.setItem('token', result['token']);
        window.localStorage.setItem('token_expire', expMoment.format('YYYY-MM-DD hh:mm:ss a'));
        window.localStorage.setItem('user', JSON.stringify(result['user']));
        dispatch({
          type: LOGIN_USER,
          payload: {
            status: true,
            isLogedIn: true,
            needChangePassword: false,
            token: result['token'],
            message: result['message'],
            user: result['user']
          }
        });
      } else if (result.status && result.needChangePassword) {
        dispatch({
          type: LOGIN_USER,
          payload: {
            status: true,
            isLogedIn: true,
            needChangePassword: true,
            token: result['token'],
            message: result['message'],
            user: result['user']
          }
        });
      } else {
        dispatch({
          type: LOGIN_USER,
          payload: {
            status: true,
            isLogedIn: false,
            needChangePassword: false,
            message: result['message'],
            user: null
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: LOGIN_USER,
        payload: {
          status: false,
          isLogedIn: false,
          message: "Backend error for now!",
          user: null
        }
      });
    });
};

export const userForgotPassword = (postData) => dispatch => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  return fetch(apiUrl + 'api/account/forgotPassword', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Cookie': '',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(postData)
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        dispatch({
          type: LOGIN_FORGOT,
          payload: {
            status: true
          }
        });
      }else{
        dispatch({
          type: LOGIN_FORGOT,
          payload: {
            status: false
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FORGOT,
        payload: {
          status: false
        }
      });
    });
};


export const userResetPassword = (postData) => dispatch => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  return fetch(apiUrl + 'api/account/resetPassword', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Cookie': '',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(postData)
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        var expMoment = moment().add(12, 'hours');
        window.localStorage.setItem('token', result['token']);
        window.localStorage.setItem('token_expire', expMoment.format('YYYY-MM-DD hh:mm:ss a'));
        window.localStorage.setItem('user', JSON.stringify(result['user']));
        dispatch({
          type: LOGIN_USER,
          payload: {
            status: true,
            token: result['token'],
            message: '',
            user: result['user']
          }
        });
      }else{
        dispatch({
          type: LOGIN_USER,
          payload: {
            status: true,
            isLogedIn: false,
            message: result['message'],
            user: null
          }
        });
      }

      /* if(result.status){
        dispatch({
          type: PWD_RESET,
          payload: {
            status: true
          }
        });
      }else{
        dispatch({
          type: PWD_RESET,
          payload: {
            status: false
          }
        });
      } */
    })
    .catch(err => {
      dispatch({
        type: PWD_RESET,
        payload: {
          status: false
        }
      });
    });
};

export const userRegister = (postData) => dispatch => {
  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  return fetch(apiUrl + 'api/account/register', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'Cookie': '',
      'X-CSRFToken': Cookies.get('csrftoken')
    },
    body: JSON.stringify(postData)
  })
    .then(res => {
      if (res.status >= 400) {
        console.log(res);
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        throw new Error("Backend Error with Status \"" + res.statusText + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        var expMoment = moment().add(12, 'hours');
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_RESULT,
        payload: {
          'status': false,
          'message': 'Backend Error Occured'
        }
      });
    });
};


export const userLogout = () => (dispatch, getState) => {

  const account = getState()['account'];
  const customerInfo = account.loginStatus.user.customer;

  window.localStorage.removeItem('token');
  window.localStorage.removeItem('token_expire');
  window.localStorage.removeItem('user');

  dispatch({
    type: LOGIN_USER,
    payload: {
      status: true,
      isLogedIn: false,
      token: '',
      message: '',
      user: null
    }
  });

  return customerInfo;
};

export const userCheck = () => dispatch => {
  var token = window.localStorage.getItem('token');
  var token_expire_str = window.localStorage.getItem('token_expire');
  var user = window.localStorage.getItem('user');
  var token_expire = moment(token_expire_str, 'YYYY-MM-DD hh:mm:ss a', true);

  console.log("User Check  Call")

  if(token_expire_str != null && user != null && token_expire != null){
    if(token_expire < moment()){

      window.localStorage.removeItem('token');
      window.localStorage.removeItem('token_expire');
      window.localStorage.removeItem('user');

      console.log("Logout Call")

      dispatch({
        type: LOGIN_USER,
        payload: {
          status: true,
          isLogedIn: false,
          token: '',
          message: '',
          user: null
        }
      });
    }else{
      dispatch({
        type: LOGIN_USER,
        payload: {
          status: true,
          isLogedIn: true,
          token: token,
          message: "Logged in already",
          user: JSON.parse(user)
        }
      });
    }
  }else{
    console.log("User Check  Failed")

    window.localStorage.removeItem('token');
    window.localStorage.removeItem('token_expire');
    window.localStorage.removeItem('user');

    console.log("Logout Call")

    dispatch({
      type: LOGIN_USER,
      payload: {
        status: true,
        isLogedIn: false,
        token: '',
        message: '',
        user: null
      }
    });
  }
};

export const updateProfile = (postData) => (dispatch, getState) => {

  const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];

  return fetch(apiUrl + 'api/account/update_profile', {
      method: 'PUT',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify(postData)
  })
  .then(res => {
    dispatch({
      type: Type.CM_ERROR,
      payload: res
    });
    if (res.status >= 400) {
      throw new Error("Backend Error with Status \"" + res.statusText + "\"");
    }
    return res.json();
  })
  .then(result => {
      if(result.status){
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }else{
        dispatch({
          type: Type.CM_RESULT,
          payload: result
        });
      }
  })
  .catch(err => {
    dispatch({
      type: Type.CM_RESULT,
      payload: {
        'status': false,
        'message': 'Backend Error Occured'
      }
    });
  });
};

// Buyer List
export const getVendorUserList = () => (dispatch, getState) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    const loginStatus = getState()['account']['loginStatus'];
    return fetch(apiUrl + 'api/account/get_vendor_userlist', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

// Buyer List
export const addVendorUserList = (userIds) => (dispatch, getState) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    const loginStatus = getState()['account']['loginStatus'];
    return fetch(apiUrl + 'api/account/get_vendor_userlist', {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + loginStatus.token,
        'content-type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      },
      body: JSON.stringify({
        users: userIds
      })
    })
      .then(res => {
        dispatch({
          type: Type.CM_ERROR,
          payload: res
        });
        if (res.status >= 400) {
          throw new Error("Backend Error with Status \"" + res.status + "\"");
        }
        return res.json();
      })
      .then(result => {
        if(result.status){
          var expMoment = moment().add(12, 'hours');
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }else{
          dispatch({
            type: Type.CM_RESULT,
            payload: result
          });
        }
      })
      .catch(err => {
        dispatch({
          type: Type.CM_RESULT,
          payload: {
            'status': false,
            'message': 'Backend Error Occured'
          }
        });
      });
};

//Update user profile
export const saveProfileChanges = (profile) => (dispatch) =>  {
    dispatch({
        type: 'SAVE_PROFILE_CHANGES',
        payload: profile
    });
}

//Update user profile
export const saveAvatar = (data) => (dispatch) =>  {
    dispatch({
        type: 'SAVE_AVATAR',
        payload: data
    });
}

//Update user profile
export const clearErrorMsg = () => (dispatch) =>  {
    dispatch({
        type: 'CLEAR_ERR_MSG'
    });
}

// Set user profile
export const setUserProfile = (data) => (dispatch) =>  {
  dispatch({
      type: 'SET_USER_PROFILE',
      payload: data
  });
}

// Refresh user profile
export const refreshUserProfile = () => (dispatch, getState) =>  {
  const apiUrl = process.env.NODE_ENV === 'production' ? 
    (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) :
    process.env.REACT_APP_DEV_BACKEND;
  const loginStatus = getState()['account']['loginStatus'];
  return fetch(apiUrl + 'api/account/profile', {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + loginStatus.token,
      'content-type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken')
    }
  })
    .then(res => {
      if (res.status >= 400) {
        throw new Error("Backend Error with Status \"" + res.status + "\"");
      }
      return res.json();
    })
    .then(result => {
      if(result.status){
        window.localStorage.setItem('user', JSON.stringify(result.user));
        dispatch({
          type: 'SET_USER_PROFILE',
          payload: result.user
        });
      }
    })
    .catch(err => {
      dispatch({
        type: Type.CM_RESULT,
        payload: {
          'status': false,
          'message': ''
        }
      });
    });
}