import commonStyle from 'assets/jss/commonStyle'

const certificatesPageStyle = theme => ({
    ...commonStyle,
    setupPageContainer: {
        background: "url('/assets/img/bgMain.png')",
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
        width: '100%',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    formContainer: {
        position: 'relative',
        background: 'white',
        width: '100%',
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        display: 'flex',
        padding: '40px 30px 20px 30px',
        flexDirection: 'column',
        color: '#707070'
    },
    uploadAttachment: {
        color: '#1D8ACF',
        fontSize: 20,
    },
    addIcon: {
        color: '#1D8ACF',
        marginLeft: '10px',
    },

    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1',
        maxHeight: '66px',
        paddingTop: 20,

        '& $SearchWrapper': {

        },
        '& $listFilter': {
            fontSize: '1rem',
            color: '#a30c0c',
            justifyContent: 'flex-end',
            display: 'flex',
            '& .listFilterDropDown': {
                minWidth: '3.5rem',
                marginLeft: '0.6rem'
            }
        },
    },
    coiContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: '10',
        position: 'relative',
        background: 'white',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 7,
        overflow: 'hidden',
        paddingBottom: 50,
        marginBottom: 100,
        '& .itemHeader': {
            marginTop: '0',
            marginBottom: '0',
            paddingTop: 40,
            padding: '10px 0.5rem',
            fontSize: '1rem',
            fontWeight: 600,
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            '& > div': {
                display: 'flex',
                flex: 2,
                justifyContent: 'center',
                alignItems: 'center',
                '&.date': {
                    fontSize: '90%',
                    lineHeight: '120%'
                }
            }
        },
        '& .itemList': {
            padding: '0 0',
            marginTop: '0',
            marginBottom: '0',
            fontSize: '1rem',
            borderLeft: '1px solid #eaeaea',
            borderRight: '1px solid #eaeaea',
            background: 'white',
            '& .itemLabel': {
                padding: '0 0.5rem',
                marginTop: '0',
                marginBottom: '0',
                backgroundColor: '#F2F2F2',
                fontSize: 16,
                '& .type': {
                },
                '& .value': {
                    color: '#1D8ACF',
                    marginLeft: 8,
                },
                '& div': {
                    paddingTop: 5,
                    paddingBottom: 5,
                }
            },
            '& .itemData': {
                padding: '0 0.5rem',
                marginTop: '0',
                marginBottom: '0',
                paddingTop: 8,
                paddingBottom: 8,
                '& > div': {
                    display: 'flex',
                    flex: 2,
                    alignItems: 'center',
                    wordBreak: 'break-word',
                    justifyContent: 'center',
                    fontSize: 12,

                }
            }
        }
    },

    NoList: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFC700',
        fontSize: '1.3rem',
        marginTop: 30,
        marginBottom: 30,
    },

    certificateFormContainer: {
        padding: '15px 30px',
        border: '1px solid #a0a0a0',
        margin: '15px 40px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        borderRadius: '3px',
        border: '1px solid #9e9e9e',
        padding: '3px 0px',
        fontSize: '14px',
        boxShadow: 'none',
        width: '100%',
        '&:focus': {
            border: '1px solid #9e9e9e',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
        '&[disabled]': {
            border: '1px solid #fdfdfd',
            background: '#dedede',
            cursor: 'not-allowed',
            outline: 'none !important',
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)',
        },
    },
    formLabel: {
        width: '100%',
        fontSize: '14px',
        color: '#000',
        margin: '5px 0px',
    },
    fileInput: {
        fontSize: '14px',
        width: '100%'
    },
    iconRequired: {
        color: '#A30C0C',
    },
    certificateContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #eaeaea',
        padding: '5px 10px',
    },
    certificateViewBtn: {
        fontWeight: 700,
        fontSize: '15px',
        color: '#1D8ACF !important',
        cursor: 'pointer',
        textShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)'
    },


    /* Unnecessary Classes, just for react bind class */
    listFilter: {},
    searchWrapper: {
        '& div': {
            width: '100%'
        }
    },
    verifiedLabel: {
        background: 'rgba(0, 153, 255, 0.2)',
        borderRadius: 7,
        padding: '2px 12px',
        color: '#1291F9',
    },
    unverifiedLabel: {
        background: 'rgba(255, 0, 46, 0.2)',
        borderRadius: 7,
        padding: '2px 12px',
        color: '#EB5757',
    }
});
export default certificatesPageStyle;