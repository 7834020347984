import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import loadingStyle from './loadingStyle'

class Loading extends Component
{
    render() {
        const { classes, uploading } = this.props;
        if (!uploading) {
            return null;
        }
        return (
            <div className={ classes.overlay }>
                <CircularProgress className={ classes.progress } size={ 60 } classes={{ svg: classes.svg }}/>
            </div>
        )
    }
}

export default withStyles(loadingStyle)(Loading);