import React, { Component } from 'react'
import { userLogout } from '../../redux/actions/accountActions';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

class LogoutPage extends Component
{
    state = {
        loginUrl: ''
    }

    redirectToLogin = () => {
        if (this.state.loginUrl.length > 0) {
            return <Redirect to={ this.state.loginUrl } />
        }
        
    }

    async componentDidMount() {
        await this.props.userLogout();

        this.setState({ loginUrl: '/login' });
    }

    render() {
        return (
            <div>
                { this.redirectToLogin() }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ...state,
    }
}

const mapDispatchToProps = { userLogout }
export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);