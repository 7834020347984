const buttonStyle = theme => ({
    button: {
        fontSize: '1rem',
        textAlign: 'center',
        borderRadius: 7,
        opacity: '0.85',
        '&:hover': {
            opacity: '1',
            cursor: 'pointer'
        },
        border: 'none',
        '&:active': {
            outline: 'none',
            border: 'none'
        },
        '&[disabled]': {
            cursor: 'not-allowed'
        },
        '&[disabled]:hover': {
            opacity: '0.85',
        }
    }
});
export default buttonStyle;