import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import GlobalProcessor from "components/GlobalProcessor/index";
import Footer from "components/Footer/index.jsx";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import leanDocumentListPageStyle from "./leanDocumentListPageStyle";

import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SearchInput from "components/SearchInput/SearchInput";
import moment from "moment";
import Loading from 'components/Loading';
import _ from "lodash";
import { withTranslation  } from 'react-i18next';
import NativeSelect from 'components/NativeSelect';

var numeral = require("numeral");

class LeanDocumentListPage extends Component {
  state = {
    isDataLoading: true,
    loginInfo: {
      user: {
        first_name: "",
        last_name: "",
        profile: {
          user_type: ""
        }
      }
    },
    type: 'final',
    groupByFilter: "vendor",
    query: "",
    formExpanded: false,
    canUpload: false,
    title: "",
    certificates: [],
    filteredData: [],
    goToUpload: false
  };

  constructor(props) {
    super(props);
    this.state.loginInfo = {
      ...this.state.loginInfo,
      ...this.props["account"]["loginStatus"]
    };
  }

  componentDidMount() {
    this.fetchCertificates();
    const userType =
      typeof this.state.loginInfo.user != "undefined" &&
      this.state.loginInfo.user != null
        ? this.state.loginInfo.user.profile.user_type
        : "";

    if (userType !== "ADMIN") {
      this.setState({ groupByFilter: "date_created" });
    }
  }

  render() {
    const { classes, t } = this.props;
    const { formExpanded, goToUpload, query } = this.state;

    const userType =
      typeof this.state.loginInfo.user != "undefined" &&
      this.state.loginInfo.user != null
        ? this.state.loginInfo.user.profile.user_type
        : "";

    if (goToUpload) {
      return <Redirect to="/certificates/upload" />;
    }

    return (
        <div className={ classes.container }>
            <GlobalProcessor />
            <div className={ classes.headerContainer }>
                <div className={ classes.pageTitle }>
                    {t('Lien Release Documents')}
                </div>
            </div>
            <GridContainer
                spacing={0}
                style={{ marginBottom: "13px", marginTop: 20 }}
            >
                {userType == "ADMIN" ? (
                <GridItem sm={3} ></GridItem>
                ) : (
                <GridItem sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                    className={classes.uploadAttachment}
                    onClick={this.uploadIconClicked}
                    >
                    {t('Click to complete Lien')}
                    </span>
                    <AddCircleOutlineIcon
                    className={classes.addIcon}
                    onClick={this.uploadIconClicked}
                    />
                </GridItem>
                )}



                <GridItem sm={9} className={classes.listFilter} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ marginRight: 30, }}>
                      <SearchInput
                          value={query}
                          onChange={event => this.queryChanged(event.target.value)}
                          onClose={() => this.queryChanged("")}
                      />
                    </div>

                    <NativeSelect
                      color='blue'
                      value={this.state.groupByFilter}
                      onChange={this.handleChangeGroupBy}
                      style={{ width: 200, marginRight: 40 }}
                    >
                        {userType == "ADMIN" ? (
                        <MenuItem value={"vendor"}>{t('Vendor Name')}</MenuItem>
                        ) : null}
                        <MenuItem value={"notary"}>{t('Notary Name')}</MenuItem>
                        <MenuItem value={"date_created"}>{t('Date of Created')}</MenuItem>
                        <MenuItem value={"date_completed"}>
                        {t('Date of Completed')}
                        </MenuItem>
                        <MenuItem value={"sign_email"}>{t('Signing email')}</MenuItem>
                        <MenuItem value={"status"}>{t('Status')}</MenuItem>
                    </NativeSelect>
                    <span style={{ color: '#1D8ACF', marginRight: 10, fontSize: 15 }}>{t('Type')}</span>
                    <NativeSelect
                        color="blue"
                        value={this.state.type}
                        onChange={this.docTypeChanged}
                        style={{ width: 150 }}
                    >
                        <MenuItem value={"final"}>{t('Final Lien')}</MenuItem>
                        <MenuItem value={"interim"}>{t('Interim Lien')}</MenuItem>
                    </NativeSelect>
                </GridItem>
            </GridContainer>
            {this.renderCertificates()}
        </div>

    )
    /**
     * View Render
     */
    return (
      <div className={classes.setupPageContainer}>
        <GlobalProcessor />
        <div className={classes.contentContainer}>
          <div className={classes.pageContent}>
            <div className={classes.formContainer}>
              <GridContainer
                className={classes.uploadIconContainer}
                spacing={0}
                style={{ marginBottom: "13px" }}
              >
                {userType == "ADMIN" ? (
                  <GridItem sm={12} md={12} lg={6}></GridItem>
                ) : (
                  <GridItem sm={12} md={12} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      className={classes.uploadAttachment}
                      onClick={this.uploadIconClicked}
                    >
                      {t('Click to complete Lien')}
                    </span>
                    <AddCircleOutlineIcon
                      className={classes.addIcon}
                      onClick={this.uploadIconClicked}
                    />
                  </GridItem>
                )}
                <GridItem md={7} lg={3} xl={4} className="SearchWrapper" style={{ display: 'flex', alignItems: 'center' }}>
                  <SearchInput
                    value={query}
                    onChange={event => this.queryChanged(event.target.value)}
                    onClose={() => this.queryChanged("")}
                  />
                </GridItem>
                <GridItem md={5} lg={3} xl={2} className={classes.listFilter} style={{ display: 'flex', alignItems: 'center' }}>
                  Group By
                  <Select
                    className="listFilterDropDown"
                    value={this.state.groupByFilter}
                    onChange={this.handleChangeGroupBy}
                  >
                    {userType == "ADMIN" ? (
                      <MenuItem value={"vendor"}>{t('Vendor Name')}</MenuItem>
                    ) : null}
                    <MenuItem value={"notary"}>{t('Notary Name')}</MenuItem>
                    <MenuItem value={"date_created"}>{t('Date of Created')}</MenuItem>
                    <MenuItem value={"date_completed"}>
                      {t('Date of Completed')}
                    </MenuItem>
                    <MenuItem value={"sign_email"}>{t('Signing email')}</MenuItem>
                    <MenuItem value={"status"}>{t('Status')}</MenuItem>
                  </Select>
                </GridItem>
              </GridContainer>
              {this.renderCertificates()}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  docTypeChanged = async (event) => {
    const certificates = this.origianlCertificates.filter(doc => doc.type === event.target.value);
    await this.setState({ type: event.target.value, certificates });
    this.applyFilters();
  }

  renderCertificates = () => {

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;

    const { certificates, filteredData } = this.state;
    const { classes, t } = this.props;

    if (this.state.isDataLoading) {
        return (
            <Loading uploading={ this.state.isDataLoading }/>
        );
    }

    if (typeof certificates === "undefined" || certificates.length < 1) {
      return (
        <div className={`${classes.coiContainer} ${classes.NoList}`}>
          <ErrorOutlineIcon
            style={{ fontSize: "2.8rem", marginRight: "1rem" }}
          />
          {t("There isn't any COI for now!")}
        </div>
      );
    }

    return (
      <div className={classes.coiContainer}>
        {/* Table Header */}
        <Grid container className="itemHeader" spacing={16}>
          <div style={{ flex: 3 }}>
            {t('Vendor')}
          </div>
          <div style={{ flex: 3 }}>
            {t('Notary')}
          </div>
          <div>
            {t('Cond. LIEN')}
          </div>
          <div>
            {t('Uncond. LIEN')}
          </div>
          <div>
            {t('Status')}
          </div>
          <div style={{ flex: 3 }}>
            {t('Completed Date')}
          </div>
          <div style={{ flex: 3 }}>
            {t('Creation Date')}
          </div>
          <div style={{ minWidth: 40, maxWidth: 40 }}>

          </div>
        </Grid>

        {/* Table List */}
        <Grid container spacing={16} className="itemList">
          {filteredData.map((groupData, key) => {
            return (
              <Grid key={key} container>
                <Grid key={key} container className="itemLabel" spacing={16}>
                  <Grid item sm={12} md={12}>
                    <span className="type">
                      {groupData.key.type == "vendor"
                        ? t("Vendor")
                        : groupData.key.type == "notary"
                        ? t("Notary")
                        : groupData.key.type == "date_created"
                        ? t("Created At")
                        : groupData.key.type == "date_completed"
                        ? t("Completed At")
                        : groupData.key.type == "sign_email"
                        ? t("Signing email")
                        : groupData.key.type == "status"
                        ? t("Status")
                        : ""}
                      :
                    </span>{" "}
                    <span className="value">
                      {groupData.key.type == "date_created"
                        ? moment(groupData.key.value).format("MMM DD, YYYY")
                        : groupData.key.type == "date_completed"
                        ? groupData.key.value == ""
                          ? t("Not completed")
                          : moment(groupData.key.value).format("MMM DD, YYYY")
                        : groupData.key.type == "status"
                        ? groupData.key.value == "completed"
                          ? t("Completed")
                          : t("Waiting for sign")
                        : groupData.key.value}
                    </span>
                  </Grid>
                </Grid>
                {groupData.list.map((item, key2) => {
                  return (
                    <Grid
                      key={key2}
                      container
                      className="itemData"
                      spacing={16}
                      style={{ cursor: "Pointer" }}
                    >
                      <div
                        onClick={() => this.gotoVeiwPage(item.id)}
                        style={{ flexDirection: 'column', alignItems: 'center', flex: 3 }}
                      >
                        <div>
                          {item.vendor.name}
                        </div>
                        <div>
                          {item.vendor.email}
                        </div>
                      </div>
                      <div
                        item
                        sm={4}
                        md={2}
                        onClick={() => this.gotoVeiwPage(item.id)}
                        style={{ flexDirection: 'column', alignItems: 'center', flex: 3 }}
                      >
                        <div>
                          {item.notary.name}
                        </div>
                        <div>
                          {item.notary.email}
                        </div>
                      </div>
                      <div
                        item
                        sm={4}
                        md={2}
                        onClick={() => this.gotoVeiwPage(item.id)}
                      >
                        ${" "}
                        {numeral(item.conditional_release_amount).format("0,0")}
                      </div>
                      <div
                        item
                        sm={4}
                        md={2}
                        onClick={() => this.gotoVeiwPage(item.id)}
                      >
                        ${" "}
                        {numeral(item.unconditional_release_amount).format(
                          "0,0"
                        )}
                      </div>
                      <div
                        onClick={() => this.gotoVeiwPage(item.id)}
                      >
                        {
                          item.status === "completed" ?
                            <span className={ classes.verifiedLabel }>
                              {t('Completed')}
                            </span>
                            :
                            <span className={ classes.unverifiedLabel }>
                              {t('Not Completed')}
                            </span>
                        }
                      </div>
                      <div style={{ flex: 3 }}>
                        {moment(item.created_at).format("MMM DD, hh:mm A")}
                      </div>
                      <div style={{ flex: 3 }}>
                        {item.signed_at != null
                          ? moment(item.created_at).format(
                              "MMM DD, hh:mm A"
                            )
                          : t("Not completed") }
                      </div>
                      <div style={{ minWidth: 40, maxWidth: 40 }}>
                        {item.status === "completed" ? (
                            <a
                              className={classes.certificateViewBtn}
                              href={
                                apiUrl +
                                "api/lean_release/download/" +
                                item.id
                              }
                            >
                              <img src="/assets/img/newui/pdf.png" />
                            </a>
                          ) : null}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  /**
   * Change Group by function
   */
  handleChangeGroupBy = async event => {
    let groupBy = event.target.value;
    await this.setState({ groupByFilter: groupBy });
    this.applyFilters();
  };

  /**
   * Change search input value
   */
  queryChanged = async value => {
    await this.setState({ query: value });
    this.applyFilters();
  };

  /**
   * Apply Filter
   */
  applyFilters = () => {
    const { groupByFilter, query, certificates } = this.state;
    let listData = [...certificates];
    if (query.trim().length != 0) {
      let keyword = query.toLocaleLowerCase();
      listData = _.filter(listData, c => {
        let name = c.vendor.name;
        return name.toLowerCase().indexOf(keyword) != -1
            || c.vendor.email.toLowerCase().indexOf(keyword) != -1
            || c.notary.name.toLowerCase().indexOf(keyword) != -1
            || c.notary.email.toLowerCase().indexOf(keyword) != -1;
      });
    }

    let newGroupQuery = null;
    if (groupByFilter === "vendor") {
      newGroupQuery = _.chain(listData).groupBy("vendor.name", item => {
        return item.vendor.id;
      });
    } else if (groupByFilter === "notary") {
      newGroupQuery = _.chain(listData).groupBy("notary.name", item => {
        return item.notary.name;
      });
    } else if (groupByFilter === "sign_email") {
      newGroupQuery = _.chain(listData).groupBy("notary.email", item => {
        return item.notary.name;
      });
    } else if (groupByFilter === "date_created") {
      newGroupQuery = _.groupBy(listData, item => {
        if (!item.created_at) return "";
        return new moment(item.created_at).format("YYYY-MM-DD");
      });
      newGroupQuery = _.chain(newGroupQuery);
    } else if (groupByFilter === "date_completed") {
      newGroupQuery = _.groupBy(listData, item => {
        if (!item.signed_at) return "";
        return new moment(item.signed_at).format("YYYY-MM-DD");
      });
      newGroupQuery = _.chain(newGroupQuery);
    } else if (groupByFilter === "status") {
      newGroupQuery = _.chain(listData).groupBy("status");
    }

    if (newGroupQuery) {
      let newData = newGroupQuery
        .map((value, key) => ({
          key: {
            type: groupByFilter,
            value: key
          },
          list: value
        }))
        .value();
      console.log(newData);
      this.setState({ filteredData: newData });
    }
  };

  gotoVeiwPage = id => {
    window.location.href = "/certificates/view/" + id;
  };

  fetchCertificates = () => {
    this.setState({
      formExpanded: false,
      title: "",
      isDataLoading: true
    });
    const loginStatus = this.props.account.loginStatus;

    const apiUrl = process.env.NODE_ENV === 'production' ? (process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BACKEND_LIVE : process.env.REACT_APP_BACKEND_TEST) : process.env.REACT_APP_DEV_BACKEND;
    return fetch(apiUrl + "api/lean_release/list", {
      method: "POST",
      headers: {
        Authorization: `Token ${loginStatus.token}`,
        "X-CSRFToken": Cookies.get("csrftoken")
      }
    })
      .then(res => res.json())
      .then(async data => {
        const certificates = data.filter(doc => doc.type === this.state.type);
        this.origianlCertificates = data;
        await this.setState({ certificates });
        this.applyFilters();
        await this.setState({ isDataLoading: false });
      });
  };

  uploadIconClicked = () => {
    this.setState({ goToUpload: true });
  };
}

const mapStateToProps = state => {
  return { ...state, account: state.account };
};

const mapDispatchToProps = {};
export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(leanDocumentListPageStyle)(LeanDocumentListPage)));
