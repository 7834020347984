import * as Type from '../actions/types';

const initialState = {
    result: {}
};

export const budgetDelResult = (state = initialState, action) => {
  switch (action.type) {
    case Type.CM_BUDGET_DEL:
      return {
        ...state,
        result: action.payload
      };
    default:
      return state;
  }
};