import { CM_INIT_DATA } from '../actions/types';

const initialState = {
  data: {}
};

export const initialResponse = (state = initialState, action) => {
  switch (action.type) {
    case CM_INIT_DATA:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
