import commonStyle from 'assets/jss/commonStyle'

const setupPageStyle = theme => ({
    ...commonStyle,
    inactiveNav: {
        width: '140px',
        height: '38px',
        border: '1px solid #757575',
        color: '#0072BB',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        fontSize: '1rem',
        marginRight: '30px',
        '&:hover': {
            textDecoration: 'none',
            color: '0072BB',
        }
    },
    activeNav: {
        color: '#fff',
        background: '#1291F9 !important',
        '&:hover': {
            color: '#fff',
        }
    },
    tabLeftWrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 0px',
        alignItems: 'center',
        borderRight: '1px solid #DBDBDB',
        marginRight: '30px',
    },
    activeGeyNav: {
        color: '#fff',
        border: '1px solid #0072BB',
        background: '#0072BB',
        '&:hover': {
            color: '#fff',
        }
    },
    inputLabel: {
        marginBottom: '10px',
        marginTop: '20px',
    },
    input: {
        width: '100%'
    },
    btnSubmit: {
        background: '#1291F9',
        color: 'white',
        height: 35,
        width: '120px',
        display: 'inline-block',
        fontSize: '1.1rem',
    },
    submitContainer: {
        marginTop: '20px',
        textAlign: 'center'
    },
    subFormTitle: {
        color: '#1D8ACF',
        fontSize: '1.4rem',
    },
    subFormContainer: {
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '40px',
    },
    check: {
        color: '#1291F9 !important'
    },
    statBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 30
    },
    statBtn: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 4,
        padding: '25px 0px',
        marginRight: 30,
    },
    statBtnTitle: {
        color: '#fff',
        fontSize: 36,
        textDecoration: 'none',
        '&:hover': {
            color: '#fff',
            textDecoration: 'none'
        }
    },
    statBtnSubtitle: {
        color: '#fff',
        opacity: 0.8,
        fontSize: 13,
        fontWeight: 400,
        textAlign: 'center',
    },
    viewModeToggle: {
        maxHeight: 32,
        padding: '4px 10px',
        background: 'white',
        borderRadius: 25,
        borderColor: 'white',
        borderWidth: 1,
        fontSize: 14,
        cursor: 'pointer',
        opacity: 0.7,
        marginLeft: 5,
        marginRight: 5,
        transition: 'opacity 0.5s, border 0.5s',
        '&.active': {
            border: '1px solid #ABABAB',
            opacity: 1,
        },
        '&:hover': {
            border: '1px solid #5B5B5B',
            opacity: 1,
        }
    },
    budgetContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 12,
        alignItems: 'center',
        '&:nth-child(odd)': {
            background: 'rgba(170, 170, 170, 0.1)'
        },
        '&:nth-child(even)': {
            background: '#fff'
        },
        '& > div': {
            wordBreak: 'break-all',
            paddingLeft: 3,
            paddingRight: 3,
        }
    },
    signUpForm: {
        width: 600,
        background: '#fff',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 7,
        padding: '35px 42px',
        paddingBottom: 17,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
    },
    filterContainer: {
        display: 'flex',
        '& > *': {
            marginLeft: 5,
            marginRight: 5,
        },
        marginTop: 20,
    },
    btnAll: {
        background: 'rgba(194, 194, 194, 0.06)',
        border: '1px solid rgba(18, 145, 249, 0.18)',
        borderRadius: 8,
        width: '100%',

        '&.active': {
            background: '#1291F9',
            color: 'white',
        }
    }
});
export default setupPageStyle;