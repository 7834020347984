import React, { Component } from 'react'
import { connect } from 'react-redux';
import BaseInput from 'components/BaseInput/index.jsx';
import Button from 'components/Button/index.jsx';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import eventsPageStyle from './eventsPageStyle.jsx';
import { confirm } from "../../../components/Confirm/index";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SortBtn from 'components/SortBtn';

import Moment from 'react-moment';

import { userBuyerList, userBuyerStatus, userBuyerDelete, addEvent, getEvents, eventDelete } from '../../../redux/actions/adminActions';
import { getGlobalInitialData } from "redux/actions/commonActions";
import { withTranslation  } from 'react-i18next';

import SearchInputType2 from "components/SearchInputType2";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

class EventsPage extends Component
{
    state = {
        eventAddClicked: false,
        eventAddModalOpen: false,
        eventList: [],
        newEventData: {
            id: 0,
            name: '',
            description: ''
        },
        sortOption: {
            sortBy: 'name',
            sortAsc: null
        },
        query: '',
    }

    constructor(props){
        super(props);
        this.loadData();
    }

    loadData = async () => {
        await this.props.getEvents();
        var res = this.props.commonResult.result;
        if(res.status){
            this.setState({eventList: res.events});
        }else{
            this.setState({eventList: []});
        }
    }

    addEventItem = async () => {
        await this.props.addEvent(this.state.newEventData);
        await this.props.getGlobalInitialData();
        let evtRlt = this.props.eventResult['evtCreateRes'];
        if (evtRlt['status']) {
            this.setState({newEventData: {
                id: 0,
                name: '',
                description: ''
            }});
            this.setState({eventAddClicked: false});
            this.setState({eventAddModalOpen: false});
            this.loadData();
        } else {
            confirm("Error", "Sorry! Backend server error occured! Please try later!").then(
                async () => {
                },
                () => {
                    console.log("Click Canceled");
                }
            );
        }
    }

    handleChangeValue = (e, valueType) => {
        const dataOject = {};
        if (typeof e == 'undefined' || e === null) {
            dataOject[valueType] = "";
        }
        else if (typeof e.value != 'undefined') {
            dataOject[valueType] = e.value;
        }
        else if (typeof e.target.type != 'undefined' && e.target.type == "checkbox") {
            if (typeof this.state.formData[valueType] != 'undefined' && this.state.formData[valueType] == true) {
                dataOject[valueType] = false;
            } else {
                dataOject[valueType] = true;
            }
        } else {
            dataOject[valueType] = e.target.value;
        }
        if (valueType == "category") {
            dataOject["sub_category"] = "";
        }
        this.setState({ newEventData: Object.assign(this.state.newEventData, dataOject) });
    }

    openAddEventModal = (e) => {
        e.preventDefault();
        this.setState({newEventData: {
            id: 0,
            name: '',
            description: ''
        }});
        this.setState({eventAddClicked: true});
        this.setState({eventAddModalOpen: true});
    }

    closeAddEventModal = () => {
        this.setState({eventAddClicked: false});
        this.setState({eventAddModalOpen: false});
    }

    // User Detail Dialog
    showAddEventModal = () => {
        const { classes, t } = this.props;
        if (!this.state.eventAddClicked) return null;

        let title = this.state.newEventData.id == 0 ? 'Add Event' : 'Update Event';

        return (
            <Dialog
                open={this.state.eventAddModalOpen}
                onClose={this.closeUserDetail}
                className={classes.addEventDialog}
                classes={{ paper: classes.dlgContent }}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle id="form-dialog-title">{ t(title) }</DialogTitle>
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            {t('Event Name')} :
                        </Grid>
                        <Grid item xs={12}>
                            <BaseInput type="text" className={classes.inputName} onChange={e => this.handleChangeValue(e, 'name')} value={this.state.newEventData.name} />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                            {t('Description')} :
                        </Grid>
                        <Grid item xs={12}>
                            <textarea
                                id="datepicker"
                                label=""
                                value={this.state.newEventData.description}
                                onChange={e => this.handleChangeValue(e, 'description')}
                                className={classes.inputDescription}
                            >
                                </textarea>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={ classes.btnSave } onClick={this.addEventItem}>
                        {t('Save')}
                    </Button>
                    <Button className={ classes.btnCancel } onClick={this.closeAddEventModal}>
                        {t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    // User approve
    actionEditEvent = async (e, event) => {
        this.setState({newEventData: event});
        this.setState({eventAddClicked: true});
        this.setState({eventAddModalOpen: true});
    }

    // User delete
    actionDeleteUser = async (e, user) => {
        const { t } = this.props;

        confirm("Confirm", t('Are you sure delete this event') + " \"" + user.name + "\"?").then(
            async () => {
                const result = await this.props.eventDelete(user.id);
                if (!result['status']) {
                    confirm("Error", "This event already has budgets associated to it. Please delete them first and try again.").then(
                        async () => {
                        },
                        () => {
                        }
                    );

                    return;
                }

                await this.props.getGlobalInitialData();
                this.loadData();
            },
            () => {
                console.log("Click Canceled");
            }
        );
    }

    showeventList = () => {
        const { classes } = this.props;
        var eventList = this.state.eventList;
        const { sortBy, sortAsc } = this.state.sortOption;

        let filteredEventList = [...eventList];
        filteredEventList.sort((a, b) => {
            if (sortBy == '' || sortAsc == null) {
                return 1;
            }

            let retVal = 1;
            if (sortBy === 'name') {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'description') {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'description') {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                    retVal = -1;
                }
            }
            if (sortBy === 'date') {
                if (a.created_at < b.created_at) {
                    retVal = -1;
                }
            }

            retVal = !sortAsc ? -1 * retVal : retVal;
            return retVal;
        });

        // Filter users
        if (this.state.query != '') {
            filteredEventList = filteredEventList.filter(item => {
                if (item.name.toLowerCase().includes(this.state.query.toLowerCase())
                    || item.description.toLowerCase().includes(this.state.query.toLowerCase())
                ) {
                    return true;
                }
                return false;
            });
        }

        return filteredEventList.map((user, key) => {
            return(
                <div key={key} className={ classes.tableRow }>
                    <div className={ classes.columnName }>
                        <span style={{ marginLeft: '20px' }}>{user.name}</span>
                    </div>
                    <div className={ classes.columnDescription }>
                        <span style={{ marginLeft: '20px' }}>{user.description}</span>
                    </div>
                    <div className={ classes.columnDate }>
                        <span style={{ marginLeft: '20px' }}>
                            <Moment format="MMM, D, YYYY">{user.created_at}</Moment>
                        </span>
                    </div>
                    <div className={ classes.columnActions }>
                        <button onClick={e => this.actionEditEvent(e, user)}>
                            <img src='/assets/img/black-pen.svg' />
                        </button>
                        <button onClick={e => this.actionDeleteUser(e, user)}>
                            <img src='/assets/img/black-trash.svg' />
                        </button>
                    </div>
                    <hr  style={{ position: 'absolute', width: '98%', bottom: 0, left: '1%' }} />
                </div>
            );
        });
    }

    sortOptionChanged = (sortBy, sortAsc) => {
        this.setState({
            sortOption: {sortBy, sortAsc}
        })
    }

    queryChanged = (value) => {
      this.setState({ query: value });
    }

    render(){
        const { classes, t } = this.props;
        const { query, eventList } = this.state;
        const { sortBy, sortAsc } = this.state.sortOption;
        return(
            <div className={ classes.tableContainer }>
                <div className={ classes.addEventWrapper }>
                    <Button onClick={ e => this.openAddEventModal(e) } className={ classes.btnAddEvent }>
                        Add Event <AddCircleIcon />
                    </Button>
                    <div className={`ml-3 -mt-1`}>
                        <SearchInputType2
                            placeholder={t('Search') + "..."}
                            value={query}
                            onChange={(event) => this.queryChanged(event.target.value)}
                            onClose={() => this.queryChanged("")}
                        />
                    </div>
                </div>
                <div className={ classes.tableHeader }>
                    <div className={ classes.columnName }>
                        {t('Event')}
                        <SortBtn sortOptionChanged={this.sortOptionChanged}
                            sortBy={sortBy} isAscSort={sortAsc} sortName={'event'}
                        />
                    </div>
                    <div className={ classes.columnDescription }>
                        {t('Description')}
                        <SortBtn sortOptionChanged={this.sortOptionChanged}
                            sortBy={sortBy} isAscSort={sortAsc} sortName={'description'}
                        />
                    </div>
                    <div className={ classes.columnDate }>
                        {t('Date')}
                        <SortBtn sortOptionChanged={this.sortOptionChanged}
                            sortBy={sortBy} isAscSort={sortAsc} sortName={'date'}
                        />
                    </div>
                    <div className={ classes.columnActions }>
                        {t('Actions')}
                    </div>
                </div>
                <div style={{
                    background: '#fff',
                    minHeight: 400,
                    border: '1px solid rgba(18, 145, 249, 0.18)',
                    borderTopWidth: 0,
                    fontSize: 12,
                    color: '#000',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderBottomLeftRadius: 7,
                    borderBottomRightRadius: 7,
                }}>
                    { this.showeventList() }
                    {
                        eventList.length === 0 &&
                        <div className="flex flex-col w-full items-center pt-5" style={{ color: '#ee2337' }}>
                            <ErrorOutlineIcon
                                style={{ fontSize: "2.8rem", marginRight: "1rem", marginBottom: 15 }}
                            />
                            {t("No events found")}
                            <a
                                href="javascript:void(0)"
                                className="hover:no-underline"
                                style={{
                                    color: 'rgb(29, 138, 207)',
                                    textDecoration: 'underline',
                                    marginTop: 10,
                                    marginBottom: 30,
                                }}
                                onClick={ this.openAddEventModal }
                            >
                                {t('Add Event')}
                            </a>
                        </div>
                    }
                </div>
                { this.showAddEventModal() }
            </div>
        )

    }

}

const mapStateToProps = state => ({
    commonResult: state.commonResult,
    eventResult: state.eventResult
});
const mapDispatchToProps = {userBuyerList, userBuyerStatus, userBuyerDelete, addEvent, getEvents, eventDelete, getGlobalInitialData}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(eventsPageStyle)(EventsPage)));