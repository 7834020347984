import commonStyle from 'assets/jss/commonStyle'

const leanDocumentStyle = theme => ({
    ...commonStyle,
    uploadAttachment: {
        color: '#A30C0C',
        fontSize: '1.4rem',
    },
    addIcon: {
        marginLeft: '10px',
    },
    uploadIconContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    leanTitle: {
        color: '#1D8ACF',
        textAlign: 'center'
    },
    input: {
        width: '100%',
    },
    description: {
        padding: '0px 10px',
        margin: '15px 0px'
    },
    notifyWrapper: {
        margin: '10px 0px'
    },
    btnSubmit: {
        background: '#A30C0C',
        color: 'white',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '13px',
        paddingRight: '13px',
        fontSize: '1.2rem',
        margin: '0px auto'
    }
});
export default leanDocumentStyle;