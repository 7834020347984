const SelectCustomerPageStyle = theme => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        background: "url('/assets/img/bgSignUp.jpg')",
        backgroundPosition: 'top center',
        backgroundRepeat:   'no-repeat',
        backgroundSize:   'cover',
    },
    container: {
        width: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    formContainer: {
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.41)',
        padding: '40px 70px',
        borderRadius: '6px',
        background: 'white',
    },
    logoContainer: {
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '30px'
    },
    customerContainer: {
        minHeight: '20rem',
        maxHeight: '30rem',
        overflowY: 'auto',
        padding: '0.8rem 1rem 0.3rem 1rem',
        '& ul': {
            listStyle: 'none',
            paddingLeft: '0',
            '& li': {
                '& a': {
                    backgroundColor: '#a30c0c',
                    textDecoration: 'none',
                    color: 'white',
                    marginBottom: '1rem',
                    width: '100%',
                    display: 'block',
                    padding: '0.5rem 1rem',
                    textAlign: 'center',
                    borderRadius: '0.5rem',
                    '&:hover': {
                        backgroundColor: '#c14b4b',
                        color: '#EEEEEE'
                    }
                }
            }
        }
    }
});
export default SelectCustomerPageStyle;